angular.module('Cockpit.State.Dgda.ShippingCounter', [
  'ui.router',
  'Cockpit.State.Settings',
  'Cockpit.Service.ShippingCounter'
])

.config(function config($stateProvider) {
	$stateProvider.state('private.orders.shippingcounter', {
		url: '/shippingcounter',
		views: {
			'@private': {
				controller: 'CockpitShippingCounterController',
				templateUrl: 'states/dgda/orders/shippingcounter/shippingcounter.tpl.html'
			}
		},
		data: {
			pageTitle: 'Shipping Counter Settings'
		}
	})

	;
})

.run(function (Navigation) {
	Navigation.sidebar.register('private.orders', 'General', [
		{
			Label: "Shipping Counter",
			State: 'private.orders.shippingcounter',
			Groups: ["admin", "support"]
		}
	]);
})

.controller('CockpitShippingCounterController', function CockpitShippingCounterController($scope, ShippingCounter, Language) {
	$scope.data = ShippingCounter.get({deliveryCountry: "da-dk"}, function(){},function(){
		$scope.data = {
			deliveryCountry: 'da-dk'
		}
	});
	$scope.selectedLanguage = null;
	$scope.days = [
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
		"Sunday"
	];
	$scope.countries = [
		{
			"name": "Denmark",
			"languageCode": "da-dk"
		},
		{
			"name": "Norway",
			"languageCode": "nb-no"
		},
		{
			"name": "Sweden",
			"languageCode": "sv-se"
		},
		{
			"name": "Germany",
			"languageCode": "de-de"
		}
	]
	$scope.activeCountry = 'da-dk';
	$scope.setCountry = function setTab(country){
		$scope.data = {};

		$scope.data = ShippingCounter.get({deliveryCountry: country.languageCode}, function(v){
			$scope.activeCountry = country.languageCode;
		}, function(){
			$scope.data = {
				deliveryCountry: country.languageCode
			};
			$scope.activeCountry = country.languageCode;
		});
	}
	$scope.languages = Language.query(function(d){
		$scope.selectedLanguage = d[0];
	});
	$scope.setLanguage = function(l){
		$scope.selectedLanguage = l;
	};
	$scope.saveMessage = false;

	$scope.newOverride = {
		Date: '',
		Message: '',
		IsNew: true
	};

	$scope.addOverride = function addShippingCounterOverride(country) {
		if ($scope.newOverride.date === '') {
			return;
		}
		if ($scope.newOverride.message === '') {
			return;
		}

		if(!angular.isDefined($scope.data[country.languageCode])){
			$scope.data[country.languageCode] = {
				overrides: []
			};
		}

		$scope.data[country.languageCode].overrides.push($scope.newOverride);

		$scope.newOverride = {
			date: '',
			message: '',
			isNew: true
		};
	};

	$scope.deleteOverride = function deleteShippingCounterOverride(o) {
		var index = $scope.data.overrides.indexOf(o);

		if (index === -1) {
			return;
		}

		$scope.data.overrides.splice(index, 1);
	};

	$scope.process = function processShippingCounter() {
		ShippingCounter.save($scope.data, function () {
			for (var i in $scope.data.overrides) {
				$scope.data.overrides[i].isNew = false;
			}

			$scope.saveMessage = true;
		});
	};
})

;
