angular.module('Cockpit.State.Dgda', [
    'ui.router',
    'Cockpit.State.Dgda.Orders.Add',
    'Cockpit.State.Dgda.Printfile',
    'Cockpit.State.Printfile.DataVerification',
    'Cockpit.State.Dgda.Printfile.Status',
    'Cockpit.State.Dgda.ShippingCounter',
    'Cockpit.State.Dgda.Ease',
    'Cockpit.State.Dgda.GiftCardPreview',
    'Cockpit.State.Dgda.Refunds',
    'Cockpit.State.ProductRankingWeights',
    'Cockpit.State.Dgda.Faq',
  'Cockpit.State.Dgda.Discounts'
])

.config(function config($stateProvider) {
    $stateProvider

        .state('private.dgda', {
        abstract: true,
        template: '<ui-view/>'
    });
});