angular.module('Cockpit.Service.Dgda.OpenGiftCards', ['ngResource'])
  .factory('OpenGiftCard', ['$resource',
    function ($resource) {
      return $resource(Config.ApiV2 + 'opengiftcards/:code', {code: '@code'}, {});
    }])

  .factory('OpenGiftCardOrder', ['$resource',
    function ($resource) {
      return $resource(Config.ApiV2 + 'opengiftcards/:code/orders', {}, {});
    }])

;