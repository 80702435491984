var resolvers = window.resolvers || {};
resolvers.currenciesResolver = ['$q', 'Currency', function ($q, Currency) {
  var d = $q.defer();

  Currency.query(function (res) {
    d.resolve(res);
  }, function (err) {
    d.reject();
  });

  return d.promise;
}];
