angular.module('Cockpit.State.Dgda.Faq', [
    'ui.router',
    'Cockpit.Service.Dgda.Faq'
])

.config(function config($stateProvider) {
  $stateProvider.state('private.settings.faq', {
    url: 'faqsubjects/',
    controller: 'CockpitFaqSubjectsController',
    templateUrl: 'states/dgda/settings/faq/faq.tpl.html',
    data: {
      pageTitle: 'Faq Subjects'
    }
  })
      .state('private.settings.faqadd', {
        url: 'faqsubjects/add',
        resolve: {
          subjectData: [function () {
            return {
              Id: -1,
              Text: '',
              Order: 0
            };
          }]
        },
        controller: 'CockpitFaqSubjectsAddController',
        templateUrl: 'states/dgda/settings/faq/subject.tpl.html',
        data: {
          pageTitle: 'Faq Subjects'
        }
      })
      .state('private.settings.faqedit', {
        url: '/editsubject/{SubjectId}',
        resolve: {
          subjectData: ['$q', '$stateParams', 'Faq', function ($q, $stateParams, Faq) {
            var d = $q.defer();

            Faq.getSubject({ id: $stateParams.SubjectId }, function (o) {
              d.resolve(o);
            }, function () {
              console.log("Error loading subject");
            });

            return d.promise;
          }]
        },
        controller: 'CockpitFaqSubjectsEditController',
        templateUrl: 'states/dgda/settings/faq/editSubject.tpl.html',
        data: {
          pageTitle: 'Faq Subjects'
        }
      })

  ;
})

.run(function (Navigation) {
  Navigation.sidebar.register('private.settings', 'Truestory', [{
    Label: "Faq subjects",
    State: 'private.settings.faq',
    Groups: ["admin"]
  }]);
})

.controller('CockpitFaqSubjectsController', function CockpitFaqSubjectsController($scope, $state, Faq) {
  $scope.subjects = [];

  Faq.query(null, function (result) {
    $scope.subjects = result;
  });

  $scope.deleteSubject = function deleteSubject(id) {
    Faq.delete(id, function (d) {
      $scope.subjects = Faq.query();
    }, function (err) {
      console.log('error saving faq subject: ', err);
    });
  }
})

.controller('CockpitFaqSubjectsAddController', function CockpitFaqSubjectsAddController($scope, $state, Faq, subjectData) {
  $scope.subject = subjectData;

  $scope.process = function processSaveSubject() {
    Faq.save($scope.subject, function (d) {
      $state.go('private.settings.faq');
    }, function (err) {
      console.log('error saving faq subject: ', err);
    });
  }

})


.controller('CockpitFaqSubjectsEditController', function CockpitFaqSubjectsAddController($scope, $state, Faq, subjectData) {
  $scope.subject = subjectData;

  $scope.process = function processSaveSubject() {
    Faq.edit($scope.subject, function (d) {
      $state.go('private.settings.faq');
    }, function (err) {
      console.log('error saving faq subject: ', err);
    });
  }

})

;
