angular.module('Cockpit.State.Reviews', [
    'ui.router',
    'Cockpit.Service.PimProduct',
    'Cockpit.Service.Review'
])

.config(function config($stateProvider) {
    $stateProvider.state('private.products.reviews', {
        url: '/reviews',
        views: {
            '@private': {
                controller: 'CockpitReviewsController',
                templateUrl: 'states/products/reviews/reviews.tpl.html',
            }
        },
        data: {
            pageTitle: 'Reviews'
        }
    })

    .state('private.products.reviews.add', {
        url: '/add',
        views: {
            '@private': {
                controller: 'CockpitAddEditReviewController',
                templateUrl: 'states/products/reviews/addreview.tpl.html',
            }
        },
        data: {
            pageTitle: 'Add Review'
        },
        resolve: {
          theLanguages: resolvers.languagesResolver
        }
    })

    .state('private.products.reviews.edit', {
        url: '/edit/:Id',
        views: {
            '@private': {
                controller: 'CockpitAddEditReviewController',
                templateUrl: 'states/products/reviews/editreview.tpl.html',
            }
        },
        data: {
            pageTitle: 'Edit Review'
        },
        resolve: {
          theLanguages: resolvers.languagesResolver
        }
    })

    ;
})

.run(function(Navigation) {
    Navigation.sidebar.register('private.products', 'Reviews', [{
            Label: 'Reviews',
            State: 'private.products.reviews',
            Groups: ["admin", "support", "user"]
        },
        {
            Label: "Add Review",
            State: 'private.products.reviews.add',
            Groups: ["admin", "support", "user"]
        }
    ]);
})

.controller('CockpitReviewsController', function CockpitReviewsController($scope, Review, PimProduct) {
    $scope.reviews = [];
    Review.query({ status: 'new' }, function(result) {
        $scope.reviews = result;
    });

    $scope.data = {
        Product: null,
        Status: null
    };

    $scope.statuses = [
        { id: "new", name: "New" },
        { id: "approved", name: "Approved" },
        { id: "unapproved", name: "Unapproved" },
        { id: "reportedasabuse", name: "Reported As Abuse" }
    ];

    if ('allProducts' in window.sessionStorage) {
      $scope.products = JSON.parse(window.sessionStorage.getItem('allProducts'));
    } else {
      $scope.isLoadingProducts = true;
      $scope.products = PimProduct.query(
        function successCb(products) {
          $scope.isLoadingProducts = false;
          $scope.products = products;
          window.sessionStorage.setItem('allProducts', JSON.stringify(products));
        },
        function errorCb() {
          $scope.isLoadingProducts = false;
          $scope.products = [];
          alert('could not fetch products. Try again.');
        }
      );
    }
    // $scope.products = Product.query(function(products) {
    //     var map = {};
    //     var productSkus = [];

    //     // Init typeahead
    //     $('#inputProduct').typeahead({
    //         source: function(query, process) {
    //             map = {};
    //             productSkus = [];

    //             $.each(products, function(i, product) {
    //                 map[product.Sku + " - " + product.Name] = product;
    //                 productSkus.push(product.Sku + " - " + product.Name);
    //             });

    //             process(productSkus);
    //         },
    //         updater: function(item) {
    //             $scope.$apply(function() {
    //                 $scope.data.Product = map[item];
    //             });
    //             return item;
    //         },
    //         matcher: function(item) {
    //             return (item.toLowerCase().indexOf(this.query.trim().toLowerCase()) != -1);
    //         },
    //         /*sorter: function (items) {
    //         	// implementation
    //         },*/
    //         highlighter: function(item) {
    //             var regex = new RegExp('(' + this.query + ')', 'gi');
    //             return item.replace(regex, "<strong>$1</strong>");
    //         }
    //     });
    // });

    $scope.doSearch = function doSearch() {
        var sku = ($scope.data.sku != null ? $scope.data.sku : '');
        var statusId = ($scope.data.Status == null ? 'new' : $scope.data.Status.id );
        $scope.reviews = Review.query({ sku: sku, status: statusId });
    };

    $scope.approve = function ApproveReview(id) {
        Review.approve({ id: id }, function() {
            $scope.doSearch();
        });
    };

    $scope.unapprove = function UnapproveReview(id) {
        Review.unapprove({ id: id }, function() {
            $scope.doSearch();
        });
    };

    $scope.delete = function DeleteReview(id) {
        Review.delete({ id: id }, function() {
            $scope.doSearch();
        });
    };
})

.controller('CockpitAddEditReviewController', function CockpitAddReviewController($scope, $stateParams, PimProduct, Review, theLanguages) {
    if ('allProducts' in window.sessionStorage) {
      $scope.products = JSON.parse(window.sessionStorage.getItem('allProducts'));
    } else {
      $scope.isLoadingProducts = true;
      $scope.products = PimProduct.query(
        function successCb(products) {
          $scope.isLoadingProducts = false;
          $scope.products = products;
          window.sessionStorage.setItem('allProducts', JSON.stringify(products));
        },
        function errorCb() {
          $scope.isLoadingProducts = false;
          $scope.products = [];
          alert('could not fetch products. Try again.');
        }
      );
    }

    $scope.languages = theLanguages;

    $scope.model = {
        id: 0,
        sku: null,
        author: "",
        email: "",
        age: "",
        title: "",
        text: "",
        tips: "",
        wouldRecommend: true,
        avgRating: 5,
        generalRating: 5,
        staffRating: 5,
        guidanceRating: 5,
        cultureCode: null
    };

    if (typeof($stateParams.Id) !== "undefined") {
        $scope.model = Review.get({ id: $stateParams.Id });
    }

    $scope.alert = {
        Show: false,
        Type: 'success',
        Message: ''
    };

    $scope.process = function ProcessAddReview() {

      $scope.model.generalRating = parseInt($scope.model.generalRating);
      $scope.model.staffRating = parseInt($scope.model.staffRating);
      $scope.model.guidanceRating = parseInt($scope.model.guidanceRating);
      $scope.model.avgRating = ($scope.model.generalRating + $scope.model.staffRating + $scope.model.guidanceRating) / 3;

        Review.save($scope.model, function() {
            // Reset the model, if we're not
            // editing a product review.
            if ($scope.model.id === 0) {
                $scope.model = {
                    sku: null,
                    author: "",
                    email: "",
                    age: "",
                    title: "",
                    text: "",
                    tips: "",
                    wouldRecommend: true,
                    avgRating: 5,
                    generalRating: 5,
                    staffRating: 5,
                    guidanceRating: 5,
                    cultureCode: null
                };
            }

            $scope.alert.Message = 'Your review has been saved and approved';
            $scope.alert.Show = true;
        });
    };
})

;
