angular.module('Cockpit.Directive.LocalizedInput', [

])
  .directive('cpLocalizedInput', function (LocalizedInputData) {
    return {
      restrict: 'E',
      scope: {
        languages: '=',
        model: '=?',
        ngRequired: '=',
        type: '@',
        name: '@formName',
        maxlength: '@'
      },
      templateUrl: 'directives/localized-input/input.tpl.html',
      link: function ($scope){
        $scope.selectedLanguage = null;

        // Show first language on the array if it's not empty 
        if( $scope.languages.length > 0 ) {
          LocalizedInputData.showLanguage($scope.languages[0]);
        }

        $scope.$watch(function(){
          return LocalizedInputData.getLanguageToShow()
        }, function(v){
          if(!angular.isObject($scope.model)){
            $scope.model = {};
          }

          if(v !== null && angular.isDefined(v) && !angular.isDefined($scope.model[v.languageCode])){
            $scope.model[v.languageCode] = "";
          }

          $scope.selectedLanguage = v;
        })

        $scope.setLanguage = function(l){
          LocalizedInputData.showLanguage(l);
        };
      }
    };
  })

  .factory('LocalizedInputData', [
    function() {
      var languageToShow = null;

      return {
        getLanguageToShow: function(){
          return languageToShow;
        },
        showLanguage: function(language){
          languageToShow = language;
        }
      };
    }
  ])

;
