angular.module('Cockpit.State.Tiles', [
  'ui.router',
  'Cockpit.Service.Tiles',
  'Cockpit.State.Tiles.AddEdit',
  'Cockpit.Directive.ConvertToNumber',
  'Cockpit.Service.PimProduct'
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.products.tiles', {
      url: '/tiles',
      views: {
        '@private': {
          controller: 'TilesController',
          templateUrl: 'states/products/tiles/tiles.tpl.html',
        }
      },
      data: {
        pageTitle: 'Tiles Settings'
      },
      resolve: {
        theProducts: ['$q', 'PimProduct', function ($q, PimProduct) {
          var d = $q.defer();

          PimProduct.query(function (res) {
            d.resolve(res);
          }, function (err) {
            d.reject();
          });

          return d.promise;
        }],
        theLanguages: ['$q', 'Language', function ($q, Language) {
          var d = $q.defer();

          Language.query(function (res) {
            d.resolve(res);
          }, function (err) {
            d.reject();
          });

          return d.promise;
        }]
      }
    });
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.products', 'General', [
      {
        Label: "Custom Tiles",
        State: 'private.products.tiles',
        Groups: ['admin']
      }
    ]);
  })

  .controller('TilesController', function TilesController($scope, Tiles, $state, theProducts, theLanguages) {
    $scope.languages = theLanguages;
    $scope.products = theProducts;
    $scope.currentLanguageCode = 'da-dk';
    $scope.tiles = [];
    $scope.isLoading = true;
    $scope.currentSortColumn = 'title';
    $scope.isReverseSort = false;
    initTiles();

    function initTiles() {
      Tiles.query().$promise.then(function (tiles) {
        $scope.tiles = tiles;
        $scope.isLoading = false;
      })
    }

    $scope.getProductName = function (sku) {
      const product = $scope.products.find(function (p) {
        return p.sku === sku
      });

      if (!product) {
        return sku;
      }

      return sku + ' (' + product.name + ')';
    }

    $scope.addOrEdit = function (id) {
      if (id && id > 0) {
        $state.go('private.products.tiles.edit', { id: id });
      } else {
        $state.go('private.products.tiles.add');
      }
    }

    $scope.delete = function (tile) {
      if (confirm('Are you sure you want to delete: ' + tile.title + '?')) {
        Tiles.delete({ id: tile.id }, function (res) {
          $scope.tiles = Tiles.query();
        });
      }
    }

    $scope.positionSorter = function (tile) {
      const position = tile.positions.split(',')[0];
      return parseInt(position);
    }

    $scope.sortByColumn = function (column) {
      if ($scope.currentSortColumn === column) {
        $scope.isReverseSort = !$scope.isReverseSort;
      } else {
        $scope.isReverseSort = false;
      }
      $scope.currentSortColumn = column;
    }

    $scope.getClass = function (column) {
      if ($scope.currentSortColumn === column) {
        return $scope.isReverseSort ? 'arrow down' : 'arrow up';
      }
      return '';
    }
  })

  ;
