angular.module('TS.Refunds.Service', ['ngResource'])
	.factory('Refund', ['$resource',
	function ($resource) {
		return $resource(Config.GlobalApi + 'refunds/:id/:action', {}, {
			complete: { method: 'POST', params: { action: 'complete', id: '@id' }, hasBody: false },
      deny: { method: 'POST', params: { action: 'deny', id: '@id' }, hasBody: false },
      update: {method: 'PUT' },
      failed: {method: 'PUT', url: Config.GlobalApi + 'refunds/failed'},
      batchUpdate: { method: 'POST', hasBody: true }
		});
	}]);