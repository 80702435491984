angular.module('Cockpit.State.Products', [
  'Cockpit.Service.Navigation',
  'Cockpit.State.ProductCodes',
  'Cockpit.State.Tiles',
  'Cockpit.State.CallOuts'
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.products', {
      url: '/products',
      controller: 'ProductsController',
      templateUrl: 'states/products/products.tpl.html',
      data: {
        pageTitle: 'Products'
      }
    })
    ;
  })

  .run(function (Navigation) {
    Navigation.primary.register("Products", "private.products");
  })

  .controller('ProductsController', function ProductsController() {

  })
;
