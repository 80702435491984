angular
  .module("Cockpit.Service.LifePeaksGiftcard", ["ngResource"])
  .factory("LifePeaksGiftcard", [
    "$resource",
    function ($resource) {
      return $resource(
        Config.CockpitApi + "/experimental/orders/gift-cards/lifepeaks",
        {},
        {
          get: { method: "GET" },
          cancel: {
            method: "POST",
            url:
              Config.CockpitApi + "/experimental/orders/gift-cards/lifepeaks/:id/cancel",
            params: {
              id: "@id"
            },
          },
          create: {
            method: "POST",
            url: Config.CockpitApi + "/experimental/orders/:orderNumber/gift-cards/lifepeaks",
            params: {
              orderNumber: "@orderNumber"
            },
          }
        }
      );
    },
  ]);
