angular.module('Cockpit.State.Settings.FooterWidgets', [
  'ui.router',
  'Cockpit.Service.FooterWidgets'
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.settings.footer-widgets', {
      url: 'footerwidgets',
      controller: 'FooterWidgetSettingsController',
      templateUrl: 'states/settings/footerwidgets/footer-widgets.tpl.html',
      data: {
        pageTitle: 'Footer Widget Settings'
      },
      resolve: {
        languages: resolvers.languagesResolver
      }
    })
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.settings', 'Footers', [
      {
        Label: 'Footer Widgets',
        State: 'private.settings.footer-widgets',
        Groups: ['admin']
      }
    ])
  })

  .controller('FooterWidgetSettingsController', function FooterWidgetSettingsController($scope, FooterWidgets, languages) {
    $scope.isProcessing = false;

    $scope.refresh = function refreshFooterWidgets() {
      FooterWidgets.query(function (widgets) {
        $scope.widgets = widgets
      })
    }

    $scope.delete = function (id) {
      $scope.isProcessing = true;

      FooterWidgets.delete({ id: id }, function () {
        $scope.isProcessing = false;
        $scope.refresh();
      })
    }

    $scope.refresh();
  })
