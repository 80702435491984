angular
  .module("Cockpit.State.ProductCodes.Madklubben", [
    "ui.router",
    "Cockpit.Service.LifePeaksGiftcard",
    "Cockpit.Modal.ProductCodes.MadklubbenCancelCode",
    "Cockpit.State.ProductCodes.MadklubbenCreateCode",
  ])
  .config(function config($stateProvider) {
    $stateProvider.state("private.products.codes.madklubben", {
      url: "/madklubben",
      views: {
        "@private": {
          controller: "CockpitMadklubbenCodesController",
          templateUrl: "states/products/codes/madklubben/index.tpl.html",
        },
      },
      data: {
        pageTitle: "Madklubben Codes",
      },
    });
  })
  .controller(
    "CockpitMadklubbenCodesController",
    function CockpitEventsController($scope, LifePeaksGiftcard, $uibModal, $timeout) {
      $scope.search = {};
      $scope.isLoading = true;
      $scope.filtered = [];
      var giftcards = [];
      $scope.allGiftcards = [];
      $scope.isDownloading = false;

      var convertToCsv = function (giftcards) {
        var csv = "Order number,Created at,Code, Amount, Remaining amount, Status\n";
        giftcards.forEach(function (g) {
          csv += g.orderNumber + "," + g.createdAt + "," + g.code + "," + g.amount + "," + g.remainingAmount + "," + (g.status === "Paid" ? "Active" : g.status) + "," + "\n";
        });
        // Create a Blob from the CSV string
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        // Create a URL for the Blob
        return URL.createObjectURL(blob);
      };

      $scope.downloadReport = function () {
        $scope.isDownloading = true;
        var url = convertToCsv($scope.allGiftcards);
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          link.setAttribute("href", url);

          var date = new Date();
          var timestamp = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate() + "-" + date.getHours() + "" + date.getMinutes() + "" + date.getSeconds();
          link.setAttribute("download", "madklubben-codes-reports-" + timestamp +".csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          $timeout(function () {
            $scope.isDownloading = false;
          }, 0)
        }
      };

      var fetchCodes = function () {
        $scope.filtered = giftcards = [];
        $scope.isLoading = true;
        LifePeaksGiftcard.get(
          function successCb(res) {
            if (res.items) {
              res.items.forEach(function (i) {
                const updated = i.giftCards.map(function (g) {
                  g.orderNumber = i.orderNumber;
                  return g;
                });
                // assign response to both filtered and giftcards
                $scope.filtered = $scope.allGiftcards =  giftcards = giftcards.concat(updated);
              });
            }

            $scope.isLoading = false;
          },
          function errorCb(err) {
            console.log("Error =>", err);
            $scope.isLoading = false;
          }
        );
      };

      fetchCodes();

      $scope.handleSearch = function () {
        $scope.filtered = giftcards.filter(function (g) {
          return (
            g.orderNumber
              .toLowerCase()
              .includes($scope.search.q.toLowerCase()) ||
            g.code.includes($scope.search.q)
          );
        });
      };

      $scope.handleCancel = function (id) {
        var order = giftcards.find(function (g) {
          return g.id === id;
        });
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: "states/products/codes/madklubben/cancel.tpl.html",
          resolve: {
            order: order,
          },
          controller: "MadklubbenCancelCodeCtl",
        });

        modalInstance.result.then(function (res) {
          if (!res) {
            return;
          }
          if (res.didCancelCode) {
            fetchCodes();
          }
        });
      };
    }
  );
