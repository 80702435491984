angular.module('Cockpit.State.Mademyday.Texts', [
  'ui.router',
  'Cockpit.Service.Mademyday.Texts'
])

.config(function config($stateProvider) {
	$stateProvider.state('private.settings.texts', {
		url: '/texts',
		views: {
			'@private': {
				controller: 'CockpitMademydayTextsController',
				templateUrl: 'states/mademyday/settings/texts/texts.tpl.html',
			}
		},
		data: {
			pageTitle: 'Filters'
		}
	})

	;
})

.run(function (Navigation) {
	Navigation.sidebar.register('private.settings', 'mademyday', [
		{
			Label: 'Texts',
			State: 'private.settings.texts',
			Groups: ["admin"]
		}
	]);
})

.controller('CockpitMademydayTextsController', function CockpitMademydayTextsController($scope, MmdTexts) {
	$scope.texts = MmdTexts.get();

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.process = function ProcessSaveTexts() {
		$scope.alert.Show = false;
		
		$scope.texts.$save(function () {
			$scope.alert.Type = "success";
			$scope.alert.Message = "Your texts have been saved :-)";
			$scope.alert.Show = true;
		}, function () {
			$scope.alert.Type = "warning";
			$scope.alert.Message = "An error occured while saving your texts.";
			$scope.alert.Show = true;
		});
	};
})

;