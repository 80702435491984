angular.module('Cockpit.Directive.CampaignList', [

])
    .directive('cpCampaignList', function (Campaign) {
        return {
            restrict: 'E',
            scope: {
                campaigns: '=',
                showActions: '&',
                refresh: '&'
            },
            templateUrl: 'directives/campaign-list/list.tpl.html',
            link: function ($scope){
                $scope.loading = false;
                $scope.delete = function(id){
                  if( window.confirm('Are you sure you want to delete this campaign?')  ) {
                    $scope.loading = true;
                    Campaign.delete({id: id}, function(){
                      $scope.refresh();
                      $scope.loading = false;
                    });
                  } else {
                    $scope.loading = false;
                  }

                };

                $scope.prioritize = function priorizeCampaign(id, direction){
                    Campaign.prioritize({id: id, direction: direction}, {}, function(){
                        $scope.refresh();
                    })
                };
            }
        };
    })

;
