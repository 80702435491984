angular
  .module("Cockpit.Directive.BookingDetails", [
    "Cockpit.Service.Bookings",
    "Cockpit.Modal.BookingDetails",
    "Cockpit.Modal.BookingCancel"
  ])
  .directive("cpBookingDetails", function (Bookings, $uibModal) {
    return {
      restrict: "E",
      scope: {
        order: "<",
        orderLine: "<",
      },
      templateUrl: "directives/booking-details/details.tpl.html",
      link: function ($scope) {
        const orderData = $scope.order;
        $scope.booking = null;
        $scope.shouldReFetch = false;
        $scope.bookingNotFound = false;
        $scope.disableCancel = false;

        $scope.handleRefetch = function() {
          $scope.loading = true;
          Bookings.get(
            {
              orderNumber: $scope.order.OrderNumber,
            },
            function (data) {
              $scope.loading = false;
              $scope.booking = data;
              $scope.shouldReFetch = false;
              $scope.bookingNotFound = false;
              $scope.disableCancel = data.bookingStatus.toLowerCase() == "cancelled";
            },
            function(err) {
              $scope.loading = false;
              if( err.status === 404 ) {
                $scope.bookingNotFound = true;
                return;
              }

              if( err.status === 500 ) {
                $scope.shouldReFetch = false;
                // alert("Cannot fetch booking details. " + (err.data && err.data.data ? "Endpoint returns with the following error: " + err.data.data : "") + ". Please contact tech team.");
                $scope.errorMessage = err.data && err.data.data ? err.data.data : "Cannot fetch booking details. Please contact tech team.";

                return;
              }
              $scope.shouldReFetch = true;
              console.log("Bookings.get =>", err);
            }
          );
        }

        $scope.handleRefetch();

        $scope.bookingCode =
          orderData.OrderLines &&
          orderData.OrderLines[0] &&
          orderData.OrderLines[0].Properties.bookingCode
            ? orderData.OrderLines[0].Properties.bookingCode
            : null;

        var langCode = orderData.LanguageCode
          ? orderData.LanguageCode.toLowerCase()
          : "da-dk";
        var stagingUrlPrefix =
          window.location.hostname.indexOf("staging") > -1 ? "staging." : "";
        if ($scope.bookingCode) {
          var mainDomain = "";
          switch (langCode) {
            case "sv-se": {
              mainDomain = "https://" + stagingUrlPrefix + "truestory.se";
              break;
            }
            case "nb-no": {
              mainDomain = "https://" + stagingUrlPrefix + "truestory.no";
              break;
            }
            default: {
              mainDomain = "https://" + stagingUrlPrefix + "truestory.dk";
              break;
            }
          }
          var currentBookingBaseUrl = mainDomain + "/booking/";
          $scope.bookingLoginLink = currentBookingBaseUrl + $scope.bookingCode;
        }

        $scope.openBookingDetailsModal = function () {
          if (!$scope.booking) {
            return;
          }
          var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: "directives/booking-details/modal.tpl.html",
            resolve: {
              booking: $scope.booking,
            },
            windowClass: "booking-modal-wrap",
            controller: "BookingDetailsModalController",
          });
        };

        $scope.openBookingCancelModal = function () {
          if (!$scope.booking) {
            return;
          }

          var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: "directives/booking-details/modal/cancel.tpl.html",
            resolve: {
              TheBookingData: {
                orderNumber: $scope.order.OrderNumber,
                bookingId: $scope.booking.bookingId,
              },
            },
            windowClass: "booking-cancel-modal",
            controller: "BookingCancelModalController",
          });

          modalInstance.result.then(function(res) {
            if( !res ) {
              return;
            }
            if( res.didCancelBooking ) {
              $scope.handleRefetch();
            }
          })
        };
      },
    };
  });
