angular
  .module("Cockpit.Modal.ProductCodes.MadklubbenCancelCode", [
    "ui.router",
    "Cockpit.Service.LifePeaksGiftcard",
    "Cockpit.Service.OrderNote",
  ])
  .controller(
    "MadklubbenCancelCodeCtl",
    function MadklubbenCancelCodeCtl(
      $scope,
      order,
      $uibModalInstance,
      LifePeaksGiftcard,
      OrderNote
    ) {
      $scope.close = function () {
        $uibModalInstance.close();
      };

      var createCancelledCodeNote = function (code, orderNumber, callback) {
        var orderNote = "Cancelled a Madklubben code: " + code + ".";
        OrderNote.save(
          { orderNumber: orderNumber },
          {
            note: orderNote,
          },
          function (res) {
            callback && typeof callback === "function" && callback();
          },
          function (err) {
            alert("Error saving note: ", err);
            callback && typeof callback === "function" && callback();
          }
        );
      };


      $scope.cancelCode = function () {
        LifePeaksGiftcard.cancel(
          { id: order.id },
          null,
          function successCb() {
            createCancelledCodeNote(order.code, order.orderNumber, function() {
              $uibModalInstance.close({ didCancelCode: true });
            })
          },
          function errorCb() {
            alert(
              "Error while trying to cancel code. Please try again, or contact tech team if problem persists."
            );
          }
        );
      };
    }
  );
