angular.module('Cockpit.State.Printfile.DataVerification', [
  'ui.router',
  'Cockpit.Service.Dgda.Printfile',
  'Cockpit.PrintFile.Shared'
])

  .config(function ($stateProvider) {
    $stateProvider.state('private.orders.printfile.dataverification', {
      url: '/printfile/{exportLogId}/verify?step',
      views: {
        "@private": {
          controller: 'CockpitDgdaExportPrintfileDataVerificationController',
          templateUrl: 'states/dgda/orders/printfile/printfile-dataverification.tpl.html'
        }
      },
      data: {
        pageTitle: 'Export print-file verify'
      }
    });
  })

  .controller('CockpitDgdaExportPrintfileDataVerificationController', function ($scope, $state, $stateParams, ExportDgdaPrintfile, PrintFileLog, PrintFileHelper) {
    $scope.isLoading = false;
    $scope.exportLogId = $stateParams.exportLogId;
    $scope.countries = PrintFileHelper.countries;
    $scope.isAddingOrder = false;
    $scope.orderToAdd = '';
    $scope.step = $stateParams.step ? $stateParams.step : 2; // default start step is 2
    $scope.personalizedForms = [];
    $scope.deliveryForms = [];
    $scope.errorData = null;
    var currentPrintFileOrderLogs = [];
    var ordersToSave = [];
    var orderNumbers = [];

    getExportLog();

    function getExportLog() {
      $scope.isLoading = true;

      PrintFileLog.getExportLogStatus({ exportLogId: $stateParams.exportLogId }, function (response) {

        $scope.currentPrintFileLog = response.printFileLog;
        currentPrintFileOrderLogs = response.printFileOrderLogs;

        if (!$scope.orders || $scope.orders.length === 0) {
          getOrders();
          return;
        }

        $scope.isLoading = false;
      }, function (err) {
        console.log(err);
        $scope.isLoading = false;
        alert('Failed to get export log. Please try refreshing page again.');
      })
    }

    function getOrders() {
      $scope.orders = ExportDgdaPrintfile.query(PrintFileHelper.getExportDtoFromPrintFileLog($scope.currentPrintFileLog), function (response) {
        $scope.initialOrders = angular.copy(response);
        $scope.isLoading = false;
      }, function (err) {
        $scope.isLoading = false;
        alert("Could not load orders... Please try refreshing page again.");
        $scope.errorData = err;
      })
    }

    $scope.isOrderPreviouslyShipped = function (order) {
      var orderLog = currentPrintFileOrderLogs.find(function (x) { return x.orderNumber === order.OrderNumber });
      if (!orderLog) {
        console.log(order.OrderNumber + ' was not in export log');
        return;
      }

      return orderLog.isPreviouslyShipped;
    }

    $scope.addOrder = function addOrder() {
      if ($scope.orderToAdd === "") {
        return;
      }
      $scope.errorData = null;
      $scope.isAddingOrder = true;

      ExportDgdaPrintfile.get({ orderNumber: $scope.orderToAdd }, function (data) {
        for (var i = 0; i < $scope.orders.length; i++) {
          if (data.OrderNumber === $scope.orders[i].OrderNumber) {
            alert("The order is already included!");
            $scope.isAddingOrder = false;
            return;
          }
        }

        // add order to printfile order log
        PrintFileLog.addOrder({ exportLogId: $scope.exportLogId }, { orderNumber: $scope.orderToAdd }, function (res) {
          $scope.isAddingOrder = false;
          $scope.orderToAdd = '';
          $scope.orders.push(data);

        }, function (err) {
          $scope.errorData = err;
          $scope.isAddingOrder = false;
          alert('Could not add order');
        })

      }, function err(a) {
        $scope.isAddingOrder = false;

        if (a.status === 404) {
          alert("The order was not found.");

        } else if (a.status === 400) {
          alert("The order could not be added. This is either because it's not a 'New Order', or it's an order purchased as 'Print at home'.")
        } else {
          $scope.errorData = a;
          alert("A server error occurred.")
        }
      });
    };

    $scope.isButtonDisabled = function () {
      return $scope.deliveryForms.find(function (f) {
        return f.$invalid
      })
    }

    $scope.changeStep = function(step) {
      $scope.step = step;
    }

    $scope.onContinue = function () {
      $scope.isLoading = true;

      // Generate list of all order numbers
      orderNumbers = [];
      for (var i = 0; i < $scope.orders.length; i++) {
        if (!$scope.orders[i].Include) {
          continue;
        }

        orderNumbers.push($scope.orders[i].OrderNumber);
      }

      // Start the save process, if needed
      $scope.processingText = 'Checking for orders to save...';
      for (var i = 0; i < $scope.orders.length; i++) {
        if ($scope.orders[i].Include && ($scope.deliveryForms[i].$dirty || $scope.personalizedForms[i].$dirty)) {
          ordersToSave.push($scope.orders[i]);
        }
      }

      if (ordersToSave.length === 0) {
        $scope.isLoading = false;
        $state.go('private.orders.printfile.status', { exportLogId: $stateParams.exportLogId });
        return;
      }

      // Save orders
      $scope.processingText = 'Saving orders...';
      ExportDgdaPrintfile.saveOrders(ordersToSave, function (r) {

        // Since we updated the PDF files, we also need to regenerate the PDF files
        regeneratePdfs();

      }, function (a) {
        $scope.errorData = a;
        $scope.isLoading = false;
        $scope.processingText = null;
        alert("Unfortunately the orders couldn't be saved.")
      })
    }

    function regeneratePdfs() {
      $scope.processingText = 'Regenerating PDF files...';

      var savedOrderNumbers = [];
      for (var i = 0; i < ordersToSave.length; i++) {
        savedOrderNumbers.push(ordersToSave[i].OrderNumber);
      }

      ExportDgdaPrintfile.regenPdfs(savedOrderNumbers, function () {
        $scope.isLoading = false;
        $state.go('private.orders.printfile.status', { exportLogId: $stateParams.exportLogId });

      }, function (a) {
        $scope.errorData = a;
        $scope.isLoading = false;
        alert("Could not regenerate PDF files.");
      })
    }
  })
