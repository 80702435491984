var cockpitBaseApiUrl = window.location.host.indexOf('.local') > -1 ? "api.staging.truestory.com" : window.location.host.replace("cockpit", "api");
var apiGatewayBaseUrl = window.location.host.indexOf('staging.') > -1 ? "admin-api.staging.truestory.com" : "admin-api.truestory.com";
// Change this if api is running locally
var useLocalApi = false;
var isProduction = window.location.host.indexOf("staging") === -1 && window.location.host.indexOf('.local') === -1;

var localAdminApi = useLocalApi ? "admin-api.dgda.local" : "admin-api.staging.truestory.com";
var localApi = useLocalApi ? "api.dgda.local" : "api.staging.truestory.com";
var stagingPrefix = (window.location.host.indexOf('staging.') > -1 || window.location.host.indexOf('.local') > -1) ? ".staging" : "";

var Config = {
    Api: window.location.protocol + "//" + cockpitBaseApiUrl + "/Umbraco/backoffice/CockpitApi/",
    AuthApi: window.location.protocol + "//" + cockpitBaseApiUrl + "/Umbraco/CockpitApi/",
    AdminApi: window.location.protocol + "//" + (window.location.host.indexOf('staging.') > -1 ? 'admin-api.staging.truestory.com' : (window.location.host.indexOf('.local') > -1 ? localAdminApi : 'admin-api.truestory.com')) + "/v1/",
    AdminApiV2: window.location.protocol + "//" + (window.location.host.indexOf('staging.') > -1 ? 'admin-api.staging.truestory.com' : (window.location.host.indexOf('.local') > -1 ? localAdminApi : 'admin-api.truestory.com')) + "/v2/",
    GlobalApi: window.location.protocol + "//" + (window.location.host.indexOf('staging.') > -1 ? 'api.staging.truestory.com' : (window.location.host.indexOf('.local') > -1 ? localApi : 'api.truestory.com')) + "/v2/",
    ApiV3: window.location.protocol + "//" + cockpitBaseApiUrl + "/v3/",
    ApiV2: window.location.protocol + "//" + cockpitBaseApiUrl + "/v2/",
    ApiV1: window.location.protocol + "//" + cockpitBaseApiUrl + "/v1/",
    TestApi: window.location.protocol + "//" + cockpitBaseApiUrl + "/Umbraco/CockpitApi/",
    AwsApiGateway: window.location.protocol + "//" + apiGatewayBaseUrl + "/v1/",
    CockpitApi: "https://api" + stagingPrefix+ ".truestory.com/cockpit",
    CockpitApiV1: "https://api" + stagingPrefix+ ".truestory.com/cockpit/v1/",
    NewCockpitApiV1: "https://api" + stagingPrefix+ ".truestory.com/cockpit-api/v1/",
    WebShipperBaseUrl: isProduction ? 'https://truestory.webshipper.io/' : 'https://truestory-test.webshipper.io/'
};

var modules = [
    /** Jingle Internals **/
    'dgda-cockpit.Module.Templates',

    /** External Libs **/
    'ui.router',
    'ui.bootstrap',
    'ngCookies',

    /** Filters **/
    'Cockpit.Filter.CurrencyDkk',
    'Cockpit.Filter.FilterByDate',
    'Cockpit.Filter.NegateAmount',

    /** States/Routes **/
    'Cockpit.State.Abstracts',
    'Cockpit.State.Login',
    'Cockpit.State.Dashboard',
    'Cockpit.State.ProductRank',
    'Cockpit.State.ProductRankings',
    'Cockpit.State.Reviews',
    'Cockpit.State.Products',
    'Cockpit.State.Campaigns',
    'Cockpit.State.Notifications',
    'Cockpit.Service.CampaignType',
    'Cockpit.State.Orders',
    'Cockpit.State.Search',
    'Cockpit.State.Site',
    'Cockpit.State.Settings',

    /** Internal Services **/
    'Cockpit.Service.User',
    'Cockpit.Service.Auth',
    'Cockpit.Service.Navigation',
    'Cockpit.Service.Product',
    'Cockpit.Service.Order',
    'Cockpit.Service.Settings',
    'Cockpit.Service.Language',
    'TS.Refunds.Service',
    'Cockpit.Service.Currency',
    'Cockpit.Service.FreeWrappingMethod',

    // Interceptors
    'Cockpit.HttpResponse.Interceptor',

    /** Internal Directives **/
    'Cockpit.Directive.InputFocus',
    'Cockpit.Directive.ConfirmClick',
    'Cockpit.Directive.IsNumber',
    'Cockpit.Directive.LocalizedInput',
    'dgda.directive.LoaderButton'
];

if (window.location.host.indexOf("mademyday") > -1) {
    modules.push('Cockpit.State.Mademyday');
}
if (window.location.host.indexOf("duglemmerdetaldrig") > -1 || window.location.host.indexOf("dgda") > -1 || window.location.host.indexOf("truestory") > -1 || window.location.host.indexOf("tsdk") > -1)  {
    console.log('loading dgda modules');
    modules.push('Cockpit.State.Dgda');
}

angular.module('Cockpit', modules)

.config(["$urlRouterProvider", function($urlRouterProvider) {

    $urlRouterProvider.rule(function($injector, $location) {
        var path = $location.path(),
            normalized = path.toLowerCase();

        if (path != normalized) {
            path = normalized;
        }

        if (path[path.length - 1] !== '/') {
            path = path + "/";
        }

        return path;
    })

    /**
     * If no other routes match, simply redirect to the front page
     * (or change this to any other page, like a 404).
     */
    .otherwise('/login/');
}])

.run(["$rootScope", "$location", "$http", "User", function($rootScope, $location, $http, User) {
    // Enable credientials (ie. cookies etc.) through the $http Angular Service
    $http.defaults.withCredentials = true;
}])

.controller('MainController', ["$scope", "$rootScope", "Auth", function MainController($scope, $rootScope, Auth) {
    // Auth.renew();

    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
        if (angular.isDefined(toState.data.pageTitle)) {
            $scope.pageTitle = toState.data.pageTitle + ' | Truestory Cockpit';
            $scope.bodyClass = toState.data.bodyClass;
        }
    });
}])

.controller('navigationCtrl', function navigationCtrl() {

})

.controller('headerCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "User", "Admin", function($scope, $rootScope, $state, $stateParams, User, Admin) {

    // Setup defaults
    $scope.user = {
        name: User.getName()
    };
    $scope.username = $rootScope.Admin.username;
    $scope.suppliers = [];

    // Setup watcher to enable user changes
    $scope.$watch(function() {
        return User.isLogged();
    }, function(v) {
        if (!v) {
            return;
        }

        $scope.user = {
            name: User.getName()
        };
    });
}])

.config(["$httpProvider", function($httpProvider) {
  $httpProvider.interceptors.push('HttpResponseInterceptor');
}])

;


angular.module('Cockpit.Directive.ConfirmClick', [])
    // I define an asynchronous wrapper to the native prompt() method. It returns a
        // promise that will be "resolved" if the user submits the prompt; or will be
        // "rejected" if the user cancels the prompt.
        .factory(
            "prompt",
            ["$window", "$q", function( $window, $q ) {
                // Define promise-based prompt() method.
                function prompt( message, defaultValue ) {
                    var defer = $q.defer();
                    // The native prompt will return null or a string.
                    var response = $window.prompt( message, defaultValue );
                    if ( response === null ) {
                        defer.reject();
                    } else {
                        defer.resolve( response );
                    }
                    return( defer.promise );
                }
                return( prompt );
            }]
        );
angular.module('Cockpit.Directive.InputFocus', [])
	.directive('inputFocus', [function () {
		var FOCUS_CLASS = "input-focused";
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ctrl) {
				ctrl.$focused = false;
				element.bind('focus', function (evt) {
					element.addClass(FOCUS_CLASS);
					scope.$apply(function () { ctrl.$focused = true; });
				}).bind('blur', function (evt) {
					element.removeClass(FOCUS_CLASS);
					scope.$apply(function () { ctrl.$focused = false; });
				});
			}
		};
	}]);

angular.module('Cockpit.Directive.IsNumber', [])
    .directive('input', [function() {
    return {
        restrict: 'E',
        require: '?ngModel',
        link: function(scope, element, attrs, ngModel) {
            if (
                'undefined' !== typeof attrs.type
                && 'number' === attrs.type
                && ngModel
            ) {
                ngModel.$formatters.push(function(modelValue) {
                    return Number(modelValue);
                });

                ngModel.$parsers.push(function(viewValue) {
                    return Number(viewValue);
                });
            }
        }
    }
}]);
var currencyDkk = angular.module('Cockpit.Filter.CurrencyDkk', []);

currencyDkk.filter('dkk', function () {
	Number.prototype.formatMoney = function (decPlaces, thouSeparator, decSeparator) {
		var n = this;
		decPlaces = 2;//isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
		decSeparator = (decSeparator === undefined ? "." : decSeparator);
		thouSeparator = (thouSeparator === undefined ? "," : thouSeparator);
		sign = n < 0 ? "-" : "";
		i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "";
		j = (j = i.length) > 3 ? j % 3 : 0;
		return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
	};

	return function (nb, thousandSeperator) {
		if (nb === undefined) {
			return "- kr.";
		}
    if (!thousandSeperator && thousandSeperator !== '') {
      thousandSeperator = ".";
    }

		return nb.formatMoney(0, thousandSeperator, ",") + " kr.";
	};
})
.filter('zeroUndefined', function () {
	return function (amount) {
    if( amount !== 0 ) {
      return amount;
    }
    return undefined;
	};
});

var currencyDkk = angular.module('Cockpit.Filter.FilterByDate', []);

currencyDkk.filter('filterByDate', function () {
    return function filterByDate(items, prop, fromDate, toDate, includeNullDate) {
      var result = [];

      if (!fromDate) {
        fromDate = new Date(2010, 6,31);
      }
      if(!toDate){
        toDate = new Date((new Date()).getFullYear()+10, 1, 1);
      }

      angular.forEach(items, function (obj) {
        if (!items) {
          return [];
        }

        var objDate = obj[prop];
        if(!angular.isDate(objDate) && !angular.isUndefined(objDate) && objDate !== null){
          console.log("Not date - start parse", objDate);
          objDate = new Date(objDate);
        }

        if (!angular.isDate(objDate)) {
          if (!includeNullDate) {
            return;
          }
        } else if (!(fromDate <= objDate <= toDate)) {
          return;
        }
        result.push(obj);
      });

      return result;
    }
});

angular.module('Cockpit.Filter.NegateAmount', [])
.filter('negateAmount', function () {
	return function (amount) {
    if( amount !== 0 ) {
      return -(amount);
    }
    return 0;
	};
});

angular.module('Partner.Filter.Offset', [])
.filter('offset', function () {
	return function (input, start) {
		start = +start; //parse to int
		return input.slice(start);
	};
});
angular.module('Cockpit.HttpResponse.Interceptor', ['ngResource'])
  .factory('HttpResponseInterceptor',  ['$q', '$location', function($q, $location) {
    return {
      responseError: function (rejection) {
        if (rejection.status === 401) {
          $location.path('logout');
        }
        return $q.reject(rejection);
      }
    }
  }]);

angular.module('Cockpit.Controller.GenericAddEdit', [
])
  .controller('GenericAddEditController', ["$scope", "$state", "theInstance", "scopePropertyName", "goStateName", function GenericAddEditController($scope, $state, theInstance, scopePropertyName, goStateName) {
    $scope[scopePropertyName] = theInstance;
    $scope.isProcessing = false;

    $scope.onSubmit = function () {
      $scope.isProcessing = true;

      if ($scope[scopePropertyName].id < 1) {
        $scope[scopePropertyName].$save(function(){
          $scope.isProcessing = false;
          $state.go(goStateName, {}, { reload: true });
        });
      }
      else {
        $scope[scopePropertyName].$update(function(){
          $scope.isProcessing = false;
          $state.go(goStateName, {}, { reload: true });
        });
      }
    }
  }])

angular.module('Cockpit.Service.AccountingGroups', ['ngResource'])
  .factory('AccountingGroups', ['$resource',
    function ($resource) {
      return $resource(Config.NewCockpitApiV1 + 'orders/accounting-groups', {}, {
        get: {
          method: 'GET',
          isArray: false
        }
      });
    }
  ]);

var authService = angular.module('Cockpit.Service.Auth', ['ngResource']);

authService.factory('Auth', ["$resource", "$rootScope", "$location", "$state", "$cookies", "User", function ($resource, $rootScope, $location, $state, $cookies, User) {
	/**
	 * Listen for route changes and verify that the user is logged in
	 */
	$rootScope.prevState = null;

	$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
		// Handle trying to access the login screen, while being logged in
		if (toState.name == "public.login" && User.isLogged()) {
			event.preventDefault();
			$state.go('private.dashboard');

		// Handle trying to access a page, which has restricted access, while not being logged in
		} else if (angular.isDefined(toState.data.access) && toState.data.access.isPrivate && !User.isLogged()) {
			// reload the login route
			event.preventDefault();

			$rootScope.prevState = {
				state: toState,
				params: toParams
			};

			$state.go('public.login');
		} else if( angular.isDefined(toState.data.access) && toState.data.access.isPrivate && User.isLogged() && !User.can(toState.data.access.groups)){
			event.preventDefault();
			$state.go('private.dashboard');
		}
	});

	var authResource = $resource(Config.ApiV3 + 'auth/:Action', {}, {
		login: { method: 'POST', params: { Action: 'login' } },
		logout: { method: 'GET', params: { Action: 'logout' } },
		renew: { method: 'GET', params: { Action: 'renew' } }
	});

  var jwtTokenKey = "tsAdminJwt";

	var updateUser = function (data) {
		if (!angular.isDefined(data.isLogged) || !angular.isDefined(data.username) || !angular.isDefined(data.email) || !angular.isDefined(data.name)) {
			return false;
		}
    if (!angular.isDefined(data.token || data.token.length === 0)) {
      return false;
    }

		// Update logged in status
		User.setLogged(data.isLogged);
		User.setUsername(data.username);
		User.setEmail(data.email);
		User.setName(data.name);
		User.setGroup(data.group);

    //update token
    $cookies.put(jwtTokenKey, data.token);
		User.setToken(data.token);

		return true;
	};

	var handleSuccessLogin = function success(authResponse, success, error){
        if (!updateUser(authResponse)) {
            User.resetUser();
            if (typeof (error) === "function") {
                error();
            }
            return;
        }

        if (typeof (success) === "function") {
            success();
        }

        // Redirect to frontpage
        $state.go('private.dashboard');
    };

	return {
		login: function (data, success, error) {

			// Try to login
			authResource.login({}, data, function (authResponse) {
        handleSuccessLogin(authResponse, success, error);
			}, function () {
				User.resetUser();
				if (typeof (error) === "function") {
					error();
				}
			});
		},
		logout: function () {
			User.resetUser();
      $cookies.remove(jwtTokenKey);
			authResource.logout(function () {
				$state.go('public.login');
			});
		},
		renew: function () {
			return authResource.renew(function (authResponse) {
        if (!authResponse || !authResponse.isLogged) {
          authResource.logout(function () {
            $state.go('public.logout');
            $cookies.remove(jwtTokenKey);
          });
        }

        // Otherwise, continue
        User.setToken(authResponse.token);
				if (!updateUser(authResponse)) {
					return;
				}

				// Redirect to frontpage or prev state
				if ($rootScope.prevState !== null) {
					$state.go($rootScope.prevState.state, $rootScope.prevState.params);
				} else {
					$state.go('private.dashboard');
				}

			}, function (err) {
        $cookies.remove(jwtTokenKey);
        User.resetUser();
        // $state.go('public.logout');
			});

		}
	};
}]);

angular.module('Cockpit.Service.Bookings', ['ngResource'])
  .factory('Bookings', ['$resource',
    function ($resource) {
      return $resource(Config.CockpitApiV1 + 'orders/:orderNumber/bookings/latest', {orderNumber: '@orderNumber'}, {
        cancel: {
          method: 'POST',
          url: Config.CockpitApiV1 + 'orders/:orderNumber/bookings/cancel?bookingId=:bookingId',
          isArray: false,
          params: {orderNumber: '@orderNumber', bookingId: '@bookingId'}
        }
      });
    }
  ]);

angular.module('Cockpit.Service.CallOuts', ['ngResource'])
  .factory('CallOut', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'callouts/:id', {id: '@id'}, {
        update: { method: 'PUT', url: Config.AdminApi + 'callouts' }
      });
    }
  ]);

angular.module('Cockpit.Service.CallOutTypes', ['ngResource'])
  .factory('CallOutType', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'callouttypes/:id', {id: '@id'}, {
        update: { method: 'PUT', url: Config.AdminApi + 'callouttypes' }
      });
    }
  ]);

angular.module('Cockpit.Service.Campaign', ['ngResource'])
    .factory('Campaign', ['$resource',
        function($resource) {
            return $resource(Config.AwsApiGateway + 'campaigns/:id', {id: '@id'}, {
                prioritize: { url: Config.AwsApiGateway + 'campaigns/:id/prioritize', method: 'POST'}
            });
        }
    ]);

angular.module('Cockpit.Service.CampaignGrouping', ['ngResource'])
    .factory('CampaignGrouping', [
        function () {

            return {
                group: function(campaigns){
                    var ready = [];
                    var running = [];
                    var paused = [];
                    var ended = [];

                    for(var i = 0; i < campaigns.length; i++){
                        var c = campaigns[i];
                        var start = new Date(c.start);
                        var end = new Date(c.end);
                        var now = new Date();

                        // Running
                        if(c.isActive && start <= now && now < end) {
                            running.push(c);

                            // Ready
                        }else if(c.isActive && now < start){
                            ready.push(c);

                            // Paused
                        }else if(!c.isActive && now < end){
                            paused.push(c);

                            // Ended
                        }else{
                            ended.push(c);
                        }
                    }

                    return {
                        ready: ready,
                        running: running,
                        paused: paused,
                        ended: ended
                    };
                }
            }
        }
    ]);
angular.module('Cockpit.Service.CampaignType', ['ngResource'])
    .factory('CampaignType', ['$resource',
        function($resource) {
            return $resource(Config.AwsApiGateway + 'campaigntypes', {}, {});
        }
    ]);

angular.module('Cockpit.Service.Category', ['ngResource'])
  .factory('Category', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'categories');
    }]);

angular.module('Cockpit.Service.CategoryV2', ['ngResource'])
  .factory('CategoryV2', ['$resource',
    function ($resource) {
      return $resource(Config.GlobalApi + 'categories');
    }]);

angular.module('Cockpit.Service.Collection', ['ngResource'])
  .factory('Collection', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'collections');
    }]);

angular.module('Cockpit.Service.Country', ['ngResource'])
  .factory('Country', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'countries');
    }]);

angular.module('Cockpit.Service.Currency', ['ngResource'])
  .factory('Currency', ['$resource',
    function($resource) {
      return $resource(Config.GlobalApi + 'currencies');
    }
  ]);

angular.module('Cockpit.Service.Extensions', ['ngResource'])
  .factory('Extensions', ['$resource',
    function ($resource) {
      return $resource(Config.NewCockpitApiV1 + 'orders/:orderNumber/extensions', { orderNumber: '@orderNumber' }, {
        query: { isArray: false }
      });
    }
  ]);

angular.module('Cockpit.Service.FooterWidgets', ['ngResource'])
  .factory('FooterWidgets', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'footerwidgets/:id', {}, {
        update: { method: 'PUT', url: Config.AdminApi + 'footerwidgets' }
      });
    }
  ]);
angular.module('Cockpit.Service.FreeWrappingMethod', ['ngResource'])
  .factory('FreeWrappingMethod', ['$resource',
    function ($resource) {
      return $resource(Config.ApiV2 + 'wrappingmethods/admin/free', {}, {
        get: { method: 'GET', isArray: false },
      });
    }]);

angular.module('Cockpit.Service.Municipality', ['ngResource'])
  .factory('Municipality', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'municipalities');
    }]);

angular.module('Cockpit.Service.Navigation', [])

	.factory('Navigation', ["User", function (User) {
		var sidebarNav = {};
		var headerNav = [];

		var filterMenuGroupItems = function filterMenuGroupItems(menuItem){
			if (!angular.isArray(menuItem.Groups)) {
				menuItem.Groups = [ 'non-obtainable-capability' ];
			}

			return User.can(menuItem.Groups);
		};

		var getSidebarMenu = function getSidebarMenu(state) {
			// Make sure we have a valid state before doing anything
			if (state === '') {
				return [];
			}

			var hasParent = (state.indexOf('.') >= 0);
			var hasMenu = angular.isDefined(sidebarNav[state]);

			// Do we have a menu for this state?
			// If we have no menu, and no parent,
			// simply return an empty array.
			if (!hasMenu && !hasParent) {
				return [];
			} else if (!hasMenu && hasParent) {
				// Since we do not have a menu for
				// this state, recurse up the tree
				// and get the menu of an ancestor
				var parent = (hasParent ? state.substring(0, state.lastIndexOf('.')) : '');
				return getSidebarMenu(parent);
			}

			var groups = angular.copy( sidebarNav[state] );

			// We got a menu, so simply return it!
			return groups.filter(function (menuGroup) {
				menuGroup.Items = menuGroup.Items.filter(filterMenuGroupItems);

				return (menuGroup.Items.length > 0);
			});
		};

		var indexOfGroup = function hasMenu(state, group) {
			var menu = sidebarNav[state];

			for (var i in menu) {
				var d = menu[i];

				if (d.Name == group) {
					return i;
				}
			}

			return -1;
		};
		var indexOfLabel = function hasLabel(items, item) {

			for (var i in items) {
				var d = items[i];

				if (d.Label == item.Label) {
					return i;
				}
			}

			return -1;
		};

		var registerSidebarMenu = function registerSidebarMenu(state, group, items) {
			if (!angular.isDefined(sidebarNav[state])) {
				sidebarNav[state] = [];
			}

			// Get the index of the current group (eg Orders)
			var groupIndex = indexOfGroup(state, group);

			// Check if the group already exists. If not, add it
			if (groupIndex == -1) {
				sidebarNav[state].push({
					Name: group,
					Items: items
				});
				return;
			}

			// Iterate through all items and add them
			// or replace with existing items.
			// The label has to be unique, hence if
			// an item is added with an existing label,
			// the existing label will be replaced.
			for (var i in items) {
				var item = items[i];

				// Get the index of the item label, if it
				// exists in the menu.
				var labelIndex = indexOfLabel(sidebarNav[state][groupIndex].Items, item);

				// If the label doesn't exist, simply add it.
				// Otherwise replace the existing item.
				if (labelIndex == -1) {
					sidebarNav[state][groupIndex].Items.push(item);
				} else {
					sidebarNav[state][groupIndex].Items[labelIndex] = item;
				}
			}


		};

		var getPrimaryMenu = function getPrimaryMenu() {
			return headerNav.filter(function (menuItem) {
				return User.can(menuItem.Groups);
			}).sort(function (a, b) {
				var priority = a.Priority - b.Priority;

				if (priority === 0) {
					return a.Label.localeCompare(b.Label);
				}

				return priority;
			});
		};
		var registerPrimaryMenu = function registerPrimaryMenu(label, state, priority, icon, groups, activeState) {
			if (typeof(groups) !== "object") {
				groups = ["admin", "support", "user"];
			}
			if( typeof(icon) !== "string"){
				icon = "md md-chevron-right";
			}
			if( typeof(activeState) !== "string"){
				activeState = state;
			}

			if (typeof (priority) !== "number" || priority < 0) {
				priority = 10;
			}

			headerNav.push({
				Label: label,
				State: state,
				ActiveState: activeState,
				Priority: priority,
				Groups: groups,
				Icon: icon
			});
		};

		return {
			sidebar: {
				get: getSidebarMenu,
				register: registerSidebarMenu
			},
			primary: {
				get: getPrimaryMenu,
				register: registerPrimaryMenu
			}
		};
	}]);
angular.module('Cockpit.Service.Notification', ['ngResource'])
  .factory('Notification', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'notifications/:id', { id: '@id' }, {
        getContent: {
          method: 'GET', url: Config.AdminApi + 'notifications/:id/content', transformResponse: function (data) {
            return { html: data }
          }
        },
        getFailed: {
          method: 'GET', url: Config.AdminApi + 'notifications/failed', isArray: true
        },
        resendNotifications: {
          method: 'POST', url: Config.AdminApi + 'notifications/resendnotifications'
        },
        update: { method: 'PUT' }
      });
    }
  ]);

angular.module('Cockpit.Service.Order', ['ngResource'])
    .factory('Order', ['$resource',
        function($resource) {
            return $resource(Config.Api + 'orderapi/:action', {}, {
                get: { params: { action: 'Get' } },
                save: { method: 'POST', params: { action: 'Save' } },
                query: { params: { action: 'All' }, isArray: true },
                bulkSave: { method: 'POST', params: { action: 'BulkSave' } },
                queryAnalogShipped: { params: { action: 'AnalogShipped' }, isArray: true },
                orderStatuses: { params: { action: 'OrderStatuses' }, isArray: true }
            });
        }
    ]);

angular.module('Cockpit.Service.OrderExpiration', ['ngResource'])
    .factory('OrderExpiration', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'orderexpirationapi/:action', {}, {
                get: { params: { action: 'Get' } },
                save: { method: 'POST', params: { action: 'Save' } }
            });
        }]);
angular.module('Cockpit.Service.OrderNote', ['ngResource'])
  .factory('OrderNote', ['$resource',
    function ($resource) {
      return $resource(Config.CockpitApiV1 + 'orders/:orderNumber/notes/:id', {}, {
      })
    }]);

angular.module('Cockpit.Service.Portfolio', ['ngResource'])
    .factory('Portfolio', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'portfolioapi/:action', {}, {
                get: { params: { action: 'Get' } },
                query: { params: { action: 'All' }, isArray: true }
            });
        }]);
angular.module('Cockpit.Service.PortfolioSkuOverride', ['ngResource'])
    .factory('PortfolioSkuOverride', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'portfolioapi/:action', {}, {
                get: { params: { action: 'GetSkuOverrides' } },
                save: { method: 'POST', params: { action: 'SetSkuOverrides' } }
            });
        }]);
angular.module('Cockpit.Service.Product', ['ngResource'])
	.factory('Product', ['$resource',
	function ($resource) {
		return $resource(Config.Api + 'productapi/:action', {}, {
			get: { params: { action: 'Get' }, timeout: 45000 },
			save: { method: 'POST', params: { action: 'Save' }, timeout: 45000 },
			query: { params: { action: 'All' }, isArray: true, timeout: 45000 }
		});
	}]);

angular.module('Cockpit.Service.ProductV2', ['ngResource'])
  .factory('ProductV2', ['$resource',
    function ($resource) {
      return $resource(Config.GlobalApi + 'products', {}, {
        getBySku: { method: 'GET', url: Config.GlobalApi + 'products/sku/:sku', isArray: false, params: { sku: '@sku' } }
      });
    }]);

angular.module('Cockpit.Service.Region', ['ngResource'])
  .factory('Region', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'regions');
    }]);

angular.module('Cockpit.Service.RegionsV2', ['ngResource'])
  .factory('RegionsV2', ['$resource',
    function ($resource) {
      return $resource(Config.GlobalApi + 'regions');
    }]);

angular.module('Cockpit.Service.Search', ['ngResource'])
    .factory('Search', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'searchapi/get');
        }]);

angular.module('Cockpit.Service.Settings', ['ngResource'])
    .factory('Settings', ['$resource',
        function ($resource) {
            return $resource(Config.ApiV2 + 'settings/:key', {key: '@Key'});
        }]);
angular.module('Cockpit.Service.TopbarGrouping', ['ngResource'])
    .factory('TopbarGrouping', [
        function () {

            return {
                group: function(topbars){
                    var ready = [];
                    var running = [];
                    var paused = [];
                    var ended = [];

                    for(var i = 0; i < topbars.length; i++){
                        var c = topbars[i];
                        var start = new Date(c.start);
                        var end = new Date(c.end);
                        var now = new Date();

                        // Running
                        if(c.isActive && start <= now && now < end) {
                            running.push(c);

                            // Ready
                        }else if(c.isActive && now < start){
                            ready.push(c);

                            // Paused
                        }else if(!c.isActive && now < end){
                            paused.push(c);

                            // Ended
                        }else{
                            ended.push(c);
                        }
                    }

                    return {
                        ready: ready,
                        running: running,
                        paused: paused,
                        ended: ended
                    };
                }
            }
        }
    ]);

angular.module('Cockpit.Service.TopbarTypes', ['ngResource'])
  .factory('TopbarTypeService', ['$resource',
    function ($resource) {
      return $resource(Config.AwsApiGateway + 'topbartypes/', {}, {});
    }]);

angular.module('Cockpit.Service.Topbars', ['ngResource'])
  .factory('TopbarService', ['$resource',
    function ($resource) {
      return $resource(Config.AwsApiGateway + 'topbars', {}, {
        getById: { method: 'GET', url: Config.AwsApiGateway + 'topbars/:id', isArray: false, params: { id: '@id' } },
        remove: { method: 'DELETE', url: Config.AwsApiGateway + 'topbars/:id', isArray: false, params: { id: '@id' } }
      });
    }]);

var userService = angular.module('Cockpit.Service.User', []);

userService.factory('User',
["$http", function ($http) {
	var userObject = {
		isLogged: false,
		name: "",
		email: "",
		username: "",
		group: "",
		token: ""
	};

	return {
		isLogged: function () { return userObject.isLogged && userObject.group !== ""; },
		getName: function () { return userObject.name; },
		getEmail: function () { return userObject.email; },
		getUsername: function () { return userObject.username; },
		getGroup: function () { return userObject.group; },

		can: function currentUserCan(groups){
			if(!userObject.isLogged){
				return false;
			}

			if(!angular.isArray(groups)){
				return (groups.toLowerCase() == userObject.group);
			}

			for(var i = 0; i < groups.length; i++){
				var group = groups[i].toLowerCase();
				if(group == userObject.group){
					return true;
				}
			}

			return false;
		},

		setLogged: function (v) { userObject.isLogged = v; },
		setName: function (v) { userObject.name = v; },
		setEmail: function (v) { userObject.email = v; },
		setUsername: function (v) { userObject.username = v; },
		setGroup: function (v) {
            if(v === undefined){
                userObject.group = '';
                return;
            }
            userObject.group = v.toLowerCase();
        },
		setToken: function(v){
			userObject.token = v;
			if(!angular.isDefined(v) || v.length === 0){
        $http.defaults.headers.common['Authorization'] = "";
			}else{
        $http.defaults.headers.common['Authorization'] = "Bearer " + v;
			}
		},
		getUser: function(){
			return angular.copy(userObject);
		},

		resetUser: function () {
			userObject = {
				isLogged: false,
				name: "",
				email: "",
				username: "",
				group: "",
				token: "",
				isAdmin: false
			};
		}
	};
}]);

angular.module('Cockpit.Service.Language', ['ngResource'])
    .factory('Language', ['$resource',
        function ($resource) {
            return $resource(Config.ApiV2 + 'languages');
        }]);
angular.module('Cockpit.Service.PimProduct', ['ngResource'])
  .factory('PimProduct', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'products');
    }]);

angular.module('Cockpit.Service.Platform', ['ngResource'])
  .factory('Platform', ['$resource',
    function ($resource) {
      return $resource(Config.ApiV2 + 'platforms');
    }]);

angular.module('Cockpit.Service.Host', ['ngResource'])
    .factory('Host', ['$resource',
        function ($resource) {
            return $resource(Config.GlobalApi + 'suppliers');
        }]);

angular.module('Cockpit.Service.WrappingMethods', ['ngResource'])
    .factory('WrappingMethods', ['$resource',
        function ($resource) {
            return $resource(Config.ApiV2 + 'wrappingmethods:id', {languageCode: '@id'});
        }]);

angular
  .module("Cockpit.Directive.BookingDetails", [
    "Cockpit.Service.Bookings",
    "Cockpit.Modal.BookingDetails",
    "Cockpit.Modal.BookingCancel"
  ])
  .directive("cpBookingDetails", ["Bookings", "$uibModal", function (Bookings, $uibModal) {
    return {
      restrict: "E",
      scope: {
        order: "<",
        orderLine: "<",
      },
      templateUrl: "directives/booking-details/details.tpl.html",
      link: function ($scope) {
        const orderData = $scope.order;
        $scope.booking = null;
        $scope.shouldReFetch = false;
        $scope.bookingNotFound = false;
        $scope.disableCancel = false;

        $scope.handleRefetch = function() {
          $scope.loading = true;
          Bookings.get(
            {
              orderNumber: $scope.order.OrderNumber,
            },
            function (data) {
              $scope.loading = false;
              $scope.booking = data;
              $scope.shouldReFetch = false;
              $scope.bookingNotFound = false;
              $scope.disableCancel = data.bookingStatus.toLowerCase() == "cancelled";
            },
            function(err) {
              $scope.loading = false;
              if( err.status === 404 ) {
                $scope.bookingNotFound = true;
                return;
              }

              if( err.status === 500 ) {
                $scope.shouldReFetch = false;
                // alert("Cannot fetch booking details. " + (err.data && err.data.data ? "Endpoint returns with the following error: " + err.data.data : "") + ". Please contact tech team.");
                $scope.errorMessage = err.data && err.data.data ? err.data.data : "Cannot fetch booking details. Please contact tech team.";

                return;
              }
              $scope.shouldReFetch = true;
              console.log("Bookings.get =>", err);
            }
          );
        }

        $scope.handleRefetch();

        $scope.bookingCode =
          orderData.OrderLines &&
          orderData.OrderLines[0] &&
          orderData.OrderLines[0].Properties.bookingCode
            ? orderData.OrderLines[0].Properties.bookingCode
            : null;

        var langCode = orderData.LanguageCode
          ? orderData.LanguageCode.toLowerCase()
          : "da-dk";
        var stagingUrlPrefix =
          window.location.hostname.indexOf("staging") > -1 ? "staging." : "";
        if ($scope.bookingCode) {
          var mainDomain = "";
          switch (langCode) {
            case "sv-se": {
              mainDomain = "https://" + stagingUrlPrefix + "truestory.se";
              break;
            }
            case "nb-no": {
              mainDomain = "https://" + stagingUrlPrefix + "truestory.no";
              break;
            }
            default: {
              mainDomain = "https://" + stagingUrlPrefix + "truestory.dk";
              break;
            }
          }
          var currentBookingBaseUrl = mainDomain + "/booking/";
          $scope.bookingLoginLink = currentBookingBaseUrl + $scope.bookingCode;
        }

        $scope.openBookingDetailsModal = function () {
          if (!$scope.booking) {
            return;
          }
          var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: "directives/booking-details/modal.tpl.html",
            resolve: {
              booking: $scope.booking,
            },
            windowClass: "booking-modal-wrap",
            controller: "BookingDetailsModalController",
          });
        };

        $scope.openBookingCancelModal = function () {
          if (!$scope.booking) {
            return;
          }

          var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: "directives/booking-details/modal/cancel.tpl.html",
            resolve: {
              TheBookingData: {
                orderNumber: $scope.order.OrderNumber,
                bookingId: $scope.booking.bookingId,
              },
            },
            windowClass: "booking-cancel-modal",
            controller: "BookingCancelModalController",
          });

          modalInstance.result.then(function(res) {
            if( !res ) {
              return;
            }
            if( res.didCancelBooking ) {
              $scope.handleRefetch();
            }
          })
        };
      },
    };
  }]);

angular
  .module("Cockpit.Modal.BookingDetails", [])
  .controller(
    "BookingDetailsModalController",
    ["$scope", "booking", "$uibModalInstance", function ($scope, booking, $uibModalInstance) {
      $scope.booking = booking;

      $scope.close = function () {
        $uibModalInstance.close();
      }

      $scope.eventLocation =
        (booking.location.localAreaName || "") +
        booking.location.streetAddress +
        ", " +
        booking.location.zipCode +
        " " +
        booking.location.city;

      $scope.eventLanguage = booking.spokenLanguages
        .map(function (s) {
          return s.name;
        })
        .join(", ");

      $scope.primaryContact = booking.participants[0];
      $scope.bookingCustomFields = booking.customFields ?  Object.entries(booking.customFields) : [];
      $scope.bookingParticipants = booking.participants.map(function(p) {
        p.metas = p.customFields ? Object.entries(p.customFields) : [];
        return p;
      });

      function isMultipleDate(dateStr1, dateStr2) {
        var date1 = new Date(dateStr1);
        var date2 = new Date(dateStr2);

        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() < date2.getDate();
      }

      $scope.isMultiDay = isMultipleDate(booking.start, booking.end);

      $scope.bookingEndDateFilter = !$scope.isMultiDay ? "HH:mm" : "dd/MM/yyyy HH:mm";

      $scope.hasParticipantsMeta = booking.participants.some(function(p) {
        return p.customFields ? Object.keys(p.customFields).length > 0 : false;
      });

    }]
  );

angular.module('Cockpit.Directive.CampaignList', [

])
    .directive('cpCampaignList', ["Campaign", function (Campaign) {
        return {
            restrict: 'E',
            scope: {
                campaigns: '=',
                showActions: '&',
                refresh: '&'
            },
            templateUrl: 'directives/campaign-list/list.tpl.html',
            link: function ($scope){
                $scope.loading = false;
                $scope.delete = function(id){
                  if( window.confirm('Are you sure you want to delete this campaign?')  ) {
                    $scope.loading = true;
                    Campaign.delete({id: id}, function(){
                      $scope.refresh();
                      $scope.loading = false;
                    });
                  } else {
                    $scope.loading = false;
                  }

                };

                $scope.prioritize = function priorizeCampaign(id, direction){
                    Campaign.prioritize({id: id, direction: direction}, {}, function(){
                        $scope.refresh();
                    })
                };
            }
        };
    }])

;

angular.module('dgda.directive.LoaderButton', [])
  .directive('dgdaLoaderButton', function () {
    return {
      restrict: 'A',
      scope: {
        isProcessing: '&',
        processingText: '=',
        callback: '&dgdaLoaderButtonCallback',
        validate: '&dgdaLoaderButtonValidate'
      },
      link: function (scope, elem, attrs) {
        var orig = "";
        var clicked = false;

        function setProcessing() {
          if (clicked) {
            return;
          }

          clicked = true;
          elem.addClass('loader-button-active');
          elem.attr('disabled', 'disabled');

          var html = '<div class="loader-button-spinner-wrapper"><span class="loader-button-spinner"><span class="glyphicon glyphicon-refresh" aria-hidden="true"></span></span></div>';

          if (angular.isDefined(scope.processingText) && scope.processingText !== "") {
            html += '<div class="loader-button-text">' + scope.processingText + '</div>';
          }

          orig = elem.html();
          elem.html(html);
        }


        function unsetProcessing() {
          elem.removeClass('loader-button-active');
          elem.removeAttr('disabled');
          elem.html(orig);
          clicked = false;
        }

        if (angular.isDefined(attrs.isProcessing)) {
          scope.$watch(function () {
            return scope.isProcessing();
          }, function (isProcessing) {

            if (isProcessing) {
              setProcessing();
            } else if (clicked) {
              // Reset button to original state
              unsetProcessing();
            }
          });
        } else {
          elem.bind('click', setProcessing);
        }
      }
    };
  });

angular.module('Cockpit.Directive.LocalizedInput', [

])
  .directive('cpLocalizedInput', ["LocalizedInputData", function (LocalizedInputData) {
    return {
      restrict: 'E',
      scope: {
        languages: '=',
        model: '=?',
        ngRequired: '=',
        type: '@',
        name: '@formName',
        maxlength: '@'
      },
      templateUrl: 'directives/localized-input/input.tpl.html',
      link: function ($scope){
        $scope.selectedLanguage = null;

        // Show first language on the array if it's not empty 
        if( $scope.languages.length > 0 ) {
          LocalizedInputData.showLanguage($scope.languages[0]);
        }

        $scope.$watch(function(){
          return LocalizedInputData.getLanguageToShow()
        }, function(v){
          if(!angular.isObject($scope.model)){
            $scope.model = {};
          }

          if(v !== null && angular.isDefined(v) && !angular.isDefined($scope.model[v.languageCode])){
            $scope.model[v.languageCode] = "";
          }

          $scope.selectedLanguage = v;
        })

        $scope.setLanguage = function(l){
          LocalizedInputData.showLanguage(l);
        };
      }
    };
  }])

  .factory('LocalizedInputData', [
    function() {
      var languageToShow = null;

      return {
        getLanguageToShow: function(){
          return languageToShow;
        },
        showLanguage: function(language){
          languageToShow = language;
        }
      };
    }
  ])

;

angular
  .module("Cockpit.Directive.TopbarList", [
    'Cockpit.Service.Topbars'
  ])
  .directive("tsTopbarList", ["$state", "TopbarService", function ($state, TopbarService) {
    return {
      restrict: "E",
      scope: {
        topbars: "=",
        isLoading: "=",
        isProcessing: "=",
        refresh: "&",
      },
      templateUrl: "directives/topbar-list/list.tpl.html",
      link: function ($scope) {
        $scope.edit = function(topbar) {
          $scope.isProcessing = true;
          topbar.processing = true;
          $state.go("private.site.topbars.edit", {id: topbar.id});
        }
        $scope.delete = function (topbar) {
          topbar.processing = true;
          TopbarService.remove({ id: topbar.id }, function () {
            $scope.refresh();
            topbar.processing = false;
          });
        };
      },
    };
  }]);

var resolvers = window.resolvers || {};
resolvers.callOutTypesResolver = ['$q', 'CallOutType', function($q, CallOutType){
  var d = $q.defer();

  CallOutType.query(function (res) {
    d.resolve(res);
  }, function (err) {
    d.reject();
    alert("Something didn't work out right - please check your console for any errors")
    console.log(err);
  });

  return d.promise;
}];

var resolvers = window.resolvers || {};
resolvers.callOutsResolver = function(status){
  return ['$q', 'CallOut', function($q, CallOut){
    var d = $q.defer();

    CallOut.query({status: status}, function (res) {
      d.resolve(res);
    }, function (err) {
      d.reject();
      alert("Something didn't work out right - please check your console for any errors")
      console.log(err);
    });

    return d.promise;
  }]
};

var resolvers = window.resolvers || {};
resolvers.currenciesResolver = ['$q', 'Currency', function ($q, Currency) {
  var d = $q.defer();

  Currency.query(function (res) {
    d.resolve(res);
  }, function (err) {
    d.reject();
  });

  return d.promise;
}];

var resolvers = window.resolvers || {};
resolvers.languagesResolver = ['$q', 'Language', function ($q, Language) {
  var d = $q.defer();

  Language.query(function (res) {
    d.resolve(res);
  }, function (err) {
    d.reject();
  });

  return d.promise;
}];

angular.module('Cockpit.State.Abstracts', [
  'ui.router'
])

.config(["$stateProvider", function config($stateProvider) {
	$stateProvider
		.state('public', {
			abstract: true,
			views: {
				"main": {
					template: '<ui-view/>'
				}
			},
			data: {
				access: {
					isPrivate: false
				},
				bodyClass: ""
			}
		})

		.state('private', {
			abstract: true,
			views: {
				"main": {
					templateUrl: 'states/abstracts/private.tpl.html'
				}
			},
			data: {
				access: {
					isPrivate: true,
					groups: ["admin", "support", "user"]
				},
				bodyClass: ""
			}
		})
	;
}])

.controller('SidebarNavigationController', ["$scope", "$state", "$rootScope", "Navigation", function SidebarNavigationController($scope, $state, $rootScope, Navigation) {
	$scope.navigation = Navigation.sidebar.get($state.current.name);

	$scope.getUrl = function leftNavGetUrl(state) {
    if( state.indexOf('/') === 0 ) {
      return state;
    }
		return $state.href(state);
	};

	$scope.isActiveState = function isActiveState(stateName) {
		return ($state.current.name === stateName);
	};

	$rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
		$scope.navigation = Navigation.sidebar.get(toState.name);
		$scope.state = toState.name;
	});
}])

.controller('PrimaryNavigationController', ["$scope", "$state", "Navigation", function PrimaryNavigationController($scope, $state, Navigation) {
	$scope.navigation = Navigation.primary.get();

	$scope.isActiveState = function isActiveState(stateName) {
		var currentStateName = $state.current.name;
		return (currentStateName.indexOf(stateName) >= 0);
	};

	$scope.getUrl = function primaryNavGetUrl(state) {
		return $state.href(state);
	};
}])

;

angular.module('Cockpit.State.Campaigns.AddEdit', [
    'Cockpit.Service.Navigation',
    'Cockpit.Service.Campaign'
  ])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.campaigns.add', {
        url: '/add',
        controller: 'CampaignsAddEditController',
        templateUrl: 'states/campaigns/add-edit.tpl.html',
        data: {
          pageTitle: 'Add Campaign'
        },
        resolve: {
          theCampaign: ['$q', 'Campaign', function ($q, Campaign) {
            var d = $q.defer();

            var campaignDto = new Campaign();
            campaignDto.parameters = {};
            d.resolve(campaignDto);

            return d.promise;
          }],
          campaignTypes: ['$q', 'CampaignType', function ($q, CampaignType) {
            var d = $q.defer();

            CampaignType.query(function (res) {
              d.resolve(res);
            }, function (err) {
              d.reject();
            });

            return d.promise;
          }],
          theLanguages: resolvers.languagesResolver
        }
      })

      .state('private.campaigns.edit', {
        url: '/{id}/edit',
        controller: 'CampaignsAddEditController',
        templateUrl: 'states/campaigns/add-edit.tpl.html',
        data: {
          pageTitle: 'Edit Campaign'
        },
        resolve: {
          theCampaign: ['$q', 'Campaign', '$stateParams', function ($q, Campaign, $stateParams) {
            var d = $q.defer();

            Campaign.get({
              id: $stateParams.id
            }, function (res) {
              res.start = new Date(res.start);
              res.end = new Date(res.end);
              d.resolve(res);
            }, function (err) {
              d.reject();
            });

            return d.promise;
          }],
          campaignTypes: ['$q', 'CampaignType', function ($q, CampaignType) {
            var d = $q.defer();

            CampaignType.query(function (res) {
              d.resolve(res);
            }, function (err) {
              d.reject();
            });

            return d.promise;
          }],
          theLanguages: resolvers.languagesResolver
        }
      })

    ;
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.campaigns', 'Campaigns', [{
      Label: "Add campaign",
      State: 'private.campaigns.add',
      Groups: ["admin", "marketing"]
    }]);
  }])

  .controller('CampaignsAddEditController', ["$scope", "$timeout", "Campaign", "CampaignType", "theCampaign", "campaignTypes", "theLanguages", "$state", function CampaignsAddEditController($scope, $timeout, Campaign, CampaignType, theCampaign, campaignTypes, theLanguages, $state) {

    $scope.types = campaignTypes;
    $scope.selectedType = null;
    $scope.languages = theLanguages;



    $scope.$watch('campaign.campaignType', function watchCampaignType(ct) {
      if (ct === null || ct === undefined) {
        $scope.selectedType = null;
        return;
      }

      if( "flash-sale" === ct ) {
        if( angular.isDefined($scope.campaign.parameters ) ) {
          $scope.campaign.parameters["partner-payed"] = "0";
        }
      }

      for (var i = 0; i < $scope.types.length; i++) {
        if ($scope.types[i].slug === ct) {
          $scope.selectedType = $scope.types[i];
          return;
        }
      }

      $scope.selectedType = null;
    });


    $scope.campaign = theCampaign;


    $scope.errorMessages = [];

    $scope.isRequiredParameter = function (param) {
      if (!param.parameters.optional) {
        return true;
      }

      return param.parameters.optional !== 'true';
    }

    $scope.saveCampaign = function saveCampaign() {
      // make sure parameter values are of type string
      Object.keys($scope.campaign.parameters).forEach(function (paramKey) {
        if (typeof($scope.campaign.parameters[paramKey]) !== 'string' ) {
          $scope.campaign.parameters[paramKey] = $scope.campaign.parameters[paramKey].toString();
        }
        if( paramKey === "skus" || paramKey === "variant-skus" ) {
          $scope.campaign.parameters[paramKey] = $scope.campaign.parameters[paramKey].trim().replace(/\s/g, '');
        }
      })

      Campaign.save($scope.campaign, function (r) {
        // Empty error message on success callback
        $scope.errorMessages = [];
        r.start = new Date(r.start);
        r.end = new Date(r.end);

        $scope.campaign = r;

        $state.go('private.campaigns', {}, {
          reload: true
        });

      }, function errorCb(err) {
        $scope.errorMessages = err.data;
        // Clear message after 15s
        $timeout(function () {
          $scope.errorMessages = [];
        }, 15000);
      });
    }
  }]);

angular.module('Cockpit.State.Campaigns', [
    'Cockpit.Service.Navigation',
    'Cockpit.Service.Campaign',
    'Cockpit.Service.CampaignGrouping',
    'Cockpit.Directive.CampaignList',
    'Cockpit.State.Campaigns.AddEdit',
    'Cockpit.State.FreeWrapping'
])

    .config(["$stateProvider", function config($stateProvider) {
        $stateProvider.state('private.campaigns', {
            url: '/campaigns',
            controller: 'CampaignsController',
            templateUrl: 'states/campaigns/campaigns.tpl.html',
            resolve: {
                campaigns: ['$q', 'Campaign', 'CampaignGrouping', function resolveCampaigns($q, Campaign, CampaignGrouping){
                    var d = $q.defer();

                    Campaign.query(function(data){
                        d.resolve(CampaignGrouping.group(data));
                    });

                    return d.promise;
                }]
            },
            data: {
                pageTitle: 'Campaigns'
            }
        })

        ;
    }])

    .run(["Navigation", function (Navigation) {
        Navigation.primary.register("Campaigns", "private.campaigns");

        Navigation.sidebar.register('private.campaigns', 'Campaigns', [
            {
                Label: 'Campaigns',
                State: 'private.campaigns',
                Groups: ["admin", "marketing"]
            }
        ]);
    }])

    .controller('CampaignsController', ["$scope", "Campaign", "campaigns", "CampaignGrouping", function CampaignsController($scope, Campaign, campaigns, CampaignGrouping){
        $scope.campaigns = campaigns;

        $scope.refresh = function refresh(){
            Campaign.query(function(data){
                $scope.campaigns = CampaignGrouping.group(data);
            });
        }
    }])
;

angular.module('Cockpit.State.FreeWrapping', [
  'Cockpit.Service.Navigation',
])

  .config(["$stateProvider", function config($stateProvider) {
      $stateProvider.state('private.campaigns.freewrapping', {
          url: '/free-wrapping',
          controller: 'FreeWrappingController',
          templateUrl: 'states/campaigns/free-wrapping.tpl.html',
          data: {
              pageTitle: 'Free Wrapping'
          }
      })

      ;
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.campaigns', 'Free Wrapping', [{
      Label: "Free Wrapping",
      State: 'private.campaigns.freewrapping',
      Groups: ["admin", "marketing"]
    }]);
  }])

  .controller('FreeWrappingController', ["$scope", "PimProduct", "FreeWrappingMethod", function FreeWrappingController($scope, PimProduct, FreeWrappingMethod){
    $scope.loadingFreeWrappingMethods = true;
    $scope.freeWrappingMethod = null;
    $scope.isSaving = false;
    $scope.productSkus = [];
    FreeWrappingMethod.get(function(data){
      $scope.freeWrappingMethod = data;
      $scope.productSkus = data.skus.length > 0 ? data.skus.split(",") : [];
      $scope.loadingFreeWrappingMethods = false;
    }, function(err){
      console.log("error", err)
      $scope.freeWrappingMethod = null;
      $scope.loadingFreeWrappingMethods = false;
      $scope.productSkus = []
    })

    $scope.loadingProducts = true;
    $scope.selectedProduct = null;
    $scope.products = PimProduct.query(function() {
      $scope.loadingProducts = false;
    });

    $scope.selectProduct = function selectProduct($item, $model, $label){
      if($scope.productSkus.indexOf($item.sku) > -1){
        $scope.selectedProduct = null;
        return;
      }
      $scope.productSkus.push($item.sku);
      $scope.selectedProduct = null;
    };

    $scope.removeProduct = function removeProduct(index){
      $scope.productSkus.splice(index, 1);
    };

    $scope.save = function() {
      $scope.isSaving = true;
      FreeWrappingMethod.save({skus: $scope.productSkus.join(",")}, function(data){
        $scope.isSaving = false;
      })
    }
  }])
;

angular.module('Cockpit.State.Dashboard', [
  'ui.router'
])

.config(["$stateProvider", function ($stateProvider) {
	$stateProvider.state('private.dashboard', {
		url: '/',
		views: {
			"": {
				controller: 'CockpitDashboardCtrl',
				templateUrl: 'states/dashboard/dashboard.tpl.html'
			}
		},
		data: {
			pageTitle: 'Dashboard'
		}
	});
}])

.run(["Navigation", function (Navigation) {
	Navigation.primary.register("Dashboard", "private.dashboard", 0);
}])

.controller('CockpitDashboardCtrl', ["$scope", function CockpitDashboardCtrl($scope) {

}])

;

angular.module('Cockpit.State.Dgda', [
    'ui.router',
    'Cockpit.State.Dgda.Orders.Add',
    'Cockpit.State.Dgda.Printfile',
    'Cockpit.State.Printfile.DataVerification',
    'Cockpit.State.Dgda.Printfile.Status',
    'Cockpit.State.Dgda.ShippingCounter',
    'Cockpit.State.Dgda.Ease',
    'Cockpit.State.Dgda.GiftCardPreview',
    'Cockpit.State.Dgda.Refunds',
    'Cockpit.State.ProductRankingWeights',
    'Cockpit.State.Dgda.Faq',
  'Cockpit.State.Dgda.Discounts'
])

.config(["$stateProvider", function config($stateProvider) {
    $stateProvider

        .state('private.dgda', {
        abstract: true,
        template: '<ui-view/>'
    });
}]);
angular.module('Cockpit.Service.Mademyday.Export', ['ngResource'])
    .factory('MmdExport', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'exportapi/:action', {}, {
                query: { params: { action: 'Get' }, isArray: true },
                queryDeliveryDays: { params: { action: 'DeliveryDays' }, isArray: true },
                queryDelivery: { params: { action: 'Delivery' }, isArray: true },
                csvUrl: { params: { action: 'DeliveryCsv' } },
                pdfUrl: { params: { action: 'DeliveryPdf' } }
            });
        }]);

angular.module('Cockpit.State.Mademyday', [
  'ui.router',
  'Cockpit.State.Mademyday.Filters',
  'Cockpit.State.Mademyday.BatchSend',
  'Cockpit.State.Mademyday.Texts',
  'Cockpit.State.Mademyday.Export.Delivery',
  'Cockpit.State.Mademyday.Export.ReviewData',
  'Cockpit.State.Mademyday.Parse',
  'Cockpit.State.Mademyday.Krifa'
])

.config(["$stateProvider", function config($stateProvider) {
	$stateProvider
		
		.state('private.mademyday', {
			abstract: true,
			template: '<ui-view/>'
		})
	;
}])
;
angular.module('Cockpit.State.Notifications.Details', [
    'Cockpit.Service.Notification'
  ])

    .config(["$stateProvider", function config($stateProvider) {
        $stateProvider.state('private.notifications.details', {
          url: '/{id}/details',
          controller: 'NotificationDetailsController',
          templateUrl: 'states/notifications/notification-details.tpl.html',
          resolve: {
            notificationDetails: ['$q', 'Notification', '$stateParams', function resolveCampaigns($q, Notification, $stateParams){
              var d = $q.defer();

              Notification.get({id: $stateParams.id}, function(data){
                  d.resolve(data);
              });

              return d.promise;
            }],
            previousState: ['$state', function ($state) {
              return $state.current.name + '({id: ' + $state.params.id +'})';
            }]
          },
          data: {
            pageTitle: 'Notifications | Details'
          }
        })
      ;
    }])

    .run(function () {

    })

    .controller('NotificationDetailsController', ["$scope", "$uibModal", "Notification", "notificationDetails", "previousState", function NotificationDetailsController($scope, $uibModal, Notification, notificationDetails, previousState){
      $scope.notificationDetails = notificationDetails
      $scope.previousState = previousState;
      $scope.hasError = false;
      $scope.infoMessage = null;
      $scope.recipient = $scope.notificationDetails.customer.recipient;

      $scope.ResendEmail = function (recipient) {
        if (!window.confirm('Do you want to resend email to this recipient: ' + recipient)) {
          return;
        }

        Notification.save({
          notificationId: $scope.notificationDetails.id,
          recipient: recipient
        }, function(res) {
          $scope.hasError = false;
          $scope.infoMessage = 'Notification has been sent to: ' + recipient;
        }, function(err) {
          $scope.hasError = true;
          $scope.infoMessage = err.data;
        })
      }

      $scope.previewContent = function() {
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: "states/notifications/notification-preview.tpl.html",
          resolve: {
            iframeSrc: ['$q', 'Notification', function ($q, Notification){
              var d = $q.defer();

              Notification.getContent({id: $scope.notificationDetails.id}, function(data){
                d.resolve(data.html);
              })

              return d.promise;

            }]
          },
          controller: ["$scope", "$sce", "iframeSrc", function NotificationPreviewController($scope, $sce, iframeSrc){
            $scope.iframeSrc = $sce.trustAsHtml(iframeSrc);
          }]
        });
        // silence console.log errors by handle result..
        modalInstance.result.then(function (item) {}, function () {});
      }

      $scope.manualHandleFailedEmail = function (message) {
        if (!window.confirm('Are you sure this email has been handled?')) {
          return;
        }

        Notification.update({
          notificationId: $scope.notificationDetails.id,
          message: message
        }, function (res) {
          $scope.hasError = false;
          $scope.infoMessage = 'Updated!';
          $scope.notificationDetails = Notification.get({id: $scope.notificationDetails.id});
        }, function (err) {
          $scope.hasError = true;
          $scope.infoMessage = err.data;
        })
      }

      $scope.getTooltip = function(status){
        switch (status) {
          case "delivered": {
            return "Message has been received but not yet opened."
          }

          case "sent": {
            return "Message has been sent but not yet delivered."
          }

          case "opened": {
            return "Message has been opened by recipient."
          }

          case "resent": {
            return "Message has been resent but not yet delivered."
          }

          case "clicked": {
            return "The recipient has clicked on a link";
          }

          case "handled": {
            return "It has been manually handled";
          }

          default: {
            return "It was not possible to deliver the message.";
          }
        }
      }
    }])
;

angular.module('Cockpit.State.Notifications', [
    'Cockpit.Service.Navigation',
    'Cockpit.Service.Notification',
    'Cockpit.State.Notifications.Details',
    'Cockpit.Service.Notification.Templates'
  ])

    .config(["$stateProvider", function config($stateProvider) {
        $stateProvider.state('private.notifications', {
            url: '/notifications',
            controller: 'NotificationsController',
            templateUrl: 'states/notifications/notifications.tpl.html',
            resolve: {
                theLanguages: resolvers.languagesResolver,
            },
        })

        ;
    }])

    .run(["Navigation", function (Navigation) {
        Navigation.primary.register("Notifications", "private.notifications");

        Navigation.sidebar.register('private.notifications', 'Notifications', [
            {
                Label: 'Notifications',
                State: 'private.notifications',
                Groups: ["admin"]
            }
        ]);
    }])

    .controller('NotificationsController', ["$scope", "$filter", "Notification", "theLanguages", "NotificationTemplates", function NotificationsController($scope, $filter, Notification, theLanguages, NotificationTemplates){
        $scope.languages = theLanguages;
        $scope.showPagination = true;
        $scope.request = {};
        $scope.totalFailedNotifications = 0;
        $scope.selectedNotificationTypes = []
        $scope.availableNotificationTypes = [];
        NotificationTemplates.available(function(res) {
          $scope.availableNotificationTypes = res;
        })
        $scope.selectedNotifications = [];
        $scope.hasError = false;

        // split into array of arrays for ng-repeat in multiple columns
        function chunk(templates, size) {
          var listOfTempaltes = [];
          for (var i=0; i<templates.length; i+=size) {
            listOfTempaltes.push(templates.slice(i, i+size));
          }
          return listOfTempaltes;
        }

        $scope.doSearch = function () {
          $scope.request.currentPage = 1;
          getNotifications()
        }

        $scope.reset = function () {
          $scope.request = {
            currentPage: 1,
            limit: '10'
          };
          $scope.selectedNotificationTypes = [];
          // reset checkboxes
          resetFiltersAndSearch();
          $scope.doSearch();
        }

        function resetFiltersAndSearch(){
          $scope.request = {
            currentPage: 1,
            limit: '10'
          };
          $scope.selectedNotificationTypes = [];
          $scope.availableNotificationTypes.forEach(function (type) {
            type.selected = false;
          })
        }

        $scope.filterNotifications = function(slug){
          var index = $scope.selectedNotificationTypes.findIndex(function (type) {
            return type.slug === slug;
          });
          if (index > -1) {
            $scope.selectedNotificationTypes.splice(index, 1);
          } else {
            const notificationType = $scope.availableNotificationTypes.find(function (t) {
              return t.slug === slug;
            })
            $scope.selectedNotificationTypes.push({
              slug: notificationType.slug,
              name: notificationType.name
            });
          }
          $scope.request.typeNames = $scope.selectedNotificationTypes.map(function(t) {
            return t.name;
          }).join(',');
          $scope.request.typeSlugs = $scope.selectedNotificationTypes.map(function(t) {
            return t.slug;
          }).join(',');
        }

        $scope.fetchFailed = function () {
          $scope.notificationsLoading = true;
          resetFiltersAndSearch();

          Notification.query(
            {
              excludeFailedCount: false,
              pageCount: 1,
            },
            function (data, headers) {
              $scope.totalFailedNotifications = headers("x-total-failed");

              Notification.getFailed(
                { failedCount: $scope.totalFailedNotifications },
                function (data) {
                  $scope.showPagination = false;
                  $scope.notifications = data;
                  $scope.notificationsLoading = false;
                },
                function (err) {
                  $scope.notificationsLoading = false;
                }
              );
            },
            function (err) {
              console.log("Error getting notifications => ", err);
              $scope.notificationsLoading = false;
            }
          );
        };

        $scope.updateSelectedNotifications = function(){
          $scope.selectedNotifications = $scope.notifications.filter(function(n) {
            return n.selected;
          })
        }

        $scope.resendNotifications = function (){

          if (!window.confirm('Do you want to resend ' + $scope.selectedNotifications.length + ' notifications?')) {
            return;
          }

          $scope.notificationsLoading = true;

          var selectedNotificationsIds = $scope.selectedNotifications.map(function(n){
            return n.id
          })

          Notification.resendNotifications({notificationIds: selectedNotificationsIds}, function (data) {
            if(data.errorMessage){
              $scope.infoMessage = data.errorMessage
              $scope.hasError = true;
            } else {
              $scope.infoMessage = "Success! Selected notifications have been resent!"
              $scope.hasError = false;
            }
            removeInfoMessage()
            $scope.notificationsLoading = false;
          }, function (err) {
            $scope.infoMessage = "Failed. Try again later or contact a developer.";
            $scope.hasError = true;
            $scope.notificationsLoading = false;
            removeInfoMessage();
          });
        }

        function removeInfoMessage() {
          setTimeout(function () {
            $scope.infoMessage = null;
          }, 5000);
        }


        $scope.confirmManualHandling = function (){
          if (!window.confirm('Are you sure the selected emails (' + $scope.selectedNotifications.length + ') have been handled?')) {
            return;
          }

          var selectedNotificationsIds = $scope.selectedNotifications.map(function(n){
            return n.id
          })

          Notification.update({notificationIds: selectedNotificationsIds}, function (data) {
            if(data.failedNotificationIds.length){
              $scope.infoMessage = "Failed. Try again later or contact a developer.";
              $scope.hasError = true;
            } else {
              $scope.infoMessage = "Success! Manual handling has been confirmed!"
              $scope.hasError = false;
            }
            $scope.notificationsLoading = false;
            removeInfoMessage()
          }, function (err) {
            $scope.hasError = true;
            $scope.infoMessage = "Failed. Try again later or contact a developer.";
            $scope.notificationsLoading = false;
            removeInfoMessage()
          });
        }

        $scope.clearSelectedNotifications = function() {
          $scope.notifications.forEach(function (n){
            n.selected = false;
          })
          $scope.selectedNotifications = [];
        }

        $scope.paginationArr = [];
        $scope.request.currentPage = 1;
        $scope.request.limit = '10';
        $scope.totalPages = 1;

        function getNotifications (){
          $scope.notificationsLoading = true;
          $scope.showPagination = true;

          var excludeFailedCount = shouldExcludeFailedNotifications();

          Notification.query({
            PageNumber: $scope.request.currentPage,
            NotificationTypes: $scope.request.typeNames,
            NotificationSlugs: $scope.request.typeSlugs,
            orderNumber: $scope.request.orderNumber,
            recipient: $scope.request.recipient,
            deliveryType: $scope.request.type,
            languageCode: $scope.request.languageCode,
            excludeFailedCount: true,
            pageCount: $scope.request.limit
          }, function (data, headers) {
            makePaginationArr(headers('x-total-count'));
            if (!shouldExcludeFailedNotifications()) {
              $scope.totalFailedNotifications = headers('x-total-failed');
            }

            $scope.notifications = data;
            $scope.notificationsLoading = false;
          }, function(err) {
            console.log("Error getting notifications => ", err);
            $scope.notificationsLoading = false;
          });
        }

        function shouldExcludeFailedNotifications() {
          return (
            !isNullOrEmpty($scope.request.types)
            || !isNullOrEmpty($scope.request.orderNumber)
            || !isNullOrEmpty($scope.request.recipient)
            || !isNullOrEmpty($scope.request.type)
            || !isNullOrEmpty($scope.request.languageCode)
          );
        }

        function isNullOrEmpty(input) {
          return input === null || angular.isUndefined(input) || input === '';
        }

        function makePaginationArr(totalNotifications){
          // Calculate total pages
          $scope.totalPages = Math.ceil(totalNotifications / 20);
          $scope.paginationArr = [];

          // First and last visible pages
          var firstVisiblePage, lastVisiblePage;
          if ($scope.request.currentPage <= 6) {
            firstVisiblePage = 1;
            lastVisiblePage = $scope.totalPages > 10 ? 10 : $scope.totalPages;
          } else if ($scope.request.currentPage + 4 >= $scope.totalPages) {
            firstVisiblePage = $scope.totalPages - 9 > 0 ? $scope.totalPages - 9 : 1;
            lastVisiblePage = $scope.totalPages;
          } else {
            firstVisiblePage = $scope.request.currentPage - 5;
            lastVisiblePage = $scope.request.currentPage + 4;
          }

          // Push the visible pages to the pagination array
          for (var i = firstVisiblePage; i <= lastVisiblePage; i++){
            $scope.paginationArr.push(i);
          }
        }

        // Initial load of notification
        getNotifications();

        $scope.doNewPage = function (number) {
          if(number == '...' || Number(number) < 1 || Number(number) > $scope.totalPages){
            return;
          }
          $scope.request.currentPage = number;
          $scope.paginationArr = [];
          getNotifications(number);
        }

        $scope.getTooltip = function(status){
          switch (status) {
            case "delivered": {
              return "Message has been received but not yet opened."
            }

            case "sent": {
              return "Message has been sent but not yet delivered."
            }

            case "opened": {
              return "Message has been opened by recipient."
            }

            case "resent": {
              return "Message has been resent but not yet delivered."
            }

            case "clicked": {
              return "The recipient has clicked on a link";
            }

            case "handled": {
              return "It has been manually handled";
            }

            default: {
              return "It was not possible to deliver the message.";
            }
          }
        }
    }])
;

angular.module('Cockpit.Partial.Orders.Add.Preview', [
  'ui.router',
  'Cockpit.Service.Orders.Basket'
])

  .controller('CockpitOrderAddPreviewController', ["$scope", "basketId", "$uibModalInstance", "BasketService", "numberOfOrders", function CockpitOrderAddPreviewController($scope, basketId, $uibModalInstance, BasketService, numberOfOrders) {
    $scope.numberOfOrders = numberOfOrders;

    getBasket();

    function getBasket() {
      $scope.isLoading = true;

      BasketService.get({ basket: basketId }, function (res) {
        $scope.isLoading = false;
        $scope.basket = res;

      }, function (err) {
        $scope.isLoading = false;
        alert('Failed to get order. Please try again.');
        console.log('Err => ', err)
      });
    }

    $scope.closeModal = function (orderConfirmed) {
      $uibModalInstance.close({
        orderConfirmed: orderConfirmed
      });
    }
  }])

angular.module('Cockpit.Service.Orders.Basket', ['ngResource'])
  .factory('BasketService', ['$resource', function ($resource) {
    return $resource(Config.ApiV1 + 'basket', {}, {
      get: { method: 'GET', headers: {
        'Authorization': undefined // CORS will stop reqeust on basket service if this is present so removing the header from this request
      } }
    });
  }])

angular
  .module("Cockpit.State.Orders.Add", [
    "Cockpit.Service.OrderNote",
    "Cockpit.Service.WrappingMethods",
    "Cockpit.Partial.Orders.Add.Preview",
    "Cockpit.Service.AccountingGroups"
  ])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider
      .state("private.orders.add", {
        url: "/add",
        resolve: {
          theLanguages: resolvers.languagesResolver,
          accountingGroups: [
            "$q",
            "AccountingGroups",
            function($q, AccountingGroups) {
              var d = $q.defer();
              AccountingGroups.get(function(ag) {
                try {
                  d.resolve(ag.items);
                } catch (e) {
                  console.log('error parsing accounting groups', e);
                  d.resolve([]);
                }
              }, function(error) {
                console.log("Error loading accounting groups => ", error);
                d.resolve([]);
              });

              return d.promise;
            }
          ],
          orderData: [
            function () {
              return {
                OrderStatus: null,
                OrderNumber: "",
                Customer: {
                  Type: "privat",
                  Company: "",
                  VatNo: "",
                  Firstname: "",
                  Lastname: "",
                  Address: "",
                  Postcode: "",
                  City: "",
                  Email: "",
                  PhoneNumber: "",
                  Newsletter: false,
                },
                Orderlines: [],
                CustomProperties: {}
              };
            },
          ],
        },
        views: {
          "@private": {
            controller: "CockpitOrderAddController",
            templateUrl: "states/orders/order-add.tpl.html",
          },
        },
        data: {
          pageTitle: "Orders",
        },
      })

      .state("private.orders.edit", {
        url: "/edit/{OrderNumber}",
        resolve: {
          theLanguages: resolvers.languagesResolver,
          accountingGroups: [
            "$q",
            "AccountingGroups",
            function($q, AccountingGroups) {
              var d = $q.defer();
              AccountingGroups.get(function(ag) {
                try {
                  d.resolve(ag.items);
                } catch (e) {
                  console.log('error parsing accounting groups', e);
                  d.resolve([]);
                }
              }, function(error) {
                console.log("Error loading accounting groups => ", error);
                d.resolve([]);
              });

              return d.promise;
            }
          ],
          orderData: [
            "$q",
            "$stateParams",
            "Order",
            function ($q, $stateParams, Order) {
              var d = $q.defer();

              Order.getDetailed(
                { OrderNumber: $stateParams.OrderNumber },
                function (o) {
                  d.resolve(o);
                },
                function () {
                  console.log("Error loading order");
                }
              );

              return d.promise;
            },
          ],
        },
        views: {
          "@private": {
            controller: "CockpitOrderAddController",
            templateUrl: "states/orders/order-add.tpl.html",
          },
        },
        data: {
          pageTitle: "Orders",
        },
      });
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register("private.orders", "Orders", [
      {
        Label: "Add Order",
        State: "private.orders.add",
        Groups: ["admin", "support"],
      },
      {
        Label: "Edit Validity",
        State: "/v2/admin/orders/edit-validity/",
        Groups: ["admin", "support"],
      },
    ]);
  }])

  .controller(
    "CockpitOrderAddController",
    ["$scope", "$state", "WrappingMethods", "PimProduct", "ProductV2", "Order", "accountingGroups", "orderData", "OrderNote", "DiscountCode", "theLanguages", "$uibModal", function CockpitOrderAddController(
      $scope,
      $state,
      WrappingMethods,
      PimProduct,
      ProductV2,
      Order,
      accountingGroups,
      orderData,
      OrderNote,
      DiscountCode,
      theLanguages,
      $uibModal
    ) {
      var ORDER_EXPIRATION_MAX_YEARS = 3;
      $scope.product = null;
      $scope.orderline = {
        Product: null,
        Variant: null,
        Quantity: 1,
      };
      $scope.currencies = ["DKK", "NOK", "SEK"];
      $scope.languages = theLanguages;
      $scope.accountingGroups = accountingGroups;
      $scope.newOrderNote = null;

      // v2 product data
      $scope.bookingRequestVariants = [];
      $scope.selectedProduct = null;
      $scope.participantTypes = null;
      $scope.variants = null;
      $scope.selectedParticipantType = null;
      $scope.selectedVariant = null;
      $scope.shouldSelectVariant = false;
      $scope.isStaticQuantity = false;
      $scope.selectedWrapping = null;
      $scope.numberOfOrders = 1;

      $scope.isValidatingCode = false;
      $scope.codeValidationStatus = '';
      $scope.codeRemainingUses = -1;
      $scope.validateCode = function validateCode(){
        $scope.codeRemainingUses = -1;
        $scope.isValidatingCode = true;
        $scope.codeValidationStatus = '';

        $scope.voucher = DiscountCode.get({code: $scope.order.VoucherCode}, function (data) {
          $scope.isValidatingCode = false;

          if(data.maxUses > data.used){
              $scope.codeValidationStatus = 'valid';
          }else{
              $scope.codeValidationStatus = 'used';
          }

          $scope.codeRemainingUses = data.maxUses - data.used;
        }, function (err) {
          $scope.isValidatingCode = false;
          $scope.codeValidationStatus = 'invalid';
      });
    }


      $scope.saveOrderNote = function () {
        OrderNote.save(
          { orderNumber: orderData.OrderNumber },
          {
            note: $scope.newOrderNote,
          },
          function (res) {
            alert("Note saved!");
            $scope.newOrderNote = null;
          },
          function (err) {
            alert("Error saving note: ", err);
          }
        );
      };

      $scope.order = orderData;
      $scope.isBulkOrder = angular.isDefined(orderData.CustomProperties.BulkId) && orderData.CustomProperties.BulkId.length === 36;

      $scope.order.SendMails = false;
      $scope.isEdit = orderData.OrderNumber != '';

      $scope.expirationDate = (function () {
        if($scope.isEdit){
          var dateObj = new Date($scope.order.CustomProperties.Expires);
          return dateObj.toLocaleDateString("da-dk");
        } else {
          var expirationDate = new Date();
          expirationDate.setFullYear(expirationDate.getFullYear() + 2);
          return expirationDate;
        }
      })();


      if ($scope.isEdit) {
        if (
          !angular.isUndefined($scope.order.CustomProperties) &&
          $scope.order.CustomProperties != null
        ) {
          $scope.order.Personalization = {
            Message: $scope.order.CustomProperties.PersonalizedDate,
            Date: $scope.order.CustomProperties.PersonalizedMessage,
            Name: $scope.order.CustomProperties.PersonalizedName,
          };
        }
      }

      function setDefaultDeliveryAddress(){
        if($scope.selectedWrapping && !$scope.selectedWrapping.isDigital){
          $scope.order.DeliveryAddress.Company = $scope.order.DeliveryAddress.Company || $scope.order.Customer.Company;
          $scope.order.DeliveryAddress.Firstname = $scope.order.DeliveryAddress.Firstname || $scope.order.Customer.Firstname;
          $scope.order.DeliveryAddress.Lastname = $scope.order.DeliveryAddress.Lastname || $scope.order.Customer.Lastname;
          $scope.order.DeliveryAddress.Address = $scope.order.DeliveryAddress.Address || $scope.order.Customer.Address;
          $scope.order.DeliveryAddress.Postcode = $scope.order.DeliveryAddress.Postcode || $scope.order.Customer.Postcode;
          $scope.order.DeliveryAddress.City = $scope.order.DeliveryAddress.City || $scope.order.Customer.City;
          $scope.order.DeliveryAddress.Email = $scope.order.DeliveryAddress.Email || $scope.order.Customer.Email;
          $scope.order.DeliveryAddress.PhoneNumber = $scope.order.DeliveryAddress.PhoneNumber || $scope.order.Customer.PhoneNumber;
        }
      }
      $scope.$watch("order.LanguageCode", function(languageCode) {

        // set OrderCurrency if !isEdit
        if( !$scope.isEdit ) {
          switch (languageCode) {
            case "nb-no": {
              $scope.order.OrderCurrency = "NOK"
              break;
            }

            case "sv-se": {
              $scope.order.OrderCurrency = "SEK"
              break;
            }

            default: {
              $scope.order.OrderCurrency = "DKK"
              break;
            }
          }
        }

        if( languageCode ) {
          WrappingMethods.query({languageCode: languageCode}, function(wrappingMethods){
            $scope.wrappingMethods = wrappingMethods;

            if($scope.order.WrappingMethodId){
              $scope.selectedWrapping = $scope.wrappingMethods.find(function(wm){
                return wm.methodId === $scope.order.WrappingMethodId
              })
            }
          })
        } else {
          $scope.wrappingMethods = null;
          $scope.selectedWrapping = null;
        }
      })

      $scope.$watch("order.Customer.Type", function (type){
        if($scope.selectedWrapping){
          $scope.order.DeliveryMethod = null;
          $scope.order.DeliveryAddress = null;

          if($scope.selectedWrapping && !$scope.selectedWrapping.isDigital){
            $scope.order.DeliveryMethod = "address";
            $scope.order.DeliveryAddress = {};
            $scope.order.DeliveryAddress.Type = "private";
            setDefaultDeliveryAddress();
          }
        }
      })

      $scope.$watch("order.DeliveryMethod", function (type){
        if(!$scope.order.DeliveryAddress){
          $scope.order.DeliveryAddress = {}
        }
        $scope.order.DeliveryAddress.Type = "private";
        if(!$scope.isEdit){
          setDefaultDeliveryAddress();
        }
      })

      $scope.$watch("order.DeliveryAddress.Type", function (type){
        if(!$scope.isEdit){
          setDefaultDeliveryAddress();
        }
      })

      $scope.orderStatuses = Order.orderStatuses();

      var truestoryGiftcard = {
        sku: '99999',
        name: 'Truestory giftcard'
      }

      $scope.isLoadingProducts = true;
      if ('allProducts' in window.sessionStorage) {
        $scope.products = JSON.parse(window.sessionStorage.getItem('allProducts'));

        if( $scope.products.findIndex(function(p) {return p.sku === truestoryGiftcard.sku} ) < 0 ) {
          $scope.products.push(truestoryGiftcard);
        }

        $scope.isLoadingProducts = false;
      } else {
        PimProduct.query(function (products) {
          $scope.isLoadingProducts = false;

          //manual add Truestory gift card since not available from struct
          products.push(truestoryGiftcard);

          $scope.products = products;
          window.sessionStorage.setItem('allProducts', JSON.stringify(products));
        }, function () {
          $scope.isLoadingProducts = false;
          $scope.products = null;
          alert('could not fetch products. Try again.');
        });
      }

      $scope.price = 0;
      $scope.vatRate = 0;

      $scope.$watch("products", function (products) {
        if (!products || products.length === 0) {
          return;
        }

        if ($scope.isEdit) {
          $scope.selectedProduct = products.find(function (product) {
            return product.sku === $scope.order.Orderlines[0].Sku;
          });
        }
      });

      $scope.$watch("selectedProduct", function (product) {
        if (!product) {
          return;
        }

        resetOrderlineInput();
        $scope.participantTypes = [];
        $scope.variants = [];
        $scope.isStaticQuantity = false;

        // don't fetch truestory giftcard
        if (product.sku === '99999') {
          $scope.vatRate = 0;
          return;
        }

        var languageCode = $scope.order.LanguageCode || "da-dk";
        ProductV2.getBySku({ sku: product.sku, languageCode: languageCode }, function (structProduct) {
          $scope.bookingRequestVariants = structProduct.bookingRequest && structProduct.bookingRequest.variants ? structProduct.bookingRequest.variants.map(function(v) {
            return v.sku;
          }) : [];
          $scope.participantTypes = structProduct.participantTypes.filter(function(pt) {
            return pt.sku ? !$scope.bookingRequestVariants.includes(pt.sku) : true;
          });
          if (!$scope.isEdit) {
            return;
          }

          // set vatRate for proper display of prices. We just need the first available.
          var variantSku = $scope.order.Orderlines[0].VariantSku;
          var productItem = $scope.participantTypes.find(function(pt) { return pt.sku === variantSku });
          if (!productItem) {
            productItem = $scope.participantTypes[0].variants.find(function(v) { return v.sku === variantSku });
          }

          var priceData = getPriceDataFromOrderCurrency(productItem.prices);
          updatePriceAndVatRate(priceData);

        }, function (err) {
          $scope.participantTypes = [];
          alert('Could not get product data with currency: ' + $scope.order.OrderCurrency);
          $scope.selectedProduct = null;
        });
      });

      $scope.$watch("selectedParticipantType", function (participantType) {
        // default value if non selected
        if (!participantType && $scope.participantTypes && $scope.participantTypes.length > 0) {
          $scope.selectedParticipantType = $scope.participantTypes[0];
          return;
        }

        if (!participantType) {
          return;
        }

        // if product has variants stop here
        $scope.shouldSelectVariant = participantType.variants.length > 0;
        if ($scope.shouldSelectVariant) {

          $scope.variants = participantType.variants.filter(function(variant) {
            return variant.sku ? !$scope.bookingRequestVariants.includes(variant.sku) : true;
          });
          return;
        }

        $scope.isStaticQuantity = participantType.staticQuantity;
        setInitialOrderlineQuantity(participantType.minQuantity);
        var priceData = getPriceDataFromOrderCurrency(participantType.prices);
        updatePriceAndVatRate(priceData);
      });

      $scope.onExpirationChange = function(expirationDate) {
        $scope.expirationDate = expirationDate;
      }

      $scope.onWrappingChange = function(wrapping) {
        $scope.selectedWrapping = wrapping;
        if($scope.selectedWrapping.isDigital){
          $scope.order.DeliveryMethod = null;
          $scope.order.DeliveryAddress = null;
        } else {
          if(!$scope.order.DeliveryAddress){
            $scope.order.DeliveryAddress = {};
          }
          $scope.order.DeliveryAddress.Type = "private";
          if($scope.order.Customer.Type === "erhverv"){
            $scope.order.DeliveryMethod = "address";
          }
        }
      }

      $scope.$watch("selectedVariant", function (variant) {
        if (!variant) {
          return;
        }

        $scope.isStaticQuantity = variant.staticQuantity;
        setInitialOrderlineQuantity(variant.minQuantity);
        var priceData = getPriceDataFromOrderCurrency(variant.prices);
        updatePriceAndVatRate(priceData);
      });

      function getPriceDataFromOrderCurrency(prices) {
        return prices.find(function (x) {
          return x.currency.isoCode === $scope.order.OrderCurrency;
        });
      }

      function updatePriceAndVatRate(priceData) {
        $scope.price = priceData.price;
        $scope.vatRate = priceData.vatGroup.vatRate;
      }

      $scope.addOrderline = function addOrderline() {
        if (!$scope.selectedProduct && $scope.price != 0) {
          return;
        }

        var selectedItem = $scope.selectedVariant
          ? $scope.selectedVariant
          : $scope.selectedParticipantType;

        // check for variantSku when not truestory giftcard
        if ((!selectedItem || !selectedItem.sku) && $scope.selectedProduct.sku !== '99999') {
          alert('You need to select the variant or participantType');
          return;
        }

        var orderline = {
          Price: $scope.price,
          Quantity: $scope.orderline.Quantity,
          Sku: $scope.selectedProduct.sku,
          VariantSku: selectedItem ? selectedItem.sku : null,
          Name: $scope.selectedProduct.name + ' ' + (selectedItem ? (selectedItem.name ? selectedItem.name : selectedItem.nameSingle) : '')
        };

        $scope.order.Orderlines.push(orderline);
        resetOrderlineInput();
      };

      function resetOrderlineInput() {
        $scope.selectedParticipantType = null;
        $scope.selectedVariant = null;
        $scope.orderline = {
          Quantity: 1
        };
        $scope.price = 0;
      }

      function setInitialOrderlineQuantity(quantity) {
        $scope.orderline.Quantity = quantity;
      }

      $scope.removeOrderline = function removeOrderline(orderline) {
        var index = $scope.order.Orderlines.indexOf(orderline);
        if (index < 0) {
          return;
        }
        $scope.order.Orderlines.splice(index, 1);
        if ($scope.order.Orderlines.length === 0) {
          $scope.price = 0;
        }
      };

      $scope.shouldHideAddOrderline = function () {
        if ($scope.isEdit) {
          return true;
        }

        if ($scope.shouldSelectVariant && $scope.order.Orderlines.length > 0) {
          return true;
        }

        if ($scope.order.Orderlines.length > 0 && $scope.order.Orderlines[0].Sku === '99999') {
          return true;
        }

        if ($scope.order.Orderlines.length > 0 && $scope.isStaticQuantity) {
          return true;
        }

        return false;
      }

      $scope.isProcessing = false;
      $scope.process = function processSaveOrder() {
        if (!$scope.isEdit) {
          $scope.addOrders(true);
          return;
        }

        $scope.isProcessing = true;
        $scope.order.CustomProperties = null;
        Order.save(
          $scope.order,
          function (d) {
            $state.go("private.search-details", { "orderNumber": $scope.order.OrderNumber }, { reload: "private.search-details" });
          },
          function (e) {
            console.log("Error: ", e);
            alert("Error saving order!");
          }
        );
      };


      $scope.addOrders = function addOrders(shouldPreviewBasket) {
        $scope.errorMessage = null;
        $scope.isProcessing = true;

        var expirationDateObj = new Date($scope.expirationDate);
        var currentDate = new Date();
        var maxDate = new Date();
        maxDate.setFullYear(currentDate.getFullYear() + ORDER_EXPIRATION_MAX_YEARS);

        if (isNaN(expirationDateObj.getTime())) {
          $scope.errorMessage = "The expiration date is invalid. Please enter a valid date.";
          $scope.isProcessing = false;
          return;
        }

        if (expirationDateObj <= currentDate) {
          $scope.errorMessage = "The expiration date must be a future date. Please select a date that is later than today.";
          $scope.isProcessing = false;
          return;
        }

        if (expirationDateObj > maxDate) {
          $scope.errorMessage = "The expiration date must be within the next " + ORDER_EXPIRATION_MAX_YEARS + " years. Please select a closer date.";
          $scope.isProcessing = false;
          return;
        }

        var expirationDateStr = expirationDateObj.toISOString().slice(0, 10);
        $scope.order.CustomProperties.Expires = expirationDateStr;
        Order.bulkSave({
          Count: $scope.numberOfOrders,
          Order: $scope.order,
          IsBulkOrder: $scope.isBulkOrder,
          ExistingBulkId: $scope.existingBulkId,
          PreviewBasket: shouldPreviewBasket
        },
          function (d) {
            // show preview
            if (shouldPreviewBasket && d.Success) {
              $scope.isProcessing = false;
              showPreviewBasket(d.BasketId);
              return;
            }

            if (!d.Success) {
              $scope.isProcessing = false;
              $scope.errorMessage = "Failed saving. Did you input everything correctly?";
              return;
            }

            if (d.OrderNumbers && d.OrderNumbers.length > 0) {
              $state.go("private.search-details", { "orderNumber": d.OrderNumbers[0] });
              return;
            }
            $state.go("private.orders");
            return;
          },
          function (e) {
            $scope.isProcessing = false;
            console.log("Error: ", e);
            alert("Error saving bulk orders!");
          }
        );
      };

      function showPreviewBasket(basketId) {
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: "states/orders/preview-add-order.tpl.html",
          resolve: {
            basketId: function () { return basketId; },
            numberOfOrders: function () { return $scope.numberOfOrders; }
          },
          size: 'lg',
          controller: "CockpitOrderAddPreviewController"
        })

        modalInstance.result.then(function (res) {
          if (!res) {
            return;
          }

          if (res.orderConfirmed) {
            $scope.addOrders(false);
          }
        }, function() {})
      }
    }]
  );

angular.module('Cockpit.State.Orders', [
	'ui.router',
	'Cockpit.State.Orders.Add',
	'Cockpit.Service.Product',
	'Cockpit.State.Redeem',
	'Cockpit.State.Portfolio'
])

.config(["$stateProvider", function config($stateProvider) {
	$stateProvider.state('private.orders', {
		url: '/orders',
		controller: 'CockpitOrdersController',
		templateUrl: 'states/orders/orders.tpl.html',
		data: {
			pageTitle: 'Orders'
		}
	})

	;
}])

.run(["Navigation", function (Navigation) {
	Navigation.primary.register("Orders", "private.orders");
}])

.controller('CockpitOrdersController', ["$scope", "Search", function CockpitOrdersController($scope, Search) {
  $scope.isSearching = true;
  $scope.search = {};
  $scope.search.Results = Search.query(
    { query: 'recentorders', simpleSearch: true },
    function searchSuccess(res) {
      $scope.isSearching = false;
    },
    function searchError(err) {
      console.log("Error on search resolve: ", err);
      $scope.isSearching = false;

    }
  );


}])

;

angular.module('Cockpit.State.Products', [
  'Cockpit.Service.Navigation',
  'Cockpit.State.ProductCodes',
  'Cockpit.State.Tiles',
  'Cockpit.State.CallOuts'
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.products', {
      url: '/products',
      controller: 'ProductsController',
      templateUrl: 'states/products/products.tpl.html',
      data: {
        pageTitle: 'Products'
      }
    })
    ;
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.primary.register("Products", "private.products");
  }])

  .controller('ProductsController', function ProductsController() {

  })
;

angular
  .module("Cockpit.State.Search.Details", [
    "ui.router",
    "Cockpit.Service.Search",
    "Cockpit.Service.OrderNote",
    "Cockpit.Service.Dgda.OpenGiftCards",
    "Cockpit.Service.Notification",
    "Cockpit.Directive.BookingDetails",
    "Cockpit.Service.Extensions",
  ])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider
      .state("private.search-details", {
        url: "/:orderNumber/details",
        resolve: {
          orderData: [
            "$q",
            "$stateParams",
            "Search",
            function resolveOrderData($q, $stateParams, Search) {
              var d = $q.defer();
              Search.query(
                { query: $stateParams.orderNumber },
                function searchSuccess(res) {
                  if (res.length > 0) {
                    d.resolve(res[0]);
                  } else {
                    d.reject();
                  }
                },
                function searchError(err) {
                  console.log("Error on search resolve: ", err);
                  d.reject();
                }
              );

              return d.promise;
            },
          ],
          productData: [
            "$q",
            "orderData",
            "ProductV2",
            function($q, orderData, ProductV2) {
              var orderSku = orderData.OrderLines ? orderData.OrderLines[0].Sku : null;
              if( !orderSku ||  "99999" === orderSku ||  "S-00001" === orderSku ) {
                return;
              }

              var d = $q.defer();
              ProductV2.getBySku(
                {
                  sku: orderData.OrderLines[0].Sku,
                  languageCode: orderData.LanguageCode ? orderData.LanguageCode.toLowerCase() : "da-dk"
                },
                function(data) {
                  d.resolve(data);
                },
                function(err) {
                  console.log("Error getting product data => ", err);
                  d.resolve(null);
                }
              );

              return d.promise;
            }
          ]
        },
        controller: "CockpitSearchDetailsController",
        templateUrl: "states/search/details.tpl.html",
        data: {
          pageTitle: "Details",
        },
      });
  }])

.controller(
  "CockpitSearchDetailsController",
  ["$filter", "$scope", "$timeout", "orderData", "productData", "Notification", "OrderNote", "OpenGiftCard", "OpenGiftCardOrder", "Extensions", function CockpitSearchDetailsController(
    $filter,
    $scope,
    $timeout,
    orderData,
    productData,
    Notification,
    OrderNote,
    OpenGiftCard,
    OpenGiftCardOrder,
    Extensions
  ) {
    //Scroll to top on enter
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    $scope.order = orderData;
    var didFetchOrderNotes = false;
    $scope.notifications = [];
    $scope.extensions = [];
    $scope.isTruestoryGiftcardOrder = orderData.OrderLines && orderData.OrderLines[0].Sku === "99999";
    $scope.isExtensionFeeOrder = orderData.OrderLines && orderData.OrderLines[0].Sku === "S-00001";
    $scope.showBookingDetails = !$scope.isTruestoryGiftcardOrder && !$scope.isExtensionFeeOrder;

    getNotifications();
    function getNotifications() {
      Notification.query(
        {
          orderNumber: orderData.OrderNumber,
          PageNumber: 1,
          excludeFailedCount: true
        },
        function (res) {
          $scope.errorMessage = null;
          $scope.notifications =  JSON.parse(angular.toJson(res));
          refreshOrderHistory(true);
        },
        function(err) {
          $scope.errorMessage = "Notifications are missing in order history. Try refreshing again.";
          console.log('Error getting notifications => ', err)
        }
      );
    }
    if( !$scope.isExtensionFeeOrder ) {
      getOrderExtensions();
    }

    function getOrderExtensions() {
      Extensions.query(
        { orderNumber: orderData.OrderNumber },
        function (res) {
          $scope.errorMessage = null;
          $scope.extensions = res.items ? res.items : [];
          refreshOrderHistory(true);
        },
        function (err) {
          $scope.errorMessage = "Extensions are missing in order history. Try refreshing again.";
          console.log("Error getting extensions => ", err);
        }
      );
    }

    $scope.getDiscountDescription = function(itemName, description) {
      return itemName !== "" || description !== "" ? "(" + itemName +  (description !== "" ? " - " + description : "") +")" : "";
    }

    $scope.getDroppointAddress = function(){

      if(!$scope.order.Shipping || !$scope.order.Shipping.ShipmentDropPoint){
        return "";
      }

      var droppoint = $scope.order.Shipping.ShipmentDropPoint;

      var droppointAddress = "";

      if(droppoint.Address1){
        droppointAddress += droppoint.Address1;

        if(droppoint.Address2){
          droppointAddress += (" " + droppoint.Address2);
        }

      }

      if(droppoint.Zip){
        droppointAddress += (", " + droppoint.Zip);
      }

      if(droppoint.City){
        droppointAddress += (" " + droppoint.City);
      }

      if(droppoint.Country){
        droppointAddress += (", " + droppoint.Country);
      }

      return droppointAddress;
    }

    $scope.getShopDistance = function(){

      if(!$scope.order.Shipping || !$scope.order.Shipping.ShipmentDropPoint || !$scope.order.Shipping.ShipmentDropPoint.Distance){
        return "";
      }

      return "(" + order.Shipping.ShipmentDropPoint.Distance.toFixed(1) + ")" + " km";
    }

    $scope.formatedRefundableUntil = $scope.order.Properties.RefundableUntil !== '-' ? new Date($scope.order.Properties.RefundableUntil).toLocaleDateString("da-dk") : null;

    //Copy order id
    $scope.orderIdCopied = false;
    $scope.copyOrderId = function(id) {
      $scope.orderIdCopied = true;
      navigator.clipboard.writeText(id.toUpperCase());
      $timeout(function() {
        $scope.orderIdCopied = false;
      },500)
    };
    var participantTypes = productData && productData.participantTypes;

    $scope.orderHasParticipantTypes = participantTypes && participantTypes.length > 1 && null !== participantTypes[0].sku;

    $scope.getParticipantTypeName = function(variantSku, quantity) {
      var filteredParticipantTypes  = participantTypes.filter(function(participant) {
        return participant.sku === variantSku;
      });
      return quantity > 1 ? filteredParticipantTypes[0].namePlural : filteredParticipantTypes[0].nameSingle;
    }

    // $scope.isExchangeOrder = orderData.PaymentMethod && orderData.PaymentMethod === "Exchange";

    // if( $scope.isExchangeOrder ) {
    //   $scope.exchangeVoucherPdfUrl = "https://static." + stagingUrlPrefix + "truestory.com/pdf/exchange-vouchers/" + orderData.Properties.OldOrder + ".pdf";
    // }

    // Product price
    $scope.productTotal = $scope.order.OrderLines.reduce(function(sum, current) {
      return sum + current.Total;
    }, 0);
    $scope.productVat = $scope.order.OrderLines.reduce(function(sum, current) {
      return sum + ($scope.orderHasParticipantTypes ? (current.VatTotal) : current.Vat);
    }, 0);
    $scope.productTotalQuantity = $scope.order.OrderLines.reduce(function(sum, current) {
      return sum + current.Quantity;
    }, 0);

    $scope.getDiscountAmount = function(discount, includingVat) {
      if( includingVat ) {
        return discount.Description.indexOf("Voucher-") > -1 ? discount.Amount : 0;
      }
      return discount.Description.indexOf("Voucher-") > -1 ? 0 : discount.Amount;
    }

    $scope.getTSGiftcardVoucher = function(discount) {
      if( discount.Description.indexOf("Voucher-") > -1) {
        var voucherCode = discount.Description.replace("Voucher-", "");
        return "(" + discount.Description + ")";
      }
      return "";
    }

    $scope.totalDiscountsExVat = 0;
    $scope.totalDiscountsIncVat = 0;
    $scope.tsGiftcardApplied = false;
    $scope.totalDiscountVat = 0;

    if( orderData.Discounts ) {
      orderData.Discounts.forEach(function(discount) {
        if( discount.Description.indexOf("Voucher-") > -1 ) {
          $scope.totalDiscountsIncVat += discount.Amount;
          $scope.tsGiftcardApplied = true;
          return;
        }
        $scope.totalDiscountsExVat += discount.Amount;
      });

      if( !$scope.tsGiftcardApplied ) {
        $scope.totalDiscountVat = $scope.productVat + $scope.order.Shipping.Vat - $scope.order.Vat;
        $scope.totalDiscountsIncVat = $scope.totalDiscountsExVat + $scope.totalDiscountVat;
      } else {
        $scope.totalDiscountVat = $scope.productVat + $scope.order.Shipping.Vat - $scope.order.Vat;
        $scope.totalDiscountsExVat = $scope.totalDiscountsIncVat - $scope.totalDiscountVat;
      }
    }

    refreshOrderHistory(false);

    function refreshOrderHistory(shouldSkipFetchingOrderNotes) {
      if (shouldSkipFetchingOrderNotes && didFetchOrderNotes) {
        $scope.orderLogGroups = concatOrderNotes($scope.orderNotes, $scope.order.Audits, $scope.notifications, $scope.extensions);
        return;
      }

      $scope.orderNotes = OrderNote.query({
        orderNumber: orderData.OrderNumber,
      }, function successCb(response) {
        $scope.errorMessage = null;
        didFetchOrderNotes = true;
        $scope.orderLogGroups = concatOrderNotes(response, $scope.order.Audits, $scope.notifications, $scope.extensions);
      }, function errorCb(err) {
        console.log("Error getting notes => ", err);
        $scope.errorMessage = "Some order notes are missing in order history. Try refreshing again.";
        $scope.orderLogGroups = concatOrderNotes([], $scope.order.Audits, $scope.notifications, $scope.extensions);
      });

    }

    function concatOrderNotes(orderNotes, auditHistory, notifications, extensions) {
      var orderLogs = orderNotes
      .map(function (note) {
        return {
          createdAt: note.createdAt,
          content: note,
          type: "note",
        };
      })
      .concat(
        auditHistory.map(function (audit) {
          return {
            createdAt: audit.CreatedOn,
            content: audit,
            type: "audit",
          };
        })
      )
      .concat(
        notifications.map(function (notification) {
          return {
            createdAt: notification.createdAt,
            content: notification,
            type: "notification",
          };
        })
      )
      .concat(
        extensions.map(function (extension) {
          return {
            createdAt: extension.appliedAt,
            content: extension,
            type: "extension",
          };
        })
      )
      .sort(function (a, b) {
        if (new Date(b.createdAt) > new Date(a.createdAt)) {
          return 1;
        }
        return -1;
      });


      return groupOrderLogsByDate(orderLogs);
    }

    function groupOrderLogsByDate(logs) {
      var group = {};
      logs.forEach(function(log){
        var normalizedLogDate = new Date(log.createdAt).setHours(0, 0, 0, 0);
        if( undefined === group[normalizedLogDate] ) {
          group[normalizedLogDate] = [];
          group[normalizedLogDate].push(log);
        } else {
          group[normalizedLogDate].push(log);
        }
      });
      return group;
    }

    $scope.getLogGroupHeading = function(date) {
      var formattedDate = $filter("date")(date, "dd. MMM yyyy");
      var now = new Date();
      var yesterday = new Date();
      yesterday = yesterday.setDate(yesterday.getDate() - 1);
      if( $filter("date")(now, "dd. MMM yyyy") ===  $filter("date")(date, "dd. MMM yyyy")) {
        return "Today";
      }
      if( $filter("date")(yesterday, "dd. MMM yyyy") ===  $filter("date")(date, "dd. MMM yyyy")) {
        return "Yesterday";
      }
      if( now.getFullYear() === new Date(formattedDate).getFullYear() ) {
        return $filter("date")(date, "dd. MMM");
      }
      return formattedDate;
    }

    $scope.giftCard = null;
    $scope.giftCardLoading = true;
    $scope.isGiftcardOrder =
      $scope.order.OrderLines && "99999" === $scope.order.OrderLines[0].Sku;
    if ($scope.isGiftcardOrder) {
      var giftcardLineItem = $scope.order.OrderLines[0];
      var voucherCode = giftcardLineItem.Properties.voucherCode;
      $scope.isGettingOrders = true;
      $scope.giftCard = OpenGiftCard.get(
        { code: voucherCode },
        function () {
          $scope.giftCardLoading = false;
          $scope.giftCardOrders = OpenGiftCardOrder.query(
            { code: voucherCode },
            function () {
              $scope.isGettingOrders = false;
            }
          );
        },
        function (err) {
          $scope.giftCard = null;
          if (err.status === 404) {
            $scope.errorData = {
              Title: "The code was not found / is not valid.",
            };
          }
          $scope.giftCardLoading = false;
        }
      );
    }

    $scope.scrollToOgc = function () {
      var ogcElement = document.getElementById("order-open-giftcard");
      if (ogcElement) {
        ogcElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    };

    $scope.scrollTo = function(targetId) {
      var element = document.getElementById(targetId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }

    $scope.newOrderNote = null;
    $scope.saveOrderNote = function () {
      OrderNote.save(
        { orderNumber: orderData.OrderNumber },
        {
          note: $scope.newOrderNote,
        },
        function (res) {
          refreshOrderHistory(false);
          alert("Note saved!");
          $scope.newOrderNote = null;
        },
        function (err) {
          alert("Error saving note: ", err);
        }
      );
    };

    $scope.deleteOrderNote = function (orderNote) {
      if (
        !confirm("Are you sure you want to delete: " + orderNote.note + "?")
      ) {
        return;
      }

      OrderNote.delete(
        { orderNumber: orderData.OrderNumber, id: orderNote.id },
        function (res) {
          refreshOrderHistory(false);
        },
        function (err) {
          alert("Could not delete order note: ", err);
        }
      );
    };
  }]
);

angular
  .module("Cockpit.State.Search", [
    "ui.router",
    "Cockpit.Service.Search",
    "Cockpit.Service.OrderNote",
    "Cockpit.Service.Dgda.OpenGiftCards",
    "Cockpit.Service.Notification",
    "Cockpit.State.Search.Details"
  ])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider
      .state("private.search", {
        url: "/search",
        controller: "CockpitSearchController",
        templateUrl: "states/search/search.tpl.html",
        data: {
          pageTitle: "Search",
        },
      })
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.primary.register("Searching", "private.search");
  }])

  .controller(
    "CockpitSearchController",
    ["$scope", "Search", function CockpitReviewsController($scope, Search) {
      $scope.search = {
        Query: "",
        Results: [],
      };
      $scope.isSearching = false;

      $scope.doSearch = function doSearch() {
        $scope.isSearching = true;
        $scope.search.Results = Search.query(
          { query: $scope.search.Query, simpleSearch: true },
          function () {
            $scope.isSearching = false;
          },
          function () {
            $scope.isSearching = false;
          }
        );
      };
    }]
  );

angular.module('Cockpit.State.Settings', [
	'ui.router',
	'Cockpit.State.Settings.Geckoboard',
	'Cockpit.State.Settings.MailChimp',
  'Cockpit.State.Settings.Notifications.Templates',
  'Cockpit.State.Settings.Notifications',
  'Cockpit.State.Settings.FooterWidgets',
  'Cockpit.State.Settings.FooterWidget.AddEdit',
	'Cockpit.State.Settings.CallOutTypes',
])

.config(["$stateProvider", function config($stateProvider) {
	$stateProvider.state('private.settings', {
		url: '/settings/',
		controller: 'CockpitSettingsController',
		templateUrl: 'states/settings/settings.tpl.html',
		data: {
			pageTitle: 'Settings'
		}
	})

	;
}])

.run(["Navigation", function(Navigation){
	Navigation.primary.register("Settings", "private.settings");
}])

.controller('CockpitSettingsController', ["$scope", function CockpitSettingsController($scope) {}])

;

angular.module('Cockpit.State.Site', [
	'ui.router',
  'Cockpit.State.Site.TopBars',
])
  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state("private.site", {
      url: "/site/",
      templateUrl: "states/site/site.tpl.html",
      data: {
        pageTitle: "Site",
      },
    });
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.primary.register("Site", "private.site");
  }])
;

angular
  .module("Cockpit.State.Login", ["ui.router"])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider
      .state("public.login", {
        url: "/login/",
        resolve: {
          renew: [
            "$q",
            "Auth",
            function ($q, Auth) {
              var d = $q.defer();
              Auth.renew().$promise.then(
                function (success) {
                  d.resolve(success);
                },
                function (err) {
                  d.resolve(false);
                }
              );
              return d.promise;
            },
          ],
        },
        controller: "CockpitLoginCtrl",
        templateUrl: "states/login/login.tpl.html",
        data: {
          pageTitle: "Login til Truestory Cockpit",
          bodyClass: "login",
        },
      })

      .state("public.logout", {
        url: "/logout/",
        controller: "CockpitLogoutCtrl",
        templateUrl: "states/login/logout.tpl.html",
        data: {
          pageTitle: "Logger ud...",
          bodyClass: "login",
        },
      });
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.primary.register("Logout", "public.logout", 100);
  }])

  .controller("CockpitLoginCtrl", ["$scope", "Auth", function CockpitLoginCtrl($scope, Auth) {
    // Auth.renew();

    $scope.model = {
      Username: "",
      Password: "",
      Remember: false,
    };

    $scope.process = function () {
      $scope.message = "";
      Auth.login(
        $scope.model,
        function (d) {
          // Success
        },
        function () {
          // Error
          $scope.message =
            "Kunne ikke logge ind. Kontroller du har indtastet korrekt brugernavn / kodeord.";
        }
      );
    };
  }])

  .controller(
    "CockpitLogoutCtrl",
    ["$scope", "$rootScope", "$state", "Auth", function CockpitLogoutCtrl($scope, $rootScope, $state, Auth) {
      Auth.logout();
      // $state.go('public.login');
    }]
  );

angular
  .module("Cockpit.Modal.BookingCancel", [
    'toaster', 'ngAnimate',
    "Cockpit.Service.Bookings"
  ])
  .controller(
    "BookingCancelModalController",
    ["$scope", "TheBookingData", "$uibModalInstance", "Bookings", "toaster", function ($scope, TheBookingData, $uibModalInstance, Bookings, toaster) {
      $scope.isProcessing = false;
      $scope.hasError = false;

      $scope.close = function () {
        $scope.isProcessing = false;
        $uibModalInstance.close();
      }

      $scope.cancelBooking = function() {
        $scope.isProcessing = true;
        $scope.hasError = false;
        Bookings.cancel(
          {
            orderNumber: TheBookingData.orderNumber,
            bookingId: TheBookingData.bookingId
          },
          function (data) {
            $scope.isProcessing = false;
            $scope.hasError = false;
            toaster.pop({
              type: "success",
              title: "",
              body: "Booking cancelled successfully.",
              timeout: 5000
            });
            $uibModalInstance.close({didCancelBooking: true});
          },
          function(err) {
            $scope.isProcessing = false;
            $scope.hasError = true;
            var errorDetails =
              err.data && err.data.metadata && err.data.metadata.details
                ? err.data.metadata.details
                : "Booking cancellation was unsuccessful. Please contact tech team.";
            toaster.pop({
              type: "error",
              title: "",
              body: errorDetails,
              timeout: 0,
            });
            console.log("Bookings.cancel error =>", err);
          });
      }

    }]
  );

angular.module('Cockpit.Service.Dgda.Order', ['ngResource'])
    .factory('Order', ['$resource',
        function($resource) {
            return $resource(Config.Api + 'dgdaorderapi/:action', {}, {
                get: { params: { action: 'Get' } },
                getDetailed: { params: { action: 'GetDetailed' } },
                save: { method: 'POST', params: { action: 'Save' } },
                query: { params: { action: 'All' }, isArray: true },
                bulkSave: { method: 'POST', params: { action: 'BulkSave' } },
                queryAnalogShipped: { params: { action: 'AnalogShipped' }, isArray: true },
                orderStatuses: { params: { action: 'OrderStatuses' }, isArray: true },
                personalization: { method: 'GET', params: { action: 'Personalization' } }
            });
        }
    ]);

angular.module('Cockpit.State.Dgda.Orders.Add', [
    'Cockpit.Service.Dgda.Order'
])

.config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.orders.add.dgda', {
        url: '/dgda',
        views: {
            'customProperties': {
                controller: 'CockpitOrderDgdaAddController',
                templateUrl: 'states/dgda/orders/custom-properties.tpl.html'
            }
        }
    })

    .state('private.orders.edit.dgda', {
        url: '/dgda',
        views: {
            customProperties: {
                controller: 'CockpitOrderDgdaAddController',
                templateUrl: 'states/dgda/orders/custom-properties.tpl.html'
            }
        }
    })

    ;
}])

.run(["Navigation", function(Navigation) {
    // Replace generic "Add Order" menu
    // with the new one.
    Navigation.sidebar.register('private.orders', 'Orders', [{
        Label: "Add Order",
        State: 'private.orders.add.dgda',
        Groups: ["admin", "support"]
    }]);
}])

.controller('CockpitOrderDgdaAddController', ["$scope", "Order", function CockpitOrderDgdaAddController($scope, Order) {
    if (!angular.isUndefined($scope.order.CustomProperties) && $scope.order.CustomProperties != null) {
        $scope.order.Personalization = {
            Message: $scope.order.CustomProperties.PersonalizedDate,
            Date: $scope.order.CustomProperties.PersonalizedMessage,
            Name: $scope.order.CustomProperties.PersonalizedName
        };
    }

    $scope.order.Personalization = Order.personalization({ OrderNumber: $scope.order.OrderNumber });
}]);

angular.module('Cockpit.State.Portfolio', [
    'ui.router',
    'Cockpit.Service.Portfolio',
    'Cockpit.Service.PortfolioSkuOverride'
])

    .config(["$stateProvider", function config($stateProvider) {
        $stateProvider.state('private.orders.portfolio', {
            url: '/portfolio',
            views: {
                '@private': {
                    controller: 'ReportingPortfolioController',
                    templateUrl: 'states/orders/portfolio/portfolio.tpl.html'
                }
            },
            data: {
                pageTitle: 'Reporting'
            }
        })

        ;
    }])

    .run(["Navigation", function (Navigation) {
        Navigation.sidebar.register('private.orders', 'Reporting', [
            {
                Label: 'Portfolio',
                State: 'private.orders.portfolio',
                Groups: ["admin"]
            }
        ]);
    }])

    .controller('ReportingPortfolioController', ["$scope", "Portfolio", "PortfolioSkuOverride", "$http", function ReportingPortfolioController($scope, Portfolio, PortfolioSkuOverride, $http) {
        var date = new Date();
        $scope.month = (date.getMonth() + 1) + '';
        $scope.year = date.getFullYear();
        $scope.months = [
            {
                Id: '1',
                Name: 'January'
            },{
                Id: '2',
                Name: 'February'
            },{
                Id: '3',
                Name: 'March'
            },{
                Id: '4',
                Name: 'April'
            },{
                Id: '5',
                Name: 'May'
            },{
                Id: '6',
                Name: 'June'
            },{
                Id: '7',
                Name: 'July'
            },{
                Id: '8',
                Name: 'August'
            },{
                Id: '9',
                Name: 'September'
            },{
                Id: '10',
                Name: 'October'
            },{
                Id: '11',
                Name: 'November'
            },{
                Id: '12',
                Name: 'December'
            }
        ];
        $scope.years = [];
        for(var i = 0; i < 5; i++){
            var y = date.getFullYear() - 4 + i;
            $scope.years.push(y);
        }

        $scope.override = {
            Sku: '',
            Initials: ''
        };
        $scope.overrides = PortfolioSkuOverride.get();
        $scope.addOverride = function addOverride(){
            if($scope.override.Sku === "" || $scope.override.Initials === ""){
                return;
            }
            $scope.overrides[ $scope.override.Sku ] = $scope.override.Initials;
            $scope.overrides = PortfolioSkuOverride.save( $scope.overrides );

            $scope.override = {
                Sku: '',
                Initials: ''
            };
        };
        $scope.removeOverride = function removeOverride(sku){
            delete $scope.overrides[sku];
            $scope.overrides = PortfolioSkuOverride.save( $scope.overrides );
        };

        $scope.getPortfolio = function getPortfolio(){
            $scope.portfolio = Portfolio.query({
                month: $scope.month,
                year: $scope.year
            });
        };
        $scope.exportPortfolioData = function exportPortfolioData(initials, startDate){
            if(initials === ""){
                return;
            }
            $scope.exportError = false;

            var date = new Date(startDate);
            $http({
                url: Config.Api + "portfolioapi/export",
                method: "GET",
                params: {
                    month: date.getMonth() + 1,
                    year: date.getFullYear(),
                    initials: initials
                }
            }).then(function exportSuccess(response){
                $scope.exportLink = response.data.Link;
                $scope.exportError = false;
            }, function exportError(err){
                $scope.exportError = true;
                console.log(err);
            });
        };

        $scope.getPortfolio();
    }])

;
angular.module('Cockpit.Service.Supplier', ['ngResource'])
	.factory('Supplier', ['$resource',
	function ($resource) {
		return $resource(Config.Api + 'SupplierApi/:action', {}, {
			query: { params: { action: 'Get' }, isArray: true }
		});
	}]);
angular.module('Cockpit.State.Redeem', [
  'ui.router',
  'Cockpit.Service.Supplier'
])

.config(["$stateProvider", function ($stateProvider) {
	$stateProvider

	.state('private.orders.supplier-list', {
		url: '/supplier-list',
		views: {
			'@private': {
				controller: 'RedeemSupplierListController',
				templateUrl: 'states/orders/redeem/supplier-list.tpl.html'
			}
		},
		data: {
			pageTitle: 'List of suppliers with redeem-info'
		}
	})

	;
}])

.run(["Navigation", function (Navigation) {
	Navigation.sidebar.register('private.orders', 'Reporting', [
		{
			Label: 'Supplier Reedeem Info',
			State: 'private.orders.supplier-list',
			Groups: ["admin", "support"]
		}
	]);
}])

.controller('RedeemSupplierListController', ["$scope", "Supplier", function RedeemSupplierListController($scope, Supplier) {
		$scope.isLoading = true;
	$scope.supplierInfo = Supplier.query(function(){
		$scope.isLoading = false;
	});
	$scope.orderByPredicate = 'LastRedeemed';
	$scope.currentPredicate = 'LastRedeemed';

	$scope.setOrderBy = function setOrderBy(predicate) {
		$scope.currentPredicate = predicate;
		if ($scope.orderByPredicate === predicate) {
			$scope.orderByPredicate = '-' + $scope.orderByPredicate;
		} else {
			$scope.orderByPredicate = predicate;
		}
	};
}])

;

angular.module('Cockpit.Directive.CallOutsTable', [

])
  .directive('cpTsCallOutsTable', ["CallOut", function (CallOut) {
    return {
      restrict: 'E',
      scope: {
        callOuts: '=',
        onAction: '&'
      },
      replace: true,
      templateUrl: 'states/products/call-outs/table.tpl.html',
      link: function ($scope){
        $scope.delete = function deleteType(callOut){
          callOut.$delete(function(){
            if(angular.isDefined($scope.onAction)){
              $scope.onAction();
            }
          });
        }

        $scope.filterContexts = function filterContexts(contexts){
          if(!contexts || !contexts.length){
            contexts = ["shop", "booking-login"];
          }
          return contexts.filter(Boolean).join(", ");
        };

        $scope.getFirst = function(obj){
          var keys = Object.keys(obj);
          for(var i = 0; i < keys.length; i++){
            if(obj[keys[i]] && obj[keys[i]].length > 0){
              return obj[keys[i]];
            }
          }

          return '-';
        }
      }
    };
  }])

;

angular.module('Cockpit.State.CallOuts.AddEdit', [
  'ui.router',
  'Cockpit.Service.CallOuts',
  'Cockpit.Controller.GenericAddEdit'
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.products.call-outs.add', {
      url: '/add',
      controller: 'CallOutAddEditController',
      templateUrl: 'states/products/call-outs/add-edit.tpl.html',
      data: {
        pageTitle: 'Add new Call-Out'
      },
      resolve: {
        theInstance: ['$q', 'CallOut', function ($q, CallOut) {
          var d = $q.defer();
          var callOut = new CallOut();

          callOut.id = 0;
          callOut.type = {};
          callOut.languageCodes = [];
          callOut.contexts = [];
          callOut.title = {};
          callOut.text = {};
          callOut.bullets = {};
          callOut.link = {};
          callOut.productSkus = [];

          d.resolve(callOut);
          return d.promise;
        }],
        languages: resolvers.languagesResolver,
        callOutTypes: resolvers.callOutTypesResolver
      }
    })

    $stateProvider.state('private.products.call-outs.edit', {
      url: '/{id}/edit',
      controller: 'CallOutAddEditController',
      templateUrl: 'states/products/call-outs/add-edit.tpl.html',
      data: {
        pageTitle: 'Edit Call-Out'
      },
      resolve: {
        theInstance: ['$q', 'CallOut', '$stateParams', function ($q, CallOut, $stateParams) {
          var d = $q.defer();

          CallOut.get({ id: $stateParams.id }, function (response) {
            response.start = new Date(response.start);
            response.end = new Date(response.end);
            d.resolve(response);
          }, function (err) {
            d.reject();
          })

          return d.promise;
        }],
        languages: resolvers.languagesResolver,
        callOutTypes: resolvers.callOutTypesResolver
      }
    })
  }])

  .controller('CallOutAddEditController', ["$scope", "$state", "theInstance", "callOutTypes", "CallOut", "languages", "PimProduct", function CallOutAddEditController($scope, $state, theInstance, callOutTypes, CallOut, languages, PimProduct) {
    $scope.callOut = theInstance;
    $scope.callOutTypes = callOutTypes;

    $scope.callOutContexts = ["shop", "booking-login"];

    if(!$scope.callOut.contexts || !$scope.callOut.contexts.length){
      $scope.callOut.contexts = ["shop", "booking-login"];
    }

    $scope.languages = languages;
    $scope.activeLanguage = $scope.languages[0].languageCode;

    if(theInstance.languageCodes.length > 0){
      $scope.activeLanguage = theInstance.languageCodes[0];
    }

    $scope.showLinkControls = {};
    $scope.newBullet = {};
    angular.forEach(languages, function(v){
      var lc = v.languageCode.toLowerCase();
      $scope.showLinkControls[v.languageCode] = (angular.isDefined(theInstance.link[lc]) && theInstance.link[lc].uri.length > 0)
    })


    $scope.resetCalloutLink = function() {
      $scope.callOut.link = {};
    }

    var errFunction = function(err){
      $scope.isProcessing = false;
      console.log(err);

      if(err.status === 400){
        alert(err.data);
      }
    };

    $scope.isProcessing = false;

    $scope.onSubmit = function () {
      $scope.isProcessing = true;

      if(!$scope.callOut.contexts || !$scope.callOut.contexts.length){
        $scope.callOut.contexts = $scope.callOutContexts;
      }

      if ($scope.callOut.id < 1) {
        CallOut.save($scope.callOut, function(){
          $scope.isProcessing = false;
          $state.go('private.products.call-outs', {}, { reload: true });
        }, errFunction);
      }
      else {
        CallOut.update($scope.callOut, function(){
          $scope.isProcessing = false;
          $state.go('private.products.call-outs', {}, { reload: true });
        }, errFunction);
      }
    };

    $scope.toggleContext = function toggleContext(context){
      var index = $scope.callOut.contexts.indexOf(context);
      if (index === -1) {
        $scope.callOut.contexts.push(context);
      } else {
        $scope.callOut.contexts.splice(index, 1);
      }
    }

    $scope.toggleLanguage = function toggleLanguage(language){
      var index = $scope.callOut.languageCodes.indexOf(language.languageCode);
      if (index === -1) {
        $scope.callOut.languageCodes.push(language.languageCode);
      }else{
        $scope.callOut.languageCodes.splice(index, 1);
      }
    }

    $scope.setLanguage = function setLanguage(language){
      $scope.activeLanguage = language.languageCode;
    }

    $scope.addBullet = function addBullet(languageCode){
      if(!angular.isDefined($scope.callOut.bullets[languageCode])){
        $scope.callOut.bullets[languageCode] = [];
      }

      $scope.callOut.bullets[languageCode].push($scope.newBullet[languageCode]);
      $scope.newBullet[languageCode] = "";
    }

    $scope.removeBullet = function removeBullet(index, languageCode){
      $scope.callOut.bullets[languageCode].splice(index, 1);
    }

    $scope.removeProduct = function removeProduct(index){
      $scope.callOut.productSkus.splice(index, 1);
    }


    $scope.loadingProducts = true;
    $scope.selectedProduct = null;
    $scope.selectProduct = function selectProduct($item, $model, $label){
      if($scope.callOut.productSkus.indexOf($item.sku) > -1){
        $scope.selectedProduct = null;
        return;
      }
      $scope.callOut.productSkus.push($item.sku);
      $scope.selectedProduct = null;
    };
    $scope.products = PimProduct.query(function() {
      $scope.loadingProducts = false;
    });

  }])
;

angular.module('Cockpit.State.CallOuts', [
  'ui.router',
  'Cockpit.Service.CallOuts',
  'Cockpit.Directive.CallOutsTable',
  'Cockpit.State.CallOuts.AddEdit'
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.products.call-outs', {
      url: 'call-outs',
      views: {
        '@private': {
          controller: 'CallOutsController',
          templateUrl: 'states/products/call-outs/list.tpl.html'
        }
      },
      data: {
        pageTitle: 'Call-outs'
      },
      resolve: {
        activeCallOuts: resolvers.callOutsResolver('active'),
        pendingCallOuts: resolvers.callOutsResolver('pending'),
        inactiveCallOuts: resolvers.callOutsResolver('inactive'),
      }
    })
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.products', 'General', [
      {
        Label: 'Call-Outs',
        State: 'private.products.call-outs',
        Groups: ['admin']
      }
    ])
  }])

  .controller('CallOutsController', ["$scope", "activeCallOuts", "pendingCallOuts", "inactiveCallOuts", "CallOut", function CallOutsController($scope, activeCallOuts, pendingCallOuts, inactiveCallOuts, CallOut) {
    $scope.activeCallOuts = activeCallOuts;
    $scope.pendingCallOuts = pendingCallOuts;
    $scope.inactiveCallOuts = inactiveCallOuts;

    $scope.refreshLists = function (){
      $scope.activeCallOuts = CallOut.query({status: 'active'});
      $scope.pendingCallOuts = CallOut.query({status: 'pending'});
      $scope.inactiveCallOuts = CallOut.query({status: 'inactive'});
    }
  }])

angular.module('Cockpit.Service.SupplierCodes', ['ngResource'])
  .factory('SupplierCode', ['$resource',
    function ($resource) {
      return $resource(Config.Api + 'productcodeapi/:action', {}, {
        get: { params: { action: 'Get' } },
        import: { method: 'POST', params: { action: 'Import' } },
        query: { params: { action: 'All' }, isArray: true },
        orders: { params: { action: 'Orders' }, isArray: true },
        remaining: { params: { action: 'Remaining' }, isArray: true },
        revoke: { params: { action: 'Revoke' }, isArray: true },
        revokeByReference: { params: { action: 'RevokeByReference' } },
        deleteCodes: { method: 'POST', params: { action: 'DeleteCodes' }, isArray: true },
      });
    }]);

angular.module('Cockpit.State.ProductCodes', [
  'ui.router',
  'Cockpit.Service.SupplierCodes',
  "Cockpit.State.ProductCodes.Madklubben"
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.products.codes', {
      url: '/codes',
      data: {
        pageTitle: 'Product Codes'
      }
    })

    $stateProvider.state('private.products.codes.supplier', {
      url: '/supplier',
      views: {
        '@private': {
          controller: 'CockpitProductSupplierCodesController',
          templateUrl: 'states/products/codes/products.tpl.html'
        }
      },
      data: {
        pageTitle: 'Supplier Codes'
      }
    })

      .state('private.products.codes.supplier.codes-import', {
        url: '/import/:Id',
        views: {
          '@private': {
            controller: 'CockpitProductSupplierCodeImportController',
            templateUrl: 'states/products/codes/import.tpl.html',
          }
        },
        data: {
          pageTitle: 'Import codes'
        }
      })

      .state('private.products.codes.supplier.used-codes', {
        url: '/usedcodes/:Sku',
        views: {
          '@private': {
            controller: 'CockpitProductSupplierCodesUsedController',
            templateUrl: 'states/products/codes/usedcodes.tpl.html',
          }
        },
        data: {
          pageTitle: 'Used codes'
        }
      })

      .state('private.products.codes.supplier.remaining-codes', {
        url: '/remainingcodes/:Sku/:VariantSku',
        views: {
          '@private': {
            controller: 'CockpitProductSupplierCodesRemainingController',
            templateUrl: 'states/products/codes/remainingcodes.tpl.html',
          }
        },
        data: {
          pageTitle: 'Remaining codes'
        }
      })

      ;
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.products', 'Product Codes', [
      {
        Label: "Madklubben Codes",
        State: "private.products.codes.madklubben",
        Groups: ["admin", "support"],
      },
      {
        Label: 'Supplier Codes',
        State: 'private.products.codes.supplier',
        Groups: ["admin", "support"]
      }
    ]);
  }])

  .controller('CockpitProductSupplierCodesController', ["$scope", "SupplierCode", function CockpitProductSupplierCodesController($scope, SupplierCode) {
    $scope.products = SupplierCode.query();
  }])

  .controller('CockpitProductSupplierCodeImportController', ["$scope", "$stateParams", "SupplierCode", function CockpitProductSupplierCodeImportController($scope, $stateParams, SupplierCode) {
    $scope.model = {
      Codes: "",
      Id: $stateParams.Id
    };

    $scope.alert = {
      Type: '',
      Show: false,
      Message: ''
    };

    $scope.process = function ProcessImportEventCodes() {
      $scope.alert.Show = false;

      if ($scope.model.Codes === "") {
        $scope.alert.Type = "warning";
        $scope.alert.Message = "You should at least enter one code.";
        $scope.alert.Show = true;
        return;
      }

      SupplierCode.import($scope.model, function (resp) {
        if (resp.Success) {
          $scope.alert.Type = "success";
        } else {
          $scope.alert.Type = "warning";
        }

        $scope.alert.Message = resp.Message;
        $scope.alert.Show = true;
      });
    };
  }])


  .controller('CockpitProductSupplierCodesUsedController', ["$scope", "$stateParams", "SupplierCode", function CockpitProductSupplierCodesUsedController($scope, $stateParams, SupplierCode) {

    $scope.orders = [];
    $scope.sku = $stateParams.Sku;

    SupplierCode.orders({ sku: $stateParams.Sku }, function (result) {
      if (result.length) {
        $scope.orders = result;
      }
    });

    $scope.revoke = function (order, sku) {
      SupplierCode.revoke({ id: order.Id, sku: sku }, function (result) {
        if (result.length) {
          $scope.orders = result;
        }
        else {
          $scope.orders = [];
        }
      });
    };
  }])

  .controller('CockpitProductSupplierCodesRemainingController', ["$scope", "$stateParams", "SupplierCode", function CockpitProductSupplierCodesRemainingController($scope, $stateParams, SupplierCode) {

    $scope.productCodes = [];
    $scope.sku = $stateParams.Sku;
    $scope.deletedCodes = [];

    $scope.canDelete = function () {
      const selectedCodes = $scope.productCodes.filter(function (pc) { return pc.isChecked }).length;
      return selectedCodes > 0;
    }

    function getRemaingCodes() {
      SupplierCode.remaining({ sku: $stateParams.Sku, variantSku: $stateParams.VariantSku }, function (result) {
        if (result.length) {
          $scope.productCodes = result;
        }
      });
    }

    getRemaingCodes();

    $scope.deleteCodes = function () {
      if (!confirm('Are you sure you want to delete selected codes? They will be gone afterwards!')) {
        return;
      }

      // reset state
      $scope.deletedCodes = [];
      $scope.errorMessage = null;

      const idsToDelete = $scope.productCodes
        .filter(function (p) { return p.isChecked; })
        .map(function (p) { return p.Id });

      SupplierCode.deleteCodes(idsToDelete, function (result) {
        $scope.deletedCodes = result;
        getRemaingCodes();
      }, function (err) {
        $scope.errorMessage = "Failed to delete codes";
        console.log(err);
      });
    };
  }])
  ;

angular.module('Cockpit.Service.ProductRank', ['ngResource'])
	.factory('ProductRank', ['$resource',
	function ($resource) {
		return $resource(Config.Api + 'productrankapi/:action', {}, {
			get: { params: { action: 'Get' } },
			save: { method: 'POST', params: { action: 'Save' } }
		});
	}]);
angular.module('Cockpit.State.ProductRank', [
    'ui.router',
    'Cockpit.Service.ProductRank',
    'Cockpit.State.Products'
])

.config(["$stateProvider", function config($stateProvider) {

	$stateProvider.state('private.products.productrank', {
		url: '/productrank',
        views: {
            '@private': {
                controller: 'ProductRankController',
                templateUrl: 'states/products/productrank/productrank.tpl.html',
            }
        },
		data: {
			pageTitle: 'Product Ranking Settings'
		}
	})

	;
}])

.run(["Navigation", function (Navigation) {
	Navigation.sidebar.register('private.products', 'General', [
		{
			Label: "Product Ranking",
			State: 'private.products.productrank',
			Groups: ['admin']
		}
	]);
}])

.controller('ProductRankController', ["$scope", "ProductRank", function ProductRankController($scope, ProductRank) {
	console.log("Test");
	$scope.model = ProductRank.get();

	$scope.process = function processSaveProductRank() {
		$scope.model.$save();
	};
}])

;
angular.module('Cockpit.Service.RankingServices', ['ngResource'])
    .factory('Rankings', ['$resource',
        function ($resource) {
            return $resource(Config.ApiV2 + 'rankingapi/rankings');
        }]);
angular.module('Cockpit.State.ProductRankings', [
    'ui.router',
    'Cockpit.State.Products',
    'Cockpit.Service.RankingServices'
])

    .config(["$stateProvider", function config($stateProvider) {

        $stateProvider.state('private.products.productrankings', {
            url: '/rankings',
            views: {
                '@private': {
                    controller: 'ProductRankingController',
                    templateUrl: 'states/products/rankings/rankings.tpl.html',
                }
            },
            data: {
                pageTitle: 'Product Ranking Settings'
            },
            resolve: {
              theLanguages: resolvers.languagesResolver
            }
        })

        ;
    }])

    .run(["Navigation", function (Navigation) {
        Navigation.sidebar.register('private.products', 'Rankings', [
            {
                Label: "Product Ranking",
                State: 'private.products.productrankings',
                Groups: ['admin']
            }
        ]);
    }])

    .controller('ProductRankingController', ["$scope", "Settings", "Rankings", "theLanguages", function ProductRankController($scope, Settings, Rankings, theLanguages) {
        $scope.languages = theLanguages;
        $scope.showLists = false;
        $scope.settings = Settings.get({key: 'ranking-settings'});
        $scope.rankings = [];
        $scope.lists = Settings.get({key: 'all-rankings-list'});

        $scope.processSettings = function saveSettings(){
            $scope.settings.$save({key: 'ranking-settings'});
        };

        $scope.processLists = function saveLists(){
            $scope.lists.$save({key: 'all-rankings-list'});
        };

        $scope.$watch('settings', function watchSettings(v){
            $scope.showLists = !angular.isDefined(v) || v == null || !angular.isDefined(v.ApiUrl) || !angular.isDefined(v.ApiKey) || v.ApiUrl.length === 0 || v.ApiKey.length === 0;
        });

        $scope.$watch('showLists', function watchShowLists(v){
            if(v !== true){
                return;
            }

            $scope.rankings = Rankings.query();
        })
    }])

;

angular.module('Cockpit.Service.Review', ['ngResource'])
    .factory('Review', ['$resource',
        function($resource) {
            return $resource(Config.AdminApi + 'reviews/:id/:action', {}, {
                unapprove: { method: 'POST', params: { action: 'unapprove', id: '@id' } },
                approve: { method: 'POST', params: { id: '@id', action: 'approve' } },
            });
        }
    ]);

angular.module('Cockpit.State.Reviews', [
    'ui.router',
    'Cockpit.Service.PimProduct',
    'Cockpit.Service.Review'
])

.config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.products.reviews', {
        url: '/reviews',
        views: {
            '@private': {
                controller: 'CockpitReviewsController',
                templateUrl: 'states/products/reviews/reviews.tpl.html',
            }
        },
        data: {
            pageTitle: 'Reviews'
        }
    })

    .state('private.products.reviews.add', {
        url: '/add',
        views: {
            '@private': {
                controller: 'CockpitAddEditReviewController',
                templateUrl: 'states/products/reviews/addreview.tpl.html',
            }
        },
        data: {
            pageTitle: 'Add Review'
        },
        resolve: {
          theLanguages: resolvers.languagesResolver
        }
    })

    .state('private.products.reviews.edit', {
        url: '/edit/:Id',
        views: {
            '@private': {
                controller: 'CockpitAddEditReviewController',
                templateUrl: 'states/products/reviews/editreview.tpl.html',
            }
        },
        data: {
            pageTitle: 'Edit Review'
        },
        resolve: {
          theLanguages: resolvers.languagesResolver
        }
    })

    ;
}])

.run(["Navigation", function(Navigation) {
    Navigation.sidebar.register('private.products', 'Reviews', [{
            Label: 'Reviews',
            State: 'private.products.reviews',
            Groups: ["admin", "support", "user"]
        },
        {
            Label: "Add Review",
            State: 'private.products.reviews.add',
            Groups: ["admin", "support", "user"]
        }
    ]);
}])

.controller('CockpitReviewsController', ["$scope", "Review", "PimProduct", function CockpitReviewsController($scope, Review, PimProduct) {
    $scope.reviews = [];
    Review.query({ status: 'new' }, function(result) {
        $scope.reviews = result;
    });

    $scope.data = {
        Product: null,
        Status: null
    };

    $scope.statuses = [
        { id: "new", name: "New" },
        { id: "approved", name: "Approved" },
        { id: "unapproved", name: "Unapproved" },
        { id: "reportedasabuse", name: "Reported As Abuse" }
    ];

    if ('allProducts' in window.sessionStorage) {
      $scope.products = JSON.parse(window.sessionStorage.getItem('allProducts'));
    } else {
      $scope.isLoadingProducts = true;
      $scope.products = PimProduct.query(
        function successCb(products) {
          $scope.isLoadingProducts = false;
          $scope.products = products;
          window.sessionStorage.setItem('allProducts', JSON.stringify(products));
        },
        function errorCb() {
          $scope.isLoadingProducts = false;
          $scope.products = [];
          alert('could not fetch products. Try again.');
        }
      );
    }
    // $scope.products = Product.query(function(products) {
    //     var map = {};
    //     var productSkus = [];

    //     // Init typeahead
    //     $('#inputProduct').typeahead({
    //         source: function(query, process) {
    //             map = {};
    //             productSkus = [];

    //             $.each(products, function(i, product) {
    //                 map[product.Sku + " - " + product.Name] = product;
    //                 productSkus.push(product.Sku + " - " + product.Name);
    //             });

    //             process(productSkus);
    //         },
    //         updater: function(item) {
    //             $scope.$apply(function() {
    //                 $scope.data.Product = map[item];
    //             });
    //             return item;
    //         },
    //         matcher: function(item) {
    //             return (item.toLowerCase().indexOf(this.query.trim().toLowerCase()) != -1);
    //         },
    //         /*sorter: function (items) {
    //         	// implementation
    //         },*/
    //         highlighter: function(item) {
    //             var regex = new RegExp('(' + this.query + ')', 'gi');
    //             return item.replace(regex, "<strong>$1</strong>");
    //         }
    //     });
    // });

    $scope.doSearch = function doSearch() {
        var sku = ($scope.data.sku != null ? $scope.data.sku : '');
        var statusId = ($scope.data.Status == null ? 'new' : $scope.data.Status.id );
        $scope.reviews = Review.query({ sku: sku, status: statusId });
    };

    $scope.approve = function ApproveReview(id) {
        Review.approve({ id: id }, function() {
            $scope.doSearch();
        });
    };

    $scope.unapprove = function UnapproveReview(id) {
        Review.unapprove({ id: id }, function() {
            $scope.doSearch();
        });
    };

    $scope.delete = function DeleteReview(id) {
        Review.delete({ id: id }, function() {
            $scope.doSearch();
        });
    };
}])

.controller('CockpitAddEditReviewController', ["$scope", "$stateParams", "PimProduct", "Review", "theLanguages", function CockpitAddReviewController($scope, $stateParams, PimProduct, Review, theLanguages) {
    if ('allProducts' in window.sessionStorage) {
      $scope.products = JSON.parse(window.sessionStorage.getItem('allProducts'));
    } else {
      $scope.isLoadingProducts = true;
      $scope.products = PimProduct.query(
        function successCb(products) {
          $scope.isLoadingProducts = false;
          $scope.products = products;
          window.sessionStorage.setItem('allProducts', JSON.stringify(products));
        },
        function errorCb() {
          $scope.isLoadingProducts = false;
          $scope.products = [];
          alert('could not fetch products. Try again.');
        }
      );
    }

    $scope.languages = theLanguages;

    $scope.model = {
        id: 0,
        sku: null,
        author: "",
        email: "",
        age: "",
        title: "",
        text: "",
        tips: "",
        wouldRecommend: true,
        avgRating: 5,
        generalRating: 5,
        staffRating: 5,
        guidanceRating: 5,
        cultureCode: null
    };

    if (typeof($stateParams.Id) !== "undefined") {
        $scope.model = Review.get({ id: $stateParams.Id });
    }

    $scope.alert = {
        Show: false,
        Type: 'success',
        Message: ''
    };

    $scope.process = function ProcessAddReview() {

      $scope.model.generalRating = parseInt($scope.model.generalRating);
      $scope.model.staffRating = parseInt($scope.model.staffRating);
      $scope.model.guidanceRating = parseInt($scope.model.guidanceRating);
      $scope.model.avgRating = ($scope.model.generalRating + $scope.model.staffRating + $scope.model.guidanceRating) / 3;

        Review.save($scope.model, function() {
            // Reset the model, if we're not
            // editing a product review.
            if ($scope.model.id === 0) {
                $scope.model = {
                    sku: null,
                    author: "",
                    email: "",
                    age: "",
                    title: "",
                    text: "",
                    tips: "",
                    wouldRecommend: true,
                    avgRating: 5,
                    generalRating: 5,
                    staffRating: 5,
                    guidanceRating: 5,
                    cultureCode: null
                };
            }

            $scope.alert.Message = 'Your review has been saved and approved';
            $scope.alert.Show = true;
        });
    };
}])

;

angular.module('Cockpit.Service.Tiles', ['ngResource'])
    .factory('Tiles', ['$resource',
        function ($resource) {
            return $resource(Config.ApiV2 + 'tiles/:id');
        }]);
angular.module('Cockpit.State.Tiles.AddEdit', [
  'ui.router',
  'Cockpit.Service.CategoryV2',
  'Cockpit.Service.RegionsV2',
  'Cockpit.Service.ProductV2',
  'Cockpit.Directive.TileFilter',
  'Cockpit.Directive.ConvertToNumber'
])

  .config(["$stateProvider", function ($stateProvider) {
    $stateProvider.state('private.products.tiles.edit', {
      url: '/tiles/{id}/edit',
      views: {
        '@private': {
          controller: 'TilesAddEditController',
          templateUrl: 'states/products/tiles/add-edit.tpl.html',
        }
      },
      data: {
        pageTitle: 'Edit tile'
      },
      resolve: {
        theLanguages: ['$q', 'Language', function ($q, Language) {
          return resolve($q, Language);
        }],
        theInstance: ['$q', 'Tiles', '$stateParams', function ($q, Tiles, $stateParams) {
          const d = $q.defer();

          Tiles.get({ id: $stateParams.id }, function (res) {
            res.dateFrom = res.dateFrom ? new Date(res.dateFrom) : null;
            res.dateTo = res.dateTo ? new Date(res.dateTo) : null

            d.resolve(res);
          }, function (err) {
            d.reject();
          });

          return d.promise;
        }]
      }
    })

    $stateProvider.state('private.products.tiles.add', {
      url: '/tiles/add',
      views: {
        '@private': {
          controller: 'TilesAddEditController',
          templateUrl: 'states/products/tiles/add-edit.tpl.html',
        }
      },
      data: {
        pageTitle: 'Add tile'
      },
      resolve: {
        theLanguages: ['$q', 'Language', function ($q, Language) {
          return resolve($q, Language);
        }],
        theInstance: ['$q', 'Tiles', function ($q, Tiles) {
          const d = $q.defer();

          var tile = new Tiles();
          tile.categories = '';
          tile.regions = '';
          tile.positions = '';
          tile.type = tileType.Normal

          d.resolve(tile);
          return d.promise;
        }]
      }
    })

  }])

  .controller('TilesAddEditController', ["$scope", "RegionsV2", "CategoryV2", "theLanguages", "theInstance", "Tiles", "ProductV2", function ($scope, RegionsV2, CategoryV2, theLanguages, theInstance, Tiles, ProductV2) {
    $scope.tile = theInstance;
    $scope.regions = [];
    $scope.categories = [];
    $scope.languages = theLanguages;
    $scope.selectedPosition = $scope.tile.positions ? $scope.tile.positions.split(',').pop() : 1;
    $scope.infoMessage = null;
    $scope.hasError = false;
    $scope.model = {
      categories: $scope.tile.categories ? $scope.tile.categories.split(',') : [],
      regions: $scope.tile.regions ? $scope.tile.regions.split(',') : [],
      positions: $scope.tile.positions ? $scope.tile.positions.split(',') : []
    };

    onInit();

    function onInit() {
      if ($scope.tile.type === tileType.Product) {
        fetchProducts($scope.tile.languageCode);
      } else {
        fetchRegionAndCategories($scope.tile.languageCode);
      }
    }

    $scope.$watch('tile.type', function (newTileType, oldTileType) {
      if (newTileType === oldTileType) {
        return;
      }

      if (newTileType === tileType.Product && $scope.tile.languageCode) {
        fetchProducts($scope.tile.languageCode);
        return;
      }

      if ($scope.tile.languageCode) {
        fetchRegionAndCategories($scope.tile.languageCode);
        resetChosenRegionsAndCategories();
      }
    });

    $scope.$watch('tile.languageCode', function (newLanguageCode, oldLanguageCode) {
      if (newLanguageCode === oldLanguageCode) {
        return;
      }

      if ($scope.tile.type === tileType.Product) {
        fetchProducts($scope.tile.languageCode);
      } else {
        fetchRegionAndCategories(newLanguageCode);
        resetChosenRegionsAndCategories();
      }
    });

    function fetchProducts(languageCode) {
      $scope.products = ProductV2.query({ languageCode: languageCode });
    }

    function fetchRegionAndCategories(languageCode) {
      $scope.categories = CategoryV2.query({ languageCode: languageCode });
      $scope.regions = RegionsV2.query({ languageCode: languageCode });
    }

    function resetChosenRegionsAndCategories() {
      $scope.model.categories = [];
      $scope.model.regions = [];
    }

    $scope.saveTile = function saveTile() {
      $scope.tile.categories = $scope.model.categories.join(',');
      $scope.tile.regions = $scope.model.regions.join(',');
      $scope.tile.positions = $scope.model.positions.join(',');

      if (!isValidTile()) {
        return;
      }

      if ($scope.tile.type === tileType.Product) {
        // set unused properties to default values
        $scope.tile.image = '';
        $scope.tile.link = '';
      }

      $scope.tile.$save()
        .then(function () {
          $scope.infoMessage = 'Custom tile saved!';
          $scope.hasError = false;
        })
        .catch(function (err) {
          $scope.infoMessage = 'Could not save tile';
          $scope.hasError = true;
        })
    };

    $scope.reset = function () {
      $scope.tile = new Tiles();
      $scope.model = {
        categories: [],
        regions: [],
        positions: []
      };
    }

    function isValidTile() {
      if ($scope.tile.positions.length === 0) {
        $scope.infoMessage = 'You need to enter a position';
        $scope.hasError = true;
        return false;
      }

      if (($scope.tile.dateFrom && !$scope.tile.dateTo) ||
        (!$scope.tile.dateFrom && $scope.tile.dateTo)) {
        $scope.infoMessage = 'Period need start and end date';
        $scope.hasError = true;
        return false;
      }

      if ($scope.tile.dateFrom && $scope.tile.dateTo) {
        if (!isValidDate($scope.tile.dateFrom, $scope.tile.dateTo)) {
          $scope.infoMessage = 'Period is invalid';
          $scope.hasError = true;
          return false;
        }
      }

      $scope.infoMessage = null;
      $scope.hasError = false;
      return true;
    }

    function isValidDate(dateFrom, dateTo) {
      return dateFrom < dateTo;
    }

    $scope.addPosition = function () {
      if (!$scope.selectedPosition || $scope.selectedPosition < 1 || isNaN(Number($scope.selectedPosition))) {
        return;
      }

      if ($scope.model.positions.includes($scope.selectedPosition)) {
        return;
      }

      $scope.model.positions.push($scope.selectedPosition);
    }

    $scope.removePosition = function (position) {
      var index = $scope.model.positions.indexOf(position);
      if (index === -1) {
        return;
      }

      $scope.model.positions.splice(index, 1);
    }

    $scope.clearPeriod = function () {
      $scope.tile.dateFrom = null;
      $scope.tile.dateTo = null;
    }
  }]);


function resolve($q, service) {
  const d = $q.defer();

  service.query(function (res) {
    d.resolve(res);
  }, function (err) {
    d.reject();
  });

  return d.promise;
}
angular.module('Cockpit.State.Tiles', [
  'ui.router',
  'Cockpit.Service.Tiles',
  'Cockpit.State.Tiles.AddEdit',
  'Cockpit.Directive.ConvertToNumber',
  'Cockpit.Service.PimProduct'
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.products.tiles', {
      url: '/tiles',
      views: {
        '@private': {
          controller: 'TilesController',
          templateUrl: 'states/products/tiles/tiles.tpl.html',
        }
      },
      data: {
        pageTitle: 'Tiles Settings'
      },
      resolve: {
        theProducts: ['$q', 'PimProduct', function ($q, PimProduct) {
          var d = $q.defer();

          PimProduct.query(function (res) {
            d.resolve(res);
          }, function (err) {
            d.reject();
          });

          return d.promise;
        }],
        theLanguages: ['$q', 'Language', function ($q, Language) {
          var d = $q.defer();

          Language.query(function (res) {
            d.resolve(res);
          }, function (err) {
            d.reject();
          });

          return d.promise;
        }]
      }
    });
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.products', 'General', [
      {
        Label: "Custom Tiles",
        State: 'private.products.tiles',
        Groups: ['admin']
      }
    ]);
  }])

  .controller('TilesController', ["$scope", "Tiles", "$state", "theProducts", "theLanguages", function TilesController($scope, Tiles, $state, theProducts, theLanguages) {
    $scope.languages = theLanguages;
    $scope.products = theProducts;
    $scope.currentLanguageCode = 'da-dk';
    $scope.tiles = [];
    $scope.isLoading = true;
    $scope.currentSortColumn = 'title';
    $scope.isReverseSort = false;
    initTiles();

    function initTiles() {
      Tiles.query().$promise.then(function (tiles) {
        $scope.tiles = tiles;
        $scope.isLoading = false;
      })
    }

    $scope.getProductName = function (sku) {
      const product = $scope.products.find(function (p) {
        return p.sku === sku
      });

      if (!product) {
        return sku;
      }

      return sku + ' (' + product.name + ')';
    }

    $scope.addOrEdit = function (id) {
      if (id && id > 0) {
        $state.go('private.products.tiles.edit', { id: id });
      } else {
        $state.go('private.products.tiles.add');
      }
    }

    $scope.delete = function (tile) {
      if (confirm('Are you sure you want to delete: ' + tile.title + '?')) {
        Tiles.delete({ id: tile.id }, function (res) {
          $scope.tiles = Tiles.query();
        });
      }
    }

    $scope.positionSorter = function (tile) {
      const position = tile.positions.split(',')[0];
      return parseInt(position);
    }

    $scope.sortByColumn = function (column) {
      if ($scope.currentSortColumn === column) {
        $scope.isReverseSort = !$scope.isReverseSort;
      } else {
        $scope.isReverseSort = false;
      }
      $scope.currentSortColumn = column;
    }

    $scope.getClass = function (column) {
      if ($scope.currentSortColumn === column) {
        return $scope.isReverseSort ? 'arrow down' : 'arrow up';
      }
      return '';
    }
  }])

  ;

const tileType = {
  Normal: 1,
  Product: 2
};
angular.module('Cockpit.State.Settings.CallOutTypes.AddEdit', [
  'ui.router',
  'Cockpit.Service.CallOutTypes',
  'Cockpit.Controller.GenericAddEdit'
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.settings.call-out-types.add', {
      url: '/add',
      controller: 'GenericAddEditController',
      templateUrl: 'states/settings/call-outs/add-edit.tpl.html',
      data: {
        pageTitle: 'Add new Call-Out Type'
      },
      resolve: {
        theInstance: ['$q', 'CallOutType', function ($q, CallOutType) {
          var d = $q.defer();

          var callOutType = new CallOutType();
          callOutType.id = 0;
          callOutType.name = '';
          callOutType.icon = '';
          callOutType.color = 'black';

          d.resolve(callOutType);

          return d.promise;
        }],
        scopePropertyName: function(){
          return "callOutType";
        },
        goStateName: function(){
          return 'private.settings.call-out-types';
        }
      }
    })

    $stateProvider.state('private.settings.call-out-types.edit', {
      url: '/{id}/edit',
      controller: 'GenericAddEditController',
      templateUrl: 'states/settings/call-outs/add-edit.tpl.html',
      data: {
        pageTitle: 'Edit Call-Out Type'
      },
      resolve: {
        theInstance: ['$q', 'CallOutType', '$stateParams', function ($q, CallOutType, $stateParams) {
          var d = $q.defer();

          CallOutType.get({ id: $stateParams.id }, function (response) {
            d.resolve(response);
          }, function (err) {
            d.reject();
          })

          return d.promise;
        }],
        scopePropertyName: function(){
          return "callOutType";
        },
        goStateName: function(){
          return 'private.settings.call-out-types';
        }
      }
    })
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.settings', 'Call-Outs', [
      {
        Label: 'Add',
        State: 'private.settings.call-out-types.add',
        Groups: ['admin']
      }
    ])
  }])
;

angular.module('Cockpit.State.Settings.CallOutTypes', [
  'ui.router',
  'Cockpit.Service.CallOutTypes',
  'Cockpit.State.Settings.CallOutTypes.AddEdit'
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.settings.call-out-types', {
      url: 'call-out-types',
      controller: 'CallOutTypeSettingsController',
      templateUrl: 'states/settings/call-outs/list.tpl.html',
      data: {
        pageTitle: 'Call-out Type Settings'
      },
      resolve: {
        callOutTypes: resolvers.callOutTypesResolver
      }
    })
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.settings', 'Call-Outs', [
      {
        Label: 'Types',
        State: 'private.settings.call-out-types',
        Groups: ['admin']
      }
    ])
  }])

  .controller('CallOutTypeSettingsController', ["$scope", "callOutTypes", "CallOutType", "$sce", function CallOutTypeSettingsController($scope, callOutTypes, CallOutType, $sce) {
    $scope.sce = $sce;
    $scope.callOutTypes = callOutTypes;

    $scope.delete = function deleteType(type){
      type.$delete(function(){
        $scope.callOutTypes = CallOutType.query();
      });
    }
  }])

angular.module('Cockpit.State.Settings.FooterWidget.AddEdit', [
  'ui.router',
  'Cockpit.Service.FooterWidgets'
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.settings.footer-widgets.add', {
      url: '/add',
      controller: 'FooterWidgetAddEditController',
      templateUrl: 'states/settings/footerwidgets/footer-widget-add-edit.tpl.html',
      data: {
        pageTitle: 'Add new Footer Widget'
      },
      resolve: {
        theInstance: ['$q', 'FooterWidgets', function ($q, FooterWidgets) {
          var d = $q.defer();

          var widget = new FooterWidgets();
          widget.id = 0;
          widget.heading = '';
          widget.languageCode = undefined;
          widget.footerLinks = [];

          d.resolve(widget);

          return d.promise;
        }],
        languages: resolvers.languagesResolver
      }
    })

    $stateProvider.state('private.settings.footer-widgets.edit', {
      url: '/{id}/edit',
      controller: 'FooterWidgetAddEditController',
      templateUrl: 'states/settings/footerwidgets/footer-widget-add-edit.tpl.html',
      data: {
        pageTitle: 'Edit Footer Widget'
      },
      resolve: {
        theInstance: ['$q', 'FooterWidgets', '$stateParams', function ($q, FooterWidgets, $stateParams) {
          var d = $q.defer();

          FooterWidgets.get({ id: $stateParams.id }, function (response) {
            d.resolve(response);
          }, function (err) {
            d.reject();
          })

          return d.promise;
        }],
        languages: resolvers.languagesResolver
      }
    })
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.settings', 'Footers', [
      {
        Label: 'Add Footer Widget',
        State: 'private.settings.footer-widgets.add',
        Groups: ['Admin']
      }
    ])
  }])

  .controller('FooterWidgetAddEditController', ["$scope", "$state", "FooterWidgets", "theInstance", "languages", function FooterWidgetAddEditController($scope, $state, FooterWidgets, theInstance, languages) {
    $scope.widget = theInstance;
    $scope.isProcessing = false;

    $scope.languages = languages;
    $scope.newFooterLink = {
      caption: '',
      link: '',
      shouldOpenInNewWindow: true
    };

    $scope.addFooterLink = function () {
      const footerLink = $scope.newFooterLink;

      if (footerLink.caption.length === 0 || footerLink.link.length === 0) {
        return;
      }

      $scope.widget.footerLinks.push(footerLink);

      $scope.newFooterLink = {
        caption: '',
        link: '',
        shouldOpenInNewWindow: true
      }
    }

    $scope.removeFooterLink = function (footerLink) {
      const index = $scope.widget.footerLinks.indexOf(footerLink);
      $scope.widget.footerLinks.splice(index, 1);
    }

    $scope.onSubmit = function () {
      $scope.isProcessing = true;

      if ($scope.widget.id < 1) {
        $scope.widget.$save();
        $scope.isProcessing = false;
        $state.go('private.settings.footer-widgets', {}, { reload: true });
      }
      else {
        $scope.widget.$update();
        $scope.isProcessing = false;
        $state.go('private.settings.footer-widgets', {}, { reload: true });
      }
    }
  }])

angular.module('Cockpit.State.Settings.FooterWidgets', [
  'ui.router',
  'Cockpit.Service.FooterWidgets'
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.settings.footer-widgets', {
      url: 'footerwidgets',
      controller: 'FooterWidgetSettingsController',
      templateUrl: 'states/settings/footerwidgets/footer-widgets.tpl.html',
      data: {
        pageTitle: 'Footer Widget Settings'
      },
      resolve: {
        languages: resolvers.languagesResolver
      }
    })
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.settings', 'Footers', [
      {
        Label: 'Footer Widgets',
        State: 'private.settings.footer-widgets',
        Groups: ['admin']
      }
    ])
  }])

  .controller('FooterWidgetSettingsController', ["$scope", "FooterWidgets", "languages", function FooterWidgetSettingsController($scope, FooterWidgets, languages) {
    $scope.isProcessing = false;

    $scope.refresh = function refreshFooterWidgets() {
      FooterWidgets.query(function (widgets) {
        $scope.widgets = widgets
      })
    }

    $scope.delete = function (id) {
      $scope.isProcessing = true;

      FooterWidgets.delete({ id: id }, function () {
        $scope.isProcessing = false;
        $scope.refresh();
      })
    }

    $scope.refresh();
  }])

angular.module('Cockpit.State.Settings.Geckoboard', [
    'Cockpit.State.Settings.Geckoboard.MonthlyGoals'
])
;
angular.module('Cockpit.State.Settings.MailChimp', [
    'Cockpit.State.Settings.MailChimp.ECommerce'
])
;
angular.module('Cockpit.Service.SlackChannel', ['ngResource'])
    .factory('SlackChannel', ['$resource',
        function ($resource) {
            return $resource(Config.ApiV1 + 'slack/channels');
        }]);
angular.module('Cockpit.State.Settings.Notifications', [
    'ui.router',
    'Cockpit.Service.SlackChannel'
])

    .config(["$stateProvider", function config($stateProvider) {
        $stateProvider.state('private.settings.notifications', {
            url: 'notifications/',
            controller: 'NotificationSettingsController',
            templateUrl: 'states/settings/notifications/settings.tpl.html',
            data: {
                pageTitle: 'Notification Settings'
            }
        })

        ;
    }])

    .run(["Navigation", function (Navigation) {
        Navigation.sidebar.register('private.settings', 'Notifications', [
            {
                Label: "Slack",
                State: 'private.settings.notifications',
                Groups: ["admin"]
            }
        ]);
    }])

    .controller('NotificationSettingsController', ["$scope", "Settings", "SlackChannel", function NotificationSettingsController($scope, Settings, SlackChannel) {
        $scope.isEnabled = false;
        $scope.slackSettings = Settings.get({key: 'slack'}, function(v){
            $scope.isEnabled = v.IsEnabled === "true";
            return v;
        });

        $scope.$watch('isEnabled', function (v){
            $scope.slackSettings.IsEnabled = v ? "true" : "false";
        });

        $scope.saveSettings = function saveSettings(){
            $scope.slackSettings.$save({key: 'slack'});
        };
    }])

;
angular.module('Cockpit.State.Site.TopBars', [
  'ui.router',
  'Cockpit.State.Site.TopBars.List',
  'Cockpit.State.Site.TopBars.AddEdit'
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.site.topbars', {
      url: 'topbars/',
      template: "<ui-view />",
      data: {
        pageTitle: 'Topbars Settings'
      },
    })
  }])
;

angular.module('Cockpit.State.Site.TopBars.AddEdit', [
    'ui.router',
    'Cockpit.Service.Topbars',
    'Cockpit.Service.TopbarTypes',
    'Cockpit.Service.Campaign'
  ])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.site.topbars.add', {
        url: 'add/',
        controller: 'TopbarAddEditController',
        templateUrl: 'states/site/topbars/add-edit.tpl.html',
        data: {
          pageTitle: 'Add a Topbar'
        },
        resolve: {
          theLanguages: resolvers.languagesResolver,
          callOutTypes: resolvers.callOutTypesResolver,
          campaignsData: ['$q', 'Campaign', function resolveCampaigns($q, Campaign){
            var d = $q.defer();
            Campaign.query(function(data){
              d.resolve(data);
            });
            return d.promise;
          }],
          TopbarTypeData: ['$q', 'TopbarTypeService', function ($q, TopbarTypeService) {
            var d = $q.defer();

            TopbarTypeService.query(function (res) {
              d.resolve(res);
            }, function (err) {
              d.resolve([]);
            });

            return d.promise;
          }],
          TopbarData: ['$q', function ($q) {
            var d = $q.defer();

            var topbar = {
              "name": "",
              "languageCode": "",
              "topBarType": "",
              "limitedTime": false,
              "start": null,
              "end": null,
              "parameters": {},
              "locations": [],
              "pages": null,
              "overlay": null,
              "link": null,
              "isActive": true
          };

            d.resolve(topbar);
            return d.promise;
          }]
        }
      })
      .state('private.site.topbars.edit', {
        url: '{id}/edit/',
        controller: 'TopbarAddEditController',
        templateUrl: 'states/site/topbars/add-edit.tpl.html',
        data: {
          pageTitle: 'Edit Topbar'
        },
        resolve: {
          theLanguages: resolvers.languagesResolver,
          callOutTypes: resolvers.callOutTypesResolver,
          campaignsData: ['$q', 'Campaign', function resolveCampaigns($q, Campaign){
            var d = $q.defer();
            Campaign.query(function(data){
              d.resolve(data);
            });
            return d.promise;
          }],
          TopbarTypeData: ['$q', 'TopbarTypeService', function ($q, TopbarTypeService) {
            var d = $q.defer();

            TopbarTypeService.query(function(res){
              d.resolve(res);
            }, function (err){
              d.resolve([]);
            });
            return d.promise;
          }],
          TopbarData: ['$q', '$stateParams', 'TopbarService', function ($q, $stateParams, TopbarService) {
            var d = $q.defer();

            TopbarService.getById({
              id: $stateParams.id
            }, function (res) {
              res.start = new Date(res.start);
              res.end = new Date(res.end);
              d.resolve(res);
            }, function (err) {
              d.reject();
            })

            return d.promise;
          }]
        }
      })
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.site', 'Topbars', [{
      Label: 'Add',
      State: 'private.site.topbars.add',
      Groups: ['admin']
    }])
  }])

  .controller('TopbarAddEditController', ["$scope", "$state", "theLanguages", "TopbarTypeData", "TopbarData", "callOutTypes", "campaignsData", "TopbarService", function TopbarAddEditController($scope, $state, theLanguages, TopbarTypeData, TopbarData, callOutTypes, campaignsData, TopbarService) {

    $scope.languages = theLanguages;
    $scope.campaigns = campaignsData;
    $scope.topbarTypes = TopbarTypeData;
    $scope.callOutTypes = callOutTypes;
    $scope.newBullet = "";
    $scope.topbarLocations = ["frontpage", "products-list", "search-page", "content-site", "product-page", "ts-giftcard", "checkout", "booking-login", "your-booking", "exchange-page", "refund-page"];
    $scope.seasonTypes = [
      {
        "slug": "christmas",
        "name": "Christmas"
      },
      {
        "slug": "black-friday",
        "name": "Black Friday"
      }
    ];
    $scope.selectedType = null;
    $scope.campaignDateChanged = false;

    $scope.topbar = TopbarData;
    $scope.showOnSpecificPage = TopbarData.pages && TopbarData.pages.length > 0;

    // $scope.topbar.additionalInfo = "none";
    $scope.additionalInfo = $scope.topbar.link !== null ? "link" : $scope.topbar.overlay !== null ? "overlay" : "none";

    var filterCampaigns = function(campaigns, langCode) {
      if( campaigns.length > 0 ) {
        return campaigns.filter(function(c) {
          return c.isActive && c.languageCode === langCode;
        })
      }
      return [];
    };

    var setSelectedMessageType = function(callOutTypes, topbar) {
      if( "service-message" === topbar.topBarType && topbar.parameters && topbar.parameters.id ) {
        return callOutTypes.find(function(ct){
          return ct.id === Number(topbar.parameters.id);
        })
      }
      return null;
    }

    $scope.selectedMessageType = setSelectedMessageType(callOutTypes, $scope.topbar);


    $scope.campaigns = filterCampaigns(campaignsData, $scope.topbar.languageCode);

    $scope.toggleLocation = function togggleLocation(loc){
      var index = $scope.topbar.locations.indexOf(loc);
      if (index === -1) {
        $scope.topbar.locations.push(loc);
      } else {
        $scope.topbar.locations.splice(index, 1);
      }
    };

    // Watches
    $scope.$watch("topbar.languageCode", function(langCode, oldLangCode) {
      if( langCode !== oldLangCode && $scope.topbar.topBarType === "campaign") {
        if (langCode === null || langCode === undefined) {
          return;
        }

        delete $scope.topbar.parameters.campaignId;
        $scope.campaigns = filterCampaigns(campaignsData, langCode);
      }
    });

    $scope.$watch("topbar.topBarType", function(tt) {
      if (tt === null || tt === undefined) {
        $scope.selectedType = null;
        return;
      }

      $scope.selectedType = $scope.topbarTypes.find( function(t) {
        return t.slug === tt;
      });

      if( "campaign" !== tt ) {
        delete $scope.topbar.parameters.campaignId;
      }
      return;
    });

    $scope.$watch("topbar.parameters.campaignId", function(id, oldId) {
      // console.log(id, oldId);
      if( $scope.topbar.topBarType === "campaign") {
        if (id === null || id === undefined) {
          $scope.topbar.limitedTime = false;
          $scope.topbar.start = null;
          $scope.topbar.end = null;
          return;
        }

        var selectedCampaign = $scope.campaigns.find(function(c) {
          return c.id === Number(id);
        });

        if( id === oldId && $scope.topbar.id && $scope.topbar.start && $scope.topbar.end ) {
          $scope.campaignDateChanged = Date.parse($scope.topbar.start) !== Date.parse(selectedCampaign.start) || Date.parse($scope.topbar.end) !== Date.parse(selectedCampaign.end);
        }

        if( selectedCampaign ) {
          $scope.topbar.limitedTime = true;
          $scope.topbar.start = new Date(selectedCampaign.start);
          $scope.topbar.end = new Date(selectedCampaign.end);
        }
      }
      return;
    });

    $scope.selectMessageType = function() {
      // convert ID to string as parameters only accept string values
      $scope.selectedMessageType.id = $scope.selectedMessageType.id.toString();
      Object.assign($scope.topbar.parameters, $scope.selectedMessageType);
    }

    $scope.changeLimitedTimeSetting = function() {
      if( !$scope.topbar.limitedTime ) {
        $scope.topbar.start = null;
        $scope.topbar.end = null;
        return;
      }
      $scope.topbar.start = "";
      $scope.topbar.end = "";

    }

    $scope.updateAdditionalInfo = function() {
      if( "none" === $scope.additionalInfo) {
        $scope.topbar.link = null;
        $scope.topbar.overlay = null;
      }
      if( "link" === $scope.additionalInfo) {
        $scope.topbar.link = "";
        $scope.topbar.overlay = null;
      }
      if( "overlay" === $scope.additionalInfo) {
        $scope.topbar.link = null;
        $scope.topbar.overlay = {
          "title": "",
          "text": "",
          "bullets": []
        };
      }
    };

    $scope.addBullet = function() {
      if( $scope.topbar.overlay === null ) {
        console.error("Overlay is null");
        return;
      }
      if( $scope.newBullet ) {
        $scope.topbar.overlay.bullets.push($scope.newBullet);
        $scope.newBullet = "";
      }
    }

    $scope.removeBullet = function(index) {
      if( $scope.topbar.overlay === null ) {
        console.error("Overlay is null");
        return;
      }

      $scope.topbar.overlay.bullets.splice(index, 1);
    }

    $scope.changeSpecificPageSetting = function(maybe) {
      $scope.topbar.locations = [];
      $scope.topbar.pages = [];
    };

    $scope.addPage = function(uri) {
      if( $scope.topbar.pages === null ) {
        console.error("pages is null");
        return;
      }
      if( uri) {
        $scope.topbar.pages.push(uri);
        $scope.newPage = "";
      }
    }

    $scope.removePage = function(index) {
      if( $scope.topbar.pages === null ) {
        console.error("pages is null");
        return;
      }

      $scope.topbar.pages.splice(index, 1);
    }


    // Saving
    $scope.saveTopbar = function () {
      if( $scope.topbar.parameters.campaignId ) {
        $scope.topbar.parameters.campaignId = $scope.topbar.parameters.campaignId.toString();
      }
      $scope.isProcessing = true;

      TopbarService.save(
        $scope.topbar,
        function successCb(data) {
          $scope.isProcessing = false;
          $scope.topbar.id = data.id;
          // TODO maybe we should redirect to list page?
          $state.go("private.site.topbars.list");
        },
        function errCb(err) {
          $scope.isProcessing = false;
          console.error("Err saving topbar =>", err);
        }
      );

    }
  }]);

angular.module('Cockpit.State.Site.TopBars.List', [
  'ui.router',
  'Cockpit.Service.Topbars',
  'Cockpit.Service.TopbarGrouping',
  'Cockpit.Directive.TopbarList'
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.site.topbars.list', {
      url: 'list/',
      controller: 'TopbarsListController',
      templateUrl: "states/site/topbars/list.tpl.html",
      data: {
        pageTitle: 'Topbars List'
      },
      resolve: {}
    })
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.site', 'Topbars', [
      {
        Label: 'List',
        State: 'private.site.topbars.list',
        Groups: ['admin']
      }
    ])
  }])

  .controller('TopbarsListController', ["$scope", "TopbarService", "TopbarGrouping", function TopbarsListController($scope, TopbarService, TopbarGrouping) {

    $scope.topbars = [];
    $scope.processing = false;

    var refresh = function() {
      $scope.isLoading = true;

      TopbarService.query(
        function successCb(data) {
          $scope.isLoading = false;
          $scope.topbars = TopbarGrouping.group(data);
        },
        function errorCb(err) {
          $scope.isLoading = false;
          console.log("Err getting topbars =>", err)
        }
      );
    }

    $scope.refresh = function() {
      refresh();
    }

    refresh();
  }])
;

angular.module('Cockpit.Service.Dgda.DiscountCodes', ['ngResource'])
  .factory('DiscountCode', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'discounts/vouchers/:code', {code: '@code'}, {
        queryUsage: { method: 'GET', isArray: true}
      });
    }])

  .factory('DiscountCodeOrder', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'discounts/vouchers/:code/orders', {}, {});
    }])
;
angular.module('Cockpit.Service.Dgda.OpenGiftCards', ['ngResource'])
  .factory('OpenGiftCard', ['$resource',
    function ($resource) {
      return $resource(Config.ApiV2 + 'opengiftcards/:code', {code: '@code'}, {});
    }])

  .factory('OpenGiftCardOrder', ['$resource',
    function ($resource) {
      return $resource(Config.ApiV2 + 'opengiftcards/:code/orders', {}, {});
    }])

;
angular.module('Cockpit.State.Dgda.Discounts', [
  'ui.router',
  'Cockpit.Service.Dgda.OpenGiftCards',
  'Cockpit.Service.Dgda.DiscountCodes'
])

  .config(["$stateProvider", function ($stateProvider) {
    $stateProvider.state('private.orders.open-gift-cards', {
      url: '/open-gift-cards',
      views: {
        "@private": {
          controller: 'CockpitDgdaOpenGiftCardsController',
          templateUrl: 'states/dgda/orders/discounts/open-gift-cards.tpl.html'
        }
      },
      data: {
        pageTitle: 'Open Gift Card Lookup'
      }
    })
      .state('private.orders.discount-lookup', {
        url: '/discount-lookup',
        views: {
          "@private": {
            controller: 'CockpitDgdaDiscountLookupController',
            templateUrl: 'states/dgda/orders/discounts/discount-lookup.tpl.html'
          }
        },
        data: {
          pageTitle: 'Discount Lookup'
        }
      });
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.orders', 'Discounts', [
      {
        Label: 'Open Gift Cards',
        State: 'private.orders.open-gift-cards',
        Groups: ["admin"]
      },{
        Label: 'Voucher Codes',
        State: 'private.orders.discount-lookup',
        Groups: ["admin"]
      }
    ]);
  }])

  .controller('CockpitDgdaOpenGiftCardsController', ["$scope", "OpenGiftCard", "OpenGiftCardOrder", function CockpitDgdaOpenGiftCardsController($scope, OpenGiftCard, OpenGiftCardOrder) {
    $scope.errorData = null;
    $scope.giftCard = null;
    $scope.isGettingOrders = false;
    $scope.editingValue = false;

    $scope.search = {
      Code: ""
    };

    $scope.isSearching = false;

    $scope.doSearch = function doSearch(){
      // Reset variables
      $scope.errorData = null;
      $scope.giftCard = null;
      $scope.isSearching = true;
      $scope.editingValue = false;

      $scope.isGettingOrders = true;

      // Query gift card
      $scope.giftCard = OpenGiftCard.get({code: $scope.search.Code}, function(){
        $scope.giftCardOrders = OpenGiftCardOrder.query({code: $scope.search.Code}, function(){
          $scope.isGettingOrders = false;
        });

        $scope.isSearching = false;
      }, function(err){
        $scope.giftCard = null;
        if(err.status === 404){
          $scope.errorData = {
            Title: "The code was not found / is not valid."
          }
        }
        $scope.isSearching = false;
        $scope.isGettingOrders = false;
      });
    };
  }])

  .controller('CockpitDgdaDiscountLookupController', ["$scope", "DiscountCode", "DiscountCodeOrder", function CockpitDgdaDiscountLookupController($scope, DiscountCode, DiscountCodeOrder) {
    $scope.errorData = null;
    $scope.voucher = null;
    $scope.isGettingOrders = false;
    $scope.editingValue = false;
    $scope.isGettingOrders = true;
    $scope.isSaving = false;

    $scope.search = {
      Code: ""
    };

    $scope.isSearching = false;

    $scope.saveVoucher = function saveVoucher(){
      $scope.isSaving = true;
      $scope.errorData = null;

      $scope.voucher.$save(function(){
        $scope.isSaving = false;
      }, function(err){
        $scope.errorData = {
          Title: "Unable to save voucher!",
          Description: err.message
        };
        $scope.isSaving = false;
      })
    };

    $scope.doSearch = function doSearch() {
      // Reset variables
      $scope.errorData = null;
      $scope.voucher = null;
      $scope.isSearching = true;
      $scope.editingValue = false;

      $scope.isGettingOrders = true;
      // Query gift card
      $scope.voucher = DiscountCode.get({code: $scope.search.Code}, function () {
        $scope.voucherOrders = DiscountCodeOrder.query({code: $scope.search.Code}, function () {
          $scope.isGettingOrders = false;
        });

        $scope.isSearching = false;
      }, function (err) {
        $scope.voucher = null;
        $scope.voucherOrders = [];
        if (err.status === 404) {
          $scope.errorData = {
            Title: "The code was not found / is not valid."
          }
        }
        $scope.isSearching = false;
        $scope.isGettingOrders = false;
      });
    }
  }])
;

angular.module('Cockpit.Service.Dgda.Printfile', ['ngResource'])
	.factory('ExportDgdaPrintfile', ['$resource',
		function ($resource) {
			return $resource(Config.Api + 'exportapi/:action', {}, {
				generate: { method: 'POST', params: { action: 'Printfile' }, isArray: true },
				info: { method: 'GET', params: { action: 'LastExport' } },
				query: { method: 'GET', params: {action: 'GetOrders'}, isArray: true},
				get: { method: 'GET', params: {action: 'GetOrder'}},
				saveOrders: { method: 'POST', params: {action: 'UpdateOrders'}, isArray: true},
				regenPdfs: { method: 'POST', params: {action: 'UpdatePdfs'}}
			});
		}])

	.factory('PrintFileLog', ['$resource',
		function ($resource) {
      const mainUrl = Config.ApiV1 + 'export/printfile/logs';

			return $resource(mainUrl, {}, {
        createExportLog: { method: 'PUT', transformResponse: function (data) {
          // primitive types cannot be returned directly so we transform it to an object
          if (!isNaN(parseInt(data))) {
            return {
              exportLogId: Number.parseInt(data)
            };
          }

          return data;
        }},
        getExportLogStatus: { method: 'GET', url: mainUrl + '/:exportLogId/status', isArray: false },
        updateOrderStatus: { method: 'POST', url: mainUrl + '/:exportLogId/orders/:orderNumber/status' },
        addOrder: { method: 'POST', url: mainUrl + '/:exportLogId/orders' },
        performExport: { method: 'POST', url: mainUrl + '/:exportLogId/orders/shipments/create' },
        getExportZipFiles: { method: 'GET', url: mainUrl + '/:exportLogId', isArray: true },
        deleteExportLog: { method: 'DELETE', url: mainUrl + '/:exportLogId' },
      });
		}])
;

angular.module('Cockpit.State.Printfile.DataVerification', [
  'ui.router',
  'Cockpit.Service.Dgda.Printfile',
  'Cockpit.PrintFile.Shared'
])

  .config(["$stateProvider", function ($stateProvider) {
    $stateProvider.state('private.orders.printfile.dataverification', {
      url: '/printfile/{exportLogId}/verify?step',
      views: {
        "@private": {
          controller: 'CockpitDgdaExportPrintfileDataVerificationController',
          templateUrl: 'states/dgda/orders/printfile/printfile-dataverification.tpl.html'
        }
      },
      data: {
        pageTitle: 'Export print-file verify'
      }
    });
  }])

  .controller('CockpitDgdaExportPrintfileDataVerificationController', ["$scope", "$state", "$stateParams", "ExportDgdaPrintfile", "PrintFileLog", "PrintFileHelper", function ($scope, $state, $stateParams, ExportDgdaPrintfile, PrintFileLog, PrintFileHelper) {
    $scope.isLoading = false;
    $scope.exportLogId = $stateParams.exportLogId;
    $scope.countries = PrintFileHelper.countries;
    $scope.isAddingOrder = false;
    $scope.orderToAdd = '';
    $scope.step = $stateParams.step ? $stateParams.step : 2; // default start step is 2
    $scope.personalizedForms = [];
    $scope.deliveryForms = [];
    $scope.errorData = null;
    var currentPrintFileOrderLogs = [];
    var ordersToSave = [];
    var orderNumbers = [];

    getExportLog();

    function getExportLog() {
      $scope.isLoading = true;

      PrintFileLog.getExportLogStatus({ exportLogId: $stateParams.exportLogId }, function (response) {

        $scope.currentPrintFileLog = response.printFileLog;
        currentPrintFileOrderLogs = response.printFileOrderLogs;

        if (!$scope.orders || $scope.orders.length === 0) {
          getOrders();
          return;
        }

        $scope.isLoading = false;
      }, function (err) {
        console.log(err);
        $scope.isLoading = false;
        alert('Failed to get export log. Please try refreshing page again.');
      })
    }

    function getOrders() {
      $scope.orders = ExportDgdaPrintfile.query(PrintFileHelper.getExportDtoFromPrintFileLog($scope.currentPrintFileLog), function (response) {
        $scope.initialOrders = angular.copy(response);
        $scope.isLoading = false;
      }, function (err) {
        $scope.isLoading = false;
        alert("Could not load orders... Please try refreshing page again.");
        $scope.errorData = err;
      })
    }

    $scope.isOrderPreviouslyShipped = function (order) {
      var orderLog = currentPrintFileOrderLogs.find(function (x) { return x.orderNumber === order.OrderNumber });
      if (!orderLog) {
        console.log(order.OrderNumber + ' was not in export log');
        return;
      }

      return orderLog.isPreviouslyShipped;
    }

    $scope.addOrder = function addOrder() {
      if ($scope.orderToAdd === "") {
        return;
      }
      $scope.errorData = null;
      $scope.isAddingOrder = true;

      ExportDgdaPrintfile.get({ orderNumber: $scope.orderToAdd }, function (data) {
        for (var i = 0; i < $scope.orders.length; i++) {
          if (data.OrderNumber === $scope.orders[i].OrderNumber) {
            alert("The order is already included!");
            $scope.isAddingOrder = false;
            return;
          }
        }

        // add order to printfile order log
        PrintFileLog.addOrder({ exportLogId: $scope.exportLogId }, { orderNumber: $scope.orderToAdd }, function (res) {
          $scope.isAddingOrder = false;
          $scope.orderToAdd = '';
          $scope.orders.push(data);

        }, function (err) {
          $scope.errorData = err;
          $scope.isAddingOrder = false;
          alert('Could not add order');
        })

      }, function err(a) {
        $scope.isAddingOrder = false;

        if (a.status === 404) {
          alert("The order was not found.");

        } else if (a.status === 400) {
          alert("The order could not be added. This is either because it's not a 'New Order', or it's an order purchased as 'Print at home'.")
        } else {
          $scope.errorData = a;
          alert("A server error occurred.")
        }
      });
    };

    $scope.isButtonDisabled = function () {
      return $scope.deliveryForms.find(function (f) {
        return f.$invalid
      })
    }

    $scope.changeStep = function(step) {
      $scope.step = step;
    }

    $scope.onContinue = function () {
      $scope.isLoading = true;

      // Generate list of all order numbers
      orderNumbers = [];
      for (var i = 0; i < $scope.orders.length; i++) {
        if (!$scope.orders[i].Include) {
          continue;
        }

        orderNumbers.push($scope.orders[i].OrderNumber);
      }

      // Start the save process, if needed
      $scope.processingText = 'Checking for orders to save...';
      for (var i = 0; i < $scope.orders.length; i++) {
        if ($scope.orders[i].Include && ($scope.deliveryForms[i].$dirty || $scope.personalizedForms[i].$dirty)) {
          ordersToSave.push($scope.orders[i]);
        }
      }

      if (ordersToSave.length === 0) {
        $scope.isLoading = false;
        $state.go('private.orders.printfile.status', { exportLogId: $stateParams.exportLogId });
        return;
      }

      // Save orders
      $scope.processingText = 'Saving orders...';
      ExportDgdaPrintfile.saveOrders(ordersToSave, function (r) {

        // Since we updated the PDF files, we also need to regenerate the PDF files
        regeneratePdfs();

      }, function (a) {
        $scope.errorData = a;
        $scope.isLoading = false;
        $scope.processingText = null;
        alert("Unfortunately the orders couldn't be saved.")
      })
    }

    function regeneratePdfs() {
      $scope.processingText = 'Regenerating PDF files...';

      var savedOrderNumbers = [];
      for (var i = 0; i < ordersToSave.length; i++) {
        savedOrderNumbers.push(ordersToSave[i].OrderNumber);
      }

      ExportDgdaPrintfile.regenPdfs(savedOrderNumbers, function () {
        $scope.isLoading = false;
        $state.go('private.orders.printfile.status', { exportLogId: $stateParams.exportLogId });

      }, function (a) {
        $scope.errorData = a;
        $scope.isLoading = false;
        alert("Could not regenerate PDF files.");
      })
    }
  }])

angular.module('Cockpit.State.Dgda.Printfile.Status.EditOrder', [
  'ui.router',
  'Cockpit.Service.Dgda.Printfile',
])

  .controller("PrintFileEditOrderCtl", ["$scope", "order", "printFileOrderLog", "$uibModalInstance", "ExportDgdaPrintfile", "PrintFileLog", function PrintFileEditOrderCtl($scope, order, printFileOrderLog, $uibModalInstance, ExportDgdaPrintfile, PrintFileLog) {
    $scope.order = order;
    $scope.printFileOrderLog = printFileOrderLog;
    $scope.isLoading = false;
    $scope.errorData = null;

    $scope.shippingDetailsUrl = Config.WebShipperBaseUrl + 'ship/orders/' + printFileOrderLog.externalReferenceId;

    function regeneratePdfs(ordersToSave) {
      $scope.processingText = 'Regenerating PDF files...';

      var savedOrderNumbers = [];
      for (var i = 0; i < ordersToSave.length; i++) {
        savedOrderNumbers.push(ordersToSave[i].OrderNumber);
      }

      ExportDgdaPrintfile.regenPdfs(savedOrderNumbers, function () {
        $scope.isLoading = false;
        $scope.processingText = null;
        $uibModalInstance.close({ didUpdateOrder: true });
      }, function (a) {
        $scope.errorData = a;
        $scope.isLoading = false;
        $scope.processingText = 'Failed regenerating PDF files';
        alert("Could not regenerate PDF files.");
      })
    }

    $scope.updateOrder = function () {

      var ordersToUpdate = [$scope.order];
      $scope.isLoading = true;
      $scope.errorData = null;

      $scope.processingText = 'Updating order...';

      ExportDgdaPrintfile.saveOrders(ordersToUpdate, function (response) {
        regeneratePdfs(ordersToUpdate);
      }, function (a) {
        $scope.errorData = a;
        $scope.isLoading = false;
        $scope.processingText = 'Failed updating order';
        alert("Unfortunately the order couldn't be saved.")
      })
    }

    $scope.excludeOrder = function () {
      $scope.isLoading = true;
      $scope.errorData = null;

      var excludedStatus = 'Excluded';

      PrintFileLog.updateOrderStatus({ exportLogId: $scope.printFileOrderLog.exportLogId, orderNumber: printFileOrderLog.orderNumber, orderExportStatus: excludedStatus }, {}, function (res) {
        printFileOrderLog.status = excludedStatus;
        $scope.isLoading = false;
        $uibModalInstance.close({ didUpdateOrderStatus: true });

      }, function (err) {
        $scope.isLoading = false;
        $scope.errorData = err;
        $scope.processingText = 'Failed excluding order';
      })
    }

    $scope.onClose = function () {
      $uibModalInstance.close();
    }
  }])

angular.module('Cockpit.State.Dgda.Printfile.Status', [
  'ui.router',
  'Cockpit.Service.Dgda.Printfile',
  'Cockpit.PrintFile.Shared',
  'Cockpit.PrintFile.ExportStatus',
  'Cockpit.State.Dgda.Printfile.Status.EditOrder'
])

  .config(["$stateProvider", function ($stateProvider) {
    $stateProvider.state('private.orders.printfile.status', {
      url: '/printfile/{exportLogId}/status',
      views: {
        "@private": {
          controller: 'CockpitDgdaExportPrintfileStatusController',
          templateUrl: 'states/dgda/orders/printfile/printfile-status.tpl.html'
        }
      },
      data: {
        pageTitle: 'Export print-file status'
      }
    });
  }])

  .controller('CockpitDgdaExportPrintfileStatusController', ["$scope", "$stateParams", "$interval", "$uibModal", "ExportDgdaPrintfile", "PrintFileLog", "PrintFileHelper", "PrintFileConstants", function ($scope, $stateParams, $interval, $uibModal, ExportDgdaPrintfile, PrintFileLog, PrintFileHelper, PrintFileConstants) {
    $scope.currentPrintFileLog = {}
    $scope.currentPrintFileOrderLogs = [];
    $scope.isLoading = false;
    $scope.isRefreshingStatus = false;
    $scope.isUpdatingOrderStatus = false;
    $scope.orders = [];
    $scope.statusPollingIntervalId = null;
    $scope.errorData = null;
    $scope.getColorClass = PrintFileConstants.getColorClass;
    $scope.showLoadingBubbles = PrintFileConstants.showLoadingBubbles;
    $scope.getDisplayStatus = PrintFileConstants.getDisplayStatus;
    $scope.exportedData = null;
    $scope.step = 4;
    $scope.sortPropertyName = "status";
    $scope.sortReverse = false;
    $scope.sortByStatus = function() {
      $scope.sortReverse = !$scope.sortReverse;
    }

    // initial request + polling status endpoint every x seconds
    var intervalPromise = null;
    setupExportStatusInterval();
    $scope.$on('$destroy', function () {
      if (intervalPromise) {
        $interval.cancel(intervalPromise);
        intervalPromise = undefined;
      }
    })

    function setupExportStatusInterval() {
      getForExportStatus();
      intervalPromise = $interval(function () {
        if (!$scope.isRefreshingStatus) {
          getForExportStatus(); // only fetch if not already in the middle of loading something
        }
      }, 10000);
    }

    function getForExportStatus() {
      $scope.isRefreshingStatus = true;

      PrintFileLog.getExportLogStatus({ exportLogId: $stateParams.exportLogId }, function (response) {

        $scope.currentPrintFileLog = response.printFileLog;

        // update orderLog entries manually to prevent entire scope updating and making table flicker
        response.printFileOrderLogs.forEach(function (orderLog) {
          var existingOrderLog = $scope.currentPrintFileOrderLogs.find(function (x) { return x.id === orderLog.id });

          if (!existingOrderLog) {
            $scope.currentPrintFileOrderLogs.push(orderLog);
            return;
          }

          for (var key in existingOrderLog) {
            if (Object.hasOwnProperty.call(existingOrderLog, key)) {
              existingOrderLog[key] = orderLog[key];
            }
          }
        })

        if ($scope.orders.length === 0) {
          getOrders();
          return;
        }

        validateExportStatus();
        $scope.isRefreshingStatus = false;
        $scope.isUpdatingOrderStatus = false;
      }, function (err) {
        console.log(err);
        $scope.isRefreshingStatus = false;
        $scope.isUpdatingOrderStatus = false;
      })
    }

    function getOrders() {
      $scope.orders = ExportDgdaPrintfile.query(PrintFileHelper.getExportDtoFromPrintFileLog($scope.currentPrintFileLog), function (response) {
        $scope.initialOrders = angular.copy(response);
        $scope.isRefreshingStatus = false;
      }, function (err) {
        $scope.isRefreshingStatus = false;
        alert("Could not load orders. Please try refreshing page again.");
        $scope.errorData = err;
      })
    }

    function validateExportStatus() {
      // show status message when export is initialized
      if ($scope.currentPrintFileLog.status !== 'Unfinished') {
        $scope.exportingText = $scope.currentPrintFileLog.status + '...';
      }

      if ($scope.currentPrintFileLog.status !== 'Exported') {
        return;
      }

      // stop polling since status export is done
      $interval.cancel(intervalPromise);
      intervalPromise = undefined;
    }

    $scope.exportOrders = function exportOrders() {
      $scope.exportingText = 'Starting export files...';
      performExport(null);
    }


    $scope.editOrder = function (printFileOrderLog) {
      if ($scope.currentPrintFileLog.status === 'ReadyForExport') {
        alert('Cannot edit order when ready for export already');
        return;
      }

      var order = $scope.orders.find(function (o) { return o.OrderNumber === printFileOrderLog.orderNumber });

      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "states/dgda/orders/printfile/printfile-order-edit.tpl.html",
        resolve: {
          order: order,
          printFileOrderLog: printFileOrderLog
        },
        windowClass: 'app-modal-window',
        controller: "PrintFileEditOrderCtl"
      })

      modalInstance.result.then(function (res) {
        if (!res) {
          return;
        }

        if (res.didUpdateOrderStatus) {
          $scope.isUpdatingOrderStatus = true;
          getForExportStatus();
        }

        if (res.didUpdateOrder) {
          $scope.retryShipment(printFileOrderLog);
        }

      })
    };

    $scope.retryShipment = function (printFileOrderLog) {
      $scope.isLoading = true;

      if ($scope.currentPrintFileLog.status === 'ReadyForExport') {
        alert('Cannot retry order when ready for export already');
        $scope.isLoading = false;
        return;
      }

      if ( $scope.currentPrintFileLog.status === 'OrdersAdded' ) {
        $scope.isLoading = false;
        return;
      }

      if( printFileOrderLog.errors === 0 ) {
        $scope.isLoading = false;
        return;
      }

      $scope.exportingText = 'Retrying shipment';
      printFileOrderLog.isRetryingShipment = true;
      performExport(printFileOrderLog);
    }

    function performExport(printFileOrderLog) {
      $scope.isLoading = true;
      $scope.errorData = null;

      var body = printFileOrderLog
        ? { orderlog: printFileOrderLog }
        : {};

      PrintFileLog.performExport({ exportLogId: $scope.currentPrintFileLog.id }, body, function (res) {
        $scope.isLoading = false;
        $scope.exportingText = 'Export has begun';
        getForExportStatus();

      }, function (err) {
        alert('Failed performing the export');
        $scope.exportingText = 'Failed performing the export';
        $scope.isLoading = false;
        printFileOrderLog.isRetryingShipment = false;
        $scope.errorData = err;
      })
    }

    $scope.isOrderLogEditable = function (printFileOrderLog) {
      if (
        printFileOrderLog.errors.length > 0 &&
        $scope.currentPrintFileLog.status === "CreatingShipments"
      ) {
        return true;
      }

      if (
        printFileOrderLog.status === "Excluded" &&
        $scope.currentPrintFileLog.status === "ReadyForExport"
      ) {
        return true;
      }

      if (
        $scope.currentPrintFileLog.status === "CreatingShipments" ||
        $scope.getDisplayStatus(printFileOrderLog.status) === "Loading" ||
        printFileOrderLog.status === "Exported" ||
        printFileOrderLog.status === "CreatingShipmentSuccess" ||
        $scope.currentPrintFileLog.status === "Exported" ||
        $scope.currentPrintFileLog.status === "ReadyForExport"
      ) {
        return false;
      }

      return true;
    };

    $scope.isOrderLogRefreshable = function (printFileOrderLog) {
      return !$scope.isLoading && printFileOrderLog.errors.length > 0;
    };

    $scope.updateOrderStatus = function (printFileOrderLog) {
      printFileOrderLog.isUpdatingOrder = true;
      $scope.isUpdatingOrderStatus = true;

      // update to excluded status if not excluded already
      var newStatus = printFileOrderLog.status === 'Excluded' ? 'Added' : 'Excluded';

      PrintFileLog.updateOrderStatus({ exportLogId: printFileOrderLog.exportLogId, orderNumber: printFileOrderLog.orderNumber, orderExportStatus: newStatus }, {}, function (res) {
        printFileOrderLog.isUpdatingOrder = false;
        printFileOrderLog.status = newStatus;
        getForExportStatus();

      }, function (err) {
        printFileOrderLog.isUpdatingOrder = false;
        $scope.isUpdatingOrderStatus = false;
        $scope.errorData = err;
        alert('Could not exclude order');
      })
    }

    $scope.onCompleteExport = function () {
      $scope.isLoading = true;

      PrintFileLog.getExportZipFiles({ exportLogId: $scope.currentPrintFileLog.id }, function (response) {
        $scope.isLoading = false;
        $scope.exportedData = response;
        $scope.step = 5;

      }, function (err) {
        $scope.isLoading = false;
        alert('Failed to get zip files');
        console.log('Could not get zip files => ', err);
      })
    }
  }])

angular.module('Cockpit.State.Dgda.Printfile', [
  'ui.router',
  'Cockpit.Service.Dgda.Printfile',
  'Cockpit.PrintFile.Shared',
  'Cockpit.PrintFile.ExportStatus'
])

  .config(["$stateProvider", function ($stateProvider) {
    $stateProvider.state('private.orders.printfile', {
      url: '/printfile',
      views: {
        "@private": {
          controller: 'CockpitDgdaExportPrintfileController',
          templateUrl: 'states/dgda/orders/printfile/printfile.tpl.html'
        }
      },
      data: {
        pageTitle: 'Export print-file'
      }
    });
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.orders', 'Export', [
      {
        Label: 'Print file',
        State: 'private.orders.printfile',
        Groups: ["admin"]
      }
    ]);
  }])

  .controller('CockpitDgdaExportPrintfileController', ["$scope", "$state", "$interval", "ExportDgdaPrintfile", "PrintFileLog", "PrintFileHelper", "PrintFileConstants", function CockpitDgdaExportPrintfileController($scope, $state, $interval, ExportDgdaPrintfile, PrintFileLog, PrintFileHelper, PrintFileConstants) {
    $scope.errorData = null;
    $scope.logs = [];
    $scope.countries = PrintFileHelper.countries;
    $scope.getColorClass = PrintFileConstants.getColorClass;
    $scope.showLoadingBubbles = PrintFileConstants.showLoadingBubbles;
    $scope.getDisplayStatus = PrintFileConstants.getDisplayStatus;
    $scope.isLoading = false;
    $scope.lastInitiatedExportLogId = null;
    $scope.step = 1;
    var intervalPromise = null;

    intervalPromise = $interval(getLogs, 10000);
    $scope.$on('$destroy', function () {
      if (intervalPromise) {
        resetInterval();
      }
    })

    function resetInterval() {
      $interval.cancel(intervalPromise);
      intervalPromise = undefined;
    }

    getLogs();

    function getLogs() {
      $scope.isLoading = true;
      $scope.errorData = null;

      PrintFileLog.query({}, function (res) {
        $scope.isLoading = false;
        $scope.logs = res;

        var initiatingLogs = $scope.logs.filter(function (log) {
          return log.status === 'AddingOrders' || log.status === 'Unfinished'
        });

        if (initiatingLogs.length > 0) {
          return;
        }

        // cancel polling since none is AddingOrders
        resetInterval();
        $scope.lastInitiatedExportLogId = null;

      }, function (err) {
        $scope.isLoading = false;
        $scope.errorData = err;
        console.log('Failed to get logs => ', err);
        alert('Failed to get logs');
      });
    }

    setupExportInfo();

    function setupExportInfo() {
      var now = new Date();
      var prevDay = new Date();

      if (now.getDay() === 1) {
        // It's monday - set the default to friday
        prevDay.setDate(now.getDate() - 3);
      } else {
        prevDay.setDate(now.getDate() - 1);
      }
      $scope.now = now;
      $scope.exportInfo = {
        From: new Date(prevDay.getFullYear(), prevDay.getMonth(), prevDay.getDate(), 9, 0, 0, 0),
        To: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0, 0, 0),
        Exported: '',
        Countries: []
      };
    };

    $scope.step1Load = function step1Load() {
      if ($scope.isContinueBtnDisabled()) {
        alert('Did you select country and date/bulkId?');
        return;
      }

      $scope.errorData = null;
      $scope.isLoading = true;
      $scope.orders = ExportDgdaPrintfile.query(getExportDto($scope.exportInfo.Countries.join(',')), function (d) {
        if (d.length === 0) {
          $scope.isLoading = false;
          alert("No orders found for export");
          return;
        }

        // create export log in admin service
        PrintFileLog.createExportLog(getExportDto($scope.exportInfo.Countries), function (response) {
          $scope.isLoading = false;
          $scope.lastInitiatedExportLogId = response.exportLogId;

          getLogs();
          if (intervalPromise) {
            resetInterval();
          }

          intervalPromise = $interval(getLogs, 10000);
          alert('Export created. Please wait until orders have been added to the export. It will be available under initiated exports.');
          return;
        }, function (err) {
          $scope.isLoading = false;
          alert('Could not create export log... Please try again.');
          $scope.errorData = err;
        });

      }, function err(a, b, c) {
        $scope.isLoading = false;
        alert("Could not load orders... Please try refreshing page again.");
        $scope.errorData = a;
      });
    };

    function getExportDto(countries) {
      return {
        from: $scope.exportInfo.From,
        to: $scope.exportInfo.To,
        bulkId: $scope.exportInfo.BulkId || "",
        countries: countries
      };
    }

    $scope.toggleCountry = function toggleCountry(country) {
      var index = $scope.exportInfo.Countries.indexOf(country.IsoCode);
      if (index === -1) {
        $scope.exportInfo.Countries.push(country.IsoCode);
      } else {
        $scope.exportInfo.Countries.splice(index, 1);
      }
    };

    $scope.shouldHideResumeBtn = function (status) {
      switch (status) {
        case 'InitializationFailed':
        case 'Unfinished':
        case 'AddingOrders': {
          return true;
        }
        default: {
          return false;
        }
      }
    }

    $scope.getExportedFile = function (currentLog, currentLanguageCode) {
      var exportedFile = currentLog.exportedFiles.find(function(file) { return file.languageCode.toUpperCase() === currentLanguageCode.toUpperCase() });
      if (!exportedFile) {
        alert('No file found. This can happen when all orders in the export for this country were excluded.');
        return;
      }

      // download file
      window.location.href = exportedFile.url;
    }

    $scope.onResume = function (log) {
      $scope.errorData = null;
      $scope.isLoading = true;

      if (log.status === 'OrdersAdded') {
        $state.go('private.orders.printfile.dataverification', { exportLogId: log.id, step: 2 })
        return;
      }

      $state.go('private.orders.printfile.status', { exportLogId: log.id })
      return;
    }

    $scope.onDelete = function (log) {
      $scope.errorData = null;
      $scope.isLoading = true;

      PrintFileLog.deleteExportLog({exportLogId: log.id}, function (res) {
        $scope.isLoading = false;
        getLogs();

      }, function (err) {
        $scope.isLoading = false;
        if (err.data) {
          alert(err.data);
          return;
        }

        $scope.errorData = err;
        alert('Failed abandoning log');
      });
    }

    $scope.isBulkExport = function () {
      return $scope.exportInfo.BulkId && $scope.exportInfo.BulkId.length > 0;
    }

    $scope.isContinueBtnDisabled = function () {

      var areCountriesMissing = $scope.exportInfo.Countries.length == 0;
      if ($scope.isBulkExport()) {
        return areCountriesMissing;
      }

      return $scope.exportInfo.From === undefined || $scope.exportInfo.To === undefined || areCountriesMissing;
    }
  }])
  ;

angular.module('TS.Refunds.Service', ['ngResource'])
	.factory('Refund', ['$resource',
	function ($resource) {
		return $resource(Config.GlobalApi + 'refunds/:id/:action', {}, {
			complete: { method: 'POST', params: { action: 'complete', id: '@id' }, hasBody: false },
      deny: { method: 'POST', params: { action: 'deny', id: '@id' }, hasBody: false },
      update: {method: 'PUT' },
      failed: {method: 'PUT', url: Config.GlobalApi + 'refunds/failed'},
      batchUpdate: { method: 'POST', hasBody: true }
		});
	}]);
angular.module('Cockpit.State.Dgda.Refunds', [
  'ui.router',
  'Cockpit.Service.SupplierCodes',
  'Cockpit.State.Dgda.Refunds.Manual'
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.orders.refunds', {
      url: '/refunds',
      views: {
        '@private': {
          controller: 'CockpitRefundDgdaController',
          templateUrl: 'states/dgda/orders/refunds/refunds.tpl.html'
        }
      },
      data: {
        pageTitle: 'Orders'
      }
    })
      ;
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.orders', 'Orders', [
      {
        Label: "View Pending Refunds",
        State: 'private.orders.refunds',
        Groups: ["admin", "support"]
      }
    ]);
  }])

  .controller('CockpitRefundDgdaController', ["$scope", "prompt", "Refund", "SupplierCode", "$state", function CockpitRefundDgdaController($scope, prompt, Refund, SupplierCode, $state) {
    $scope.refunds = Refund.query({ action: 'pending' });
    $scope.failedRefunds = Refund.query({ action: 'failed' });

    $scope.processing = false;

    $scope.refundOrder = function (refund) {
      $scope.processing = true;
      if (!confirm("Are you sure, you want to confirm the refund of order '" + refund.orderDetails.number + "'?")) {
        $scope.processing = false;
        return;
      }

      Refund.complete({
        id: refund.id
      }, function (result) {
        doneProcessing();
      }, function (err) {
        $scope.processing = false;
      });

      SupplierCode.revokeByReference({ reference: refund.orderDetails.number }, function (result) { });
    };

    $scope.refundAll = function (fetchFailed) {
      $scope.processing = true;

      var selectedRefunds = [];
      if (!fetchFailed) {
        selectedRefunds = $scope.refunds.filter(function (refund) { return refund.isChecked; });
      } else {
        selectedRefunds = $scope.failedRefunds.filter(function (refund) { return refund.isChecked; });
      }

      if (selectedRefunds.length === 0 || !confirm("Are you sure want to accept all selected refunds?")) {
        $scope.processing = false;
        return;
      }

      Refund.batchUpdate({ action: 'complete' }, {
        ids: selectedRefunds.map(function (refund) { return refund.id; })
      }, function (res) {
        doneProcessing(fetchFailed);
      }, function (err) {
        $scope.processing = false;
      })
    };

    $scope.denyRefund = function (refund) {
      $scope.processing = true;

      prompt("Please enter reason for denying refund:", "").then(function (response) {
        if (response.length) {
          Refund.deny({
            id: refund.id,
            reason: response
          }, function (result) {
            doneProcessing();
          }, function (err) {
            $scope.processing = false;
          });
        }
        else {
          $scope.processing = false;
          window.alert("You need to add a reason to deny a refund");
        }
      },
        function () {
          $scope.processing = false;
        });
    };

    $scope.denyAll = function(fetchFailed) {
      $scope.processing = true;

      var selectedRefunds = [];
      if (!fetchFailed) {
        selectedRefunds = $scope.refunds.filter(function (refund) { return refund.isChecked; });
      } else {
        selectedRefunds = $scope.failedRefunds.filter(function (refund) { return refund.isChecked; });
      }

      if (selectedRefunds.length === 0) {
        $scope.processing = false;
        return;
      }

      prompt("Please enter reason for denying refunds: ", "").then(function (response) {
        if(response.length) {
          Refund.batchUpdate({ action: 'deny' }, {
            ids: selectedRefunds.map(function (refund) { return refund.id; }),
            reason: response
          }, function (res) {
            doneProcessing(fetchFailed);
          }, function (err) {
            $scope.processing = false;
          })
        } else {
          $scope.processing = false;
          window.alert("You need to add a reason to deny a refund");
        }
      }, function () {
        $scope.processing = false;
      });
    }

    $scope.sendRefundFailedEmail = function (refund) {
      $scope.processing = true;

      prompt('Please enter why the refund failed:', "").then(function (response) {
        if (!response.length || response.length < 1) {
          $scope.processing = false;
          return;
        }

        Refund.failed({
          ids: [ refund.id ],
          reason: response
        }, function (res) {
          doneProcessing(true);
        }, function (err) {
          $scope.processing = false;
        });
      }, function () {
        $scope.processing = false;
      });
    }

    $scope.bulkSendFailedEmail = function () {
      $scope.processing = true;

      var selectedRefunds = $scope.failedRefunds.filter(function (refund) { return refund.isChecked; });
      if (selectedRefunds.length === 0) {
        return;
      }

      prompt('Please enter why the refunds have failed:', "").then(function (response) {
        if (!response.length || response.length < 1) {
          $scope.processing = false;
          return;
        }

        Refund.failed({
          ids: selectedRefunds.map(function (refund) { return refund.id; }),
          reason: response
        }, function (res) {
          doneProcessing(true);
        }, function (err) {
          $scope.processing = false;
        })
      }, function () {
        $scope.processing = false;
      })
    };

    $scope.updateEmail = function (refund) {
      $scope.processing = true;

      prompt('Please enter the new email: ', refund.email).then(function (userEmail) {
        if (!userEmail.length || userEmail.length < 1 || refund.email === userEmail) {
          $scope.processing = false;
          return;
        }

        Refund.update({
          id: refund.id,
          userEmail: userEmail
        }, function (res) {
          doneProcessing(true);
          doneProcessing(false);
        }, function (err) {
          $scope.processing = false;
        });
      }, function() {
        $scope.processing = false;
      })
    }

    $scope.selectAllFailedRefunds = false;
    $scope.handleFailedRefundsSelect = function (isChecked) {
      $scope.failedRefunds.forEach(function (refund) { refund.isChecked = isChecked; });
    }

    $scope.selectAllRefundsToCreditCard = false;
    $scope.handleRefundsToCreditCardSelected = function (isChecked) {
      $scope.refunds.forEach(function (refund) {
        if (refund.useSameAccount) {
          refund.isChecked = isChecked;
        }
      });
    }

    $scope.selectAllRefundsToOtherAccount = false;
    $scope.handleRefundsToOtherAccountSelected = function (isChecked) {
      $scope.refunds.forEach(function (refund) {
        if (!refund.useSameAccount) {
          refund.isChecked = isChecked;
        }
      });
    }

    function doneProcessing(fetchFailed) {
      $scope.processing = false;

      if (!fetchFailed) {
        $scope.refunds = Refund.query({ action: 'pending' });
      } else {
        $scope.failedRefunds = Refund.query({ action: 'failed' });
      }
    }
  }])
  ;

angular.module('Cockpit.State.Dgda.Refunds.Manual', [
  'ui.router',
])

  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state('private.orders.manualrefund', {
      url: '/refunds/:id/manual',
      views: {
        '@private': {
          controller: 'ManualRefundController',
          templateUrl: 'states/dgda/orders/refunds/manualrefund.tpl.html'
        }
      },
      data: {
        pageTitle: 'Orders'
      }
    });
  }])

  .controller('ManualRefundController', ["$scope", "Refund", "$stateParams", function ($scope, Refund, $stateParams) {
    $scope.refund = {
      id: $stateParams.id,
      regNumber: null,
      accountNumber: null,
      useSameAccount: false,
      newStatus: 1
    };
    $scope.infoMessage = null;
    $scope.hasError = false;

    $scope.update = function () {
      Refund.update($scope.refund, function (_res) {
        $scope.infoMessage = 'Refund updated!';
        $scope.hasError = false;
      }, function () {
        $scope.infoMessage = 'Could not update refund...';
        $scope.hasError = true;
      });
    }
  }]);
angular.module('Cockpit.Service.ShippingCounter', ['ngResource'])
	.factory('ShippingCounter', ['$resource',
	function ($resource) {
		return $resource(Config.AdminApi + 'shipping-counters/:deliveryCountry');
	}]);

angular.module('Cockpit.State.Dgda.ShippingCounter', [
  'ui.router',
  'Cockpit.State.Settings',
  'Cockpit.Service.ShippingCounter'
])

.config(["$stateProvider", function config($stateProvider) {
	$stateProvider.state('private.orders.shippingcounter', {
		url: '/shippingcounter',
		views: {
			'@private': {
				controller: 'CockpitShippingCounterController',
				templateUrl: 'states/dgda/orders/shippingcounter/shippingcounter.tpl.html'
			}
		},
		data: {
			pageTitle: 'Shipping Counter Settings'
		}
	})

	;
}])

.run(["Navigation", function (Navigation) {
	Navigation.sidebar.register('private.orders', 'General', [
		{
			Label: "Shipping Counter",
			State: 'private.orders.shippingcounter',
			Groups: ["admin", "support"]
		}
	]);
}])

.controller('CockpitShippingCounterController', ["$scope", "ShippingCounter", "Language", function CockpitShippingCounterController($scope, ShippingCounter, Language) {
	$scope.data = ShippingCounter.get({deliveryCountry: "da-dk"}, function(){},function(){
		$scope.data = {
			deliveryCountry: 'da-dk'
		}
	});
	$scope.selectedLanguage = null;
	$scope.days = [
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
		"Sunday"
	];
	$scope.countries = [
		{
			"name": "Denmark",
			"languageCode": "da-dk"
		},
		{
			"name": "Norway",
			"languageCode": "nb-no"
		},
		{
			"name": "Sweden",
			"languageCode": "sv-se"
		},
		{
			"name": "Germany",
			"languageCode": "de-de"
		}
	]
	$scope.activeCountry = 'da-dk';
	$scope.setCountry = function setTab(country){
		$scope.data = {};

		$scope.data = ShippingCounter.get({deliveryCountry: country.languageCode}, function(v){
			$scope.activeCountry = country.languageCode;
		}, function(){
			$scope.data = {
				deliveryCountry: country.languageCode
			};
			$scope.activeCountry = country.languageCode;
		});
	}
	$scope.languages = Language.query(function(d){
		$scope.selectedLanguage = d[0];
	});
	$scope.setLanguage = function(l){
		$scope.selectedLanguage = l;
	};
	$scope.saveMessage = false;

	$scope.newOverride = {
		Date: '',
		Message: '',
		IsNew: true
	};

	$scope.addOverride = function addShippingCounterOverride(country) {
		if ($scope.newOverride.date === '') {
			return;
		}
		if ($scope.newOverride.message === '') {
			return;
		}

		if(!angular.isDefined($scope.data[country.languageCode])){
			$scope.data[country.languageCode] = {
				overrides: []
			};
		}

		$scope.data[country.languageCode].overrides.push($scope.newOverride);

		$scope.newOverride = {
			date: '',
			message: '',
			isNew: true
		};
	};

	$scope.deleteOverride = function deleteShippingCounterOverride(o) {
		var index = $scope.data.overrides.indexOf(o);

		if (index === -1) {
			return;
		}

		$scope.data.overrides.splice(index, 1);
	};

	$scope.process = function processShippingCounter() {
		ShippingCounter.save($scope.data, function () {
			for (var i in $scope.data.overrides) {
				$scope.data.overrides[i].isNew = false;
			}

			$scope.saveMessage = true;
		});
	};
}])

;

angular.module('Cockpit.State.Dgda.GiftCardPreview', [
    'ui.router',
    'angularFileUpload'
])

    .config(["$stateProvider", function config($stateProvider) {
        $stateProvider.state('private.products.giftcardpreview', {
            url: '/giftcard-preview',
            views: {
                "@private": {
                    controller: 'CockpitDgdaGiftCardPreviewController',
                    templateUrl: 'states/dgda/products/giftcardpreview/upload.tpl.html',
                }
            },
            data: {
                pageTitle: 'Giftcard Preview Uploader'
            }
        })

        ;
    }])

    .run(["Navigation", function (Navigation) {
        Navigation.sidebar.register('private.products', 'Truestory', [
            {
                Label: "Giftcard Previews",
                State: 'private.products.giftcardpreview',
                Groups: ["admin", "support", "user"]
            }
        ]);
    }])

    .controller('CockpitDgdaGiftCardPreviewController', ["$scope", "FileUploader", function CockpitDgdaGiftCardPreviewController($scope, FileUploader) {
        $scope.alert = {
            Type: '',
            Show: false,
            Message: ''
        };

        $scope.imageUrl = '';
        $scope.uploader = new FileUploader({
            url: Config.Api + 'giftcardpreviewapi/save',
            autoUpload: true,
            queueLimit: 1,
            removeAfterUpload: true,
            withCredentials: true,
            onSuccessItem: function(item, response, status, headers){
                if( "ImageName" in response && response.ImageName != ""){
                    $scope.$apply(function(){
                        $scope.imageUrl = response.Url;
                        $scope.imageName = response.ImageName;
                    });

                    $scope.alert = {
                        Type: 'success',
                        Show: true,
                        Message: 'The gift card preview has been uploaded!'
                    };
                }
            },
            onErrorItem: function(item, response, status, headers){
                console.log(response, status);

                $scope.alert = {
                    Type: 'warning',
                    Show: true,
                    Message: 'Uploading the image failed. Please make sure, the image is an JPG, and the filename has the format "xxxx-yyy.jpg" where X is numeric and Y are letters.'
                };
            }
        });

    }])

;

angular.module('Cockpit.Service.ProductRankingWeights', ['ngResource'])
	.factory('ProductRankingWeights', ['$resource',
	function ($resource) {
		return $resource(Config.Api + 'RankingFactorWeightsApi/:action', {}, {
			query: { params: { action: 'Get' }, isArray: true },
			save: { method: 'POST', params: { action: 'Save' } }
		});
	}]);
angular.module('Cockpit.State.ProductRankingWeights', [
    'ui.router',
    'Cockpit.Service.ProductRankingWeights',
    'Cockpit.State.Products'
])

.config(["$stateProvider", function config($stateProvider) {

	$stateProvider.state('private.products.rankingweights', {
		url: '/ranking-weights',
        views: {
            '@private': {
                controller: 'ProductRankingWeightsController',
				templateUrl: 'states/dgda/products/rankingweights/weights.tpl.html'
            }
        },
		data: {
			pageTitle: 'Product Ranking Factor Weights'
		}
	})

	;
}])

.run(["Navigation", function (Navigation) {
	Navigation.sidebar.register('private.products', 'General', [
		{
			Label: "Ranking Weights (v2)",
			State: 'private.products.rankingweights',
			Groups: ['admin']
		},
    {
			Label: "Booking configurations",
			State: '/v2/admin/booking-integrations/',
			Groups: ['admin']
		}
	]);
}])

.controller('ProductRankingWeightsController', ["$scope", "ProductRankingWeights", function ProductRankController($scope, ProductRankingWeights) {
	$scope.weights = ProductRankingWeights.query();

	$scope.process = function processSaveProductRankingWeights() {
		ProductRankingWeights.save($scope.weights);
	};
}])

;

angular.module('Cockpit.Service.Dgda.Ease', ['ngResource'])
	.factory('Ease', ['$resource',
	function ($resource) {
		return $resource(Config.Api + 'dgdaeaseapi/:action', {}, {
			get: { params: { action: 'Get' } },
			save: { method: 'POST', params: { action: 'Save' } }
		});
	}]);
angular.module('Cockpit.State.Dgda.Ease', [
  'ui.router',
  'Cockpit.Service.Dgda.Ease'
])

.config(["$stateProvider", function config($stateProvider) {
	$stateProvider.state('private.settings.ease', {
		url: '/ease-booking',
		controller: 'CockpitDgdaEaseController',
		templateUrl: 'states/dgda/settings/ease/settings.tpl.html',
		data: {
			pageTitle: 'Ease Booking Settings'
		}
	})

	;
}])

.run(["Navigation", function (Navigation) {
	Navigation.sidebar.register('private.settings', 'Truestory', [
		{
			Label: "ease booking",
			State: 'private.settings.ease',
			Groups: ["admin"]
		}
	]);
}])

.controller('CockpitDgdaEaseController', ["$scope", "Ease", function CockpitDgdaEaseController($scope, Ease) {
	$scope.model = Ease.get();
	$scope.alert = {

		Type: '',
		Show: false,
		Message: ''
	};

	$scope.process = function ProcessSaveParseData() {
		$scope.alert.Show = false;

		$scope.model.$save(function () {
			$scope.alert.Type = "success";
			$scope.alert.Message = "Your settings have been saved :-)";
			$scope.alert.Show = true;
		}, function () {
			$scope.alert.Type = "warning";
			$scope.alert.Message = "An error occured while saving your settings.";
			$scope.alert.Show = true;
		});
	};
}])

;

angular.module('Cockpit.Service.Dgda.Faq', ['ngResource'])
    .factory('Faq', ['$resource',
        function($resource) {
            return $resource(Config.Api + 'FaqApi/:action', {}, {
                query: { params: { action: 'GetAll' }, isArray: true },
                getSubject: { method: 'GET', params: { action: 'GetSubject' } },
                save: { method: 'POST', params: { action: 'Save' } },
                delete: { method: 'POST', params: { action: 'Delete' } },
                edit: { method: 'POST', params: { action: 'Edit' } },
            });
        }
    ]);
angular.module('Cockpit.State.Dgda.Faq', [
    'ui.router',
    'Cockpit.Service.Dgda.Faq'
])

.config(["$stateProvider", function config($stateProvider) {
  $stateProvider.state('private.settings.faq', {
    url: 'faqsubjects/',
    controller: 'CockpitFaqSubjectsController',
    templateUrl: 'states/dgda/settings/faq/faq.tpl.html',
    data: {
      pageTitle: 'Faq Subjects'
    }
  })
      .state('private.settings.faqadd', {
        url: 'faqsubjects/add',
        resolve: {
          subjectData: [function () {
            return {
              Id: -1,
              Text: '',
              Order: 0
            };
          }]
        },
        controller: 'CockpitFaqSubjectsAddController',
        templateUrl: 'states/dgda/settings/faq/subject.tpl.html',
        data: {
          pageTitle: 'Faq Subjects'
        }
      })
      .state('private.settings.faqedit', {
        url: '/editsubject/{SubjectId}',
        resolve: {
          subjectData: ['$q', '$stateParams', 'Faq', function ($q, $stateParams, Faq) {
            var d = $q.defer();

            Faq.getSubject({ id: $stateParams.SubjectId }, function (o) {
              d.resolve(o);
            }, function () {
              console.log("Error loading subject");
            });

            return d.promise;
          }]
        },
        controller: 'CockpitFaqSubjectsEditController',
        templateUrl: 'states/dgda/settings/faq/editSubject.tpl.html',
        data: {
          pageTitle: 'Faq Subjects'
        }
      })

  ;
}])

.run(["Navigation", function (Navigation) {
  Navigation.sidebar.register('private.settings', 'Truestory', [{
    Label: "Faq subjects",
    State: 'private.settings.faq',
    Groups: ["admin"]
  }]);
}])

.controller('CockpitFaqSubjectsController', ["$scope", "$state", "Faq", function CockpitFaqSubjectsController($scope, $state, Faq) {
  $scope.subjects = [];

  Faq.query(null, function (result) {
    $scope.subjects = result;
  });

  $scope.deleteSubject = function deleteSubject(id) {
    Faq.delete(id, function (d) {
      $scope.subjects = Faq.query();
    }, function (err) {
      console.log('error saving faq subject: ', err);
    });
  }
}])

.controller('CockpitFaqSubjectsAddController', ["$scope", "$state", "Faq", "subjectData", function CockpitFaqSubjectsAddController($scope, $state, Faq, subjectData) {
  $scope.subject = subjectData;

  $scope.process = function processSaveSubject() {
    Faq.save($scope.subject, function (d) {
      $state.go('private.settings.faq');
    }, function (err) {
      console.log('error saving faq subject: ', err);
    });
  }

}])


.controller('CockpitFaqSubjectsEditController', ["$scope", "$state", "Faq", "subjectData", function CockpitFaqSubjectsAddController($scope, $state, Faq, subjectData) {
  $scope.subject = subjectData;

  $scope.process = function processSaveSubject() {
    Faq.edit($scope.subject, function (d) {
      $state.go('private.settings.faq');
    }, function (err) {
      console.log('error saving faq subject: ', err);
    });
  }

}])

;

angular.module('Cockpit.Service.Mademyday.BatchSend', ['ngResource'])
	.factory('BatchSend', ['$resource',
	function ($resource) {
		return $resource(Config.Api + 'batchapi/:action', {}, {
			send: { method: 'POST', params: { action: 'send' } }
		});
	}]);
angular.module('Cockpit.State.Mademyday.BatchSend', [
  'ui.router',
  'Cockpit.Service.Mademyday.BatchSend'
])

.config(["$stateProvider", function config($stateProvider) {

	$stateProvider.state('private.orders.batch-send', {
		url: '/batch-send',
		views: {
			'@private': {
				controller: 'CockpitMademydayBatchSendController',
				templateUrl: 'states/mademyday/orders/batch-send/batch-send.tpl.html',
			}
		},
		data: {
			pageTitle: 'Batch-Send Gifts'
		}
	})

	;
}])

.run(["Navigation", function (Navigation) {
	Navigation.sidebar.register('private.orders', 'Orders', [
		{
			Label: 'Batch Send',
			State: 'private.orders.batch-send',
			Groups: ["admin", "support"]
		}
	]);
}])

.controller('CockpitMademydayBatchSendController', ["$scope", "Product", "BatchSend", function CockpitMademydayBatchSendController($scope, Product, BatchSend) {
	function parseRawData(lines, useHeaders) {
		var parsed = [];

		var headers = [];
		for (var i in lines) {
			// Store headers if any
			if (i == 0 && useHeaders) {
				headers = lines[i].toLowerCase().split(';');
				continue;
			}

			var cols = lines[i].split(';');

			var parsedLine = {};

			for (var j in cols) {
				parsedLine[(parseInt(j) + 1)] = cols[j];
				if (useHeaders) {
					parsedLine[headers[j]] = cols[j];
				}
			}

			parsed.push(parsedLine);
		}

		return parsed;
	}

	function parseMessage(message, data){
		var shortcodes = message.match(/\[([^\]]+)\]/g);

		if (shortcodes === null || shortcodes.length === 0) {
			return message;
		}

		for (var i in shortcodes) {
			var shortcode = shortcodes[i].replace('[', '').replace(']', '').replace('col-', '');
			
			if (!angular.isDefined(data[shortcode])) {
				continue;
			}

			message = message.replace(shortcodes[i], data[shortcode]);
		}

		return message;
	}

	function updateExampleMessage() {
		if ($scope.batchData.Message === "" || $scope.batchData.Data.length === 0) {
			$scope.exampleMessage = $scope.batchData.Message;
			return;
		}

		$scope.exampleMessage = parseMessage($scope.batchData.Message, $scope.batchData.Data[0]);
	}

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.rawData = "";
	$scope.exampleMessage = "";
	$scope.batchData = {
		SenderName: "",
		Customer: {
			Type: "privat",
			Company: "",
			VatNo: "",
			Firstname: "",
			Lastname: "",
			Address: "",
			Postcode: "",
			City: "",
			Email: "",
			Newsletter: false
		},
		Product: null,
		Variant: null,
		Message: "",
		Price: null,
        Quantity: 0,
		Data: [],
		Headers: true
	};
	$scope.products = Product.query();

	$scope.$watch('rawData', function (data) {
		if (data === null || data === "") {
			$scope.batchData.Data = [];
			return;
		}

		var lines = data.split("\n");

		// Make sure we have more than one line of data
		if (lines.length === 0) {
			$scope.batchData.Data = [];
			return;
		}

		// Check if we're using headers, and if there's only one line
		if ($scope.batchData.Headers && lines.length === 1) {
			return;
		}

		$scope.batchData.Data = parseRawData(lines, $scope.batchData.Headers);
	});

	$scope.$watch(function () {
		return $scope.batchData.Message;
	}, updateExampleMessage);

	$scope.$watch(function () {
		return $scope.batchData.Data;
	}, updateExampleMessage);

	$scope.safety = false;

	$scope.process = function processSendBatch() {
		$scope.alert.Show = false;
		$scope.safety = false;

		BatchSend.send($scope.batchData, function sendBatchSuccess(d) {
			$scope.alert.Type = "success";
			$scope.alert.Message = "Finished sending batch!";
			$scope.alert.Show = true;
		}, function sendBatchError(a) {
			console.log(a);
			$scope.alert.Type = "warning";
			$scope.alert.Message = "An error occured while sending batch.";
			$scope.alert.Show = true;
		});
	};
}])


;
angular.module('Cockpit.State.Mademyday.Export.Delivery', [
    'ui.router',
    'Cockpit.Service.Mademyday.Export'
])

    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state('private.orders.delivery', {
            url: '/delivery',
            resolve: {
                deliveryDays: ['$q', 'MmdExport', function ($q, MmdExport){
                    var d = $q.defer();

                    MmdExport.queryDeliveryDays(function(data){
                        d.resolve(data);
                    });

                    return d.promise;
                }]
            },
            views: {
                "@private": {
                    controller: 'CockpitMademydayExportDeliveryController',
                    templateUrl: 'states/mademyday/orders/delivery/delivery.tpl.html'
                }
            },
            data: {
                pageTitle: 'Export Delivery Data'
            }
        });
    }])

    .run(["Navigation", function (Navigation) {
        Navigation.sidebar.register('private.orders', 'Export', [
            {
                Label: 'Delivery',
                State: 'private.orders.delivery',
                Groups: ["admin", "support"]
            }
        ]);
    }])

    .controller('CockpitMademydayExportDeliveryController', ["$scope", "MmdExport", "deliveryDays", function CockpitMademydayExportDeliveryController($scope, MmdExport, deliveryDays) {
        $scope.days = deliveryDays;
        var loadedDay = null;
        $scope.data = [];
        $scope.exportData = {
            Supplier: ''
        };
        $scope.loadData = function loadData(dayData){
            loadedDay = dayData.Date;
            $scope.data = MmdExport.queryDelivery({ day: dayData.Date }, function(data){
                $scope.suppliers = [];

                console.log($scope.data);
                for(var i = 0; i < $scope.data.length; i++){
                    var supplier = $scope.data[i].Supplier;
                    if($scope.suppliers.indexOf(supplier) == -1){
                        $scope.suppliers.push(supplier)
                    }
                }
            });

        };

        $scope.exported = null;
        $scope.exportCsv = function exportUrl(){
            $scope.exported = null;
            $scope.exported = MmdExport.csvUrl({ day: loadedDay, supplier: $scope.exportData.Supplier });
        };

        $scope.exportPdf = function exportPdfUrl(){
            $scope.exported = null;
            $scope.exported = MmdExport.pdfUrl({ day: loadedDay, supplier: $scope.exportData.Supplier });
        };
    }])

;

angular.module('Cockpit.Service.Mademyday.Filters', ['ngResource'])
	.factory('MmdFilter', ['$resource',
	function ($resource) {
		return $resource(Config.Api + 'filterapi/:action', {}, {
			get: { params: { action: 'Get' } },
			save: { params: { action: 'Save' }, method: 'POST' },
			query: { params: { action: 'Get' }, isArray: true }
		});
	}]);
angular.module('Cockpit.State.Mademyday.Filters', [
  'ui.router',
  'Cockpit.Service.Mademyday.Filters'
])

.config(["$stateProvider", function config($stateProvider) {
	$stateProvider.state('private.products.filters', {
		url: '/filters',
		views: {
			'@private': {
				controller: 'CockpitMademydayFiltersController',
				templateUrl: 'states/mademyday/products/filters/filters.tpl.html'
			}
		},
		data: {
			pageTitle: 'Filters'
		}
	})

	;
}])

.run(["Navigation", function (Navigation) {
	Navigation.sidebar.register('private.products', 'General', [
		{
			Label: 'Filters',
			State: 'private.products.filters',
			Groups: ["admin"]
		}
	]);
}])

.controller('CockpitMademydayFiltersController', ["$scope", "MmdFilter", function CockpitMademydayFiltersController($scope, MmdFilter) {
	$scope.model = {
		Cities: ""
	};
	MmdFilter.query(function (d) {
		$scope.model.Cities = d.join("\n");
	});

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.process = function ProcessSaveFilter() {
		$scope.alert.Show = false;
		
		MmdFilter.save($scope.model.Cities.split("\n"), function (resp) {
			if (resp.Success) {
				$scope.alert.Type = "success";
				$scope.alert.Message = "Your filters have been saved :-)";
			} else {
				$scope.alert.Type = "warning";
				$scope.alert.Message = resp.Message;
			}
			$scope.alert.Show = true;
		});
	};
}])

;
angular.module('Cockpit.State.Mademyday.Export.ReviewData', [
  'ui.router',
  'Cockpit.Service.Mademyday.Export'
])

.config(["$stateProvider", function ($stateProvider) {
	$stateProvider.state('private.products.reviewdata', {
		url: '/review-data',
		views: {
			"@private": {
				controller: 'CockpitExportReviewDataController',
				templateUrl: 'states/mademyday/products/review-data/reviewdata.tpl.html'
			}
		},
		data: {
			pageTitle: 'Export Review Data'
		}
	});
}])

.run(["Navigation", function (Navigation) {
	Navigation.sidebar.register('private.products', 'Reviews', [
		{
			Label: 'Export',
			State: 'private.products.reviewdata',
			Groups: ["admin", "support"]
		}
	]);
}])

.controller('CockpitExportReviewDataController', ["$scope", "MmdExport", function CockpitExportReviewDataController($scope, MmdExport) {
	$scope.model = {
		from: '',
		to: ''
	};

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.isProcessing = false;

	$scope.process = function GetReviewData() {
		$scope.isProcessing = true;
		$scope.alert.Show = false;

		$scope.data = MmdExport.query($scope.model, function (d) {
			$scope.isProcessing = false;
			if (d.length === 0) {
				$scope.alert = {
					Type: 'warning',
					Show: true,
					Message: 'No reviews found.'
				};
			}
		});
	};

	$scope.data = [];
}])

;

angular.module('Cockpit.Service.Mademyday.Krifa', ['ngResource'])
	.factory('MmdKrifa', ['$resource',
	function ($resource) {
		return $resource(Config.Api + 'krifaapi/:action', {}, {
			get: { params: { action: 'Get' } },
			save: { params: { action: 'Save' }, method: 'POST' }
		});
	}]);
angular.module('Cockpit.State.Mademyday.Krifa', [
  'ui.router',
  'Cockpit.Service.Mademyday.Krifa'
])

.config(["$stateProvider", function config($stateProvider) {
	$stateProvider.state('private.settings.mmd-krifa', {
		url: '/krifa',
		views: {
			'@private':{
				controller: 'CockpitMademydayKrifaController',
				templateUrl: 'states/mademyday/settings/krifa/settings.tpl.html',
			}
		},
		data: {
			pageTitle: 'Krifa mademyday Settings'
		}
	})

	;
}])

.run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.settings', 'mademyday', [
        {
            Label: "Krifa",
            State: 'private.settings.mmd-krifa',
			Groups: ["admin"]
        }
    ]);
}])

.controller('CockpitMademydayKrifaController', ["$scope", "MmdKrifa", function CockpitMademydayKrifaController($scope, MmdKrifa) {
	$scope.settings = MmdKrifa.get();

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.process = function ProcessSaveTexts() {
		$scope.alert.Show = false;
		
		$scope.settings.$save(function () {
			$scope.alert.Type = "success";
			$scope.alert.Message = "Your settings have been saved :-)";
			$scope.alert.Show = true;
		}, function () {
			$scope.alert.Type = "warning";
			$scope.alert.Message = "An error occured while saving your settings.";
			$scope.alert.Show = true;
		});
	};
}])

;
angular.module('Cockpit.Service.Mademyday.Parse', ['ngResource'])
	.factory('Parse', ['$resource',
	function ($resource) {
		return $resource(Config.Api + 'parseapi/:action', {}, {
			get: { params: { action: 'Get' } },
			save: { params: { action: 'Save' }, method: 'POST' }
		});
	}]);
angular.module('Cockpit.State.Mademyday.Parse', [
  'ui.router',
  'Cockpit.Service.Mademyday.Parse'
])

.config(["$stateProvider", function config($stateProvider) {
	$stateProvider.state('private.settings.mmd-parse', {
		url: '/parse',
		views: {
			'@private': {
				controller: 'CockpitMademydayParseController',
				templateUrl: 'states/mademyday/settings/parse/settings.tpl.html',
			}
		},
		data: {
			pageTitle: 'Parse Settings'
		}
	})

	;
}])

.run(["Navigation", function (Navigation) {
	Navigation.sidebar.register('private.settings', 'mademyday', [
		{
			Label: "Parse",
			State: 'private.settings.mmd-parse',
			Groups: ["admin"]
		}
	]);
}])

.controller('CockpitMademydayParseController', ["$scope", "Parse", function CockpitMademydayParseController($scope, Parse) {
	$scope.settings = Parse.get();

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.process = function ProcessSaveParseData() {
		$scope.alert.Show = false;

		$scope.settings.$save(function () {
			$scope.alert.Type = "success";
			$scope.alert.Message = "Your settings have been saved :-)";
			$scope.alert.Show = true;
		}, function () {
			$scope.alert.Type = "warning";
			$scope.alert.Message = "An error occured while saving your settings.";
			$scope.alert.Show = true;
		});
	};
}])

;
angular.module('Cockpit.Service.Mademyday.Texts', ['ngResource'])
	.factory('MmdTexts', ['$resource',
	function ($resource) {
		return $resource(Config.Api + 'textsapi/:action', {}, {
			get: { params: { action: 'Get' } },
			save: { params: { action: 'Save' }, method: 'POST' }
		});
	}]);
angular.module('Cockpit.State.Mademyday.Texts', [
  'ui.router',
  'Cockpit.Service.Mademyday.Texts'
])

.config(["$stateProvider", function config($stateProvider) {
	$stateProvider.state('private.settings.texts', {
		url: '/texts',
		views: {
			'@private': {
				controller: 'CockpitMademydayTextsController',
				templateUrl: 'states/mademyday/settings/texts/texts.tpl.html',
			}
		},
		data: {
			pageTitle: 'Filters'
		}
	})

	;
}])

.run(["Navigation", function (Navigation) {
	Navigation.sidebar.register('private.settings', 'mademyday', [
		{
			Label: 'Texts',
			State: 'private.settings.texts',
			Groups: ["admin"]
		}
	]);
}])

.controller('CockpitMademydayTextsController', ["$scope", "MmdTexts", function CockpitMademydayTextsController($scope, MmdTexts) {
	$scope.texts = MmdTexts.get();

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.process = function ProcessSaveTexts() {
		$scope.alert.Show = false;
		
		$scope.texts.$save(function () {
			$scope.alert.Type = "success";
			$scope.alert.Message = "Your texts have been saved :-)";
			$scope.alert.Show = true;
		}, function () {
			$scope.alert.Type = "warning";
			$scope.alert.Message = "An error occured while saving your texts.";
			$scope.alert.Show = true;
		});
	};
}])

;
angular
  .module("Cockpit.Modal.ProductCodes.MadklubbenCancelCode", [
    "ui.router",
    "Cockpit.Service.LifePeaksGiftcard",
    "Cockpit.Service.OrderNote",
  ])
  .controller(
    "MadklubbenCancelCodeCtl",
    ["$scope", "order", "$uibModalInstance", "LifePeaksGiftcard", "OrderNote", function MadklubbenCancelCodeCtl(
      $scope,
      order,
      $uibModalInstance,
      LifePeaksGiftcard,
      OrderNote
    ) {
      $scope.close = function () {
        $uibModalInstance.close();
      };

      var createCancelledCodeNote = function (code, orderNumber, callback) {
        var orderNote = "Cancelled a Madklubben code: " + code + ".";
        OrderNote.save(
          { orderNumber: orderNumber },
          {
            note: orderNote,
          },
          function (res) {
            callback && typeof callback === "function" && callback();
          },
          function (err) {
            alert("Error saving note: ", err);
            callback && typeof callback === "function" && callback();
          }
        );
      };


      $scope.cancelCode = function () {
        LifePeaksGiftcard.cancel(
          { id: order.id },
          null,
          function successCb() {
            createCancelledCodeNote(order.code, order.orderNumber, function() {
              $uibModalInstance.close({ didCancelCode: true });
            })
          },
          function errorCb() {
            alert(
              "Error while trying to cancel code. Please try again, or contact tech team if problem persists."
            );
          }
        );
      };
    }]
  );

angular
  .module("Cockpit.Service.LifePeaksGiftcard", ["ngResource"])
  .factory("LifePeaksGiftcard", [
    "$resource",
    function ($resource) {
      return $resource(
        Config.CockpitApi + "/experimental/orders/gift-cards/lifepeaks",
        {},
        {
          get: { method: "GET" },
          cancel: {
            method: "POST",
            url:
              Config.CockpitApi + "/experimental/orders/gift-cards/lifepeaks/:id/cancel",
            params: {
              id: "@id"
            },
          },
          create: {
            method: "POST",
            url: Config.CockpitApi + "/experimental/orders/:orderNumber/gift-cards/lifepeaks",
            params: {
              orderNumber: "@orderNumber"
            },
          }
        }
      );
    },
  ]);

angular
  .module("Cockpit.State.ProductCodes.Madklubben", [
    "ui.router",
    "Cockpit.Service.LifePeaksGiftcard",
    "Cockpit.Modal.ProductCodes.MadklubbenCancelCode",
    "Cockpit.State.ProductCodes.MadklubbenCreateCode",
  ])
  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state("private.products.codes.madklubben", {
      url: "/madklubben",
      views: {
        "@private": {
          controller: "CockpitMadklubbenCodesController",
          templateUrl: "states/products/codes/madklubben/index.tpl.html",
        },
      },
      data: {
        pageTitle: "Madklubben Codes",
      },
    });
  }])
  .controller(
    "CockpitMadklubbenCodesController",
    ["$scope", "LifePeaksGiftcard", "$uibModal", "$timeout", function CockpitEventsController($scope, LifePeaksGiftcard, $uibModal, $timeout) {
      $scope.search = {};
      $scope.isLoading = true;
      $scope.filtered = [];
      var giftcards = [];
      $scope.allGiftcards = [];
      $scope.isDownloading = false;

      var convertToCsv = function (giftcards) {
        var csv = "Order number,Created at,Code, Amount, Remaining amount, Status\n";
        giftcards.forEach(function (g) {
          csv += g.orderNumber + "," + g.createdAt + "," + g.code + "," + g.amount + "," + g.remainingAmount + "," + (g.status === "Paid" ? "Active" : g.status) + "," + "\n";
        });
        // Create a Blob from the CSV string
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        // Create a URL for the Blob
        return URL.createObjectURL(blob);
      };

      $scope.downloadReport = function () {
        $scope.isDownloading = true;
        var url = convertToCsv($scope.allGiftcards);
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          link.setAttribute("href", url);

          var date = new Date();
          var timestamp = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate() + "-" + date.getHours() + "" + date.getMinutes() + "" + date.getSeconds();
          link.setAttribute("download", "madklubben-codes-reports-" + timestamp +".csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          $timeout(function () {
            $scope.isDownloading = false;
          }, 0)
        }
      };

      var fetchCodes = function () {
        $scope.filtered = giftcards = [];
        $scope.isLoading = true;
        LifePeaksGiftcard.get(
          function successCb(res) {
            if (res.items) {
              res.items.forEach(function (i) {
                const updated = i.giftCards.map(function (g) {
                  g.orderNumber = i.orderNumber;
                  return g;
                });
                // assign response to both filtered and giftcards
                $scope.filtered = $scope.allGiftcards =  giftcards = giftcards.concat(updated);
              });
            }

            $scope.isLoading = false;
          },
          function errorCb(err) {
            console.log("Error =>", err);
            $scope.isLoading = false;
          }
        );
      };

      fetchCodes();

      $scope.handleSearch = function () {
        $scope.filtered = giftcards.filter(function (g) {
          return (
            g.orderNumber
              .toLowerCase()
              .includes($scope.search.q.toLowerCase()) ||
            g.code.includes($scope.search.q)
          );
        });
      };

      $scope.handleCancel = function (id) {
        var order = giftcards.find(function (g) {
          return g.id === id;
        });
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: "states/products/codes/madklubben/cancel.tpl.html",
          resolve: {
            order: order,
          },
          controller: "MadklubbenCancelCodeCtl",
        });

        modalInstance.result.then(function (res) {
          if (!res) {
            return;
          }
          if (res.didCancelCode) {
            fetchCodes();
          }
        });
      };
    }]
  );

angular
  .module("Cockpit.State.ProductCodes.MadklubbenCreateCode", [
    "ui.router",
    "Cockpit.Service.LifePeaksGiftcard",
    "Cockpit.Service.OrderNote",
  ])
  .config(["$stateProvider", function config($stateProvider) {
    $stateProvider.state("private.products.codes.madklubben.create", {
      url: "/create",
      views: {
        "@private": {
          controller: "CockpitMadklubbenFetchCodesController",
          templateUrl: "states/products/codes/madklubben/create.tpl.html",
        },
      },
      data: {
        pageTitle: "Create New Madklubben Code",
      },
    });
  }])
  .controller(
    "CockpitMadklubbenFetchCodesController",
    ["$scope", "LifePeaksGiftcard", "OrderNote", function CockpitEventsController($scope, LifePeaksGiftcard, OrderNote) {
      $scope.order = {};
      $scope.isProcessing = false;

      var createCreateCodeNote = function (code, orderNumber, callback) {
        var orderNote = "Added a Madklubben code: " + code + ".";
        OrderNote.save(
          { orderNumber: orderNumber },
          {
            note: orderNote,
          },
          function (res) {
            callback && typeof callback === "function" && callback();
          },
          function (err) {
            alert("Error saving note: ", err);
            callback && typeof callback === "function" && callback();
          }
        );
      };

      $scope.handleCreate = function () {
        $scope.isProcessing = true;
        var orderNumber = $scope.order.orderNumber;
        LifePeaksGiftcard.create(
          {
            orderNumber: orderNumber,
          },
          {
            amount: $scope.order.amount
          },
          function successCb(res) {
            createCreateCodeNote(res.data.code, orderNumber, function () {
              alert("Successfully added.");
              $scope.order = {};
              $scope.isProcessing = false;
            });
          },
          function errorCb(err) {
            console.log("Error =>", err);
            $scope.isProcessing = false;
            alert(
              "Error while trying to create code. Please try again, or contact tech team if problem persists."
            );
          }
        );
      };
    }]
  );

angular.module('Cockpit.Directive.TileFilter', [

])

  .directive('tileFilter', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        added: '=',
        items: '=',
        name: '@',
        pluralname: '@'
      },
      templateUrl: 'states/products/tiles/filter-directive/filter.tpl.html',
      link: function ($scope) {
        
        $scope.add = function() {
          if (!$scope.selected || !$scope.selected.slug) {
            return;
          }

          if (indexOfItem($scope.selected.slug) > -1) {
            return;
          }

          $scope.added.push($scope.selected.slug);
          $scope.selected = null;
        }

        $scope.remove = function (slug) {
          var itemIndex = indexOfItem(slug);
          if (itemIndex === -1) {
            return;
          }

          $scope.added.splice(itemIndex, 1);
        }
        
        
        function indexOfItem(slug){
          if(!angular.isDefined($scope.added)){
            return -1;
          }

          for(var i = 0; i < $scope.added.length; i++){
            var existingSlug = $scope.added[i];

            if(existingSlug === slug){
              return i;
            }
          }

          return -1;
        }

        $scope.filterItems = function filterItems(value){
          return indexOfItem(value.slug) === -1;
        }
      }
    }
  })
angular.module('Cockpit.Directive.ConvertToNumber', [])
.directive('convertToNumber', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function(val) {
        return parseInt(val, 10);
      });
      ngModel.$formatters.push(function(val) {
        return '' + val;
      });
    }
  };
});
angular.module('Cockpit.Service.Geckoboard.MonthlyGoals', ['ngResource'])
    .factory('GeckoMonthlyGoals', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'geckoapi/:action', {}, {
                get: { params: { action: 'GetMonthlyGoals' } },
                save: { method: 'POST', params: { action: 'SaveMonthlyGoals' } }
            });
        }]);
angular.module('Cockpit.State.Settings.Geckoboard.MonthlyGoals', [
    'ui.router',
    'Cockpit.State.Settings',
    'Cockpit.Service.Geckoboard.MonthlyGoals'
])

    .config(["$stateProvider", function config($stateProvider) {
        $stateProvider.state('private.settings.gecko-monthly-goals', {
            url: 'geckoboard/monthly-goals/',
            controller: 'GeckoboardMonthlyGoalsController',
            templateUrl: 'states/settings/geckoboard/monthly-goals/goals.tpl.html',
            data: {
                pageTitle: 'Product Ranking Settings'
            }
        })

        ;
    }])

    .run(["Navigation", function (Navigation) {
        Navigation.sidebar.register('private.settings', 'Geckoboard', [
            {
                Label: "Monthly Goals",
                State: 'private.settings.gecko-monthly-goals',
                Groups: ["admin"]
            }
        ]);
    }])

    .controller('GeckoboardMonthlyGoalsController', ["$scope", "GeckoMonthlyGoals", function GeckoboardMonthlyGoalsController($scope, GeckoMonthlyGoals) {

        $scope.model = GeckoMonthlyGoals.get();

        $scope.process = function processSave() {
            $scope.model.$save();
        };
    }])

;
angular.module('Cockpit.Service.MailChimp.ECommerce', ['ngResource'])
    .factory('MCStore', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'storesapi', {},{
                query: { method: 'GET', url: Config.Api + 'storesapi/get', isArray: true },
                save: { method: 'POST', url: Config.Api + 'storesapi/save' }
            });
        }])
    .factory('MCList', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'listsapi', {},{
                query: { method: 'GET', url: Config.Api + 'listsapi/get', isArray: true }
            });
        }])

    .factory('MCSettings', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'listsapi', {},{
                get: { method: 'GET', url: Config.Api + 'mcsettingsapi/get' },
                save: { method: 'POST', url: Config.Api + 'mcsettingsapi/save' }
            });
        }])

    .factory('MCStoreManagement', ['$http', function($http){

        return {
            syncProducts: function(storeId, successCallback, errorCallback){
                $http.get(Config.Api + 'mcmanagementapi/sync', {
                    params: {
                        'storeid': storeId
                    }
                }).then(successCallback, errorCallback);
            },
            deleteProducts: function(storeId, successCallback, errorCallback){
                $http.get(Config.Api + 'mcmanagementapi/delete', {
                    params: {
                        'storeid': storeId
                    }
                }).then(successCallback, errorCallback);
            }
        }
    }])
;
angular.module('Cockpit.State.Settings.MailChimp.ECommerce', [
    'ui.router',
    'Cockpit.State.Settings',
    'Cockpit.Service.MailChimp.ECommerce'
])

    .config(["$stateProvider", function config($stateProvider) {
        $stateProvider.state('private.settings.mc-ecommerce', {
            url: 'mailchimp/e-commerce/',
            controller: 'MailchimpEcommerceControllers',
            templateUrl: 'states/settings/mailchimp/ecommerce/settings.tpl.html',
            data: {
                pageTitle: 'MailChimp E-Commerce Settings'
            }
        })

        ;
    }])

    .run(["Navigation", function (Navigation) {
        Navigation.sidebar.register('private.settings', 'MailChimp', [
            {
                Label: "E-Commerce",
                State: 'private.settings.mc-ecommerce',
                Groups: ["admin"]
            }
        ]);
    }])

    .controller('MailchimpEcommerceControllers', ["$scope", "MCStore", "MCList", "MCSettings", "MCStoreManagement", function MailchimpEcommerceControllers($scope, MCStore, MCList, MCSettings, MCStoreManagement) {
        $scope.settings = MCSettings.get();

        $scope.lists = MCList.query();
        $scope.stores = MCStore.query();

        $scope.store = {
            Id: '',
            Name: '',
            currency_code: '',
            money_format: '',
            Address: {
                Street: '',
                City: '',
                Zipcode: '',
                Country: ''
            }
        };

        $scope.management = {
            StoreId: '',
            IsDeletingProducts: false,
            IsSyncingProducts: false
        };

        $scope.deleteAll = function(){
            if($scope.management.StoreId == ""){
                return;
            }

            $scope.management.IsDeletingProducts = true;

            MCStoreManagement.deleteProducts($scope.management.StoreId, function(){
                $scope.management.IsDeletingProducts = false;
            }, function(){
                alert("Could not delete all products.");
                $scope.management.IsDeletingProducts = false;
            });
        };

        $scope.syncAll = function(){
            if($scope.management.StoreId == ""){
                return;
            }

            $scope.management.IsSyncingProducts = true;

            MCStoreManagement.syncProducts($scope.management.StoreId, function(){
                $scope.management.IsSyncingProducts = false;
            }, function(){
                alert("Could not delete all products.");
                $scope.management.IsSyncingProducts = false;
            });
        };

        $scope.processSaveStore = function processSaveStore(){
            MCStore.save($scope.store, function(){
                $scope.stores = MCStore.query();
            });
        };

        $scope.saveSettings = function saveSettings(){
            MCSettings.save($scope.settings);
        }
    }])

;
angular.module('Cockpit.Service.Notification.Templates', ['ngResource'])
  .factory('NotificationTemplates', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'notifications/templates/:id', {}, {
        update: { method: 'PUT' },
        available: { method: 'GET', url: Config.AdminApi + 'notifications/templates/available' , isArray: true }
      });
    }
  ]);
angular.module('Cockpit.State.Settings.Notifications.Templates.Edit', [
  'ui.router',
  'Cockpit.Service.Notification.Templates'
])

  .config(["$stateProvider", function ($stateProvider) {
    $stateProvider.state('private.settings.notifications.templates.edit', {
      url: ':id/edit',
      views: {
        '@private': {
          controller: 'TemplatesEditController',
          templateUrl: 'states/settings/notifications/template-settings/edit.tpl.html'
        }
      },
      data: {
        pageTitle: 'Edit template'
      },
      resolve: {
        theInstance: ['$q', 'NotificationTemplates', '$stateParams', function ($q, NotificationTemplates, $stateParams) {
          const d = $q.defer();

          NotificationTemplates.get({ id: $stateParams.id }, function(res) {
            d.resolve(res);
          }, function(err) {
            d.reject(err);
          })

          return d.promise;
        }]
      }
    })
  }])

  .controller('TemplatesEditController', ["$scope", "theInstance", "NotificationTemplates", function ($scope, theInstance, NotificationTemplates) {
    $scope.model = theInstance;
    $scope.infoMessage = null;
    $scope.hasError = false;

    $scope.onSubmit = function() {
      NotificationTemplates.update($scope.model, function(res) {
        $scope.infoMessage = 'Saved!';
        $scope.hasError = false;
      }, function (err) {
        $scope.infoMessage = 'Could not save';
        $scope.hasError = true;
      });
    }
  }])

angular.module('Cockpit.State.Settings.Notifications.Templates', [
  'ui.router',
  'Cockpit.Service.Notification.Templates',
  'Cockpit.State.Settings.Notifications.Templates.Edit'
])

  .config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
    $stateProvider.state('private.settings.notifications.templates', {
      url: 'templates/',
    })

    .state('private.settings.notifications.templates.email', {
      url: 'email/',
      views: {
        '@private': {
          controller: 'TemplatesController',
          templateUrl: 'states/settings/notifications/template-settings/templates-email.tpl.html'
        }
      },
      data: {
        pageTitle: 'Template settings'
      }
    })

    .state('private.settings.notifications.templates.sms', {
      url: 'sms/',
      views: {
        '@private': {
          controller: 'TemplatesController',
          templateUrl: 'states/settings/notifications/template-settings/templates-sms.tpl.html'
        }
      },
      data: {
        pageTitle: 'Template settings'
      }
    })

    $urlRouterProvider.otherwise('/templates/email');
  }])

  .run(["Navigation", function (Navigation) {
    Navigation.sidebar.register('private.settings', 'Notifications', [
      {
        Label: 'Email Templates',
        State: 'private.settings.notifications.templates.email',
        Groups: ['admin']
      },
      {
        Label: 'SMS Templates',
        State: 'private.settings.notifications.templates.sms',
        Groups: ['admin']
      }
    ]);
  }])

  .controller('TemplatesController', ["$scope", "NotificationTemplates", function ($scope, NotificationTemplates) {
    $scope.notificationTemplates = NotificationTemplates.query();

    $scope.delete = function(template) {
      if (confirm('Are you sure you want to delete ' + template.name + '?')) {
        NotificationTemplates.delete({id: template.id}, function (res) {
          $scope.notificationTemplates = NotificationTemplates.query();
        });
      }
    }
  }]);


// available statuses for printfile exports

angular.module('Cockpit.PrintFile.ExportStatus', [])
  .constant('PrintFileConstants', (function () {
    var exportStatus = {
      Unfinished: 'Unfinished',
      CreatingShipments: 'CreatingShipments',
      ReadyForExport: 'ReadyForExport',
      Exported: 'Exported',
      Failed: 'Failed',
      AddingOrders: 'AddingOrders',
      OrdersAdded: 'OrdersAdded',
      InitializationFailed: 'InitializationFailed'
    };

    var orderExportStatus = {
      Added: 'Added',
      CreatingShipment: 'CreatingShipment',
      CreatingShipmentSuccess: 'CreatingShipmentSuccess',
      CreatingShipmentFailed: 'CreatingShipmentFailed',
      Exported: 'Exported',
      Excluded: 'Excluded',
      CreatingShippingLabel: 'CreatingShippingLabel',
      CreatingShippingLabelFailed: 'CreatingShippingLabelFailed'
    }

    function getDisplayStatus(status) {

      // main log statues
      switch (status) {
        case 'Unfinished':
        case 'CreatingShipments':
        case 'AddingOrders': {
          return 'Loading';
        }
        case 'ReadyForExport':
        case 'OrdersAdded': {
          return 'Ready';
        }
        case 'Failed':
        case 'InitializationFailed': {
          return 'Failed';
        }
        default: {
          break;
        }
      }

      // order log statues
      switch (status) {
        case 'CreatingShipment':
        case 'CreatingShippingLabel': {
          return 'Loading';
        }
        case 'Added': {
          return 'Ready';
        }
        case 'CreatingShipmentSuccess': {
          return 'Done';
        }
        case 'CreatingShipmentFailed':
        case 'CreatingShippingLabelFailed': {
          return 'Failed';
        }
        default: {
          break;
        }
      }

      // just return the actual status if none of above matched
      return status;
    }

    function getColorClass(status) {
      switch (status) {
        case 'OrdersAdded': case 'ReadyForExport': case 'Exported': case 'CreatingShipmentSuccess': {
          return 'green';
        }
        case 'Unfinished': case 'CreatingShipments': case 'AddingOrders': case 'Added': {
          return 'orange';
        }
        case 'Failed': case 'InitializationFailed': case 'CreatingShipmentFailed': case 'CreatingShippingLabelFailed': {
          return 'red';
        }
        default: {
          return 'black';
        }
      }
    }

    function showLoadingBubbles(status) {
      switch (status) {
        case 'Unfinished':
        case 'CreatingShipment':
        case 'CreatingShippingLabel':
        case 'CreatingShipments':
        case 'AddingOrders': {
          return true;
        }
        default: {
          return false;
        }
      }
    }

    return {
      ExportStatus: exportStatus,
      OrderExportStatus: orderExportStatus,
      getDisplayStatus: getDisplayStatus,
      getColorClass: getColorClass,
      showLoadingBubbles: showLoadingBubbles
    }
  })())

angular.module('Cockpit.PrintFile.Shared', [
  'Cockpit.Service.Dgda.Printfile'
])
  .factory('PrintFileHelper', function () {
    function getExportDtoFromPrintFileLog(printFileLog) {
      return {
        from: printFileLog.exportFrom || "",
        to: printFileLog.exportTo || "",
        bulkId: printFileLog.bulkId || "",
        countries: printFileLog.countries.join(',')
      };
    }

    var countries = [
      {
        "IsoCode": "da-dk",
        "Name": "Denmark"
      },
      {
        "IsoCode": "nb-no",
        "Name": "Norway"
      },
      {
        "IsoCode": "sv-se",
        "Name": "Sweden"
      },
      {
        "IsoCode": "de-de",
        "Name": "Germany"
      }
    ];

    return {
      countries: countries,
      getExportDtoFromPrintFileLog: getExportDtoFromPrintFileLog
    }
  })