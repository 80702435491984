angular.module('Cockpit.State.Notifications.Details', [
    'Cockpit.Service.Notification'
  ])

    .config(function config($stateProvider) {
        $stateProvider.state('private.notifications.details', {
          url: '/{id}/details',
          controller: 'NotificationDetailsController',
          templateUrl: 'states/notifications/notification-details.tpl.html',
          resolve: {
            notificationDetails: ['$q', 'Notification', '$stateParams', function resolveCampaigns($q, Notification, $stateParams){
              var d = $q.defer();

              Notification.get({id: $stateParams.id}, function(data){
                  d.resolve(data);
              });

              return d.promise;
            }],
            previousState: ['$state', function ($state) {
              return $state.current.name + '({id: ' + $state.params.id +'})';
            }]
          },
          data: {
            pageTitle: 'Notifications | Details'
          }
        })
      ;
    })

    .run(function () {

    })

    .controller('NotificationDetailsController', function NotificationDetailsController($scope, $uibModal, Notification, notificationDetails, previousState){
      $scope.notificationDetails = notificationDetails
      $scope.previousState = previousState;
      $scope.hasError = false;
      $scope.infoMessage = null;
      $scope.recipient = $scope.notificationDetails.customer.recipient;

      $scope.ResendEmail = function (recipient) {
        if (!window.confirm('Do you want to resend email to this recipient: ' + recipient)) {
          return;
        }

        Notification.save({
          notificationId: $scope.notificationDetails.id,
          recipient: recipient
        }, function(res) {
          $scope.hasError = false;
          $scope.infoMessage = 'Notification has been sent to: ' + recipient;
        }, function(err) {
          $scope.hasError = true;
          $scope.infoMessage = err.data;
        })
      }

      $scope.previewContent = function() {
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: "states/notifications/notification-preview.tpl.html",
          resolve: {
            iframeSrc: ['$q', 'Notification', function ($q, Notification){
              var d = $q.defer();

              Notification.getContent({id: $scope.notificationDetails.id}, function(data){
                d.resolve(data.html);
              })

              return d.promise;

            }]
          },
          controller: function NotificationPreviewController($scope, $sce, iframeSrc){
            $scope.iframeSrc = $sce.trustAsHtml(iframeSrc);
          }
        });
        // silence console.log errors by handle result..
        modalInstance.result.then(function (item) {}, function () {});
      }

      $scope.manualHandleFailedEmail = function (message) {
        if (!window.confirm('Are you sure this email has been handled?')) {
          return;
        }

        Notification.update({
          notificationId: $scope.notificationDetails.id,
          message: message
        }, function (res) {
          $scope.hasError = false;
          $scope.infoMessage = 'Updated!';
          $scope.notificationDetails = Notification.get({id: $scope.notificationDetails.id});
        }, function (err) {
          $scope.hasError = true;
          $scope.infoMessage = err.data;
        })
      }

      $scope.getTooltip = function(status){
        switch (status) {
          case "delivered": {
            return "Message has been received but not yet opened."
          }

          case "sent": {
            return "Message has been sent but not yet delivered."
          }

          case "opened": {
            return "Message has been opened by recipient."
          }

          case "resent": {
            return "Message has been resent but not yet delivered."
          }

          case "clicked": {
            return "The recipient has clicked on a link";
          }

          case "handled": {
            return "It has been manually handled";
          }

          default: {
            return "It was not possible to deliver the message.";
          }
        }
      }
    })
;
