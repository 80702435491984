angular.module('Cockpit.State.Mademyday.Parse', [
  'ui.router',
  'Cockpit.Service.Mademyday.Parse'
])

.config(function config($stateProvider) {
	$stateProvider.state('private.settings.mmd-parse', {
		url: '/parse',
		views: {
			'@private': {
				controller: 'CockpitMademydayParseController',
				templateUrl: 'states/mademyday/settings/parse/settings.tpl.html',
			}
		},
		data: {
			pageTitle: 'Parse Settings'
		}
	})

	;
})

.run(function (Navigation) {
	Navigation.sidebar.register('private.settings', 'mademyday', [
		{
			Label: "Parse",
			State: 'private.settings.mmd-parse',
			Groups: ["admin"]
		}
	]);
})

.controller('CockpitMademydayParseController', function CockpitMademydayParseController($scope, Parse) {
	$scope.settings = Parse.get();

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.process = function ProcessSaveParseData() {
		$scope.alert.Show = false;

		$scope.settings.$save(function () {
			$scope.alert.Type = "success";
			$scope.alert.Message = "Your settings have been saved :-)";
			$scope.alert.Show = true;
		}, function () {
			$scope.alert.Type = "warning";
			$scope.alert.Message = "An error occured while saving your settings.";
			$scope.alert.Show = true;
		});
	};
})

;