angular.module('Cockpit.State.Dgda.Printfile.Status', [
  'ui.router',
  'Cockpit.Service.Dgda.Printfile',
  'Cockpit.PrintFile.Shared',
  'Cockpit.PrintFile.ExportStatus',
  'Cockpit.State.Dgda.Printfile.Status.EditOrder'
])

  .config(function ($stateProvider) {
    $stateProvider.state('private.orders.printfile.status', {
      url: '/printfile/{exportLogId}/status',
      views: {
        "@private": {
          controller: 'CockpitDgdaExportPrintfileStatusController',
          templateUrl: 'states/dgda/orders/printfile/printfile-status.tpl.html'
        }
      },
      data: {
        pageTitle: 'Export print-file status'
      }
    });
  })

  .controller('CockpitDgdaExportPrintfileStatusController', function ($scope, $stateParams, $interval, $uibModal, ExportDgdaPrintfile, PrintFileLog, PrintFileHelper, PrintFileConstants) {
    $scope.currentPrintFileLog = {}
    $scope.currentPrintFileOrderLogs = [];
    $scope.isLoading = false;
    $scope.isRefreshingStatus = false;
    $scope.isUpdatingOrderStatus = false;
    $scope.orders = [];
    $scope.statusPollingIntervalId = null;
    $scope.errorData = null;
    $scope.getColorClass = PrintFileConstants.getColorClass;
    $scope.showLoadingBubbles = PrintFileConstants.showLoadingBubbles;
    $scope.getDisplayStatus = PrintFileConstants.getDisplayStatus;
    $scope.exportedData = null;
    $scope.step = 4;
    $scope.sortPropertyName = "status";
    $scope.sortReverse = false;
    $scope.sortByStatus = function() {
      $scope.sortReverse = !$scope.sortReverse;
    }

    // initial request + polling status endpoint every x seconds
    var intervalPromise = null;
    setupExportStatusInterval();
    $scope.$on('$destroy', function () {
      if (intervalPromise) {
        $interval.cancel(intervalPromise);
        intervalPromise = undefined;
      }
    })

    function setupExportStatusInterval() {
      getForExportStatus();
      intervalPromise = $interval(function () {
        if (!$scope.isRefreshingStatus) {
          getForExportStatus(); // only fetch if not already in the middle of loading something
        }
      }, 10000);
    }

    function getForExportStatus() {
      $scope.isRefreshingStatus = true;

      PrintFileLog.getExportLogStatus({ exportLogId: $stateParams.exportLogId }, function (response) {

        $scope.currentPrintFileLog = response.printFileLog;

        // update orderLog entries manually to prevent entire scope updating and making table flicker
        response.printFileOrderLogs.forEach(function (orderLog) {
          var existingOrderLog = $scope.currentPrintFileOrderLogs.find(function (x) { return x.id === orderLog.id });

          if (!existingOrderLog) {
            $scope.currentPrintFileOrderLogs.push(orderLog);
            return;
          }

          for (var key in existingOrderLog) {
            if (Object.hasOwnProperty.call(existingOrderLog, key)) {
              existingOrderLog[key] = orderLog[key];
            }
          }
        })

        if ($scope.orders.length === 0) {
          getOrders();
          return;
        }

        validateExportStatus();
        $scope.isRefreshingStatus = false;
        $scope.isUpdatingOrderStatus = false;
      }, function (err) {
        console.log(err);
        $scope.isRefreshingStatus = false;
        $scope.isUpdatingOrderStatus = false;
      })
    }

    function getOrders() {
      $scope.orders = ExportDgdaPrintfile.query(PrintFileHelper.getExportDtoFromPrintFileLog($scope.currentPrintFileLog), function (response) {
        $scope.initialOrders = angular.copy(response);
        $scope.isRefreshingStatus = false;
      }, function (err) {
        $scope.isRefreshingStatus = false;
        alert("Could not load orders. Please try refreshing page again.");
        $scope.errorData = err;
      })
    }

    function validateExportStatus() {
      // show status message when export is initialized
      if ($scope.currentPrintFileLog.status !== 'Unfinished') {
        $scope.exportingText = $scope.currentPrintFileLog.status + '...';
      }

      if ($scope.currentPrintFileLog.status !== 'Exported') {
        return;
      }

      // stop polling since status export is done
      $interval.cancel(intervalPromise);
      intervalPromise = undefined;
    }

    $scope.exportOrders = function exportOrders() {
      $scope.exportingText = 'Starting export files...';
      performExport(null);
    }


    $scope.editOrder = function (printFileOrderLog) {
      if ($scope.currentPrintFileLog.status === 'ReadyForExport') {
        alert('Cannot edit order when ready for export already');
        return;
      }

      var order = $scope.orders.find(function (o) { return o.OrderNumber === printFileOrderLog.orderNumber });

      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "states/dgda/orders/printfile/printfile-order-edit.tpl.html",
        resolve: {
          order: order,
          printFileOrderLog: printFileOrderLog
        },
        windowClass: 'app-modal-window',
        controller: "PrintFileEditOrderCtl"
      })

      modalInstance.result.then(function (res) {
        if (!res) {
          return;
        }

        if (res.didUpdateOrderStatus) {
          $scope.isUpdatingOrderStatus = true;
          getForExportStatus();
        }

        if (res.didUpdateOrder) {
          $scope.retryShipment(printFileOrderLog);
        }

      })
    };

    $scope.retryShipment = function (printFileOrderLog) {
      $scope.isLoading = true;

      if ($scope.currentPrintFileLog.status === 'ReadyForExport') {
        alert('Cannot retry order when ready for export already');
        $scope.isLoading = false;
        return;
      }

      if ( $scope.currentPrintFileLog.status === 'OrdersAdded' ) {
        $scope.isLoading = false;
        return;
      }

      if( printFileOrderLog.errors === 0 ) {
        $scope.isLoading = false;
        return;
      }

      $scope.exportingText = 'Retrying shipment';
      printFileOrderLog.isRetryingShipment = true;
      performExport(printFileOrderLog);
    }

    function performExport(printFileOrderLog) {
      $scope.isLoading = true;
      $scope.errorData = null;

      var body = printFileOrderLog
        ? { orderlog: printFileOrderLog }
        : {};

      PrintFileLog.performExport({ exportLogId: $scope.currentPrintFileLog.id }, body, function (res) {
        $scope.isLoading = false;
        $scope.exportingText = 'Export has begun';
        getForExportStatus();

      }, function (err) {
        alert('Failed performing the export');
        $scope.exportingText = 'Failed performing the export';
        $scope.isLoading = false;
        printFileOrderLog.isRetryingShipment = false;
        $scope.errorData = err;
      })
    }

    $scope.isOrderLogEditable = function (printFileOrderLog) {
      if (
        printFileOrderLog.errors.length > 0 &&
        $scope.currentPrintFileLog.status === "CreatingShipments"
      ) {
        return true;
      }

      if (
        printFileOrderLog.status === "Excluded" &&
        $scope.currentPrintFileLog.status === "ReadyForExport"
      ) {
        return true;
      }

      if (
        $scope.currentPrintFileLog.status === "CreatingShipments" ||
        $scope.getDisplayStatus(printFileOrderLog.status) === "Loading" ||
        printFileOrderLog.status === "Exported" ||
        printFileOrderLog.status === "CreatingShipmentSuccess" ||
        $scope.currentPrintFileLog.status === "Exported" ||
        $scope.currentPrintFileLog.status === "ReadyForExport"
      ) {
        return false;
      }

      return true;
    };

    $scope.isOrderLogRefreshable = function (printFileOrderLog) {
      return !$scope.isLoading && printFileOrderLog.errors.length > 0;
    };

    $scope.updateOrderStatus = function (printFileOrderLog) {
      printFileOrderLog.isUpdatingOrder = true;
      $scope.isUpdatingOrderStatus = true;

      // update to excluded status if not excluded already
      var newStatus = printFileOrderLog.status === 'Excluded' ? 'Added' : 'Excluded';

      PrintFileLog.updateOrderStatus({ exportLogId: printFileOrderLog.exportLogId, orderNumber: printFileOrderLog.orderNumber, orderExportStatus: newStatus }, {}, function (res) {
        printFileOrderLog.isUpdatingOrder = false;
        printFileOrderLog.status = newStatus;
        getForExportStatus();

      }, function (err) {
        printFileOrderLog.isUpdatingOrder = false;
        $scope.isUpdatingOrderStatus = false;
        $scope.errorData = err;
        alert('Could not exclude order');
      })
    }

    $scope.onCompleteExport = function () {
      $scope.isLoading = true;

      PrintFileLog.getExportZipFiles({ exportLogId: $scope.currentPrintFileLog.id }, function (response) {
        $scope.isLoading = false;
        $scope.exportedData = response;
        $scope.step = 5;

      }, function (err) {
        $scope.isLoading = false;
        alert('Failed to get zip files');
        console.log('Could not get zip files => ', err);
      })
    }
  })
