angular.module('Cockpit.State.Dgda.Printfile', [
  'ui.router',
  'Cockpit.Service.Dgda.Printfile',
  'Cockpit.PrintFile.Shared',
  'Cockpit.PrintFile.ExportStatus'
])

  .config(function ($stateProvider) {
    $stateProvider.state('private.orders.printfile', {
      url: '/printfile',
      views: {
        "@private": {
          controller: 'CockpitDgdaExportPrintfileController',
          templateUrl: 'states/dgda/orders/printfile/printfile.tpl.html'
        }
      },
      data: {
        pageTitle: 'Export print-file'
      }
    });
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.orders', 'Export', [
      {
        Label: 'Print file',
        State: 'private.orders.printfile',
        Groups: ["admin"]
      }
    ]);
  })

  .controller('CockpitDgdaExportPrintfileController', function CockpitDgdaExportPrintfileController($scope, $state, $interval, ExportDgdaPrintfile, PrintFileLog, PrintFileHelper, PrintFileConstants) {
    $scope.errorData = null;
    $scope.logs = [];
    $scope.countries = PrintFileHelper.countries;
    $scope.getColorClass = PrintFileConstants.getColorClass;
    $scope.showLoadingBubbles = PrintFileConstants.showLoadingBubbles;
    $scope.getDisplayStatus = PrintFileConstants.getDisplayStatus;
    $scope.isLoading = false;
    $scope.lastInitiatedExportLogId = null;
    $scope.step = 1;
    var intervalPromise = null;

    intervalPromise = $interval(getLogs, 10000);
    $scope.$on('$destroy', function () {
      if (intervalPromise) {
        resetInterval();
      }
    })

    function resetInterval() {
      $interval.cancel(intervalPromise);
      intervalPromise = undefined;
    }

    getLogs();

    function getLogs() {
      $scope.isLoading = true;
      $scope.errorData = null;

      PrintFileLog.query({}, function (res) {
        $scope.isLoading = false;
        $scope.logs = res;

        var initiatingLogs = $scope.logs.filter(function (log) {
          return log.status === 'AddingOrders' || log.status === 'Unfinished'
        });

        if (initiatingLogs.length > 0) {
          return;
        }

        // cancel polling since none is AddingOrders
        resetInterval();
        $scope.lastInitiatedExportLogId = null;

      }, function (err) {
        $scope.isLoading = false;
        $scope.errorData = err;
        console.log('Failed to get logs => ', err);
        alert('Failed to get logs');
      });
    }

    setupExportInfo();

    function setupExportInfo() {
      var now = new Date();
      var prevDay = new Date();

      if (now.getDay() === 1) {
        // It's monday - set the default to friday
        prevDay.setDate(now.getDate() - 3);
      } else {
        prevDay.setDate(now.getDate() - 1);
      }
      $scope.now = now;
      $scope.exportInfo = {
        From: new Date(prevDay.getFullYear(), prevDay.getMonth(), prevDay.getDate(), 9, 0, 0, 0),
        To: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0, 0, 0),
        Exported: '',
        Countries: []
      };
    };

    $scope.step1Load = function step1Load() {
      if ($scope.isContinueBtnDisabled()) {
        alert('Did you select country and date/bulkId?');
        return;
      }

      $scope.errorData = null;
      $scope.isLoading = true;
      $scope.orders = ExportDgdaPrintfile.query(getExportDto($scope.exportInfo.Countries.join(',')), function (d) {
        if (d.length === 0) {
          $scope.isLoading = false;
          alert("No orders found for export");
          return;
        }

        // create export log in admin service
        PrintFileLog.createExportLog(getExportDto($scope.exportInfo.Countries), function (response) {
          $scope.isLoading = false;
          $scope.lastInitiatedExportLogId = response.exportLogId;

          getLogs();
          if (intervalPromise) {
            resetInterval();
          }

          intervalPromise = $interval(getLogs, 10000);
          alert('Export created. Please wait until orders have been added to the export. It will be available under initiated exports.');
          return;
        }, function (err) {
          $scope.isLoading = false;
          alert('Could not create export log... Please try again.');
          $scope.errorData = err;
        });

      }, function err(a, b, c) {
        $scope.isLoading = false;
        alert("Could not load orders... Please try refreshing page again.");
        $scope.errorData = a;
      });
    };

    function getExportDto(countries) {
      return {
        from: $scope.exportInfo.From,
        to: $scope.exportInfo.To,
        bulkId: $scope.exportInfo.BulkId || "",
        countries: countries
      };
    }

    $scope.toggleCountry = function toggleCountry(country) {
      var index = $scope.exportInfo.Countries.indexOf(country.IsoCode);
      if (index === -1) {
        $scope.exportInfo.Countries.push(country.IsoCode);
      } else {
        $scope.exportInfo.Countries.splice(index, 1);
      }
    };

    $scope.shouldHideResumeBtn = function (status) {
      switch (status) {
        case 'InitializationFailed':
        case 'Unfinished':
        case 'AddingOrders': {
          return true;
        }
        default: {
          return false;
        }
      }
    }

    $scope.getExportedFile = function (currentLog, currentLanguageCode) {
      var exportedFile = currentLog.exportedFiles.find(function(file) { return file.languageCode.toUpperCase() === currentLanguageCode.toUpperCase() });
      if (!exportedFile) {
        alert('No file found. This can happen when all orders in the export for this country were excluded.');
        return;
      }

      // download file
      window.location.href = exportedFile.url;
    }

    $scope.onResume = function (log) {
      $scope.errorData = null;
      $scope.isLoading = true;

      if (log.status === 'OrdersAdded') {
        $state.go('private.orders.printfile.dataverification', { exportLogId: log.id, step: 2 })
        return;
      }

      $state.go('private.orders.printfile.status', { exportLogId: log.id })
      return;
    }

    $scope.onDelete = function (log) {
      $scope.errorData = null;
      $scope.isLoading = true;

      PrintFileLog.deleteExportLog({exportLogId: log.id}, function (res) {
        $scope.isLoading = false;
        getLogs();

      }, function (err) {
        $scope.isLoading = false;
        if (err.data) {
          alert(err.data);
          return;
        }

        $scope.errorData = err;
        alert('Failed abandoning log');
      });
    }

    $scope.isBulkExport = function () {
      return $scope.exportInfo.BulkId && $scope.exportInfo.BulkId.length > 0;
    }

    $scope.isContinueBtnDisabled = function () {

      var areCountriesMissing = $scope.exportInfo.Countries.length == 0;
      if ($scope.isBulkExport()) {
        return areCountriesMissing;
      }

      return $scope.exportInfo.From === undefined || $scope.exportInfo.To === undefined || areCountriesMissing;
    }
  })
  ;
