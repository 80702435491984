angular
  .module("Cockpit.State.Search", [
    "ui.router",
    "Cockpit.Service.Search",
    "Cockpit.Service.OrderNote",
    "Cockpit.Service.Dgda.OpenGiftCards",
    "Cockpit.Service.Notification",
    "Cockpit.State.Search.Details"
  ])

  .config(function config($stateProvider) {
    $stateProvider
      .state("private.search", {
        url: "/search",
        controller: "CockpitSearchController",
        templateUrl: "states/search/search.tpl.html",
        data: {
          pageTitle: "Search",
        },
      })
  })

  .run(function (Navigation) {
    Navigation.primary.register("Searching", "private.search");
  })

  .controller(
    "CockpitSearchController",
    function CockpitReviewsController($scope, Search) {
      $scope.search = {
        Query: "",
        Results: [],
      };
      $scope.isSearching = false;

      $scope.doSearch = function doSearch() {
        $scope.isSearching = true;
        $scope.search.Results = Search.query(
          { query: $scope.search.Query, simpleSearch: true },
          function () {
            $scope.isSearching = false;
          },
          function () {
            $scope.isSearching = false;
          }
        );
      };
    }
  );
