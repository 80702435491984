angular.module('Cockpit.State.ProductRankingWeights', [
    'ui.router',
    'Cockpit.Service.ProductRankingWeights',
    'Cockpit.State.Products'
])

.config(function config($stateProvider) {

	$stateProvider.state('private.products.rankingweights', {
		url: '/ranking-weights',
        views: {
            '@private': {
                controller: 'ProductRankingWeightsController',
				templateUrl: 'states/dgda/products/rankingweights/weights.tpl.html'
            }
        },
		data: {
			pageTitle: 'Product Ranking Factor Weights'
		}
	})

	;
})

.run(function (Navigation) {
	Navigation.sidebar.register('private.products', 'General', [
		{
			Label: "Ranking Weights (v2)",
			State: 'private.products.rankingweights',
			Groups: ['admin']
		},
    {
			Label: "Booking configurations",
			State: '/v2/admin/booking-integrations/',
			Groups: ['admin']
		}
	]);
})

.controller('ProductRankingWeightsController', function ProductRankController($scope, ProductRankingWeights) {
	$scope.weights = ProductRankingWeights.query();

	$scope.process = function processSaveProductRankingWeights() {
		ProductRankingWeights.save($scope.weights);
	};
})

;
