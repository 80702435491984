angular.module('Cockpit.Partial.Orders.Add.Preview', [
  'ui.router',
  'Cockpit.Service.Orders.Basket'
])

  .controller('CockpitOrderAddPreviewController', function CockpitOrderAddPreviewController($scope, basketId, $uibModalInstance, BasketService, numberOfOrders) {
    $scope.numberOfOrders = numberOfOrders;

    getBasket();

    function getBasket() {
      $scope.isLoading = true;

      BasketService.get({ basket: basketId }, function (res) {
        $scope.isLoading = false;
        $scope.basket = res;

      }, function (err) {
        $scope.isLoading = false;
        alert('Failed to get order. Please try again.');
        console.log('Err => ', err)
      });
    }

    $scope.closeModal = function (orderConfirmed) {
      $uibModalInstance.close({
        orderConfirmed: orderConfirmed
      });
    }
  })
