angular.module('Cockpit.Service.SupplierCodes', ['ngResource'])
  .factory('SupplierCode', ['$resource',
    function ($resource) {
      return $resource(Config.Api + 'productcodeapi/:action', {}, {
        get: { params: { action: 'Get' } },
        import: { method: 'POST', params: { action: 'Import' } },
        query: { params: { action: 'All' }, isArray: true },
        orders: { params: { action: 'Orders' }, isArray: true },
        remaining: { params: { action: 'Remaining' }, isArray: true },
        revoke: { params: { action: 'Revoke' }, isArray: true },
        revokeByReference: { params: { action: 'RevokeByReference' } },
        deleteCodes: { method: 'POST', params: { action: 'DeleteCodes' }, isArray: true },
      });
    }]);
