angular.module('Cockpit.State.Settings.Notifications.Templates', [
  'ui.router',
  'Cockpit.Service.Notification.Templates',
  'Cockpit.State.Settings.Notifications.Templates.Edit'
])

  .config(function ($stateProvider, $urlRouterProvider) {
    $stateProvider.state('private.settings.notifications.templates', {
      url: 'templates/',
    })

    .state('private.settings.notifications.templates.email', {
      url: 'email/',
      views: {
        '@private': {
          controller: 'TemplatesController',
          templateUrl: 'states/settings/notifications/template-settings/templates-email.tpl.html'
        }
      },
      data: {
        pageTitle: 'Template settings'
      }
    })

    .state('private.settings.notifications.templates.sms', {
      url: 'sms/',
      views: {
        '@private': {
          controller: 'TemplatesController',
          templateUrl: 'states/settings/notifications/template-settings/templates-sms.tpl.html'
        }
      },
      data: {
        pageTitle: 'Template settings'
      }
    })

    $urlRouterProvider.otherwise('/templates/email');
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.settings', 'Notifications', [
      {
        Label: 'Email Templates',
        State: 'private.settings.notifications.templates.email',
        Groups: ['admin']
      },
      {
        Label: 'SMS Templates',
        State: 'private.settings.notifications.templates.sms',
        Groups: ['admin']
      }
    ]);
  })

  .controller('TemplatesController', function ($scope, NotificationTemplates) {
    $scope.notificationTemplates = NotificationTemplates.query();

    $scope.delete = function(template) {
      if (confirm('Are you sure you want to delete ' + template.name + '?')) {
        NotificationTemplates.delete({id: template.id}, function (res) {
          $scope.notificationTemplates = NotificationTemplates.query();
        });
      }
    }
  });
