var cockpitBaseApiUrl = window.location.host.indexOf('.local') > -1 ? "api.staging.truestory.com" : window.location.host.replace("cockpit", "api");
var apiGatewayBaseUrl = window.location.host.indexOf('staging.') > -1 ? "admin-api.staging.truestory.com" : "admin-api.truestory.com";
// Change this if api is running locally
var useLocalApi = false;
var isProduction = window.location.host.indexOf("staging") === -1 && window.location.host.indexOf('.local') === -1;

var localAdminApi = useLocalApi ? "admin-api.dgda.local" : "admin-api.staging.truestory.com";
var localApi = useLocalApi ? "api.dgda.local" : "api.staging.truestory.com";
var stagingPrefix = (window.location.host.indexOf('staging.') > -1 || window.location.host.indexOf('.local') > -1) ? ".staging" : "";

var Config = {
    Api: window.location.protocol + "//" + cockpitBaseApiUrl + "/Umbraco/backoffice/CockpitApi/",
    AuthApi: window.location.protocol + "//" + cockpitBaseApiUrl + "/Umbraco/CockpitApi/",
    AdminApi: window.location.protocol + "//" + (window.location.host.indexOf('staging.') > -1 ? 'admin-api.staging.truestory.com' : (window.location.host.indexOf('.local') > -1 ? localAdminApi : 'admin-api.truestory.com')) + "/v1/",
    AdminApiV2: window.location.protocol + "//" + (window.location.host.indexOf('staging.') > -1 ? 'admin-api.staging.truestory.com' : (window.location.host.indexOf('.local') > -1 ? localAdminApi : 'admin-api.truestory.com')) + "/v2/",
    GlobalApi: window.location.protocol + "//" + (window.location.host.indexOf('staging.') > -1 ? 'api.staging.truestory.com' : (window.location.host.indexOf('.local') > -1 ? localApi : 'api.truestory.com')) + "/v2/",
    ApiV3: window.location.protocol + "//" + cockpitBaseApiUrl + "/v3/",
    ApiV2: window.location.protocol + "//" + cockpitBaseApiUrl + "/v2/",
    ApiV1: window.location.protocol + "//" + cockpitBaseApiUrl + "/v1/",
    TestApi: window.location.protocol + "//" + cockpitBaseApiUrl + "/Umbraco/CockpitApi/",
    AwsApiGateway: window.location.protocol + "//" + apiGatewayBaseUrl + "/v1/",
    CockpitApi: "https://api" + stagingPrefix+ ".truestory.com/cockpit",
    CockpitApiV1: "https://api" + stagingPrefix+ ".truestory.com/cockpit/v1/",
    NewCockpitApiV1: "https://api" + stagingPrefix+ ".truestory.com/cockpit-api/v1/",
    WebShipperBaseUrl: isProduction ? 'https://truestory.webshipper.io/' : 'https://truestory-test.webshipper.io/'
};

var modules = [
    /** Jingle Internals **/
    'dgda-cockpit.Module.Templates',

    /** External Libs **/
    'ui.router',
    'ui.bootstrap',
    'ngCookies',

    /** Filters **/
    'Cockpit.Filter.CurrencyDkk',
    'Cockpit.Filter.FilterByDate',
    'Cockpit.Filter.NegateAmount',

    /** States/Routes **/
    'Cockpit.State.Abstracts',
    'Cockpit.State.Login',
    'Cockpit.State.Dashboard',
    'Cockpit.State.ProductRank',
    'Cockpit.State.ProductRankings',
    'Cockpit.State.Reviews',
    'Cockpit.State.Products',
    'Cockpit.State.Campaigns',
    'Cockpit.State.Notifications',
    'Cockpit.Service.CampaignType',
    'Cockpit.State.Orders',
    'Cockpit.State.Search',
    'Cockpit.State.Site',
    'Cockpit.State.Settings',

    /** Internal Services **/
    'Cockpit.Service.User',
    'Cockpit.Service.Auth',
    'Cockpit.Service.Navigation',
    'Cockpit.Service.Product',
    'Cockpit.Service.Order',
    'Cockpit.Service.Settings',
    'Cockpit.Service.Language',
    'TS.Refunds.Service',
    'Cockpit.Service.Currency',
    'Cockpit.Service.FreeWrappingMethod',

    // Interceptors
    'Cockpit.HttpResponse.Interceptor',

    /** Internal Directives **/
    'Cockpit.Directive.InputFocus',
    'Cockpit.Directive.ConfirmClick',
    'Cockpit.Directive.IsNumber',
    'Cockpit.Directive.LocalizedInput',
    'dgda.directive.LoaderButton'
];

if (window.location.host.indexOf("mademyday") > -1) {
    modules.push('Cockpit.State.Mademyday');
}
if (window.location.host.indexOf("duglemmerdetaldrig") > -1 || window.location.host.indexOf("dgda") > -1 || window.location.host.indexOf("truestory") > -1 || window.location.host.indexOf("tsdk") > -1)  {
    console.log('loading dgda modules');
    modules.push('Cockpit.State.Dgda');
}

angular.module('Cockpit', modules)

.config(function($urlRouterProvider) {

    $urlRouterProvider.rule(function($injector, $location) {
        var path = $location.path(),
            normalized = path.toLowerCase();

        if (path != normalized) {
            path = normalized;
        }

        if (path[path.length - 1] !== '/') {
            path = path + "/";
        }

        return path;
    })

    /**
     * If no other routes match, simply redirect to the front page
     * (or change this to any other page, like a 404).
     */
    .otherwise('/login/');
})

.run(function($rootScope, $location, $http, User) {
    // Enable credientials (ie. cookies etc.) through the $http Angular Service
    $http.defaults.withCredentials = true;
})

.controller('MainController', function MainController($scope, $rootScope, Auth) {
    // Auth.renew();

    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
        if (angular.isDefined(toState.data.pageTitle)) {
            $scope.pageTitle = toState.data.pageTitle + ' | Truestory Cockpit';
            $scope.bodyClass = toState.data.bodyClass;
        }
    });
})

.controller('navigationCtrl', function navigationCtrl() {

})

.controller('headerCtrl', function($scope, $rootScope, $state, $stateParams, User, Admin) {

    // Setup defaults
    $scope.user = {
        name: User.getName()
    };
    $scope.username = $rootScope.Admin.username;
    $scope.suppliers = [];

    // Setup watcher to enable user changes
    $scope.$watch(function() {
        return User.isLogged();
    }, function(v) {
        if (!v) {
            return;
        }

        $scope.user = {
            name: User.getName()
        };
    });
})

.config(function($httpProvider) {
  $httpProvider.interceptors.push('HttpResponseInterceptor');
})

;
