angular.module('Cockpit.State.Dgda.Orders.Add', [
    'Cockpit.Service.Dgda.Order'
])

.config(function config($stateProvider) {
    $stateProvider.state('private.orders.add.dgda', {
        url: '/dgda',
        views: {
            'customProperties': {
                controller: 'CockpitOrderDgdaAddController',
                templateUrl: 'states/dgda/orders/custom-properties.tpl.html'
            }
        }
    })

    .state('private.orders.edit.dgda', {
        url: '/dgda',
        views: {
            customProperties: {
                controller: 'CockpitOrderDgdaAddController',
                templateUrl: 'states/dgda/orders/custom-properties.tpl.html'
            }
        }
    })

    ;
})

.run(function(Navigation) {
    // Replace generic "Add Order" menu
    // with the new one.
    Navigation.sidebar.register('private.orders', 'Orders', [{
        Label: "Add Order",
        State: 'private.orders.add.dgda',
        Groups: ["admin", "support"]
    }]);
})

.controller('CockpitOrderDgdaAddController', function CockpitOrderDgdaAddController($scope, Order) {
    if (!angular.isUndefined($scope.order.CustomProperties) && $scope.order.CustomProperties != null) {
        $scope.order.Personalization = {
            Message: $scope.order.CustomProperties.PersonalizedDate,
            Date: $scope.order.CustomProperties.PersonalizedMessage,
            Name: $scope.order.CustomProperties.PersonalizedName
        };
    }

    $scope.order.Personalization = Order.personalization({ OrderNumber: $scope.order.OrderNumber });
});
