angular.module('Cockpit.State.FreeWrapping', [
  'Cockpit.Service.Navigation',
])

  .config(function config($stateProvider) {
      $stateProvider.state('private.campaigns.freewrapping', {
          url: '/free-wrapping',
          controller: 'FreeWrappingController',
          templateUrl: 'states/campaigns/free-wrapping.tpl.html',
          data: {
              pageTitle: 'Free Wrapping'
          }
      })

      ;
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.campaigns', 'Free Wrapping', [{
      Label: "Free Wrapping",
      State: 'private.campaigns.freewrapping',
      Groups: ["admin", "marketing"]
    }]);
  })

  .controller('FreeWrappingController', function FreeWrappingController($scope, PimProduct, FreeWrappingMethod){
    $scope.loadingFreeWrappingMethods = true;
    $scope.freeWrappingMethod = null;
    $scope.isSaving = false;
    $scope.productSkus = [];
    FreeWrappingMethod.get(function(data){
      $scope.freeWrappingMethod = data;
      $scope.productSkus = data.skus.length > 0 ? data.skus.split(",") : [];
      $scope.loadingFreeWrappingMethods = false;
    }, function(err){
      console.log("error", err)
      $scope.freeWrappingMethod = null;
      $scope.loadingFreeWrappingMethods = false;
      $scope.productSkus = []
    })

    $scope.loadingProducts = true;
    $scope.selectedProduct = null;
    $scope.products = PimProduct.query(function() {
      $scope.loadingProducts = false;
    });

    $scope.selectProduct = function selectProduct($item, $model, $label){
      if($scope.productSkus.indexOf($item.sku) > -1){
        $scope.selectedProduct = null;
        return;
      }
      $scope.productSkus.push($item.sku);
      $scope.selectedProduct = null;
    };

    $scope.removeProduct = function removeProduct(index){
      $scope.productSkus.splice(index, 1);
    };

    $scope.save = function() {
      $scope.isSaving = true;
      FreeWrappingMethod.save({skus: $scope.productSkus.join(",")}, function(data){
        $scope.isSaving = false;
      })
    }
  })
;
