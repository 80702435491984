angular.module('Cockpit.State.Site.TopBars', [
  'ui.router',
  'Cockpit.State.Site.TopBars.List',
  'Cockpit.State.Site.TopBars.AddEdit'
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.site.topbars', {
      url: 'topbars/',
      template: "<ui-view />",
      data: {
        pageTitle: 'Topbars Settings'
      },
    })
  })
;
