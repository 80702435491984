var authService = angular.module('Cockpit.Service.Auth', ['ngResource']);

authService.factory('Auth', function ($resource, $rootScope, $location, $state, $cookies, User) {
	/**
	 * Listen for route changes and verify that the user is logged in
	 */
	$rootScope.prevState = null;

	$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
		// Handle trying to access the login screen, while being logged in
		if (toState.name == "public.login" && User.isLogged()) {
			event.preventDefault();
			$state.go('private.dashboard');

		// Handle trying to access a page, which has restricted access, while not being logged in
		} else if (angular.isDefined(toState.data.access) && toState.data.access.isPrivate && !User.isLogged()) {
			// reload the login route
			event.preventDefault();

			$rootScope.prevState = {
				state: toState,
				params: toParams
			};

			$state.go('public.login');
		} else if( angular.isDefined(toState.data.access) && toState.data.access.isPrivate && User.isLogged() && !User.can(toState.data.access.groups)){
			event.preventDefault();
			$state.go('private.dashboard');
		}
	});

	var authResource = $resource(Config.ApiV3 + 'auth/:Action', {}, {
		login: { method: 'POST', params: { Action: 'login' } },
		logout: { method: 'GET', params: { Action: 'logout' } },
		renew: { method: 'GET', params: { Action: 'renew' } }
	});

  var jwtTokenKey = "tsAdminJwt";

	var updateUser = function (data) {
		if (!angular.isDefined(data.isLogged) || !angular.isDefined(data.username) || !angular.isDefined(data.email) || !angular.isDefined(data.name)) {
			return false;
		}
    if (!angular.isDefined(data.token || data.token.length === 0)) {
      return false;
    }

		// Update logged in status
		User.setLogged(data.isLogged);
		User.setUsername(data.username);
		User.setEmail(data.email);
		User.setName(data.name);
		User.setGroup(data.group);

    //update token
    $cookies.put(jwtTokenKey, data.token);
		User.setToken(data.token);

		return true;
	};

	var handleSuccessLogin = function success(authResponse, success, error){
        if (!updateUser(authResponse)) {
            User.resetUser();
            if (typeof (error) === "function") {
                error();
            }
            return;
        }

        if (typeof (success) === "function") {
            success();
        }

        // Redirect to frontpage
        $state.go('private.dashboard');
    };

	return {
		login: function (data, success, error) {

			// Try to login
			authResource.login({}, data, function (authResponse) {
        handleSuccessLogin(authResponse, success, error);
			}, function () {
				User.resetUser();
				if (typeof (error) === "function") {
					error();
				}
			});
		},
		logout: function () {
			User.resetUser();
      $cookies.remove(jwtTokenKey);
			authResource.logout(function () {
				$state.go('public.login');
			});
		},
		renew: function () {
			return authResource.renew(function (authResponse) {
        if (!authResponse || !authResponse.isLogged) {
          authResource.logout(function () {
            $state.go('public.logout');
            $cookies.remove(jwtTokenKey);
          });
        }

        // Otherwise, continue
        User.setToken(authResponse.token);
				if (!updateUser(authResponse)) {
					return;
				}

				// Redirect to frontpage or prev state
				if ($rootScope.prevState !== null) {
					$state.go($rootScope.prevState.state, $rootScope.prevState.params);
				} else {
					$state.go('private.dashboard');
				}

			}, function (err) {
        $cookies.remove(jwtTokenKey);
        User.resetUser();
        // $state.go('public.logout');
			});

		}
	};
});
