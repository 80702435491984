angular.module('Cockpit.State.Settings.FooterWidget.AddEdit', [
  'ui.router',
  'Cockpit.Service.FooterWidgets'
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.settings.footer-widgets.add', {
      url: '/add',
      controller: 'FooterWidgetAddEditController',
      templateUrl: 'states/settings/footerwidgets/footer-widget-add-edit.tpl.html',
      data: {
        pageTitle: 'Add new Footer Widget'
      },
      resolve: {
        theInstance: ['$q', 'FooterWidgets', function ($q, FooterWidgets) {
          var d = $q.defer();

          var widget = new FooterWidgets();
          widget.id = 0;
          widget.heading = '';
          widget.languageCode = undefined;
          widget.footerLinks = [];

          d.resolve(widget);

          return d.promise;
        }],
        languages: resolvers.languagesResolver
      }
    })

    $stateProvider.state('private.settings.footer-widgets.edit', {
      url: '/{id}/edit',
      controller: 'FooterWidgetAddEditController',
      templateUrl: 'states/settings/footerwidgets/footer-widget-add-edit.tpl.html',
      data: {
        pageTitle: 'Edit Footer Widget'
      },
      resolve: {
        theInstance: ['$q', 'FooterWidgets', '$stateParams', function ($q, FooterWidgets, $stateParams) {
          var d = $q.defer();

          FooterWidgets.get({ id: $stateParams.id }, function (response) {
            d.resolve(response);
          }, function (err) {
            d.reject();
          })

          return d.promise;
        }],
        languages: resolvers.languagesResolver
      }
    })
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.settings', 'Footers', [
      {
        Label: 'Add Footer Widget',
        State: 'private.settings.footer-widgets.add',
        Groups: ['Admin']
      }
    ])
  })

  .controller('FooterWidgetAddEditController', function FooterWidgetAddEditController($scope, $state, FooterWidgets, theInstance, languages) {
    $scope.widget = theInstance;
    $scope.isProcessing = false;

    $scope.languages = languages;
    $scope.newFooterLink = {
      caption: '',
      link: '',
      shouldOpenInNewWindow: true
    };

    $scope.addFooterLink = function () {
      const footerLink = $scope.newFooterLink;

      if (footerLink.caption.length === 0 || footerLink.link.length === 0) {
        return;
      }

      $scope.widget.footerLinks.push(footerLink);

      $scope.newFooterLink = {
        caption: '',
        link: '',
        shouldOpenInNewWindow: true
      }
    }

    $scope.removeFooterLink = function (footerLink) {
      const index = $scope.widget.footerLinks.indexOf(footerLink);
      $scope.widget.footerLinks.splice(index, 1);
    }

    $scope.onSubmit = function () {
      $scope.isProcessing = true;

      if ($scope.widget.id < 1) {
        $scope.widget.$save();
        $scope.isProcessing = false;
        $state.go('private.settings.footer-widgets', {}, { reload: true });
      }
      else {
        $scope.widget.$update();
        $scope.isProcessing = false;
        $state.go('private.settings.footer-widgets', {}, { reload: true });
      }
    }
  })
