angular.module('Cockpit.State.CallOuts.AddEdit', [
  'ui.router',
  'Cockpit.Service.CallOuts',
  'Cockpit.Controller.GenericAddEdit'
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.products.call-outs.add', {
      url: '/add',
      controller: 'CallOutAddEditController',
      templateUrl: 'states/products/call-outs/add-edit.tpl.html',
      data: {
        pageTitle: 'Add new Call-Out'
      },
      resolve: {
        theInstance: ['$q', 'CallOut', function ($q, CallOut) {
          var d = $q.defer();
          var callOut = new CallOut();

          callOut.id = 0;
          callOut.type = {};
          callOut.languageCodes = [];
          callOut.contexts = [];
          callOut.title = {};
          callOut.text = {};
          callOut.bullets = {};
          callOut.link = {};
          callOut.productSkus = [];

          d.resolve(callOut);
          return d.promise;
        }],
        languages: resolvers.languagesResolver,
        callOutTypes: resolvers.callOutTypesResolver
      }
    })

    $stateProvider.state('private.products.call-outs.edit', {
      url: '/{id}/edit',
      controller: 'CallOutAddEditController',
      templateUrl: 'states/products/call-outs/add-edit.tpl.html',
      data: {
        pageTitle: 'Edit Call-Out'
      },
      resolve: {
        theInstance: ['$q', 'CallOut', '$stateParams', function ($q, CallOut, $stateParams) {
          var d = $q.defer();

          CallOut.get({ id: $stateParams.id }, function (response) {
            response.start = new Date(response.start);
            response.end = new Date(response.end);
            d.resolve(response);
          }, function (err) {
            d.reject();
          })

          return d.promise;
        }],
        languages: resolvers.languagesResolver,
        callOutTypes: resolvers.callOutTypesResolver
      }
    })
  })

  .controller('CallOutAddEditController', function CallOutAddEditController($scope, $state, theInstance, callOutTypes, CallOut, languages, PimProduct) {
    $scope.callOut = theInstance;
    $scope.callOutTypes = callOutTypes;

    $scope.callOutContexts = ["shop", "booking-login"];

    if(!$scope.callOut.contexts || !$scope.callOut.contexts.length){
      $scope.callOut.contexts = ["shop", "booking-login"];
    }

    $scope.languages = languages;
    $scope.activeLanguage = $scope.languages[0].languageCode;

    if(theInstance.languageCodes.length > 0){
      $scope.activeLanguage = theInstance.languageCodes[0];
    }

    $scope.showLinkControls = {};
    $scope.newBullet = {};
    angular.forEach(languages, function(v){
      var lc = v.languageCode.toLowerCase();
      $scope.showLinkControls[v.languageCode] = (angular.isDefined(theInstance.link[lc]) && theInstance.link[lc].uri.length > 0)
    })


    $scope.resetCalloutLink = function() {
      $scope.callOut.link = {};
    }

    var errFunction = function(err){
      $scope.isProcessing = false;
      console.log(err);

      if(err.status === 400){
        alert(err.data);
      }
    };

    $scope.isProcessing = false;

    $scope.onSubmit = function () {
      $scope.isProcessing = true;

      if(!$scope.callOut.contexts || !$scope.callOut.contexts.length){
        $scope.callOut.contexts = $scope.callOutContexts;
      }

      if ($scope.callOut.id < 1) {
        CallOut.save($scope.callOut, function(){
          $scope.isProcessing = false;
          $state.go('private.products.call-outs', {}, { reload: true });
        }, errFunction);
      }
      else {
        CallOut.update($scope.callOut, function(){
          $scope.isProcessing = false;
          $state.go('private.products.call-outs', {}, { reload: true });
        }, errFunction);
      }
    };

    $scope.toggleContext = function toggleContext(context){
      var index = $scope.callOut.contexts.indexOf(context);
      if (index === -1) {
        $scope.callOut.contexts.push(context);
      } else {
        $scope.callOut.contexts.splice(index, 1);
      }
    }

    $scope.toggleLanguage = function toggleLanguage(language){
      var index = $scope.callOut.languageCodes.indexOf(language.languageCode);
      if (index === -1) {
        $scope.callOut.languageCodes.push(language.languageCode);
      }else{
        $scope.callOut.languageCodes.splice(index, 1);
      }
    }

    $scope.setLanguage = function setLanguage(language){
      $scope.activeLanguage = language.languageCode;
    }

    $scope.addBullet = function addBullet(languageCode){
      if(!angular.isDefined($scope.callOut.bullets[languageCode])){
        $scope.callOut.bullets[languageCode] = [];
      }

      $scope.callOut.bullets[languageCode].push($scope.newBullet[languageCode]);
      $scope.newBullet[languageCode] = "";
    }

    $scope.removeBullet = function removeBullet(index, languageCode){
      $scope.callOut.bullets[languageCode].splice(index, 1);
    }

    $scope.removeProduct = function removeProduct(index){
      $scope.callOut.productSkus.splice(index, 1);
    }


    $scope.loadingProducts = true;
    $scope.selectedProduct = null;
    $scope.selectProduct = function selectProduct($item, $model, $label){
      if($scope.callOut.productSkus.indexOf($item.sku) > -1){
        $scope.selectedProduct = null;
        return;
      }
      $scope.callOut.productSkus.push($item.sku);
      $scope.selectedProduct = null;
    };
    $scope.products = PimProduct.query(function() {
      $scope.loadingProducts = false;
    });

  })
;
