
angular.module('Cockpit.Directive.ConfirmClick', [])
    // I define an asynchronous wrapper to the native prompt() method. It returns a
        // promise that will be "resolved" if the user submits the prompt; or will be
        // "rejected" if the user cancels the prompt.
        .factory(
            "prompt",
            function( $window, $q ) {
                // Define promise-based prompt() method.
                function prompt( message, defaultValue ) {
                    var defer = $q.defer();
                    // The native prompt will return null or a string.
                    var response = $window.prompt( message, defaultValue );
                    if ( response === null ) {
                        defer.reject();
                    } else {
                        defer.resolve( response );
                    }
                    return( defer.promise );
                }
                return( prompt );
            }
        );