angular.module('Cockpit.State.Settings.MailChimp.ECommerce', [
    'ui.router',
    'Cockpit.State.Settings',
    'Cockpit.Service.MailChimp.ECommerce'
])

    .config(function config($stateProvider) {
        $stateProvider.state('private.settings.mc-ecommerce', {
            url: 'mailchimp/e-commerce/',
            controller: 'MailchimpEcommerceControllers',
            templateUrl: 'states/settings/mailchimp/ecommerce/settings.tpl.html',
            data: {
                pageTitle: 'MailChimp E-Commerce Settings'
            }
        })

        ;
    })

    .run(function (Navigation) {
        Navigation.sidebar.register('private.settings', 'MailChimp', [
            {
                Label: "E-Commerce",
                State: 'private.settings.mc-ecommerce',
                Groups: ["admin"]
            }
        ]);
    })

    .controller('MailchimpEcommerceControllers', function MailchimpEcommerceControllers($scope, MCStore, MCList, MCSettings, MCStoreManagement) {
        $scope.settings = MCSettings.get();

        $scope.lists = MCList.query();
        $scope.stores = MCStore.query();

        $scope.store = {
            Id: '',
            Name: '',
            currency_code: '',
            money_format: '',
            Address: {
                Street: '',
                City: '',
                Zipcode: '',
                Country: ''
            }
        };

        $scope.management = {
            StoreId: '',
            IsDeletingProducts: false,
            IsSyncingProducts: false
        };

        $scope.deleteAll = function(){
            if($scope.management.StoreId == ""){
                return;
            }

            $scope.management.IsDeletingProducts = true;

            MCStoreManagement.deleteProducts($scope.management.StoreId, function(){
                $scope.management.IsDeletingProducts = false;
            }, function(){
                alert("Could not delete all products.");
                $scope.management.IsDeletingProducts = false;
            });
        };

        $scope.syncAll = function(){
            if($scope.management.StoreId == ""){
                return;
            }

            $scope.management.IsSyncingProducts = true;

            MCStoreManagement.syncProducts($scope.management.StoreId, function(){
                $scope.management.IsSyncingProducts = false;
            }, function(){
                alert("Could not delete all products.");
                $scope.management.IsSyncingProducts = false;
            });
        };

        $scope.processSaveStore = function processSaveStore(){
            MCStore.save($scope.store, function(){
                $scope.stores = MCStore.query();
            });
        };

        $scope.saveSettings = function saveSettings(){
            MCSettings.save($scope.settings);
        }
    })

;