angular
  .module("Cockpit.State.Search.Details", [
    "ui.router",
    "Cockpit.Service.Search",
    "Cockpit.Service.OrderNote",
    "Cockpit.Service.Dgda.OpenGiftCards",
    "Cockpit.Service.Notification",
    "Cockpit.Directive.BookingDetails",
    "Cockpit.Service.Extensions",
  ])

  .config(function config($stateProvider) {
    $stateProvider
      .state("private.search-details", {
        url: "/:orderNumber/details",
        resolve: {
          orderData: [
            "$q",
            "$stateParams",
            "Search",
            function resolveOrderData($q, $stateParams, Search) {
              var d = $q.defer();
              Search.query(
                { query: $stateParams.orderNumber },
                function searchSuccess(res) {
                  if (res.length > 0) {
                    d.resolve(res[0]);
                  } else {
                    d.reject();
                  }
                },
                function searchError(err) {
                  console.log("Error on search resolve: ", err);
                  d.reject();
                }
              );

              return d.promise;
            },
          ],
          productData: [
            "$q",
            "orderData",
            "ProductV2",
            function($q, orderData, ProductV2) {
              var orderSku = orderData.OrderLines ? orderData.OrderLines[0].Sku : null;
              if( !orderSku ||  "99999" === orderSku ||  "S-00001" === orderSku ) {
                return;
              }

              var d = $q.defer();
              ProductV2.getBySku(
                {
                  sku: orderData.OrderLines[0].Sku,
                  languageCode: orderData.LanguageCode ? orderData.LanguageCode.toLowerCase() : "da-dk"
                },
                function(data) {
                  d.resolve(data);
                },
                function(err) {
                  console.log("Error getting product data => ", err);
                  d.resolve(null);
                }
              );

              return d.promise;
            }
          ]
        },
        controller: "CockpitSearchDetailsController",
        templateUrl: "states/search/details.tpl.html",
        data: {
          pageTitle: "Details",
        },
      });
  })

.controller(
  "CockpitSearchDetailsController",
  function CockpitSearchDetailsController(
    $filter,
    $scope,
    $timeout,
    orderData,
    productData,
    Notification,
    OrderNote,
    OpenGiftCard,
    OpenGiftCardOrder,
    Extensions
  ) {
    //Scroll to top on enter
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    $scope.order = orderData;
    var didFetchOrderNotes = false;
    $scope.notifications = [];
    $scope.extensions = [];
    $scope.isTruestoryGiftcardOrder = orderData.OrderLines && orderData.OrderLines[0].Sku === "99999";
    $scope.isExtensionFeeOrder = orderData.OrderLines && orderData.OrderLines[0].Sku === "S-00001";
    $scope.showBookingDetails = !$scope.isTruestoryGiftcardOrder && !$scope.isExtensionFeeOrder;

    getNotifications();
    function getNotifications() {
      Notification.query(
        {
          orderNumber: orderData.OrderNumber,
          PageNumber: 1,
          excludeFailedCount: true
        },
        function (res) {
          $scope.errorMessage = null;
          $scope.notifications =  JSON.parse(angular.toJson(res));
          refreshOrderHistory(true);
        },
        function(err) {
          $scope.errorMessage = "Notifications are missing in order history. Try refreshing again.";
          console.log('Error getting notifications => ', err)
        }
      );
    }
    if( !$scope.isExtensionFeeOrder ) {
      getOrderExtensions();
    }

    function getOrderExtensions() {
      Extensions.query(
        { orderNumber: orderData.OrderNumber },
        function (res) {
          $scope.errorMessage = null;
          $scope.extensions = res.items ? res.items : [];
          refreshOrderHistory(true);
        },
        function (err) {
          $scope.errorMessage = "Extensions are missing in order history. Try refreshing again.";
          console.log("Error getting extensions => ", err);
        }
      );
    }

    $scope.getDiscountDescription = function(itemName, description) {
      return itemName !== "" || description !== "" ? "(" + itemName +  (description !== "" ? " - " + description : "") +")" : "";
    }

    $scope.getDroppointAddress = function(){

      if(!$scope.order.Shipping || !$scope.order.Shipping.ShipmentDropPoint){
        return "";
      }

      var droppoint = $scope.order.Shipping.ShipmentDropPoint;

      var droppointAddress = "";

      if(droppoint.Address1){
        droppointAddress += droppoint.Address1;

        if(droppoint.Address2){
          droppointAddress += (" " + droppoint.Address2);
        }

      }

      if(droppoint.Zip){
        droppointAddress += (", " + droppoint.Zip);
      }

      if(droppoint.City){
        droppointAddress += (" " + droppoint.City);
      }

      if(droppoint.Country){
        droppointAddress += (", " + droppoint.Country);
      }

      return droppointAddress;
    }

    $scope.getShopDistance = function(){

      if(!$scope.order.Shipping || !$scope.order.Shipping.ShipmentDropPoint || !$scope.order.Shipping.ShipmentDropPoint.Distance){
        return "";
      }

      return "(" + order.Shipping.ShipmentDropPoint.Distance.toFixed(1) + ")" + " km";
    }

    $scope.formatedRefundableUntil = $scope.order.Properties.RefundableUntil !== '-' ? new Date($scope.order.Properties.RefundableUntil).toLocaleDateString("da-dk") : null;

    //Copy order id
    $scope.orderIdCopied = false;
    $scope.copyOrderId = function(id) {
      $scope.orderIdCopied = true;
      navigator.clipboard.writeText(id.toUpperCase());
      $timeout(function() {
        $scope.orderIdCopied = false;
      },500)
    };
    var participantTypes = productData && productData.participantTypes;

    $scope.orderHasParticipantTypes = participantTypes && participantTypes.length > 1 && null !== participantTypes[0].sku;

    $scope.getParticipantTypeName = function(variantSku, quantity) {
      var filteredParticipantTypes  = participantTypes.filter(function(participant) {
        return participant.sku === variantSku;
      });
      return quantity > 1 ? filteredParticipantTypes[0].namePlural : filteredParticipantTypes[0].nameSingle;
    }

    // $scope.isExchangeOrder = orderData.PaymentMethod && orderData.PaymentMethod === "Exchange";

    // if( $scope.isExchangeOrder ) {
    //   $scope.exchangeVoucherPdfUrl = "https://static." + stagingUrlPrefix + "truestory.com/pdf/exchange-vouchers/" + orderData.Properties.OldOrder + ".pdf";
    // }

    // Product price
    $scope.productTotal = $scope.order.OrderLines.reduce(function(sum, current) {
      return sum + current.Total;
    }, 0);
    $scope.productVat = $scope.order.OrderLines.reduce(function(sum, current) {
      return sum + ($scope.orderHasParticipantTypes ? (current.VatTotal) : current.Vat);
    }, 0);
    $scope.productTotalQuantity = $scope.order.OrderLines.reduce(function(sum, current) {
      return sum + current.Quantity;
    }, 0);

    $scope.getDiscountAmount = function(discount, includingVat) {
      if( includingVat ) {
        return discount.Description.indexOf("Voucher-") > -1 ? discount.Amount : 0;
      }
      return discount.Description.indexOf("Voucher-") > -1 ? 0 : discount.Amount;
    }

    $scope.getTSGiftcardVoucher = function(discount) {
      if( discount.Description.indexOf("Voucher-") > -1) {
        var voucherCode = discount.Description.replace("Voucher-", "");
        return "(" + discount.Description + ")";
      }
      return "";
    }

    $scope.totalDiscountsExVat = 0;
    $scope.totalDiscountsIncVat = 0;
    $scope.tsGiftcardApplied = false;
    $scope.totalDiscountVat = 0;

    if( orderData.Discounts ) {
      orderData.Discounts.forEach(function(discount) {
        if( discount.Description.indexOf("Voucher-") > -1 ) {
          $scope.totalDiscountsIncVat += discount.Amount;
          $scope.tsGiftcardApplied = true;
          return;
        }
        $scope.totalDiscountsExVat += discount.Amount;
      });

      if( !$scope.tsGiftcardApplied ) {
        $scope.totalDiscountVat = $scope.productVat + $scope.order.Shipping.Vat - $scope.order.Vat;
        $scope.totalDiscountsIncVat = $scope.totalDiscountsExVat + $scope.totalDiscountVat;
      } else {
        $scope.totalDiscountVat = $scope.productVat + $scope.order.Shipping.Vat - $scope.order.Vat;
        $scope.totalDiscountsExVat = $scope.totalDiscountsIncVat - $scope.totalDiscountVat;
      }
    }

    refreshOrderHistory(false);

    function refreshOrderHistory(shouldSkipFetchingOrderNotes) {
      if (shouldSkipFetchingOrderNotes && didFetchOrderNotes) {
        $scope.orderLogGroups = concatOrderNotes($scope.orderNotes, $scope.order.Audits, $scope.notifications, $scope.extensions);
        return;
      }

      $scope.orderNotes = OrderNote.query({
        orderNumber: orderData.OrderNumber,
      }, function successCb(response) {
        $scope.errorMessage = null;
        didFetchOrderNotes = true;
        $scope.orderLogGroups = concatOrderNotes(response, $scope.order.Audits, $scope.notifications, $scope.extensions);
      }, function errorCb(err) {
        console.log("Error getting notes => ", err);
        $scope.errorMessage = "Some order notes are missing in order history. Try refreshing again.";
        $scope.orderLogGroups = concatOrderNotes([], $scope.order.Audits, $scope.notifications, $scope.extensions);
      });

    }

    function concatOrderNotes(orderNotes, auditHistory, notifications, extensions) {
      var orderLogs = orderNotes
      .map(function (note) {
        return {
          createdAt: note.createdAt,
          content: note,
          type: "note",
        };
      })
      .concat(
        auditHistory.map(function (audit) {
          return {
            createdAt: audit.CreatedOn,
            content: audit,
            type: "audit",
          };
        })
      )
      .concat(
        notifications.map(function (notification) {
          return {
            createdAt: notification.createdAt,
            content: notification,
            type: "notification",
          };
        })
      )
      .concat(
        extensions.map(function (extension) {
          return {
            createdAt: extension.appliedAt,
            content: extension,
            type: "extension",
          };
        })
      )
      .sort(function (a, b) {
        if (new Date(b.createdAt) > new Date(a.createdAt)) {
          return 1;
        }
        return -1;
      });


      return groupOrderLogsByDate(orderLogs);
    }

    function groupOrderLogsByDate(logs) {
      var group = {};
      logs.forEach(function(log){
        var normalizedLogDate = new Date(log.createdAt).setHours(0, 0, 0, 0);
        if( undefined === group[normalizedLogDate] ) {
          group[normalizedLogDate] = [];
          group[normalizedLogDate].push(log);
        } else {
          group[normalizedLogDate].push(log);
        }
      });
      return group;
    }

    $scope.getLogGroupHeading = function(date) {
      var formattedDate = $filter("date")(date, "dd. MMM yyyy");
      var now = new Date();
      var yesterday = new Date();
      yesterday = yesterday.setDate(yesterday.getDate() - 1);
      if( $filter("date")(now, "dd. MMM yyyy") ===  $filter("date")(date, "dd. MMM yyyy")) {
        return "Today";
      }
      if( $filter("date")(yesterday, "dd. MMM yyyy") ===  $filter("date")(date, "dd. MMM yyyy")) {
        return "Yesterday";
      }
      if( now.getFullYear() === new Date(formattedDate).getFullYear() ) {
        return $filter("date")(date, "dd. MMM");
      }
      return formattedDate;
    }

    $scope.giftCard = null;
    $scope.giftCardLoading = true;
    $scope.isGiftcardOrder =
      $scope.order.OrderLines && "99999" === $scope.order.OrderLines[0].Sku;
    if ($scope.isGiftcardOrder) {
      var giftcardLineItem = $scope.order.OrderLines[0];
      var voucherCode = giftcardLineItem.Properties.voucherCode;
      $scope.isGettingOrders = true;
      $scope.giftCard = OpenGiftCard.get(
        { code: voucherCode },
        function () {
          $scope.giftCardLoading = false;
          $scope.giftCardOrders = OpenGiftCardOrder.query(
            { code: voucherCode },
            function () {
              $scope.isGettingOrders = false;
            }
          );
        },
        function (err) {
          $scope.giftCard = null;
          if (err.status === 404) {
            $scope.errorData = {
              Title: "The code was not found / is not valid.",
            };
          }
          $scope.giftCardLoading = false;
        }
      );
    }

    $scope.scrollToOgc = function () {
      var ogcElement = document.getElementById("order-open-giftcard");
      if (ogcElement) {
        ogcElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    };

    $scope.scrollTo = function(targetId) {
      var element = document.getElementById(targetId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }

    $scope.newOrderNote = null;
    $scope.saveOrderNote = function () {
      OrderNote.save(
        { orderNumber: orderData.OrderNumber },
        {
          note: $scope.newOrderNote,
        },
        function (res) {
          refreshOrderHistory(false);
          alert("Note saved!");
          $scope.newOrderNote = null;
        },
        function (err) {
          alert("Error saving note: ", err);
        }
      );
    };

    $scope.deleteOrderNote = function (orderNote) {
      if (
        !confirm("Are you sure you want to delete: " + orderNote.note + "?")
      ) {
        return;
      }

      OrderNote.delete(
        { orderNumber: orderData.OrderNumber, id: orderNote.id },
        function (res) {
          refreshOrderHistory(false);
        },
        function (err) {
          alert("Could not delete order note: ", err);
        }
      );
    };
  }
);
