angular.module('Cockpit.State.Mademyday.Export.ReviewData', [
  'ui.router',
  'Cockpit.Service.Mademyday.Export'
])

.config(function ($stateProvider) {
	$stateProvider.state('private.products.reviewdata', {
		url: '/review-data',
		views: {
			"@private": {
				controller: 'CockpitExportReviewDataController',
				templateUrl: 'states/mademyday/products/review-data/reviewdata.tpl.html'
			}
		},
		data: {
			pageTitle: 'Export Review Data'
		}
	});
})

.run(function (Navigation) {
	Navigation.sidebar.register('private.products', 'Reviews', [
		{
			Label: 'Export',
			State: 'private.products.reviewdata',
			Groups: ["admin", "support"]
		}
	]);
})

.controller('CockpitExportReviewDataController', function CockpitExportReviewDataController($scope, MmdExport) {
	$scope.model = {
		from: '',
		to: ''
	};

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.isProcessing = false;

	$scope.process = function GetReviewData() {
		$scope.isProcessing = true;
		$scope.alert.Show = false;

		$scope.data = MmdExport.query($scope.model, function (d) {
			$scope.isProcessing = false;
			if (d.length === 0) {
				$scope.alert = {
					Type: 'warning',
					Show: true,
					Message: 'No reviews found.'
				};
			}
		});
	};

	$scope.data = [];
})

;
