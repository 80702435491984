angular
  .module("Cockpit.Directive.TopbarList", [
    'Cockpit.Service.Topbars'
  ])
  .directive("tsTopbarList", function ($state, TopbarService) {
    return {
      restrict: "E",
      scope: {
        topbars: "=",
        isLoading: "=",
        isProcessing: "=",
        refresh: "&",
      },
      templateUrl: "directives/topbar-list/list.tpl.html",
      link: function ($scope) {
        $scope.edit = function(topbar) {
          $scope.isProcessing = true;
          topbar.processing = true;
          $state.go("private.site.topbars.edit", {id: topbar.id});
        }
        $scope.delete = function (topbar) {
          topbar.processing = true;
          TopbarService.remove({ id: topbar.id }, function () {
            $scope.refresh();
            topbar.processing = false;
          });
        };
      },
    };
  });
