angular.module('Cockpit.State.Dgda.Refunds.Manual', [
  'ui.router',
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.orders.manualrefund', {
      url: '/refunds/:id/manual',
      views: {
        '@private': {
          controller: 'ManualRefundController',
          templateUrl: 'states/dgda/orders/refunds/manualrefund.tpl.html'
        }
      },
      data: {
        pageTitle: 'Orders'
      }
    });
  })

  .controller('ManualRefundController', function ($scope, Refund, $stateParams) {
    $scope.refund = {
      id: $stateParams.id,
      regNumber: null,
      accountNumber: null,
      useSameAccount: false,
      newStatus: 1
    };
    $scope.infoMessage = null;
    $scope.hasError = false;

    $scope.update = function () {
      Refund.update($scope.refund, function (_res) {
        $scope.infoMessage = 'Refund updated!';
        $scope.hasError = false;
      }, function () {
        $scope.infoMessage = 'Could not update refund...';
        $scope.hasError = true;
      });
    }
  });