angular.module('Cockpit.State.CallOuts', [
  'ui.router',
  'Cockpit.Service.CallOuts',
  'Cockpit.Directive.CallOutsTable',
  'Cockpit.State.CallOuts.AddEdit'
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.products.call-outs', {
      url: 'call-outs',
      views: {
        '@private': {
          controller: 'CallOutsController',
          templateUrl: 'states/products/call-outs/list.tpl.html'
        }
      },
      data: {
        pageTitle: 'Call-outs'
      },
      resolve: {
        activeCallOuts: resolvers.callOutsResolver('active'),
        pendingCallOuts: resolvers.callOutsResolver('pending'),
        inactiveCallOuts: resolvers.callOutsResolver('inactive'),
      }
    })
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.products', 'General', [
      {
        Label: 'Call-Outs',
        State: 'private.products.call-outs',
        Groups: ['admin']
      }
    ])
  })

  .controller('CallOutsController', function CallOutsController($scope, activeCallOuts, pendingCallOuts, inactiveCallOuts, CallOut) {
    $scope.activeCallOuts = activeCallOuts;
    $scope.pendingCallOuts = pendingCallOuts;
    $scope.inactiveCallOuts = inactiveCallOuts;

    $scope.refreshLists = function (){
      $scope.activeCallOuts = CallOut.query({status: 'active'});
      $scope.pendingCallOuts = CallOut.query({status: 'pending'});
      $scope.inactiveCallOuts = CallOut.query({status: 'inactive'});
    }
  })
