var resolvers = window.resolvers || {};
resolvers.callOutsResolver = function(status){
  return ['$q', 'CallOut', function($q, CallOut){
    var d = $q.defer();

    CallOut.query({status: status}, function (res) {
      d.resolve(res);
    }, function (err) {
      d.reject();
      alert("Something didn't work out right - please check your console for any errors")
      console.log(err);
    });

    return d.promise;
  }]
};
