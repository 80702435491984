angular.module('Cockpit.State.Dgda.Discounts', [
  'ui.router',
  'Cockpit.Service.Dgda.OpenGiftCards',
  'Cockpit.Service.Dgda.DiscountCodes'
])

  .config(function ($stateProvider) {
    $stateProvider.state('private.orders.open-gift-cards', {
      url: '/open-gift-cards',
      views: {
        "@private": {
          controller: 'CockpitDgdaOpenGiftCardsController',
          templateUrl: 'states/dgda/orders/discounts/open-gift-cards.tpl.html'
        }
      },
      data: {
        pageTitle: 'Open Gift Card Lookup'
      }
    })
      .state('private.orders.discount-lookup', {
        url: '/discount-lookup',
        views: {
          "@private": {
            controller: 'CockpitDgdaDiscountLookupController',
            templateUrl: 'states/dgda/orders/discounts/discount-lookup.tpl.html'
          }
        },
        data: {
          pageTitle: 'Discount Lookup'
        }
      });
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.orders', 'Discounts', [
      {
        Label: 'Open Gift Cards',
        State: 'private.orders.open-gift-cards',
        Groups: ["admin"]
      },{
        Label: 'Voucher Codes',
        State: 'private.orders.discount-lookup',
        Groups: ["admin"]
      }
    ]);
  })

  .controller('CockpitDgdaOpenGiftCardsController', function CockpitDgdaOpenGiftCardsController($scope, OpenGiftCard, OpenGiftCardOrder) {
    $scope.errorData = null;
    $scope.giftCard = null;
    $scope.isGettingOrders = false;
    $scope.editingValue = false;

    $scope.search = {
      Code: ""
    };

    $scope.isSearching = false;

    $scope.doSearch = function doSearch(){
      // Reset variables
      $scope.errorData = null;
      $scope.giftCard = null;
      $scope.isSearching = true;
      $scope.editingValue = false;

      $scope.isGettingOrders = true;

      // Query gift card
      $scope.giftCard = OpenGiftCard.get({code: $scope.search.Code}, function(){
        $scope.giftCardOrders = OpenGiftCardOrder.query({code: $scope.search.Code}, function(){
          $scope.isGettingOrders = false;
        });

        $scope.isSearching = false;
      }, function(err){
        $scope.giftCard = null;
        if(err.status === 404){
          $scope.errorData = {
            Title: "The code was not found / is not valid."
          }
        }
        $scope.isSearching = false;
        $scope.isGettingOrders = false;
      });
    };
  })

  .controller('CockpitDgdaDiscountLookupController', function CockpitDgdaDiscountLookupController($scope, DiscountCode, DiscountCodeOrder) {
    $scope.errorData = null;
    $scope.voucher = null;
    $scope.isGettingOrders = false;
    $scope.editingValue = false;
    $scope.isGettingOrders = true;
    $scope.isSaving = false;

    $scope.search = {
      Code: ""
    };

    $scope.isSearching = false;

    $scope.saveVoucher = function saveVoucher(){
      $scope.isSaving = true;
      $scope.errorData = null;

      $scope.voucher.$save(function(){
        $scope.isSaving = false;
      }, function(err){
        $scope.errorData = {
          Title: "Unable to save voucher!",
          Description: err.message
        };
        $scope.isSaving = false;
      })
    };

    $scope.doSearch = function doSearch() {
      // Reset variables
      $scope.errorData = null;
      $scope.voucher = null;
      $scope.isSearching = true;
      $scope.editingValue = false;

      $scope.isGettingOrders = true;
      // Query gift card
      $scope.voucher = DiscountCode.get({code: $scope.search.Code}, function () {
        $scope.voucherOrders = DiscountCodeOrder.query({code: $scope.search.Code}, function () {
          $scope.isGettingOrders = false;
        });

        $scope.isSearching = false;
      }, function (err) {
        $scope.voucher = null;
        $scope.voucherOrders = [];
        if (err.status === 404) {
          $scope.errorData = {
            Title: "The code was not found / is not valid."
          }
        }
        $scope.isSearching = false;
        $scope.isGettingOrders = false;
      });
    }
  })
;
