var currencyDkk = angular.module('Cockpit.Filter.FilterByDate', []);

currencyDkk.filter('filterByDate', function () {
    return function filterByDate(items, prop, fromDate, toDate, includeNullDate) {
      var result = [];

      if (!fromDate) {
        fromDate = new Date(2010, 6,31);
      }
      if(!toDate){
        toDate = new Date((new Date()).getFullYear()+10, 1, 1);
      }

      angular.forEach(items, function (obj) {
        if (!items) {
          return [];
        }

        var objDate = obj[prop];
        if(!angular.isDate(objDate) && !angular.isUndefined(objDate) && objDate !== null){
          console.log("Not date - start parse", objDate);
          objDate = new Date(objDate);
        }

        if (!angular.isDate(objDate)) {
          if (!includeNullDate) {
            return;
          }
        } else if (!(fromDate <= objDate <= toDate)) {
          return;
        }
        result.push(obj);
      });

      return result;
    }
});
