angular.module('Cockpit.State.Mademyday', [
  'ui.router',
  'Cockpit.State.Mademyday.Filters',
  'Cockpit.State.Mademyday.BatchSend',
  'Cockpit.State.Mademyday.Texts',
  'Cockpit.State.Mademyday.Export.Delivery',
  'Cockpit.State.Mademyday.Export.ReviewData',
  'Cockpit.State.Mademyday.Parse',
  'Cockpit.State.Mademyday.Krifa'
])

.config(function config($stateProvider) {
	$stateProvider
		
		.state('private.mademyday', {
			abstract: true,
			template: '<ui-view/>'
		})
	;
})
;