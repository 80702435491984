angular.module('Cockpit.Service.Dgda.Order', ['ngResource'])
    .factory('Order', ['$resource',
        function($resource) {
            return $resource(Config.Api + 'dgdaorderapi/:action', {}, {
                get: { params: { action: 'Get' } },
                getDetailed: { params: { action: 'GetDetailed' } },
                save: { method: 'POST', params: { action: 'Save' } },
                query: { params: { action: 'All' }, isArray: true },
                bulkSave: { method: 'POST', params: { action: 'BulkSave' } },
                queryAnalogShipped: { params: { action: 'AnalogShipped' }, isArray: true },
                orderStatuses: { params: { action: 'OrderStatuses' }, isArray: true },
                personalization: { method: 'GET', params: { action: 'Personalization' } }
            });
        }
    ]);
