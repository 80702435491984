var resolvers = window.resolvers || {};
resolvers.callOutTypesResolver = ['$q', 'CallOutType', function($q, CallOutType){
  var d = $q.defer();

  CallOutType.query(function (res) {
    d.resolve(res);
  }, function (err) {
    d.reject();
    alert("Something didn't work out right - please check your console for any errors")
    console.log(err);
  });

  return d.promise;
}];
