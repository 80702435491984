
// available statuses for printfile exports

angular.module('Cockpit.PrintFile.ExportStatus', [])
  .constant('PrintFileConstants', (function () {
    var exportStatus = {
      Unfinished: 'Unfinished',
      CreatingShipments: 'CreatingShipments',
      ReadyForExport: 'ReadyForExport',
      Exported: 'Exported',
      Failed: 'Failed',
      AddingOrders: 'AddingOrders',
      OrdersAdded: 'OrdersAdded',
      InitializationFailed: 'InitializationFailed'
    };

    var orderExportStatus = {
      Added: 'Added',
      CreatingShipment: 'CreatingShipment',
      CreatingShipmentSuccess: 'CreatingShipmentSuccess',
      CreatingShipmentFailed: 'CreatingShipmentFailed',
      Exported: 'Exported',
      Excluded: 'Excluded',
      CreatingShippingLabel: 'CreatingShippingLabel',
      CreatingShippingLabelFailed: 'CreatingShippingLabelFailed'
    }

    function getDisplayStatus(status) {

      // main log statues
      switch (status) {
        case 'Unfinished':
        case 'CreatingShipments':
        case 'AddingOrders': {
          return 'Loading';
        }
        case 'ReadyForExport':
        case 'OrdersAdded': {
          return 'Ready';
        }
        case 'Failed':
        case 'InitializationFailed': {
          return 'Failed';
        }
        default: {
          break;
        }
      }

      // order log statues
      switch (status) {
        case 'CreatingShipment':
        case 'CreatingShippingLabel': {
          return 'Loading';
        }
        case 'Added': {
          return 'Ready';
        }
        case 'CreatingShipmentSuccess': {
          return 'Done';
        }
        case 'CreatingShipmentFailed':
        case 'CreatingShippingLabelFailed': {
          return 'Failed';
        }
        default: {
          break;
        }
      }

      // just return the actual status if none of above matched
      return status;
    }

    function getColorClass(status) {
      switch (status) {
        case 'OrdersAdded': case 'ReadyForExport': case 'Exported': case 'CreatingShipmentSuccess': {
          return 'green';
        }
        case 'Unfinished': case 'CreatingShipments': case 'AddingOrders': case 'Added': {
          return 'orange';
        }
        case 'Failed': case 'InitializationFailed': case 'CreatingShipmentFailed': case 'CreatingShippingLabelFailed': {
          return 'red';
        }
        default: {
          return 'black';
        }
      }
    }

    function showLoadingBubbles(status) {
      switch (status) {
        case 'Unfinished':
        case 'CreatingShipment':
        case 'CreatingShippingLabel':
        case 'CreatingShipments':
        case 'AddingOrders': {
          return true;
        }
        default: {
          return false;
        }
      }
    }

    return {
      ExportStatus: exportStatus,
      OrderExportStatus: orderExportStatus,
      getDisplayStatus: getDisplayStatus,
      getColorClass: getColorClass,
      showLoadingBubbles: showLoadingBubbles
    }
  })())
