angular.module('Cockpit.State.Dgda.Printfile.Status.EditOrder', [
  'ui.router',
  'Cockpit.Service.Dgda.Printfile',
])

  .controller("PrintFileEditOrderCtl", function PrintFileEditOrderCtl($scope, order, printFileOrderLog, $uibModalInstance, ExportDgdaPrintfile, PrintFileLog) {
    $scope.order = order;
    $scope.printFileOrderLog = printFileOrderLog;
    $scope.isLoading = false;
    $scope.errorData = null;

    $scope.shippingDetailsUrl = Config.WebShipperBaseUrl + 'ship/orders/' + printFileOrderLog.externalReferenceId;

    function regeneratePdfs(ordersToSave) {
      $scope.processingText = 'Regenerating PDF files...';

      var savedOrderNumbers = [];
      for (var i = 0; i < ordersToSave.length; i++) {
        savedOrderNumbers.push(ordersToSave[i].OrderNumber);
      }

      ExportDgdaPrintfile.regenPdfs(savedOrderNumbers, function () {
        $scope.isLoading = false;
        $scope.processingText = null;
        $uibModalInstance.close({ didUpdateOrder: true });
      }, function (a) {
        $scope.errorData = a;
        $scope.isLoading = false;
        $scope.processingText = 'Failed regenerating PDF files';
        alert("Could not regenerate PDF files.");
      })
    }

    $scope.updateOrder = function () {

      var ordersToUpdate = [$scope.order];
      $scope.isLoading = true;
      $scope.errorData = null;

      $scope.processingText = 'Updating order...';

      ExportDgdaPrintfile.saveOrders(ordersToUpdate, function (response) {
        regeneratePdfs(ordersToUpdate);
      }, function (a) {
        $scope.errorData = a;
        $scope.isLoading = false;
        $scope.processingText = 'Failed updating order';
        alert("Unfortunately the order couldn't be saved.")
      })
    }

    $scope.excludeOrder = function () {
      $scope.isLoading = true;
      $scope.errorData = null;

      var excludedStatus = 'Excluded';

      PrintFileLog.updateOrderStatus({ exportLogId: $scope.printFileOrderLog.exportLogId, orderNumber: printFileOrderLog.orderNumber, orderExportStatus: excludedStatus }, {}, function (res) {
        printFileOrderLog.status = excludedStatus;
        $scope.isLoading = false;
        $uibModalInstance.close({ didUpdateOrderStatus: true });

      }, function (err) {
        $scope.isLoading = false;
        $scope.errorData = err;
        $scope.processingText = 'Failed excluding order';
      })
    }

    $scope.onClose = function () {
      $uibModalInstance.close();
    }
  })
