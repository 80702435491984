angular.module('Cockpit.Service.Dgda.Faq', ['ngResource'])
    .factory('Faq', ['$resource',
        function($resource) {
            return $resource(Config.Api + 'FaqApi/:action', {}, {
                query: { params: { action: 'GetAll' }, isArray: true },
                getSubject: { method: 'GET', params: { action: 'GetSubject' } },
                save: { method: 'POST', params: { action: 'Save' } },
                delete: { method: 'POST', params: { action: 'Delete' } },
                edit: { method: 'POST', params: { action: 'Edit' } },
            });
        }
    ]);