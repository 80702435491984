angular.module('Cockpit.Controller.GenericAddEdit', [
])
  .controller('GenericAddEditController', function GenericAddEditController($scope, $state, theInstance, scopePropertyName, goStateName) {
    $scope[scopePropertyName] = theInstance;
    $scope.isProcessing = false;

    $scope.onSubmit = function () {
      $scope.isProcessing = true;

      if ($scope[scopePropertyName].id < 1) {
        $scope[scopePropertyName].$save(function(){
          $scope.isProcessing = false;
          $state.go(goStateName, {}, { reload: true });
        });
      }
      else {
        $scope[scopePropertyName].$update(function(){
          $scope.isProcessing = false;
          $state.go(goStateName, {}, { reload: true });
        });
      }
    }
  })
