var userService = angular.module('Cockpit.Service.User', []);

userService.factory('User',
function ($http) {
	var userObject = {
		isLogged: false,
		name: "",
		email: "",
		username: "",
		group: "",
		token: ""
	};

	return {
		isLogged: function () { return userObject.isLogged && userObject.group !== ""; },
		getName: function () { return userObject.name; },
		getEmail: function () { return userObject.email; },
		getUsername: function () { return userObject.username; },
		getGroup: function () { return userObject.group; },

		can: function currentUserCan(groups){
			if(!userObject.isLogged){
				return false;
			}

			if(!angular.isArray(groups)){
				return (groups.toLowerCase() == userObject.group);
			}

			for(var i = 0; i < groups.length; i++){
				var group = groups[i].toLowerCase();
				if(group == userObject.group){
					return true;
				}
			}

			return false;
		},

		setLogged: function (v) { userObject.isLogged = v; },
		setName: function (v) { userObject.name = v; },
		setEmail: function (v) { userObject.email = v; },
		setUsername: function (v) { userObject.username = v; },
		setGroup: function (v) {
            if(v === undefined){
                userObject.group = '';
                return;
            }
            userObject.group = v.toLowerCase();
        },
		setToken: function(v){
			userObject.token = v;
			if(!angular.isDefined(v) || v.length === 0){
        $http.defaults.headers.common['Authorization'] = "";
			}else{
        $http.defaults.headers.common['Authorization'] = "Bearer " + v;
			}
		},
		getUser: function(){
			return angular.copy(userObject);
		},

		resetUser: function () {
			userObject = {
				isLogged: false,
				name: "",
				email: "",
				username: "",
				group: "",
				token: "",
				isAdmin: false
			};
		}
	};
});
