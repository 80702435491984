angular.module('Cockpit.State.ProductRank', [
    'ui.router',
    'Cockpit.Service.ProductRank',
    'Cockpit.State.Products'
])

.config(function config($stateProvider) {

	$stateProvider.state('private.products.productrank', {
		url: '/productrank',
        views: {
            '@private': {
                controller: 'ProductRankController',
                templateUrl: 'states/products/productrank/productrank.tpl.html',
            }
        },
		data: {
			pageTitle: 'Product Ranking Settings'
		}
	})

	;
})

.run(function (Navigation) {
	Navigation.sidebar.register('private.products', 'General', [
		{
			Label: "Product Ranking",
			State: 'private.products.productrank',
			Groups: ['admin']
		}
	]);
})

.controller('ProductRankController', function ProductRankController($scope, ProductRank) {
	console.log("Test");
	$scope.model = ProductRank.get();

	$scope.process = function processSaveProductRank() {
		$scope.model.$save();
	};
})

;