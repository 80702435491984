angular.module('Cockpit.State.Mademyday.Filters', [
  'ui.router',
  'Cockpit.Service.Mademyday.Filters'
])

.config(function config($stateProvider) {
	$stateProvider.state('private.products.filters', {
		url: '/filters',
		views: {
			'@private': {
				controller: 'CockpitMademydayFiltersController',
				templateUrl: 'states/mademyday/products/filters/filters.tpl.html'
			}
		},
		data: {
			pageTitle: 'Filters'
		}
	})

	;
})

.run(function (Navigation) {
	Navigation.sidebar.register('private.products', 'General', [
		{
			Label: 'Filters',
			State: 'private.products.filters',
			Groups: ["admin"]
		}
	]);
})

.controller('CockpitMademydayFiltersController', function CockpitMademydayFiltersController($scope, MmdFilter) {
	$scope.model = {
		Cities: ""
	};
	MmdFilter.query(function (d) {
		$scope.model.Cities = d.join("\n");
	});

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.process = function ProcessSaveFilter() {
		$scope.alert.Show = false;
		
		MmdFilter.save($scope.model.Cities.split("\n"), function (resp) {
			if (resp.Success) {
				$scope.alert.Type = "success";
				$scope.alert.Message = "Your filters have been saved :-)";
			} else {
				$scope.alert.Type = "warning";
				$scope.alert.Message = resp.Message;
			}
			$scope.alert.Show = true;
		});
	};
})

;