angular.module('Cockpit.Service.MailChimp.ECommerce', ['ngResource'])
    .factory('MCStore', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'storesapi', {},{
                query: { method: 'GET', url: Config.Api + 'storesapi/get', isArray: true },
                save: { method: 'POST', url: Config.Api + 'storesapi/save' }
            });
        }])
    .factory('MCList', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'listsapi', {},{
                query: { method: 'GET', url: Config.Api + 'listsapi/get', isArray: true }
            });
        }])

    .factory('MCSettings', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'listsapi', {},{
                get: { method: 'GET', url: Config.Api + 'mcsettingsapi/get' },
                save: { method: 'POST', url: Config.Api + 'mcsettingsapi/save' }
            });
        }])

    .factory('MCStoreManagement', ['$http', function($http){

        return {
            syncProducts: function(storeId, successCallback, errorCallback){
                $http.get(Config.Api + 'mcmanagementapi/sync', {
                    params: {
                        'storeid': storeId
                    }
                }).then(successCallback, errorCallback);
            },
            deleteProducts: function(storeId, successCallback, errorCallback){
                $http.get(Config.Api + 'mcmanagementapi/delete', {
                    params: {
                        'storeid': storeId
                    }
                }).then(successCallback, errorCallback);
            }
        }
    }])
;