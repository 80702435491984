angular.module('Cockpit.State.Site.TopBars.AddEdit', [
    'ui.router',
    'Cockpit.Service.Topbars',
    'Cockpit.Service.TopbarTypes',
    'Cockpit.Service.Campaign'
  ])

  .config(function config($stateProvider) {
    $stateProvider.state('private.site.topbars.add', {
        url: 'add/',
        controller: 'TopbarAddEditController',
        templateUrl: 'states/site/topbars/add-edit.tpl.html',
        data: {
          pageTitle: 'Add a Topbar'
        },
        resolve: {
          theLanguages: resolvers.languagesResolver,
          callOutTypes: resolvers.callOutTypesResolver,
          campaignsData: ['$q', 'Campaign', function resolveCampaigns($q, Campaign){
            var d = $q.defer();
            Campaign.query(function(data){
              d.resolve(data);
            });
            return d.promise;
          }],
          TopbarTypeData: ['$q', 'TopbarTypeService', function ($q, TopbarTypeService) {
            var d = $q.defer();

            TopbarTypeService.query(function (res) {
              d.resolve(res);
            }, function (err) {
              d.resolve([]);
            });

            return d.promise;
          }],
          TopbarData: ['$q', function ($q) {
            var d = $q.defer();

            var topbar = {
              "name": "",
              "languageCode": "",
              "topBarType": "",
              "limitedTime": false,
              "start": null,
              "end": null,
              "parameters": {},
              "locations": [],
              "pages": null,
              "overlay": null,
              "link": null,
              "isActive": true
          };

            d.resolve(topbar);
            return d.promise;
          }]
        }
      })
      .state('private.site.topbars.edit', {
        url: '{id}/edit/',
        controller: 'TopbarAddEditController',
        templateUrl: 'states/site/topbars/add-edit.tpl.html',
        data: {
          pageTitle: 'Edit Topbar'
        },
        resolve: {
          theLanguages: resolvers.languagesResolver,
          callOutTypes: resolvers.callOutTypesResolver,
          campaignsData: ['$q', 'Campaign', function resolveCampaigns($q, Campaign){
            var d = $q.defer();
            Campaign.query(function(data){
              d.resolve(data);
            });
            return d.promise;
          }],
          TopbarTypeData: ['$q', 'TopbarTypeService', function ($q, TopbarTypeService) {
            var d = $q.defer();

            TopbarTypeService.query(function(res){
              d.resolve(res);
            }, function (err){
              d.resolve([]);
            });
            return d.promise;
          }],
          TopbarData: ['$q', '$stateParams', 'TopbarService', function ($q, $stateParams, TopbarService) {
            var d = $q.defer();

            TopbarService.getById({
              id: $stateParams.id
            }, function (res) {
              res.start = new Date(res.start);
              res.end = new Date(res.end);
              d.resolve(res);
            }, function (err) {
              d.reject();
            })

            return d.promise;
          }]
        }
      })
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.site', 'Topbars', [{
      Label: 'Add',
      State: 'private.site.topbars.add',
      Groups: ['admin']
    }])
  })

  .controller('TopbarAddEditController', function TopbarAddEditController($scope, $state, theLanguages, TopbarTypeData, TopbarData, callOutTypes, campaignsData, TopbarService) {

    $scope.languages = theLanguages;
    $scope.campaigns = campaignsData;
    $scope.topbarTypes = TopbarTypeData;
    $scope.callOutTypes = callOutTypes;
    $scope.newBullet = "";
    $scope.topbarLocations = ["frontpage", "products-list", "search-page", "content-site", "product-page", "ts-giftcard", "checkout", "booking-login", "your-booking", "exchange-page", "refund-page"];
    $scope.seasonTypes = [
      {
        "slug": "christmas",
        "name": "Christmas"
      },
      {
        "slug": "black-friday",
        "name": "Black Friday"
      }
    ];
    $scope.selectedType = null;
    $scope.campaignDateChanged = false;

    $scope.topbar = TopbarData;
    $scope.showOnSpecificPage = TopbarData.pages && TopbarData.pages.length > 0;

    // $scope.topbar.additionalInfo = "none";
    $scope.additionalInfo = $scope.topbar.link !== null ? "link" : $scope.topbar.overlay !== null ? "overlay" : "none";

    var filterCampaigns = function(campaigns, langCode) {
      if( campaigns.length > 0 ) {
        return campaigns.filter(function(c) {
          return c.isActive && c.languageCode === langCode;
        })
      }
      return [];
    };

    var setSelectedMessageType = function(callOutTypes, topbar) {
      if( "service-message" === topbar.topBarType && topbar.parameters && topbar.parameters.id ) {
        return callOutTypes.find(function(ct){
          return ct.id === Number(topbar.parameters.id);
        })
      }
      return null;
    }

    $scope.selectedMessageType = setSelectedMessageType(callOutTypes, $scope.topbar);


    $scope.campaigns = filterCampaigns(campaignsData, $scope.topbar.languageCode);

    $scope.toggleLocation = function togggleLocation(loc){
      var index = $scope.topbar.locations.indexOf(loc);
      if (index === -1) {
        $scope.topbar.locations.push(loc);
      } else {
        $scope.topbar.locations.splice(index, 1);
      }
    };

    // Watches
    $scope.$watch("topbar.languageCode", function(langCode, oldLangCode) {
      if( langCode !== oldLangCode && $scope.topbar.topBarType === "campaign") {
        if (langCode === null || langCode === undefined) {
          return;
        }

        delete $scope.topbar.parameters.campaignId;
        $scope.campaigns = filterCampaigns(campaignsData, langCode);
      }
    });

    $scope.$watch("topbar.topBarType", function(tt) {
      if (tt === null || tt === undefined) {
        $scope.selectedType = null;
        return;
      }

      $scope.selectedType = $scope.topbarTypes.find( function(t) {
        return t.slug === tt;
      });

      if( "campaign" !== tt ) {
        delete $scope.topbar.parameters.campaignId;
      }
      return;
    });

    $scope.$watch("topbar.parameters.campaignId", function(id, oldId) {
      // console.log(id, oldId);
      if( $scope.topbar.topBarType === "campaign") {
        if (id === null || id === undefined) {
          $scope.topbar.limitedTime = false;
          $scope.topbar.start = null;
          $scope.topbar.end = null;
          return;
        }

        var selectedCampaign = $scope.campaigns.find(function(c) {
          return c.id === Number(id);
        });

        if( id === oldId && $scope.topbar.id && $scope.topbar.start && $scope.topbar.end ) {
          $scope.campaignDateChanged = Date.parse($scope.topbar.start) !== Date.parse(selectedCampaign.start) || Date.parse($scope.topbar.end) !== Date.parse(selectedCampaign.end);
        }

        if( selectedCampaign ) {
          $scope.topbar.limitedTime = true;
          $scope.topbar.start = new Date(selectedCampaign.start);
          $scope.topbar.end = new Date(selectedCampaign.end);
        }
      }
      return;
    });

    $scope.selectMessageType = function() {
      // convert ID to string as parameters only accept string values
      $scope.selectedMessageType.id = $scope.selectedMessageType.id.toString();
      Object.assign($scope.topbar.parameters, $scope.selectedMessageType);
    }

    $scope.changeLimitedTimeSetting = function() {
      if( !$scope.topbar.limitedTime ) {
        $scope.topbar.start = null;
        $scope.topbar.end = null;
        return;
      }
      $scope.topbar.start = "";
      $scope.topbar.end = "";

    }

    $scope.updateAdditionalInfo = function() {
      if( "none" === $scope.additionalInfo) {
        $scope.topbar.link = null;
        $scope.topbar.overlay = null;
      }
      if( "link" === $scope.additionalInfo) {
        $scope.topbar.link = "";
        $scope.topbar.overlay = null;
      }
      if( "overlay" === $scope.additionalInfo) {
        $scope.topbar.link = null;
        $scope.topbar.overlay = {
          "title": "",
          "text": "",
          "bullets": []
        };
      }
    };

    $scope.addBullet = function() {
      if( $scope.topbar.overlay === null ) {
        console.error("Overlay is null");
        return;
      }
      if( $scope.newBullet ) {
        $scope.topbar.overlay.bullets.push($scope.newBullet);
        $scope.newBullet = "";
      }
    }

    $scope.removeBullet = function(index) {
      if( $scope.topbar.overlay === null ) {
        console.error("Overlay is null");
        return;
      }

      $scope.topbar.overlay.bullets.splice(index, 1);
    }

    $scope.changeSpecificPageSetting = function(maybe) {
      $scope.topbar.locations = [];
      $scope.topbar.pages = [];
    };

    $scope.addPage = function(uri) {
      if( $scope.topbar.pages === null ) {
        console.error("pages is null");
        return;
      }
      if( uri) {
        $scope.topbar.pages.push(uri);
        $scope.newPage = "";
      }
    }

    $scope.removePage = function(index) {
      if( $scope.topbar.pages === null ) {
        console.error("pages is null");
        return;
      }

      $scope.topbar.pages.splice(index, 1);
    }


    // Saving
    $scope.saveTopbar = function () {
      if( $scope.topbar.parameters.campaignId ) {
        $scope.topbar.parameters.campaignId = $scope.topbar.parameters.campaignId.toString();
      }
      $scope.isProcessing = true;

      TopbarService.save(
        $scope.topbar,
        function successCb(data) {
          $scope.isProcessing = false;
          $scope.topbar.id = data.id;
          // TODO maybe we should redirect to list page?
          $state.go("private.site.topbars.list");
        },
        function errCb(err) {
          $scope.isProcessing = false;
          console.error("Err saving topbar =>", err);
        }
      );

    }
  });
