angular.module('Cockpit.Service.Dgda.DiscountCodes', ['ngResource'])
  .factory('DiscountCode', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'discounts/vouchers/:code', {code: '@code'}, {
        queryUsage: { method: 'GET', isArray: true}
      });
    }])

  .factory('DiscountCodeOrder', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'discounts/vouchers/:code/orders', {}, {});
    }])
;