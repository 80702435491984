angular.module('Cockpit.State.Campaigns.AddEdit', [
    'Cockpit.Service.Navigation',
    'Cockpit.Service.Campaign'
  ])

  .config(function config($stateProvider) {
    $stateProvider.state('private.campaigns.add', {
        url: '/add',
        controller: 'CampaignsAddEditController',
        templateUrl: 'states/campaigns/add-edit.tpl.html',
        data: {
          pageTitle: 'Add Campaign'
        },
        resolve: {
          theCampaign: ['$q', 'Campaign', function ($q, Campaign) {
            var d = $q.defer();

            var campaignDto = new Campaign();
            campaignDto.parameters = {};
            d.resolve(campaignDto);

            return d.promise;
          }],
          campaignTypes: ['$q', 'CampaignType', function ($q, CampaignType) {
            var d = $q.defer();

            CampaignType.query(function (res) {
              d.resolve(res);
            }, function (err) {
              d.reject();
            });

            return d.promise;
          }],
          theLanguages: resolvers.languagesResolver
        }
      })

      .state('private.campaigns.edit', {
        url: '/{id}/edit',
        controller: 'CampaignsAddEditController',
        templateUrl: 'states/campaigns/add-edit.tpl.html',
        data: {
          pageTitle: 'Edit Campaign'
        },
        resolve: {
          theCampaign: ['$q', 'Campaign', '$stateParams', function ($q, Campaign, $stateParams) {
            var d = $q.defer();

            Campaign.get({
              id: $stateParams.id
            }, function (res) {
              res.start = new Date(res.start);
              res.end = new Date(res.end);
              d.resolve(res);
            }, function (err) {
              d.reject();
            });

            return d.promise;
          }],
          campaignTypes: ['$q', 'CampaignType', function ($q, CampaignType) {
            var d = $q.defer();

            CampaignType.query(function (res) {
              d.resolve(res);
            }, function (err) {
              d.reject();
            });

            return d.promise;
          }],
          theLanguages: resolvers.languagesResolver
        }
      })

    ;
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.campaigns', 'Campaigns', [{
      Label: "Add campaign",
      State: 'private.campaigns.add',
      Groups: ["admin", "marketing"]
    }]);
  })

  .controller('CampaignsAddEditController', function CampaignsAddEditController($scope, $timeout, Campaign, CampaignType, theCampaign, campaignTypes, theLanguages, $state) {

    $scope.types = campaignTypes;
    $scope.selectedType = null;
    $scope.languages = theLanguages;



    $scope.$watch('campaign.campaignType', function watchCampaignType(ct) {
      if (ct === null || ct === undefined) {
        $scope.selectedType = null;
        return;
      }

      if( "flash-sale" === ct ) {
        if( angular.isDefined($scope.campaign.parameters ) ) {
          $scope.campaign.parameters["partner-payed"] = "0";
        }
      }

      for (var i = 0; i < $scope.types.length; i++) {
        if ($scope.types[i].slug === ct) {
          $scope.selectedType = $scope.types[i];
          return;
        }
      }

      $scope.selectedType = null;
    });


    $scope.campaign = theCampaign;


    $scope.errorMessages = [];

    $scope.isRequiredParameter = function (param) {
      if (!param.parameters.optional) {
        return true;
      }

      return param.parameters.optional !== 'true';
    }

    $scope.saveCampaign = function saveCampaign() {
      // make sure parameter values are of type string
      Object.keys($scope.campaign.parameters).forEach(function (paramKey) {
        if (typeof($scope.campaign.parameters[paramKey]) !== 'string' ) {
          $scope.campaign.parameters[paramKey] = $scope.campaign.parameters[paramKey].toString();
        }
        if( paramKey === "skus" || paramKey === "variant-skus" ) {
          $scope.campaign.parameters[paramKey] = $scope.campaign.parameters[paramKey].trim().replace(/\s/g, '');
        }
      })

      Campaign.save($scope.campaign, function (r) {
        // Empty error message on success callback
        $scope.errorMessages = [];
        r.start = new Date(r.start);
        r.end = new Date(r.end);

        $scope.campaign = r;

        $state.go('private.campaigns', {}, {
          reload: true
        });

      }, function errorCb(err) {
        $scope.errorMessages = err.data;
        // Clear message after 15s
        $timeout(function () {
          $scope.errorMessages = [];
        }, 15000);
      });
    }
  });
