angular
  .module("Cockpit.State.Login", ["ui.router"])

  .config(function config($stateProvider) {
    $stateProvider
      .state("public.login", {
        url: "/login/",
        resolve: {
          renew: [
            "$q",
            "Auth",
            function ($q, Auth) {
              var d = $q.defer();
              Auth.renew().$promise.then(
                function (success) {
                  d.resolve(success);
                },
                function (err) {
                  d.resolve(false);
                }
              );
              return d.promise;
            },
          ],
        },
        controller: "CockpitLoginCtrl",
        templateUrl: "states/login/login.tpl.html",
        data: {
          pageTitle: "Login til Truestory Cockpit",
          bodyClass: "login",
        },
      })

      .state("public.logout", {
        url: "/logout/",
        controller: "CockpitLogoutCtrl",
        templateUrl: "states/login/logout.tpl.html",
        data: {
          pageTitle: "Logger ud...",
          bodyClass: "login",
        },
      });
  })

  .run(function (Navigation) {
    Navigation.primary.register("Logout", "public.logout", 100);
  })

  .controller("CockpitLoginCtrl", function CockpitLoginCtrl($scope, Auth) {
    // Auth.renew();

    $scope.model = {
      Username: "",
      Password: "",
      Remember: false,
    };

    $scope.process = function () {
      $scope.message = "";
      Auth.login(
        $scope.model,
        function (d) {
          // Success
        },
        function () {
          // Error
          $scope.message =
            "Kunne ikke logge ind. Kontroller du har indtastet korrekt brugernavn / kodeord.";
        }
      );
    };
  })

  .controller(
    "CockpitLogoutCtrl",
    function CockpitLogoutCtrl($scope, $rootScope, $state, Auth) {
      Auth.logout();
      // $state.go('public.login');
    }
  );
