angular.module('Cockpit.State.Dgda.Ease', [
  'ui.router',
  'Cockpit.Service.Dgda.Ease'
])

.config(function config($stateProvider) {
	$stateProvider.state('private.settings.ease', {
		url: '/ease-booking',
		controller: 'CockpitDgdaEaseController',
		templateUrl: 'states/dgda/settings/ease/settings.tpl.html',
		data: {
			pageTitle: 'Ease Booking Settings'
		}
	})

	;
})

.run(function (Navigation) {
	Navigation.sidebar.register('private.settings', 'Truestory', [
		{
			Label: "ease booking",
			State: 'private.settings.ease',
			Groups: ["admin"]
		}
	]);
})

.controller('CockpitDgdaEaseController', function CockpitDgdaEaseController($scope, Ease) {
	$scope.model = Ease.get();
	$scope.alert = {

		Type: '',
		Show: false,
		Message: ''
	};

	$scope.process = function ProcessSaveParseData() {
		$scope.alert.Show = false;

		$scope.model.$save(function () {
			$scope.alert.Type = "success";
			$scope.alert.Message = "Your settings have been saved :-)";
			$scope.alert.Show = true;
		}, function () {
			$scope.alert.Type = "warning";
			$scope.alert.Message = "An error occured while saving your settings.";
			$scope.alert.Show = true;
		});
	};
})

;
