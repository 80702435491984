angular.module('Cockpit.State.Mademyday.BatchSend', [
  'ui.router',
  'Cockpit.Service.Mademyday.BatchSend'
])

.config(function config($stateProvider) {

	$stateProvider.state('private.orders.batch-send', {
		url: '/batch-send',
		views: {
			'@private': {
				controller: 'CockpitMademydayBatchSendController',
				templateUrl: 'states/mademyday/orders/batch-send/batch-send.tpl.html',
			}
		},
		data: {
			pageTitle: 'Batch-Send Gifts'
		}
	})

	;
})

.run(function (Navigation) {
	Navigation.sidebar.register('private.orders', 'Orders', [
		{
			Label: 'Batch Send',
			State: 'private.orders.batch-send',
			Groups: ["admin", "support"]
		}
	]);
})

.controller('CockpitMademydayBatchSendController', function CockpitMademydayBatchSendController($scope, Product, BatchSend) {
	function parseRawData(lines, useHeaders) {
		var parsed = [];

		var headers = [];
		for (var i in lines) {
			// Store headers if any
			if (i == 0 && useHeaders) {
				headers = lines[i].toLowerCase().split(';');
				continue;
			}

			var cols = lines[i].split(';');

			var parsedLine = {};

			for (var j in cols) {
				parsedLine[(parseInt(j) + 1)] = cols[j];
				if (useHeaders) {
					parsedLine[headers[j]] = cols[j];
				}
			}

			parsed.push(parsedLine);
		}

		return parsed;
	}

	function parseMessage(message, data){
		var shortcodes = message.match(/\[([^\]]+)\]/g);

		if (shortcodes === null || shortcodes.length === 0) {
			return message;
		}

		for (var i in shortcodes) {
			var shortcode = shortcodes[i].replace('[', '').replace(']', '').replace('col-', '');
			
			if (!angular.isDefined(data[shortcode])) {
				continue;
			}

			message = message.replace(shortcodes[i], data[shortcode]);
		}

		return message;
	}

	function updateExampleMessage() {
		if ($scope.batchData.Message === "" || $scope.batchData.Data.length === 0) {
			$scope.exampleMessage = $scope.batchData.Message;
			return;
		}

		$scope.exampleMessage = parseMessage($scope.batchData.Message, $scope.batchData.Data[0]);
	}

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.rawData = "";
	$scope.exampleMessage = "";
	$scope.batchData = {
		SenderName: "",
		Customer: {
			Type: "privat",
			Company: "",
			VatNo: "",
			Firstname: "",
			Lastname: "",
			Address: "",
			Postcode: "",
			City: "",
			Email: "",
			Newsletter: false
		},
		Product: null,
		Variant: null,
		Message: "",
		Price: null,
        Quantity: 0,
		Data: [],
		Headers: true
	};
	$scope.products = Product.query();

	$scope.$watch('rawData', function (data) {
		if (data === null || data === "") {
			$scope.batchData.Data = [];
			return;
		}

		var lines = data.split("\n");

		// Make sure we have more than one line of data
		if (lines.length === 0) {
			$scope.batchData.Data = [];
			return;
		}

		// Check if we're using headers, and if there's only one line
		if ($scope.batchData.Headers && lines.length === 1) {
			return;
		}

		$scope.batchData.Data = parseRawData(lines, $scope.batchData.Headers);
	});

	$scope.$watch(function () {
		return $scope.batchData.Message;
	}, updateExampleMessage);

	$scope.$watch(function () {
		return $scope.batchData.Data;
	}, updateExampleMessage);

	$scope.safety = false;

	$scope.process = function processSendBatch() {
		$scope.alert.Show = false;
		$scope.safety = false;

		BatchSend.send($scope.batchData, function sendBatchSuccess(d) {
			$scope.alert.Type = "success";
			$scope.alert.Message = "Finished sending batch!";
			$scope.alert.Show = true;
		}, function sendBatchError(a) {
			console.log(a);
			$scope.alert.Type = "warning";
			$scope.alert.Message = "An error occured while sending batch.";
			$scope.alert.Show = true;
		});
	};
})


;