angular.module('Cockpit.State.Site', [
	'ui.router',
  'Cockpit.State.Site.TopBars',
])
  .config(function config($stateProvider) {
    $stateProvider.state("private.site", {
      url: "/site/",
      templateUrl: "states/site/site.tpl.html",
      data: {
        pageTitle: "Site",
      },
    });
  })

  .run(function (Navigation) {
    Navigation.primary.register("Site", "private.site");
  })
;
