angular.module('Cockpit.State.Dgda.GiftCardPreview', [
    'ui.router',
    'angularFileUpload'
])

    .config(function config($stateProvider) {
        $stateProvider.state('private.products.giftcardpreview', {
            url: '/giftcard-preview',
            views: {
                "@private": {
                    controller: 'CockpitDgdaGiftCardPreviewController',
                    templateUrl: 'states/dgda/products/giftcardpreview/upload.tpl.html',
                }
            },
            data: {
                pageTitle: 'Giftcard Preview Uploader'
            }
        })

        ;
    })

    .run(function (Navigation) {
        Navigation.sidebar.register('private.products', 'Truestory', [
            {
                Label: "Giftcard Previews",
                State: 'private.products.giftcardpreview',
                Groups: ["admin", "support", "user"]
            }
        ]);
    })

    .controller('CockpitDgdaGiftCardPreviewController', function CockpitDgdaGiftCardPreviewController($scope, FileUploader) {
        $scope.alert = {
            Type: '',
            Show: false,
            Message: ''
        };

        $scope.imageUrl = '';
        $scope.uploader = new FileUploader({
            url: Config.Api + 'giftcardpreviewapi/save',
            autoUpload: true,
            queueLimit: 1,
            removeAfterUpload: true,
            withCredentials: true,
            onSuccessItem: function(item, response, status, headers){
                if( "ImageName" in response && response.ImageName != ""){
                    $scope.$apply(function(){
                        $scope.imageUrl = response.Url;
                        $scope.imageName = response.ImageName;
                    });

                    $scope.alert = {
                        Type: 'success',
                        Show: true,
                        Message: 'The gift card preview has been uploaded!'
                    };
                }
            },
            onErrorItem: function(item, response, status, headers){
                console.log(response, status);

                $scope.alert = {
                    Type: 'warning',
                    Show: true,
                    Message: 'Uploading the image failed. Please make sure, the image is an JPG, and the filename has the format "xxxx-yyy.jpg" where X is numeric and Y are letters.'
                };
            }
        });

    })

;
