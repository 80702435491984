angular.module('Cockpit.State.ProductRankings', [
    'ui.router',
    'Cockpit.State.Products',
    'Cockpit.Service.RankingServices'
])

    .config(function config($stateProvider) {

        $stateProvider.state('private.products.productrankings', {
            url: '/rankings',
            views: {
                '@private': {
                    controller: 'ProductRankingController',
                    templateUrl: 'states/products/rankings/rankings.tpl.html',
                }
            },
            data: {
                pageTitle: 'Product Ranking Settings'
            },
            resolve: {
              theLanguages: resolvers.languagesResolver
            }
        })

        ;
    })

    .run(function (Navigation) {
        Navigation.sidebar.register('private.products', 'Rankings', [
            {
                Label: "Product Ranking",
                State: 'private.products.productrankings',
                Groups: ['admin']
            }
        ]);
    })

    .controller('ProductRankingController', function ProductRankController($scope, Settings, Rankings, theLanguages) {
        $scope.languages = theLanguages;
        $scope.showLists = false;
        $scope.settings = Settings.get({key: 'ranking-settings'});
        $scope.rankings = [];
        $scope.lists = Settings.get({key: 'all-rankings-list'});

        $scope.processSettings = function saveSettings(){
            $scope.settings.$save({key: 'ranking-settings'});
        };

        $scope.processLists = function saveLists(){
            $scope.lists.$save({key: 'all-rankings-list'});
        };

        $scope.$watch('settings', function watchSettings(v){
            $scope.showLists = !angular.isDefined(v) || v == null || !angular.isDefined(v.ApiUrl) || !angular.isDefined(v.ApiKey) || v.ApiUrl.length === 0 || v.ApiKey.length === 0;
        });

        $scope.$watch('showLists', function watchShowLists(v){
            if(v !== true){
                return;
            }

            $scope.rankings = Rankings.query();
        })
    })

;
