angular.module('Cockpit.State.Dgda.Refunds', [
  'ui.router',
  'Cockpit.Service.SupplierCodes',
  'Cockpit.State.Dgda.Refunds.Manual'
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.orders.refunds', {
      url: '/refunds',
      views: {
        '@private': {
          controller: 'CockpitRefundDgdaController',
          templateUrl: 'states/dgda/orders/refunds/refunds.tpl.html'
        }
      },
      data: {
        pageTitle: 'Orders'
      }
    })
      ;
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.orders', 'Orders', [
      {
        Label: "View Pending Refunds",
        State: 'private.orders.refunds',
        Groups: ["admin", "support"]
      }
    ]);
  })

  .controller('CockpitRefundDgdaController', function CockpitRefundDgdaController($scope, prompt, Refund, SupplierCode, $state) {
    $scope.refunds = Refund.query({ action: 'pending' });
    $scope.failedRefunds = Refund.query({ action: 'failed' });

    $scope.processing = false;

    $scope.refundOrder = function (refund) {
      $scope.processing = true;
      if (!confirm("Are you sure, you want to confirm the refund of order '" + refund.orderDetails.number + "'?")) {
        $scope.processing = false;
        return;
      }

      Refund.complete({
        id: refund.id
      }, function (result) {
        doneProcessing();
      }, function (err) {
        $scope.processing = false;
      });

      SupplierCode.revokeByReference({ reference: refund.orderDetails.number }, function (result) { });
    };

    $scope.refundAll = function (fetchFailed) {
      $scope.processing = true;

      var selectedRefunds = [];
      if (!fetchFailed) {
        selectedRefunds = $scope.refunds.filter(function (refund) { return refund.isChecked; });
      } else {
        selectedRefunds = $scope.failedRefunds.filter(function (refund) { return refund.isChecked; });
      }

      if (selectedRefunds.length === 0 || !confirm("Are you sure want to accept all selected refunds?")) {
        $scope.processing = false;
        return;
      }

      Refund.batchUpdate({ action: 'complete' }, {
        ids: selectedRefunds.map(function (refund) { return refund.id; })
      }, function (res) {
        doneProcessing(fetchFailed);
      }, function (err) {
        $scope.processing = false;
      })
    };

    $scope.denyRefund = function (refund) {
      $scope.processing = true;

      prompt("Please enter reason for denying refund:", "").then(function (response) {
        if (response.length) {
          Refund.deny({
            id: refund.id,
            reason: response
          }, function (result) {
            doneProcessing();
          }, function (err) {
            $scope.processing = false;
          });
        }
        else {
          $scope.processing = false;
          window.alert("You need to add a reason to deny a refund");
        }
      },
        function () {
          $scope.processing = false;
        });
    };

    $scope.denyAll = function(fetchFailed) {
      $scope.processing = true;

      var selectedRefunds = [];
      if (!fetchFailed) {
        selectedRefunds = $scope.refunds.filter(function (refund) { return refund.isChecked; });
      } else {
        selectedRefunds = $scope.failedRefunds.filter(function (refund) { return refund.isChecked; });
      }

      if (selectedRefunds.length === 0) {
        $scope.processing = false;
        return;
      }

      prompt("Please enter reason for denying refunds: ", "").then(function (response) {
        if(response.length) {
          Refund.batchUpdate({ action: 'deny' }, {
            ids: selectedRefunds.map(function (refund) { return refund.id; }),
            reason: response
          }, function (res) {
            doneProcessing(fetchFailed);
          }, function (err) {
            $scope.processing = false;
          })
        } else {
          $scope.processing = false;
          window.alert("You need to add a reason to deny a refund");
        }
      }, function () {
        $scope.processing = false;
      });
    }

    $scope.sendRefundFailedEmail = function (refund) {
      $scope.processing = true;

      prompt('Please enter why the refund failed:', "").then(function (response) {
        if (!response.length || response.length < 1) {
          $scope.processing = false;
          return;
        }

        Refund.failed({
          ids: [ refund.id ],
          reason: response
        }, function (res) {
          doneProcessing(true);
        }, function (err) {
          $scope.processing = false;
        });
      }, function () {
        $scope.processing = false;
      });
    }

    $scope.bulkSendFailedEmail = function () {
      $scope.processing = true;

      var selectedRefunds = $scope.failedRefunds.filter(function (refund) { return refund.isChecked; });
      if (selectedRefunds.length === 0) {
        return;
      }

      prompt('Please enter why the refunds have failed:', "").then(function (response) {
        if (!response.length || response.length < 1) {
          $scope.processing = false;
          return;
        }

        Refund.failed({
          ids: selectedRefunds.map(function (refund) { return refund.id; }),
          reason: response
        }, function (res) {
          doneProcessing(true);
        }, function (err) {
          $scope.processing = false;
        })
      }, function () {
        $scope.processing = false;
      })
    };

    $scope.updateEmail = function (refund) {
      $scope.processing = true;

      prompt('Please enter the new email: ', refund.email).then(function (userEmail) {
        if (!userEmail.length || userEmail.length < 1 || refund.email === userEmail) {
          $scope.processing = false;
          return;
        }

        Refund.update({
          id: refund.id,
          userEmail: userEmail
        }, function (res) {
          doneProcessing(true);
          doneProcessing(false);
        }, function (err) {
          $scope.processing = false;
        });
      }, function() {
        $scope.processing = false;
      })
    }

    $scope.selectAllFailedRefunds = false;
    $scope.handleFailedRefundsSelect = function (isChecked) {
      $scope.failedRefunds.forEach(function (refund) { refund.isChecked = isChecked; });
    }

    $scope.selectAllRefundsToCreditCard = false;
    $scope.handleRefundsToCreditCardSelected = function (isChecked) {
      $scope.refunds.forEach(function (refund) {
        if (refund.useSameAccount) {
          refund.isChecked = isChecked;
        }
      });
    }

    $scope.selectAllRefundsToOtherAccount = false;
    $scope.handleRefundsToOtherAccountSelected = function (isChecked) {
      $scope.refunds.forEach(function (refund) {
        if (!refund.useSameAccount) {
          refund.isChecked = isChecked;
        }
      });
    }

    function doneProcessing(fetchFailed) {
      $scope.processing = false;

      if (!fetchFailed) {
        $scope.refunds = Refund.query({ action: 'pending' });
      } else {
        $scope.failedRefunds = Refund.query({ action: 'failed' });
      }
    }
  })
  ;
