angular.module('Cockpit.State.Settings.CallOutTypes.AddEdit', [
  'ui.router',
  'Cockpit.Service.CallOutTypes',
  'Cockpit.Controller.GenericAddEdit'
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.settings.call-out-types.add', {
      url: '/add',
      controller: 'GenericAddEditController',
      templateUrl: 'states/settings/call-outs/add-edit.tpl.html',
      data: {
        pageTitle: 'Add new Call-Out Type'
      },
      resolve: {
        theInstance: ['$q', 'CallOutType', function ($q, CallOutType) {
          var d = $q.defer();

          var callOutType = new CallOutType();
          callOutType.id = 0;
          callOutType.name = '';
          callOutType.icon = '';
          callOutType.color = 'black';

          d.resolve(callOutType);

          return d.promise;
        }],
        scopePropertyName: function(){
          return "callOutType";
        },
        goStateName: function(){
          return 'private.settings.call-out-types';
        }
      }
    })

    $stateProvider.state('private.settings.call-out-types.edit', {
      url: '/{id}/edit',
      controller: 'GenericAddEditController',
      templateUrl: 'states/settings/call-outs/add-edit.tpl.html',
      data: {
        pageTitle: 'Edit Call-Out Type'
      },
      resolve: {
        theInstance: ['$q', 'CallOutType', '$stateParams', function ($q, CallOutType, $stateParams) {
          var d = $q.defer();

          CallOutType.get({ id: $stateParams.id }, function (response) {
            d.resolve(response);
          }, function (err) {
            d.reject();
          })

          return d.promise;
        }],
        scopePropertyName: function(){
          return "callOutType";
        },
        goStateName: function(){
          return 'private.settings.call-out-types';
        }
      }
    })
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.settings', 'Call-Outs', [
      {
        Label: 'Add',
        State: 'private.settings.call-out-types.add',
        Groups: ['admin']
      }
    ])
  })
;
