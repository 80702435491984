angular.module('Cockpit.State.Orders', [
	'ui.router',
	'Cockpit.State.Orders.Add',
	'Cockpit.Service.Product',
	'Cockpit.State.Redeem',
	'Cockpit.State.Portfolio'
])

.config(function config($stateProvider) {
	$stateProvider.state('private.orders', {
		url: '/orders',
		controller: 'CockpitOrdersController',
		templateUrl: 'states/orders/orders.tpl.html',
		data: {
			pageTitle: 'Orders'
		}
	})

	;
})

.run(function (Navigation) {
	Navigation.primary.register("Orders", "private.orders");
})

.controller('CockpitOrdersController', function CockpitOrdersController($scope, Search) {
  $scope.isSearching = true;
  $scope.search = {};
  $scope.search.Results = Search.query(
    { query: 'recentorders', simpleSearch: true },
    function searchSuccess(res) {
      $scope.isSearching = false;
    },
    function searchError(err) {
      console.log("Error on search resolve: ", err);
      $scope.isSearching = false;

    }
  );


})

;
