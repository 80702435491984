angular.module('Cockpit.Service.Bookings', ['ngResource'])
  .factory('Bookings', ['$resource',
    function ($resource) {
      return $resource(Config.CockpitApiV1 + 'orders/:orderNumber/bookings/latest', {orderNumber: '@orderNumber'}, {
        cancel: {
          method: 'POST',
          url: Config.CockpitApiV1 + 'orders/:orderNumber/bookings/cancel?bookingId=:bookingId',
          isArray: false,
          params: {orderNumber: '@orderNumber', bookingId: '@bookingId'}
        }
      });
    }
  ]);
