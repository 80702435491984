angular.module('Cockpit.State.Mademyday.Export.Delivery', [
    'ui.router',
    'Cockpit.Service.Mademyday.Export'
])

    .config(function ($stateProvider) {
        $stateProvider.state('private.orders.delivery', {
            url: '/delivery',
            resolve: {
                deliveryDays: ['$q', 'MmdExport', function ($q, MmdExport){
                    var d = $q.defer();

                    MmdExport.queryDeliveryDays(function(data){
                        d.resolve(data);
                    });

                    return d.promise;
                }]
            },
            views: {
                "@private": {
                    controller: 'CockpitMademydayExportDeliveryController',
                    templateUrl: 'states/mademyday/orders/delivery/delivery.tpl.html'
                }
            },
            data: {
                pageTitle: 'Export Delivery Data'
            }
        });
    })

    .run(function (Navigation) {
        Navigation.sidebar.register('private.orders', 'Export', [
            {
                Label: 'Delivery',
                State: 'private.orders.delivery',
                Groups: ["admin", "support"]
            }
        ]);
    })

    .controller('CockpitMademydayExportDeliveryController', function CockpitMademydayExportDeliveryController($scope, MmdExport, deliveryDays) {
        $scope.days = deliveryDays;
        var loadedDay = null;
        $scope.data = [];
        $scope.exportData = {
            Supplier: ''
        };
        $scope.loadData = function loadData(dayData){
            loadedDay = dayData.Date;
            $scope.data = MmdExport.queryDelivery({ day: dayData.Date }, function(data){
                $scope.suppliers = [];

                console.log($scope.data);
                for(var i = 0; i < $scope.data.length; i++){
                    var supplier = $scope.data[i].Supplier;
                    if($scope.suppliers.indexOf(supplier) == -1){
                        $scope.suppliers.push(supplier)
                    }
                }
            });

        };

        $scope.exported = null;
        $scope.exportCsv = function exportUrl(){
            $scope.exported = null;
            $scope.exported = MmdExport.csvUrl({ day: loadedDay, supplier: $scope.exportData.Supplier });
        };

        $scope.exportPdf = function exportPdfUrl(){
            $scope.exported = null;
            $scope.exported = MmdExport.pdfUrl({ day: loadedDay, supplier: $scope.exportData.Supplier });
        };
    })

;
