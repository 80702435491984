angular.module('Cockpit.State.Settings.Notifications', [
    'ui.router',
    'Cockpit.Service.SlackChannel'
])

    .config(function config($stateProvider) {
        $stateProvider.state('private.settings.notifications', {
            url: 'notifications/',
            controller: 'NotificationSettingsController',
            templateUrl: 'states/settings/notifications/settings.tpl.html',
            data: {
                pageTitle: 'Notification Settings'
            }
        })

        ;
    })

    .run(function (Navigation) {
        Navigation.sidebar.register('private.settings', 'Notifications', [
            {
                Label: "Slack",
                State: 'private.settings.notifications',
                Groups: ["admin"]
            }
        ]);
    })

    .controller('NotificationSettingsController', function NotificationSettingsController($scope, Settings, SlackChannel) {
        $scope.isEnabled = false;
        $scope.slackSettings = Settings.get({key: 'slack'}, function(v){
            $scope.isEnabled = v.IsEnabled === "true";
            return v;
        });

        $scope.$watch('isEnabled', function (v){
            $scope.slackSettings.IsEnabled = v ? "true" : "false";
        });

        $scope.saveSettings = function saveSettings(){
            $scope.slackSettings.$save({key: 'slack'});
        };
    })

;