angular
  .module("Cockpit.State.Orders.Add", [
    "Cockpit.Service.OrderNote",
    "Cockpit.Service.WrappingMethods",
    "Cockpit.Partial.Orders.Add.Preview",
    "Cockpit.Service.AccountingGroups"
  ])

  .config(function config($stateProvider) {
    $stateProvider
      .state("private.orders.add", {
        url: "/add",
        resolve: {
          theLanguages: resolvers.languagesResolver,
          accountingGroups: [
            "$q",
            "AccountingGroups",
            function($q, AccountingGroups) {
              var d = $q.defer();
              AccountingGroups.get(function(ag) {
                try {
                  d.resolve(ag.items);
                } catch (e) {
                  console.log('error parsing accounting groups', e);
                  d.resolve([]);
                }
              }, function(error) {
                console.log("Error loading accounting groups => ", error);
                d.resolve([]);
              });

              return d.promise;
            }
          ],
          orderData: [
            function () {
              return {
                OrderStatus: null,
                OrderNumber: "",
                Customer: {
                  Type: "privat",
                  Company: "",
                  VatNo: "",
                  Firstname: "",
                  Lastname: "",
                  Address: "",
                  Postcode: "",
                  City: "",
                  Email: "",
                  PhoneNumber: "",
                  Newsletter: false,
                },
                Orderlines: [],
                CustomProperties: {}
              };
            },
          ],
        },
        views: {
          "@private": {
            controller: "CockpitOrderAddController",
            templateUrl: "states/orders/order-add.tpl.html",
          },
        },
        data: {
          pageTitle: "Orders",
        },
      })

      .state("private.orders.edit", {
        url: "/edit/{OrderNumber}",
        resolve: {
          theLanguages: resolvers.languagesResolver,
          accountingGroups: [
            "$q",
            "AccountingGroups",
            function($q, AccountingGroups) {
              var d = $q.defer();
              AccountingGroups.get(function(ag) {
                try {
                  d.resolve(ag.items);
                } catch (e) {
                  console.log('error parsing accounting groups', e);
                  d.resolve([]);
                }
              }, function(error) {
                console.log("Error loading accounting groups => ", error);
                d.resolve([]);
              });

              return d.promise;
            }
          ],
          orderData: [
            "$q",
            "$stateParams",
            "Order",
            function ($q, $stateParams, Order) {
              var d = $q.defer();

              Order.getDetailed(
                { OrderNumber: $stateParams.OrderNumber },
                function (o) {
                  d.resolve(o);
                },
                function () {
                  console.log("Error loading order");
                }
              );

              return d.promise;
            },
          ],
        },
        views: {
          "@private": {
            controller: "CockpitOrderAddController",
            templateUrl: "states/orders/order-add.tpl.html",
          },
        },
        data: {
          pageTitle: "Orders",
        },
      });
  })

  .run(function (Navigation) {
    Navigation.sidebar.register("private.orders", "Orders", [
      {
        Label: "Add Order",
        State: "private.orders.add",
        Groups: ["admin", "support"],
      },
      {
        Label: "Edit Validity",
        State: "/v2/admin/orders/edit-validity/",
        Groups: ["admin", "support"],
      },
    ]);
  })

  .controller(
    "CockpitOrderAddController",
    function CockpitOrderAddController(
      $scope,
      $state,
      WrappingMethods,
      PimProduct,
      ProductV2,
      Order,
      accountingGroups,
      orderData,
      OrderNote,
      DiscountCode,
      theLanguages,
      $uibModal
    ) {
      var ORDER_EXPIRATION_MAX_YEARS = 3;
      $scope.product = null;
      $scope.orderline = {
        Product: null,
        Variant: null,
        Quantity: 1,
      };
      $scope.currencies = ["DKK", "NOK", "SEK"];
      $scope.languages = theLanguages;
      $scope.accountingGroups = accountingGroups;
      $scope.newOrderNote = null;

      // v2 product data
      $scope.selectedProduct = null;
      $scope.participantTypes = null;
      $scope.variants = null;
      $scope.selectedParticipantType = null;
      $scope.selectedVariant = null;
      $scope.shouldSelectVariant = false;
      $scope.isStaticQuantity = false;
      $scope.selectedWrapping = null;
      $scope.numberOfOrders = 1;

      $scope.isValidatingCode = false;
      $scope.codeValidationStatus = '';
      $scope.codeRemainingUses = -1;
      $scope.validateCode = function validateCode(){
        $scope.codeRemainingUses = -1;
        $scope.isValidatingCode = true;
        $scope.codeValidationStatus = '';

        $scope.voucher = DiscountCode.get({code: $scope.order.VoucherCode}, function (data) {
          $scope.isValidatingCode = false;

          if(data.maxUses > data.used){
              $scope.codeValidationStatus = 'valid';
          }else{
              $scope.codeValidationStatus = 'used';
          }

          $scope.codeRemainingUses = data.maxUses - data.used;
        }, function (err) {
          $scope.isValidatingCode = false;
          $scope.codeValidationStatus = 'invalid';
      });
    }


      $scope.saveOrderNote = function () {
        OrderNote.save(
          { orderNumber: orderData.OrderNumber },
          {
            note: $scope.newOrderNote,
          },
          function (res) {
            alert("Note saved!");
            $scope.newOrderNote = null;
          },
          function (err) {
            alert("Error saving note: ", err);
          }
        );
      };

      $scope.order = orderData;
      $scope.isBulkOrder = angular.isDefined(orderData.CustomProperties.BulkId) && orderData.CustomProperties.BulkId.length === 36;

      $scope.order.SendMails = false;
      $scope.isEdit = orderData.OrderNumber != '';

      $scope.expirationDate = (function () {
        if($scope.isEdit){
          var dateObj = new Date($scope.order.CustomProperties.Expires);
          return dateObj.toLocaleDateString("da-dk");
        } else {
          var expirationDate = new Date();
          expirationDate.setFullYear(expirationDate.getFullYear() + 2);
          return expirationDate;
        }
      })();


      if ($scope.isEdit) {
        if (
          !angular.isUndefined($scope.order.CustomProperties) &&
          $scope.order.CustomProperties != null
        ) {
          $scope.order.Personalization = {
            Message: $scope.order.CustomProperties.PersonalizedDate,
            Date: $scope.order.CustomProperties.PersonalizedMessage,
            Name: $scope.order.CustomProperties.PersonalizedName,
          };
        }
      }

      function setDefaultDeliveryAddress(){
        if($scope.selectedWrapping && !$scope.selectedWrapping.isDigital){
          $scope.order.DeliveryAddress.Company = $scope.order.DeliveryAddress.Company || $scope.order.Customer.Company;
          $scope.order.DeliveryAddress.Firstname = $scope.order.DeliveryAddress.Firstname || $scope.order.Customer.Firstname;
          $scope.order.DeliveryAddress.Lastname = $scope.order.DeliveryAddress.Lastname || $scope.order.Customer.Lastname;
          $scope.order.DeliveryAddress.Address = $scope.order.DeliveryAddress.Address || $scope.order.Customer.Address;
          $scope.order.DeliveryAddress.Postcode = $scope.order.DeliveryAddress.Postcode || $scope.order.Customer.Postcode;
          $scope.order.DeliveryAddress.City = $scope.order.DeliveryAddress.City || $scope.order.Customer.City;
          $scope.order.DeliveryAddress.Email = $scope.order.DeliveryAddress.Email || $scope.order.Customer.Email;
          $scope.order.DeliveryAddress.PhoneNumber = $scope.order.DeliveryAddress.PhoneNumber || $scope.order.Customer.PhoneNumber;
        }
      }
      $scope.$watch("order.LanguageCode", function(languageCode) {

        // set OrderCurrency if !isEdit
        if( !$scope.isEdit ) {
          switch (languageCode) {
            case "nb-no": {
              $scope.order.OrderCurrency = "NOK"
              break;
            }

            case "sv-se": {
              $scope.order.OrderCurrency = "SEK"
              break;
            }

            default: {
              $scope.order.OrderCurrency = "DKK"
              break;
            }
          }
        }

        if( languageCode ) {
          WrappingMethods.query({languageCode: languageCode}, function(wrappingMethods){
            $scope.wrappingMethods = wrappingMethods;

            if($scope.order.WrappingMethodId){
              $scope.selectedWrapping = $scope.wrappingMethods.find(function(wm){
                return wm.methodId === $scope.order.WrappingMethodId
              })
            }
          })
        } else {
          $scope.wrappingMethods = null;
          $scope.selectedWrapping = null;
        }
      })

      $scope.$watch("order.Customer.Type", function (type){
        if($scope.selectedWrapping){
          $scope.order.DeliveryMethod = null;
          $scope.order.DeliveryAddress = null;

          if($scope.selectedWrapping && !$scope.selectedWrapping.isDigital){
            $scope.order.DeliveryMethod = "address";
            $scope.order.DeliveryAddress = {};
            $scope.order.DeliveryAddress.Type = "private";
            setDefaultDeliveryAddress();
          }
        }
      })

      $scope.$watch("order.DeliveryMethod", function (type){
        if(!$scope.order.DeliveryAddress){
          $scope.order.DeliveryAddress = {}
        }
        $scope.order.DeliveryAddress.Type = "private";
        if(!$scope.isEdit){
          setDefaultDeliveryAddress();
        }
      })

      $scope.$watch("order.DeliveryAddress.Type", function (type){
        if(!$scope.isEdit){
          setDefaultDeliveryAddress();
        }
      })

      $scope.orderStatuses = Order.orderStatuses();

      var truestoryGiftcard = {
        sku: '99999',
        name: 'Truestory giftcard'
      }

      $scope.isLoadingProducts = true;
      if ('allProducts' in window.sessionStorage) {
        $scope.products = JSON.parse(window.sessionStorage.getItem('allProducts'));

        if( $scope.products.findIndex(function(p) {return p.sku === truestoryGiftcard.sku} ) < 0 ) {
          $scope.products.push(truestoryGiftcard);
        }

        $scope.isLoadingProducts = false;
      } else {
        PimProduct.query(function (products) {
          $scope.isLoadingProducts = false;

          //manual add Truestory gift card since not available from struct
          products.push(truestoryGiftcard);

          $scope.products = products;
          window.sessionStorage.setItem('allProducts', JSON.stringify(products));
        }, function () {
          $scope.isLoadingProducts = false;
          $scope.products = null;
          alert('could not fetch products. Try again.');
        });
      }

      $scope.price = 0;
      $scope.vatRate = 0;

      $scope.$watch("products", function (products) {
        if (!products || products.length === 0) {
          return;
        }

        if ($scope.isEdit) {
          $scope.selectedProduct = products.find(function (product) {
            return product.sku === $scope.order.Orderlines[0].Sku;
          });
        }
      });

      $scope.$watch("selectedProduct", function (product) {
        if (!product) {
          return;
        }

        resetOrderlineInput();
        $scope.participantTypes = [];
        $scope.variants = [];
        $scope.isStaticQuantity = false;

        // don't fetch truestory giftcard
        if (product.sku === '99999') {
          $scope.vatRate = 0;
          return;
        }

        var languageCode = $scope.order.LanguageCode || "da-dk";
        ProductV2.getBySku({ sku: product.sku, languageCode: languageCode }, function (structProduct) {
          $scope.participantTypes = structProduct.participantTypes;
          if (!$scope.isEdit) {
            return;
          }

          // set vatRate for proper display of prices. We just need the first available.
          var variantSku = $scope.order.Orderlines[0].VariantSku;
          var productItem = $scope.participantTypes.find(function(pt) { return pt.sku === variantSku });
          if (!productItem) {
            productItem = $scope.participantTypes[0].variants.find(function(v) { return v.sku === variantSku });
          }

          var priceData = getPriceDataFromOrderCurrency(productItem.prices);
          updatePriceAndVatRate(priceData);

        }, function (err) {
          $scope.participantTypes = [];
          alert('Could not get product data with currency: ' + $scope.order.OrderCurrency);
          $scope.selectedProduct = null;
        });
      });

      $scope.$watch("selectedParticipantType", function (participantType) {
        // default value if non selected
        if (!participantType && $scope.participantTypes && $scope.participantTypes.length > 0) {
          $scope.selectedParticipantType = $scope.participantTypes[0];
          return;
        }

        if (!participantType) {
          return;
        }

        // if product has variants stop here
        $scope.shouldSelectVariant = participantType.variants.length > 0;
        if ($scope.shouldSelectVariant) {
          $scope.variants = participantType.variants;
          return;
        }

        $scope.isStaticQuantity = participantType.staticQuantity;
        setInitialOrderlineQuantity(participantType.minQuantity);
        var priceData = getPriceDataFromOrderCurrency(participantType.prices);
        updatePriceAndVatRate(priceData);
      });

      $scope.onExpirationChange = function(expirationDate) {
        $scope.expirationDate = expirationDate;
      }

      $scope.onWrappingChange = function(wrapping) {
        $scope.selectedWrapping = wrapping;
        if($scope.selectedWrapping.isDigital){
          $scope.order.DeliveryMethod = null;
          $scope.order.DeliveryAddress = null;
        } else {
          if(!$scope.order.DeliveryAddress){
            $scope.order.DeliveryAddress = {};
          }
          $scope.order.DeliveryAddress.Type = "private";
          if($scope.order.Customer.Type === "erhverv"){
            $scope.order.DeliveryMethod = "address";
          }
        }
      }

      $scope.$watch("selectedVariant", function (variant) {
        if (!variant) {
          return;
        }

        $scope.isStaticQuantity = variant.staticQuantity;
        setInitialOrderlineQuantity(variant.minQuantity);
        var priceData = getPriceDataFromOrderCurrency(variant.prices);
        updatePriceAndVatRate(priceData);
      });

      function getPriceDataFromOrderCurrency(prices) {
        return prices.find(function (x) {
          return x.currency.isoCode === $scope.order.OrderCurrency;
        });
      }

      function updatePriceAndVatRate(priceData) {
        $scope.price = priceData.price;
        $scope.vatRate = priceData.vatGroup.vatRate;
      }

      $scope.addOrderline = function addOrderline() {
        if (!$scope.selectedProduct && $scope.price != 0) {
          return;
        }

        var selectedItem = $scope.selectedVariant
          ? $scope.selectedVariant
          : $scope.selectedParticipantType;

        // check for variantSku when not truestory giftcard
        if ((!selectedItem || !selectedItem.sku) && $scope.selectedProduct.sku !== '99999') {
          alert('You need to select the variant or participantType');
          return;
        }

        var orderline = {
          Price: $scope.price,
          Quantity: $scope.orderline.Quantity,
          Sku: $scope.selectedProduct.sku,
          VariantSku: selectedItem ? selectedItem.sku : null,
          Name: $scope.selectedProduct.name + ' ' + (selectedItem ? (selectedItem.name ? selectedItem.name : selectedItem.nameSingle) : '')
        };

        $scope.order.Orderlines.push(orderline);
        resetOrderlineInput();
      };

      function resetOrderlineInput() {
        $scope.selectedParticipantType = null;
        $scope.selectedVariant = null;
        $scope.orderline = {
          Quantity: 1
        };
        $scope.price = 0;
      }

      function setInitialOrderlineQuantity(quantity) {
        $scope.orderline.Quantity = quantity;
      }

      $scope.removeOrderline = function removeOrderline(orderline) {
        var index = $scope.order.Orderlines.indexOf(orderline);
        if (index < 0) {
          return;
        }
        $scope.order.Orderlines.splice(index, 1);
        if ($scope.order.Orderlines.length === 0) {
          $scope.price = 0;
        }
      };

      $scope.shouldHideAddOrderline = function () {
        if ($scope.isEdit) {
          return true;
        }

        if ($scope.shouldSelectVariant && $scope.order.Orderlines.length > 0) {
          return true;
        }

        if ($scope.order.Orderlines.length > 0 && $scope.order.Orderlines[0].Sku === '99999') {
          return true;
        }

        if ($scope.order.Orderlines.length > 0 && $scope.isStaticQuantity) {
          return true;
        }

        return false;
      }

      $scope.isProcessing = false;
      $scope.process = function processSaveOrder() {
        if (!$scope.isEdit) {
          $scope.addOrders(true);
          return;
        }

        $scope.isProcessing = true;
        $scope.order.CustomProperties = null;
        Order.save(
          $scope.order,
          function (d) {
            $state.go("private.search-details", { "orderNumber": $scope.order.OrderNumber }, { reload: "private.search-details" });
          },
          function (e) {
            console.log("Error: ", e);
            alert("Error saving order!");
          }
        );
      };


      $scope.addOrders = function addOrders(shouldPreviewBasket) {
        $scope.errorMessage = null;
        $scope.isProcessing = true;

        var expirationDateObj = new Date($scope.expirationDate);
        var currentDate = new Date();
        var maxDate = new Date();
        maxDate.setFullYear(currentDate.getFullYear() + ORDER_EXPIRATION_MAX_YEARS);

        if (isNaN(expirationDateObj.getTime())) {
          $scope.errorMessage = "The expiration date is invalid. Please enter a valid date.";
          $scope.isProcessing = false;
          return;
        }

        if (expirationDateObj <= currentDate) {
          $scope.errorMessage = "The expiration date must be a future date. Please select a date that is later than today.";
          $scope.isProcessing = false;
          return;
        }

        if (expirationDateObj > maxDate) {
          $scope.errorMessage = "The expiration date must be within the next " + ORDER_EXPIRATION_MAX_YEARS + " years. Please select a closer date.";
          $scope.isProcessing = false;
          return;
        }

        var expirationDateStr = expirationDateObj.toISOString().slice(0, 10);
        $scope.order.CustomProperties.Expires = expirationDateStr;
        Order.bulkSave({
          Count: $scope.numberOfOrders,
          Order: $scope.order,
          IsBulkOrder: $scope.isBulkOrder,
          ExistingBulkId: $scope.existingBulkId,
          PreviewBasket: shouldPreviewBasket
        },
          function (d) {
            // show preview
            if (shouldPreviewBasket && d.Success) {
              $scope.isProcessing = false;
              showPreviewBasket(d.BasketId);
              return;
            }

            if (!d.Success) {
              $scope.isProcessing = false;
              $scope.errorMessage = "Failed saving. Did you input everything correctly?";
              return;
            }

            if (d.OrderNumbers && d.OrderNumbers.length > 0) {
              $state.go("private.search-details", { "orderNumber": d.OrderNumbers[0] });
              return;
            }
            $state.go("private.orders");
            return;
          },
          function (e) {
            $scope.isProcessing = false;
            console.log("Error: ", e);
            alert("Error saving bulk orders!");
          }
        );
      };

      function showPreviewBasket(basketId) {
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: "states/orders/preview-add-order.tpl.html",
          resolve: {
            basketId: function () { return basketId; },
            numberOfOrders: function () { return $scope.numberOfOrders; }
          },
          size: 'lg',
          controller: "CockpitOrderAddPreviewController"
        })

        modalInstance.result.then(function (res) {
          if (!res) {
            return;
          }

          if (res.orderConfirmed) {
            $scope.addOrders(false);
          }
        }, function() {})
      }
    }
  );
