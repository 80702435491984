angular
  .module("Cockpit.State.ProductCodes.MadklubbenCreateCode", [
    "ui.router",
    "Cockpit.Service.LifePeaksGiftcard",
    "Cockpit.Service.OrderNote",
  ])
  .config(function config($stateProvider) {
    $stateProvider.state("private.products.codes.madklubben.create", {
      url: "/create",
      views: {
        "@private": {
          controller: "CockpitMadklubbenFetchCodesController",
          templateUrl: "states/products/codes/madklubben/create.tpl.html",
        },
      },
      data: {
        pageTitle: "Create New Madklubben Code",
      },
    });
  })
  .controller(
    "CockpitMadklubbenFetchCodesController",
    function CockpitEventsController($scope, LifePeaksGiftcard, OrderNote) {
      $scope.order = {};
      $scope.isProcessing = false;

      var createCreateCodeNote = function (code, orderNumber, callback) {
        var orderNote = "Added a Madklubben code: " + code + ".";
        OrderNote.save(
          { orderNumber: orderNumber },
          {
            note: orderNote,
          },
          function (res) {
            callback && typeof callback === "function" && callback();
          },
          function (err) {
            alert("Error saving note: ", err);
            callback && typeof callback === "function" && callback();
          }
        );
      };

      $scope.handleCreate = function () {
        $scope.isProcessing = true;
        var orderNumber = $scope.order.orderNumber;
        LifePeaksGiftcard.create(
          {
            orderNumber: orderNumber,
          },
          {
            amount: $scope.order.amount
          },
          function successCb(res) {
            createCreateCodeNote(res.data.code, orderNumber, function () {
              alert("Successfully added.");
              $scope.order = {};
              $scope.isProcessing = false;
            });
          },
          function errorCb(err) {
            console.log("Error =>", err);
            $scope.isProcessing = false;
            alert(
              "Error while trying to create code. Please try again, or contact tech team if problem persists."
            );
          }
        );
      };
    }
  );
