angular
  .module("Cockpit.Modal.BookingCancel", [
    'toaster', 'ngAnimate',
    "Cockpit.Service.Bookings"
  ])
  .controller(
    "BookingCancelModalController",
    function ($scope, TheBookingData, $uibModalInstance, Bookings, toaster) {
      $scope.isProcessing = false;
      $scope.hasError = false;

      $scope.close = function () {
        $scope.isProcessing = false;
        $uibModalInstance.close();
      }

      $scope.cancelBooking = function() {
        $scope.isProcessing = true;
        $scope.hasError = false;
        Bookings.cancel(
          {
            orderNumber: TheBookingData.orderNumber,
            bookingId: TheBookingData.bookingId
          },
          function (data) {
            $scope.isProcessing = false;
            $scope.hasError = false;
            toaster.pop({
              type: "success",
              title: "",
              body: "Booking cancelled successfully.",
              timeout: 5000
            });
            $uibModalInstance.close({didCancelBooking: true});
          },
          function(err) {
            $scope.isProcessing = false;
            $scope.hasError = true;
            var errorDetails =
              err.data && err.data.metadata && err.data.metadata.details
                ? err.data.metadata.details
                : "Booking cancellation was unsuccessful. Please contact tech team.";
            toaster.pop({
              type: "error",
              title: "",
              body: errorDetails,
              timeout: 0,
            });
            console.log("Bookings.cancel error =>", err);
          });
      }

    }
  );
