angular
  .module("Cockpit.Modal.BookingDetails", [])
  .controller(
    "BookingDetailsModalController",
    function ($scope, booking, $uibModalInstance) {
      $scope.booking = booking;

      $scope.close = function () {
        $uibModalInstance.close();
      }

      $scope.eventLocation =
        (booking.location.localAreaName || "") +
        booking.location.streetAddress +
        ", " +
        booking.location.zipCode +
        " " +
        booking.location.city;

      $scope.eventLanguage = booking.spokenLanguages
        .map(function (s) {
          return s.name;
        })
        .join(", ");

      $scope.primaryContact = booking.participants[0];
      $scope.bookingCustomFields = booking.customFields ?  Object.entries(booking.customFields) : [];
      $scope.bookingParticipants = booking.participants.map(function(p) {
        p.metas = p.customFields ? Object.entries(p.customFields) : [];
        return p;
      });

      function isMultipleDate(dateStr1, dateStr2) {
        var date1 = new Date(dateStr1);
        var date2 = new Date(dateStr2);

        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() < date2.getDate();
      }

      $scope.isMultiDay = isMultipleDate(booking.start, booking.end);

      $scope.bookingEndDateFilter = !$scope.isMultiDay ? "HH:mm" : "dd/MM/yyyy HH:mm";

      $scope.hasParticipantsMeta = booking.participants.some(function(p) {
        return p.customFields ? Object.keys(p.customFields).length > 0 : false;
      });

    }
  );
