angular.module('Cockpit.State.Portfolio', [
    'ui.router',
    'Cockpit.Service.Portfolio',
    'Cockpit.Service.PortfolioSkuOverride'
])

    .config(function config($stateProvider) {
        $stateProvider.state('private.orders.portfolio', {
            url: '/portfolio',
            views: {
                '@private': {
                    controller: 'ReportingPortfolioController',
                    templateUrl: 'states/orders/portfolio/portfolio.tpl.html'
                }
            },
            data: {
                pageTitle: 'Reporting'
            }
        })

        ;
    })

    .run(function (Navigation) {
        Navigation.sidebar.register('private.orders', 'Reporting', [
            {
                Label: 'Portfolio',
                State: 'private.orders.portfolio',
                Groups: ["admin"]
            }
        ]);
    })

    .controller('ReportingPortfolioController', function ReportingPortfolioController($scope, Portfolio, PortfolioSkuOverride, $http) {
        var date = new Date();
        $scope.month = (date.getMonth() + 1) + '';
        $scope.year = date.getFullYear();
        $scope.months = [
            {
                Id: '1',
                Name: 'January'
            },{
                Id: '2',
                Name: 'February'
            },{
                Id: '3',
                Name: 'March'
            },{
                Id: '4',
                Name: 'April'
            },{
                Id: '5',
                Name: 'May'
            },{
                Id: '6',
                Name: 'June'
            },{
                Id: '7',
                Name: 'July'
            },{
                Id: '8',
                Name: 'August'
            },{
                Id: '9',
                Name: 'September'
            },{
                Id: '10',
                Name: 'October'
            },{
                Id: '11',
                Name: 'November'
            },{
                Id: '12',
                Name: 'December'
            }
        ];
        $scope.years = [];
        for(var i = 0; i < 5; i++){
            var y = date.getFullYear() - 4 + i;
            $scope.years.push(y);
        }

        $scope.override = {
            Sku: '',
            Initials: ''
        };
        $scope.overrides = PortfolioSkuOverride.get();
        $scope.addOverride = function addOverride(){
            if($scope.override.Sku === "" || $scope.override.Initials === ""){
                return;
            }
            $scope.overrides[ $scope.override.Sku ] = $scope.override.Initials;
            $scope.overrides = PortfolioSkuOverride.save( $scope.overrides );

            $scope.override = {
                Sku: '',
                Initials: ''
            };
        };
        $scope.removeOverride = function removeOverride(sku){
            delete $scope.overrides[sku];
            $scope.overrides = PortfolioSkuOverride.save( $scope.overrides );
        };

        $scope.getPortfolio = function getPortfolio(){
            $scope.portfolio = Portfolio.query({
                month: $scope.month,
                year: $scope.year
            });
        };
        $scope.exportPortfolioData = function exportPortfolioData(initials, startDate){
            if(initials === ""){
                return;
            }
            $scope.exportError = false;

            var date = new Date(startDate);
            $http({
                url: Config.Api + "portfolioapi/export",
                method: "GET",
                params: {
                    month: date.getMonth() + 1,
                    year: date.getFullYear(),
                    initials: initials
                }
            }).then(function exportSuccess(response){
                $scope.exportLink = response.data.Link;
                $scope.exportError = false;
            }, function exportError(err){
                $scope.exportError = true;
                console.log(err);
            });
        };

        $scope.getPortfolio();
    })

;