angular.module('Cockpit.State.ProductCodes', [
  'ui.router',
  'Cockpit.Service.SupplierCodes',
  "Cockpit.State.ProductCodes.Madklubben"
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.products.codes', {
      url: '/codes',
      data: {
        pageTitle: 'Product Codes'
      }
    })

    $stateProvider.state('private.products.codes.supplier', {
      url: '/supplier',
      views: {
        '@private': {
          controller: 'CockpitProductSupplierCodesController',
          templateUrl: 'states/products/codes/products.tpl.html'
        }
      },
      data: {
        pageTitle: 'Supplier Codes'
      }
    })

      .state('private.products.codes.supplier.codes-import', {
        url: '/import/:Id',
        views: {
          '@private': {
            controller: 'CockpitProductSupplierCodeImportController',
            templateUrl: 'states/products/codes/import.tpl.html',
          }
        },
        data: {
          pageTitle: 'Import codes'
        }
      })

      .state('private.products.codes.supplier.used-codes', {
        url: '/usedcodes/:Sku',
        views: {
          '@private': {
            controller: 'CockpitProductSupplierCodesUsedController',
            templateUrl: 'states/products/codes/usedcodes.tpl.html',
          }
        },
        data: {
          pageTitle: 'Used codes'
        }
      })

      .state('private.products.codes.supplier.remaining-codes', {
        url: '/remainingcodes/:Sku/:VariantSku',
        views: {
          '@private': {
            controller: 'CockpitProductSupplierCodesRemainingController',
            templateUrl: 'states/products/codes/remainingcodes.tpl.html',
          }
        },
        data: {
          pageTitle: 'Remaining codes'
        }
      })

      ;
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.products', 'Product Codes', [
      {
        Label: "Madklubben Codes",
        State: "private.products.codes.madklubben",
        Groups: ["admin", "support"],
      },
      {
        Label: 'Supplier Codes',
        State: 'private.products.codes.supplier',
        Groups: ["admin", "support"]
      }
    ]);
  })

  .controller('CockpitProductSupplierCodesController', function CockpitProductSupplierCodesController($scope, SupplierCode) {
    $scope.products = SupplierCode.query();
  })

  .controller('CockpitProductSupplierCodeImportController', function CockpitProductSupplierCodeImportController($scope, $stateParams, SupplierCode) {
    $scope.model = {
      Codes: "",
      Id: $stateParams.Id
    };

    $scope.alert = {
      Type: '',
      Show: false,
      Message: ''
    };

    $scope.process = function ProcessImportEventCodes() {
      $scope.alert.Show = false;

      if ($scope.model.Codes === "") {
        $scope.alert.Type = "warning";
        $scope.alert.Message = "You should at least enter one code.";
        $scope.alert.Show = true;
        return;
      }

      SupplierCode.import($scope.model, function (resp) {
        if (resp.Success) {
          $scope.alert.Type = "success";
        } else {
          $scope.alert.Type = "warning";
        }

        $scope.alert.Message = resp.Message;
        $scope.alert.Show = true;
      });
    };
  })


  .controller('CockpitProductSupplierCodesUsedController', function CockpitProductSupplierCodesUsedController($scope, $stateParams, SupplierCode) {

    $scope.orders = [];
    $scope.sku = $stateParams.Sku;

    SupplierCode.orders({ sku: $stateParams.Sku }, function (result) {
      if (result.length) {
        $scope.orders = result;
      }
    });

    $scope.revoke = function (order, sku) {
      SupplierCode.revoke({ id: order.Id, sku: sku }, function (result) {
        if (result.length) {
          $scope.orders = result;
        }
        else {
          $scope.orders = [];
        }
      });
    };
  })

  .controller('CockpitProductSupplierCodesRemainingController', function CockpitProductSupplierCodesRemainingController($scope, $stateParams, SupplierCode) {

    $scope.productCodes = [];
    $scope.sku = $stateParams.Sku;
    $scope.deletedCodes = [];

    $scope.canDelete = function () {
      const selectedCodes = $scope.productCodes.filter(function (pc) { return pc.isChecked }).length;
      return selectedCodes > 0;
    }

    function getRemaingCodes() {
      SupplierCode.remaining({ sku: $stateParams.Sku, variantSku: $stateParams.VariantSku }, function (result) {
        if (result.length) {
          $scope.productCodes = result;
        }
      });
    }

    getRemaingCodes();

    $scope.deleteCodes = function () {
      if (!confirm('Are you sure you want to delete selected codes? They will be gone afterwards!')) {
        return;
      }

      // reset state
      $scope.deletedCodes = [];
      $scope.errorMessage = null;

      const idsToDelete = $scope.productCodes
        .filter(function (p) { return p.isChecked; })
        .map(function (p) { return p.Id });

      SupplierCode.deleteCodes(idsToDelete, function (result) {
        $scope.deletedCodes = result;
        getRemaingCodes();
      }, function (err) {
        $scope.errorMessage = "Failed to delete codes";
        console.log(err);
      });
    };
  })
  ;
