angular.module('Cockpit.State.Tiles.AddEdit', [
  'ui.router',
  'Cockpit.Service.CategoryV2',
  'Cockpit.Service.RegionsV2',
  'Cockpit.Service.ProductV2',
  'Cockpit.Directive.TileFilter',
  'Cockpit.Directive.ConvertToNumber'
])

  .config(function ($stateProvider) {
    $stateProvider.state('private.products.tiles.edit', {
      url: '/tiles/{id}/edit',
      views: {
        '@private': {
          controller: 'TilesAddEditController',
          templateUrl: 'states/products/tiles/add-edit.tpl.html',
        }
      },
      data: {
        pageTitle: 'Edit tile'
      },
      resolve: {
        theLanguages: ['$q', 'Language', function ($q, Language) {
          return resolve($q, Language);
        }],
        theInstance: ['$q', 'Tiles', '$stateParams', function ($q, Tiles, $stateParams) {
          const d = $q.defer();

          Tiles.get({ id: $stateParams.id }, function (res) {
            res.dateFrom = res.dateFrom ? new Date(res.dateFrom) : null;
            res.dateTo = res.dateTo ? new Date(res.dateTo) : null

            d.resolve(res);
          }, function (err) {
            d.reject();
          });

          return d.promise;
        }]
      }
    })

    $stateProvider.state('private.products.tiles.add', {
      url: '/tiles/add',
      views: {
        '@private': {
          controller: 'TilesAddEditController',
          templateUrl: 'states/products/tiles/add-edit.tpl.html',
        }
      },
      data: {
        pageTitle: 'Add tile'
      },
      resolve: {
        theLanguages: ['$q', 'Language', function ($q, Language) {
          return resolve($q, Language);
        }],
        theInstance: ['$q', 'Tiles', function ($q, Tiles) {
          const d = $q.defer();

          var tile = new Tiles();
          tile.categories = '';
          tile.regions = '';
          tile.positions = '';
          tile.type = tileType.Normal

          d.resolve(tile);
          return d.promise;
        }]
      }
    })

  })

  .controller('TilesAddEditController', function ($scope, RegionsV2, CategoryV2, theLanguages, theInstance, Tiles, ProductV2) {
    $scope.tile = theInstance;
    $scope.regions = [];
    $scope.categories = [];
    $scope.languages = theLanguages;
    $scope.selectedPosition = $scope.tile.positions ? $scope.tile.positions.split(',').pop() : 1;
    $scope.infoMessage = null;
    $scope.hasError = false;
    $scope.model = {
      categories: $scope.tile.categories ? $scope.tile.categories.split(',') : [],
      regions: $scope.tile.regions ? $scope.tile.regions.split(',') : [],
      positions: $scope.tile.positions ? $scope.tile.positions.split(',') : []
    };

    onInit();

    function onInit() {
      if ($scope.tile.type === tileType.Product) {
        fetchProducts($scope.tile.languageCode);
      } else {
        fetchRegionAndCategories($scope.tile.languageCode);
      }
    }

    $scope.$watch('tile.type', function (newTileType, oldTileType) {
      if (newTileType === oldTileType) {
        return;
      }

      if (newTileType === tileType.Product && $scope.tile.languageCode) {
        fetchProducts($scope.tile.languageCode);
        return;
      }

      if ($scope.tile.languageCode) {
        fetchRegionAndCategories($scope.tile.languageCode);
        resetChosenRegionsAndCategories();
      }
    });

    $scope.$watch('tile.languageCode', function (newLanguageCode, oldLanguageCode) {
      if (newLanguageCode === oldLanguageCode) {
        return;
      }

      if ($scope.tile.type === tileType.Product) {
        fetchProducts($scope.tile.languageCode);
      } else {
        fetchRegionAndCategories(newLanguageCode);
        resetChosenRegionsAndCategories();
      }
    });

    function fetchProducts(languageCode) {
      $scope.products = ProductV2.query({ languageCode: languageCode });
    }

    function fetchRegionAndCategories(languageCode) {
      $scope.categories = CategoryV2.query({ languageCode: languageCode });
      $scope.regions = RegionsV2.query({ languageCode: languageCode });
    }

    function resetChosenRegionsAndCategories() {
      $scope.model.categories = [];
      $scope.model.regions = [];
    }

    $scope.saveTile = function saveTile() {
      $scope.tile.categories = $scope.model.categories.join(',');
      $scope.tile.regions = $scope.model.regions.join(',');
      $scope.tile.positions = $scope.model.positions.join(',');

      if (!isValidTile()) {
        return;
      }

      if ($scope.tile.type === tileType.Product) {
        // set unused properties to default values
        $scope.tile.image = '';
        $scope.tile.link = '';
      }

      $scope.tile.$save()
        .then(function () {
          $scope.infoMessage = 'Custom tile saved!';
          $scope.hasError = false;
        })
        .catch(function (err) {
          $scope.infoMessage = 'Could not save tile';
          $scope.hasError = true;
        })
    };

    $scope.reset = function () {
      $scope.tile = new Tiles();
      $scope.model = {
        categories: [],
        regions: [],
        positions: []
      };
    }

    function isValidTile() {
      if ($scope.tile.positions.length === 0) {
        $scope.infoMessage = 'You need to enter a position';
        $scope.hasError = true;
        return false;
      }

      if (($scope.tile.dateFrom && !$scope.tile.dateTo) ||
        (!$scope.tile.dateFrom && $scope.tile.dateTo)) {
        $scope.infoMessage = 'Period need start and end date';
        $scope.hasError = true;
        return false;
      }

      if ($scope.tile.dateFrom && $scope.tile.dateTo) {
        if (!isValidDate($scope.tile.dateFrom, $scope.tile.dateTo)) {
          $scope.infoMessage = 'Period is invalid';
          $scope.hasError = true;
          return false;
        }
      }

      $scope.infoMessage = null;
      $scope.hasError = false;
      return true;
    }

    function isValidDate(dateFrom, dateTo) {
      return dateFrom < dateTo;
    }

    $scope.addPosition = function () {
      if (!$scope.selectedPosition || $scope.selectedPosition < 1 || isNaN(Number($scope.selectedPosition))) {
        return;
      }

      if ($scope.model.positions.includes($scope.selectedPosition)) {
        return;
      }

      $scope.model.positions.push($scope.selectedPosition);
    }

    $scope.removePosition = function (position) {
      var index = $scope.model.positions.indexOf(position);
      if (index === -1) {
        return;
      }

      $scope.model.positions.splice(index, 1);
    }

    $scope.clearPeriod = function () {
      $scope.tile.dateFrom = null;
      $scope.tile.dateTo = null;
    }
  });


function resolve($q, service) {
  const d = $q.defer();

  service.query(function (res) {
    d.resolve(res);
  }, function (err) {
    d.reject();
  });

  return d.promise;
}