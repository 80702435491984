angular.module('Cockpit.Service.Dgda.Printfile', ['ngResource'])
	.factory('ExportDgdaPrintfile', ['$resource',
		function ($resource) {
			return $resource(Config.Api + 'exportapi/:action', {}, {
				generate: { method: 'POST', params: { action: 'Printfile' }, isArray: true },
				info: { method: 'GET', params: { action: 'LastExport' } },
				query: { method: 'GET', params: {action: 'GetOrders'}, isArray: true},
				get: { method: 'GET', params: {action: 'GetOrder'}},
				saveOrders: { method: 'POST', params: {action: 'UpdateOrders'}, isArray: true},
				regenPdfs: { method: 'POST', params: {action: 'UpdatePdfs'}}
			});
		}])

	.factory('PrintFileLog', ['$resource',
		function ($resource) {
      const mainUrl = Config.ApiV1 + 'export/printfile/logs';

			return $resource(mainUrl, {}, {
        createExportLog: { method: 'PUT', transformResponse: function (data) {
          // primitive types cannot be returned directly so we transform it to an object
          if (!isNaN(parseInt(data))) {
            return {
              exportLogId: Number.parseInt(data)
            };
          }

          return data;
        }},
        getExportLogStatus: { method: 'GET', url: mainUrl + '/:exportLogId/status', isArray: false },
        updateOrderStatus: { method: 'POST', url: mainUrl + '/:exportLogId/orders/:orderNumber/status' },
        addOrder: { method: 'POST', url: mainUrl + '/:exportLogId/orders' },
        performExport: { method: 'POST', url: mainUrl + '/:exportLogId/orders/shipments/create' },
        getExportZipFiles: { method: 'GET', url: mainUrl + '/:exportLogId', isArray: true },
        deleteExportLog: { method: 'DELETE', url: mainUrl + '/:exportLogId' },
      });
		}])
;
