angular.module('Cockpit.Directive.TileFilter', [

])

  .directive('tileFilter', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        added: '=',
        items: '=',
        name: '@',
        pluralname: '@'
      },
      templateUrl: 'states/products/tiles/filter-directive/filter.tpl.html',
      link: function ($scope) {
        
        $scope.add = function() {
          if (!$scope.selected || !$scope.selected.slug) {
            return;
          }

          if (indexOfItem($scope.selected.slug) > -1) {
            return;
          }

          $scope.added.push($scope.selected.slug);
          $scope.selected = null;
        }

        $scope.remove = function (slug) {
          var itemIndex = indexOfItem(slug);
          if (itemIndex === -1) {
            return;
          }

          $scope.added.splice(itemIndex, 1);
        }
        
        
        function indexOfItem(slug){
          if(!angular.isDefined($scope.added)){
            return -1;
          }

          for(var i = 0; i < $scope.added.length; i++){
            var existingSlug = $scope.added[i];

            if(existingSlug === slug){
              return i;
            }
          }

          return -1;
        }

        $scope.filterItems = function filterItems(value){
          return indexOfItem(value.slug) === -1;
        }
      }
    }
  })