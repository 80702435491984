angular.module('Cockpit.State.Site.TopBars.List', [
  'ui.router',
  'Cockpit.Service.Topbars',
  'Cockpit.Service.TopbarGrouping',
  'Cockpit.Directive.TopbarList'
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.site.topbars.list', {
      url: 'list/',
      controller: 'TopbarsListController',
      templateUrl: "states/site/topbars/list.tpl.html",
      data: {
        pageTitle: 'Topbars List'
      },
      resolve: {}
    })
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.site', 'Topbars', [
      {
        Label: 'List',
        State: 'private.site.topbars.list',
        Groups: ['admin']
      }
    ])
  })

  .controller('TopbarsListController', function TopbarsListController($scope, TopbarService, TopbarGrouping) {

    $scope.topbars = [];
    $scope.processing = false;

    var refresh = function() {
      $scope.isLoading = true;

      TopbarService.query(
        function successCb(data) {
          $scope.isLoading = false;
          $scope.topbars = TopbarGrouping.group(data);
        },
        function errorCb(err) {
          $scope.isLoading = false;
          console.log("Err getting topbars =>", err)
        }
      );
    }

    $scope.refresh = function() {
      refresh();
    }

    refresh();
  })
;
