angular.module('Cockpit.HttpResponse.Interceptor', ['ngResource'])
  .factory('HttpResponseInterceptor',  ['$q', '$location', function($q, $location) {
    return {
      responseError: function (rejection) {
        if (rejection.status === 401) {
          $location.path('logout');
        }
        return $q.reject(rejection);
      }
    }
  }]);
