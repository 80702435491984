angular.module('Cockpit.State.Abstracts', [
  'ui.router'
])

.config(function config($stateProvider) {
	$stateProvider
		.state('public', {
			abstract: true,
			views: {
				"main": {
					template: '<ui-view/>'
				}
			},
			data: {
				access: {
					isPrivate: false
				},
				bodyClass: ""
			}
		})

		.state('private', {
			abstract: true,
			views: {
				"main": {
					templateUrl: 'states/abstracts/private.tpl.html'
				}
			},
			data: {
				access: {
					isPrivate: true,
					groups: ["admin", "support", "user"]
				},
				bodyClass: ""
			}
		})
	;
})

.controller('SidebarNavigationController', function SidebarNavigationController($scope, $state, $rootScope, Navigation) {
	$scope.navigation = Navigation.sidebar.get($state.current.name);

	$scope.getUrl = function leftNavGetUrl(state) {
    if( state.indexOf('/') === 0 ) {
      return state;
    }
		return $state.href(state);
	};

	$scope.isActiveState = function isActiveState(stateName) {
		return ($state.current.name === stateName);
	};

	$rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
		$scope.navigation = Navigation.sidebar.get(toState.name);
		$scope.state = toState.name;
	});
})

.controller('PrimaryNavigationController', function PrimaryNavigationController($scope, $state, Navigation) {
	$scope.navigation = Navigation.primary.get();

	$scope.isActiveState = function isActiveState(stateName) {
		var currentStateName = $state.current.name;
		return (currentStateName.indexOf(stateName) >= 0);
	};

	$scope.getUrl = function primaryNavGetUrl(state) {
		return $state.href(state);
	};
})

;
