angular.module('Cockpit.Directive.CallOutsTable', [

])
  .directive('cpTsCallOutsTable', function (CallOut) {
    return {
      restrict: 'E',
      scope: {
        callOuts: '=',
        onAction: '&'
      },
      replace: true,
      templateUrl: 'states/products/call-outs/table.tpl.html',
      link: function ($scope){
        $scope.delete = function deleteType(callOut){
          callOut.$delete(function(){
            if(angular.isDefined($scope.onAction)){
              $scope.onAction();
            }
          });
        }

        $scope.filterContexts = function filterContexts(contexts){
          if(!contexts || !contexts.length){
            contexts = ["shop", "booking-login"];
          }
          return contexts.filter(Boolean).join(", ");
        };

        $scope.getFirst = function(obj){
          var keys = Object.keys(obj);
          for(var i = 0; i < keys.length; i++){
            if(obj[keys[i]] && obj[keys[i]].length > 0){
              return obj[keys[i]];
            }
          }

          return '-';
        }
      }
    };
  })

;
