angular.module('Cockpit.PrintFile.Shared', [
  'Cockpit.Service.Dgda.Printfile'
])
  .factory('PrintFileHelper', function () {
    function getExportDtoFromPrintFileLog(printFileLog) {
      return {
        from: printFileLog.exportFrom || "",
        to: printFileLog.exportTo || "",
        bulkId: printFileLog.bulkId || "",
        countries: printFileLog.countries.join(',')
      };
    }

    var countries = [
      {
        "IsoCode": "da-dk",
        "Name": "Denmark"
      },
      {
        "IsoCode": "nb-no",
        "Name": "Norway"
      },
      {
        "IsoCode": "sv-se",
        "Name": "Sweden"
      },
      {
        "IsoCode": "de-de",
        "Name": "Germany"
      }
    ];

    return {
      countries: countries,
      getExportDtoFromPrintFileLog: getExportDtoFromPrintFileLog
    }
  })