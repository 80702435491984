angular.module('Cockpit.State.Dashboard', [
  'ui.router'
])

.config(function ($stateProvider) {
	$stateProvider.state('private.dashboard', {
		url: '/',
		views: {
			"": {
				controller: 'CockpitDashboardCtrl',
				templateUrl: 'states/dashboard/dashboard.tpl.html'
			}
		},
		data: {
			pageTitle: 'Dashboard'
		}
	});
})

.run(function (Navigation) {
	Navigation.primary.register("Dashboard", "private.dashboard", 0);
})

.controller('CockpitDashboardCtrl', function CockpitDashboardCtrl($scope) {

})

;
