angular.module('Cockpit.State.Settings.Notifications.Templates.Edit', [
  'ui.router',
  'Cockpit.Service.Notification.Templates'
])

  .config(function ($stateProvider) {
    $stateProvider.state('private.settings.notifications.templates.edit', {
      url: ':id/edit',
      views: {
        '@private': {
          controller: 'TemplatesEditController',
          templateUrl: 'states/settings/notifications/template-settings/edit.tpl.html'
        }
      },
      data: {
        pageTitle: 'Edit template'
      },
      resolve: {
        theInstance: ['$q', 'NotificationTemplates', '$stateParams', function ($q, NotificationTemplates, $stateParams) {
          const d = $q.defer();

          NotificationTemplates.get({ id: $stateParams.id }, function(res) {
            d.resolve(res);
          }, function(err) {
            d.reject(err);
          })

          return d.promise;
        }]
      }
    })
  })

  .controller('TemplatesEditController', function ($scope, theInstance, NotificationTemplates) {
    $scope.model = theInstance;
    $scope.infoMessage = null;
    $scope.hasError = false;

    $scope.onSubmit = function() {
      NotificationTemplates.update($scope.model, function(res) {
        $scope.infoMessage = 'Saved!';
        $scope.hasError = false;
      }, function (err) {
        $scope.infoMessage = 'Could not save';
        $scope.hasError = true;
      });
    }
  })
