angular.module('Cockpit.Service.Notification', ['ngResource'])
  .factory('Notification', ['$resource',
    function ($resource) {
      return $resource(Config.AdminApi + 'notifications/:id', { id: '@id' }, {
        getContent: {
          method: 'GET', url: Config.AdminApi + 'notifications/:id/content', transformResponse: function (data) {
            return { html: data }
          }
        },
        getFailed: {
          method: 'GET', url: Config.AdminApi + 'notifications/failed', isArray: true
        },
        resendNotifications: {
          method: 'POST', url: Config.AdminApi + 'notifications/resendnotifications'
        },
        update: { method: 'PUT' }
      });
    }
  ]);
