angular.module('dgda.directive.LoaderButton', [])
  .directive('dgdaLoaderButton', function () {
    return {
      restrict: 'A',
      scope: {
        isProcessing: '&',
        processingText: '=',
        callback: '&dgdaLoaderButtonCallback',
        validate: '&dgdaLoaderButtonValidate'
      },
      link: function (scope, elem, attrs) {
        var orig = "";
        var clicked = false;

        function setProcessing() {
          if (clicked) {
            return;
          }

          clicked = true;
          elem.addClass('loader-button-active');
          elem.attr('disabled', 'disabled');

          var html = '<div class="loader-button-spinner-wrapper"><span class="loader-button-spinner"><span class="glyphicon glyphicon-refresh" aria-hidden="true"></span></span></div>';

          if (angular.isDefined(scope.processingText) && scope.processingText !== "") {
            html += '<div class="loader-button-text">' + scope.processingText + '</div>';
          }

          orig = elem.html();
          elem.html(html);
        }


        function unsetProcessing() {
          elem.removeClass('loader-button-active');
          elem.removeAttr('disabled');
          elem.html(orig);
          clicked = false;
        }

        if (angular.isDefined(attrs.isProcessing)) {
          scope.$watch(function () {
            return scope.isProcessing();
          }, function (isProcessing) {

            if (isProcessing) {
              setProcessing();
            } else if (clicked) {
              // Reset button to original state
              unsetProcessing();
            }
          });
        } else {
          elem.bind('click', setProcessing);
        }
      }
    };
  });
