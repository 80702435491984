angular.module('Cockpit.State.Redeem', [
  'ui.router',
  'Cockpit.Service.Supplier'
])

.config(function ($stateProvider) {
	$stateProvider

	.state('private.orders.supplier-list', {
		url: '/supplier-list',
		views: {
			'@private': {
				controller: 'RedeemSupplierListController',
				templateUrl: 'states/orders/redeem/supplier-list.tpl.html'
			}
		},
		data: {
			pageTitle: 'List of suppliers with redeem-info'
		}
	})

	;
})

.run(function (Navigation) {
	Navigation.sidebar.register('private.orders', 'Reporting', [
		{
			Label: 'Supplier Reedeem Info',
			State: 'private.orders.supplier-list',
			Groups: ["admin", "support"]
		}
	]);
})

.controller('RedeemSupplierListController', function RedeemSupplierListController($scope, Supplier) {
		$scope.isLoading = true;
	$scope.supplierInfo = Supplier.query(function(){
		$scope.isLoading = false;
	});
	$scope.orderByPredicate = 'LastRedeemed';
	$scope.currentPredicate = 'LastRedeemed';

	$scope.setOrderBy = function setOrderBy(predicate) {
		$scope.currentPredicate = predicate;
		if ($scope.orderByPredicate === predicate) {
			$scope.orderByPredicate = '-' + $scope.orderByPredicate;
		} else {
			$scope.orderByPredicate = predicate;
		}
	};
})

;
