angular.module('Cockpit.State.Settings.Geckoboard.MonthlyGoals', [
    'ui.router',
    'Cockpit.State.Settings',
    'Cockpit.Service.Geckoboard.MonthlyGoals'
])

    .config(function config($stateProvider) {
        $stateProvider.state('private.settings.gecko-monthly-goals', {
            url: 'geckoboard/monthly-goals/',
            controller: 'GeckoboardMonthlyGoalsController',
            templateUrl: 'states/settings/geckoboard/monthly-goals/goals.tpl.html',
            data: {
                pageTitle: 'Product Ranking Settings'
            }
        })

        ;
    })

    .run(function (Navigation) {
        Navigation.sidebar.register('private.settings', 'Geckoboard', [
            {
                Label: "Monthly Goals",
                State: 'private.settings.gecko-monthly-goals',
                Groups: ["admin"]
            }
        ]);
    })

    .controller('GeckoboardMonthlyGoalsController', function GeckoboardMonthlyGoalsController($scope, GeckoMonthlyGoals) {

        $scope.model = GeckoMonthlyGoals.get();

        $scope.process = function processSave() {
            $scope.model.$save();
        };
    })

;