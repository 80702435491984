angular.module('Cockpit.State.Campaigns', [
    'Cockpit.Service.Navigation',
    'Cockpit.Service.Campaign',
    'Cockpit.Service.CampaignGrouping',
    'Cockpit.Directive.CampaignList',
    'Cockpit.State.Campaigns.AddEdit',
    'Cockpit.State.FreeWrapping'
])

    .config(function config($stateProvider) {
        $stateProvider.state('private.campaigns', {
            url: '/campaigns',
            controller: 'CampaignsController',
            templateUrl: 'states/campaigns/campaigns.tpl.html',
            resolve: {
                campaigns: ['$q', 'Campaign', 'CampaignGrouping', function resolveCampaigns($q, Campaign, CampaignGrouping){
                    var d = $q.defer();

                    Campaign.query(function(data){
                        d.resolve(CampaignGrouping.group(data));
                    });

                    return d.promise;
                }]
            },
            data: {
                pageTitle: 'Campaigns'
            }
        })

        ;
    })

    .run(function (Navigation) {
        Navigation.primary.register("Campaigns", "private.campaigns");

        Navigation.sidebar.register('private.campaigns', 'Campaigns', [
            {
                Label: 'Campaigns',
                State: 'private.campaigns',
                Groups: ["admin", "marketing"]
            }
        ]);
    })

    .controller('CampaignsController', function CampaignsController($scope, Campaign, campaigns, CampaignGrouping){
        $scope.campaigns = campaigns;

        $scope.refresh = function refresh(){
            Campaign.query(function(data){
                $scope.campaigns = CampaignGrouping.group(data);
            });
        }
    })
;
