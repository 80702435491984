angular.module('Cockpit.Service.Order', ['ngResource'])
    .factory('Order', ['$resource',
        function($resource) {
            return $resource(Config.Api + 'orderapi/:action', {}, {
                get: { params: { action: 'Get' } },
                save: { method: 'POST', params: { action: 'Save' } },
                query: { params: { action: 'All' }, isArray: true },
                bulkSave: { method: 'POST', params: { action: 'BulkSave' } },
                queryAnalogShipped: { params: { action: 'AnalogShipped' }, isArray: true },
                orderStatuses: { params: { action: 'OrderStatuses' }, isArray: true }
            });
        }
    ]);
