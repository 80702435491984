var resolvers = window.resolvers || {};
resolvers.languagesResolver = ['$q', 'Language', function ($q, Language) {
  var d = $q.defer();

  Language.query(function (res) {
    d.resolve(res);
  }, function (err) {
    d.reject();
  });

  return d.promise;
}];
