angular.module('Cockpit.Service.TopbarGrouping', ['ngResource'])
    .factory('TopbarGrouping', [
        function () {

            return {
                group: function(topbars){
                    var ready = [];
                    var running = [];
                    var paused = [];
                    var ended = [];

                    for(var i = 0; i < topbars.length; i++){
                        var c = topbars[i];
                        var start = new Date(c.start);
                        var end = new Date(c.end);
                        var now = new Date();

                        // Running
                        if(c.isActive && start <= now && now < end) {
                            running.push(c);

                            // Ready
                        }else if(c.isActive && now < start){
                            ready.push(c);

                            // Paused
                        }else if(!c.isActive && now < end){
                            paused.push(c);

                            // Ended
                        }else{
                            ended.push(c);
                        }
                    }

                    return {
                        ready: ready,
                        running: running,
                        paused: paused,
                        ended: ended
                    };
                }
            }
        }
    ]);
