angular.module('Cockpit.State.Settings.CallOutTypes', [
  'ui.router',
  'Cockpit.Service.CallOutTypes',
  'Cockpit.State.Settings.CallOutTypes.AddEdit'
])

  .config(function config($stateProvider) {
    $stateProvider.state('private.settings.call-out-types', {
      url: 'call-out-types',
      controller: 'CallOutTypeSettingsController',
      templateUrl: 'states/settings/call-outs/list.tpl.html',
      data: {
        pageTitle: 'Call-out Type Settings'
      },
      resolve: {
        callOutTypes: resolvers.callOutTypesResolver
      }
    })
  })

  .run(function (Navigation) {
    Navigation.sidebar.register('private.settings', 'Call-Outs', [
      {
        Label: 'Types',
        State: 'private.settings.call-out-types',
        Groups: ['admin']
      }
    ])
  })

  .controller('CallOutTypeSettingsController', function CallOutTypeSettingsController($scope, callOutTypes, CallOutType, $sce) {
    $scope.sce = $sce;
    $scope.callOutTypes = callOutTypes;

    $scope.delete = function deleteType(type){
      type.$delete(function(){
        $scope.callOutTypes = CallOutType.query();
      });
    }
  })
