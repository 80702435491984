angular.module('Cockpit.State.Mademyday.Krifa', [
  'ui.router',
  'Cockpit.Service.Mademyday.Krifa'
])

.config(function config($stateProvider) {
	$stateProvider.state('private.settings.mmd-krifa', {
		url: '/krifa',
		views: {
			'@private':{
				controller: 'CockpitMademydayKrifaController',
				templateUrl: 'states/mademyday/settings/krifa/settings.tpl.html',
			}
		},
		data: {
			pageTitle: 'Krifa mademyday Settings'
		}
	})

	;
})

.run(function (Navigation) {
    Navigation.sidebar.register('private.settings', 'mademyday', [
        {
            Label: "Krifa",
            State: 'private.settings.mmd-krifa',
			Groups: ["admin"]
        }
    ]);
})

.controller('CockpitMademydayKrifaController', function CockpitMademydayKrifaController($scope, MmdKrifa) {
	$scope.settings = MmdKrifa.get();

	$scope.alert = {
		Type: '',
		Show: false,
		Message: ''
	};

	$scope.process = function ProcessSaveTexts() {
		$scope.alert.Show = false;
		
		$scope.settings.$save(function () {
			$scope.alert.Type = "success";
			$scope.alert.Message = "Your settings have been saved :-)";
			$scope.alert.Show = true;
		}, function () {
			$scope.alert.Type = "warning";
			$scope.alert.Message = "An error occured while saving your settings.";
			$scope.alert.Show = true;
		});
	};
})

;