angular.module('Cockpit.Service.AccountingGroups', ['ngResource'])
  .factory('AccountingGroups', ['$resource',
    function ($resource) {
      return $resource(Config.NewCockpitApiV1 + 'orders/accounting-groups', {}, {
        get: {
          method: 'GET',
          isArray: false
        }
      });
    }
  ]);
