angular.module('Cockpit.State.Settings', [
	'ui.router',
	'Cockpit.State.Settings.Geckoboard',
	'Cockpit.State.Settings.MailChimp',
  'Cockpit.State.Settings.Notifications.Templates',
  'Cockpit.State.Settings.Notifications',
  'Cockpit.State.Settings.FooterWidgets',
  'Cockpit.State.Settings.FooterWidget.AddEdit',
	'Cockpit.State.Settings.CallOutTypes',
])

.config(function config($stateProvider) {
	$stateProvider.state('private.settings', {
		url: '/settings/',
		controller: 'CockpitSettingsController',
		templateUrl: 'states/settings/settings.tpl.html',
		data: {
			pageTitle: 'Settings'
		}
	})

	;
})

.run(function(Navigation){
	Navigation.primary.register("Settings", "private.settings");
})

.controller('CockpitSettingsController', function CockpitSettingsController($scope) {})

;
