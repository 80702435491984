angular.module('Cockpit.Service.Mademyday.Export', ['ngResource'])
    .factory('MmdExport', ['$resource',
        function ($resource) {
            return $resource(Config.Api + 'exportapi/:action', {}, {
                query: { params: { action: 'Get' }, isArray: true },
                queryDeliveryDays: { params: { action: 'DeliveryDays' }, isArray: true },
                queryDelivery: { params: { action: 'Delivery' }, isArray: true },
                csvUrl: { params: { action: 'DeliveryCsv' } },
                pdfUrl: { params: { action: 'DeliveryPdf' } }
            });
        }]);
