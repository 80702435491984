angular.module('Cockpit.State.Notifications', [
    'Cockpit.Service.Navigation',
    'Cockpit.Service.Notification',
    'Cockpit.State.Notifications.Details',
    'Cockpit.Service.Notification.Templates'
  ])

    .config(function config($stateProvider) {
        $stateProvider.state('private.notifications', {
            url: '/notifications',
            controller: 'NotificationsController',
            templateUrl: 'states/notifications/notifications.tpl.html',
            resolve: {
                theLanguages: resolvers.languagesResolver,
            },
        })

        ;
    })

    .run(function (Navigation) {
        Navigation.primary.register("Notifications", "private.notifications");

        Navigation.sidebar.register('private.notifications', 'Notifications', [
            {
                Label: 'Notifications',
                State: 'private.notifications',
                Groups: ["admin"]
            }
        ]);
    })

    .controller('NotificationsController', function NotificationsController($scope, $filter, Notification, theLanguages, NotificationTemplates){
        $scope.languages = theLanguages;
        $scope.showPagination = true;
        $scope.request = {};
        $scope.totalFailedNotifications = 0;
        $scope.selectedNotificationTypes = []
        $scope.availableNotificationTypes = [];
        NotificationTemplates.available(function(res) {
          $scope.availableNotificationTypes = res;
        })
        $scope.selectedNotifications = [];
        $scope.hasError = false;

        // split into array of arrays for ng-repeat in multiple columns
        function chunk(templates, size) {
          var listOfTempaltes = [];
          for (var i=0; i<templates.length; i+=size) {
            listOfTempaltes.push(templates.slice(i, i+size));
          }
          return listOfTempaltes;
        }

        $scope.doSearch = function () {
          $scope.request.currentPage = 1;
          getNotifications()
        }

        $scope.reset = function () {
          $scope.request = {
            currentPage: 1,
            limit: '10'
          };
          $scope.selectedNotificationTypes = [];
          // reset checkboxes
          resetFiltersAndSearch();
          $scope.doSearch();
        }

        function resetFiltersAndSearch(){
          $scope.request = {
            currentPage: 1,
            limit: '10'
          };
          $scope.selectedNotificationTypes = [];
          $scope.availableNotificationTypes.forEach(function (type) {
            type.selected = false;
          })
        }

        $scope.filterNotifications = function(slug){
          var index = $scope.selectedNotificationTypes.findIndex(function (type) {
            return type.slug === slug;
          });
          if (index > -1) {
            $scope.selectedNotificationTypes.splice(index, 1);
          } else {
            const notificationType = $scope.availableNotificationTypes.find(function (t) {
              return t.slug === slug;
            })
            $scope.selectedNotificationTypes.push({
              slug: notificationType.slug,
              name: notificationType.name
            });
          }
          $scope.request.typeNames = $scope.selectedNotificationTypes.map(function(t) {
            return t.name;
          }).join(',');
          $scope.request.typeSlugs = $scope.selectedNotificationTypes.map(function(t) {
            return t.slug;
          }).join(',');
        }

        $scope.fetchFailed = function () {
          $scope.notificationsLoading = true;
          resetFiltersAndSearch();

          Notification.query(
            {
              excludeFailedCount: false,
              pageCount: 1,
            },
            function (data, headers) {
              $scope.totalFailedNotifications = headers("x-total-failed");

              Notification.getFailed(
                { failedCount: $scope.totalFailedNotifications },
                function (data) {
                  $scope.showPagination = false;
                  $scope.notifications = data;
                  $scope.notificationsLoading = false;
                },
                function (err) {
                  $scope.notificationsLoading = false;
                }
              );
            },
            function (err) {
              console.log("Error getting notifications => ", err);
              $scope.notificationsLoading = false;
            }
          );
        };

        $scope.updateSelectedNotifications = function(){
          $scope.selectedNotifications = $scope.notifications.filter(function(n) {
            return n.selected;
          })
        }

        $scope.resendNotifications = function (){

          if (!window.confirm('Do you want to resend ' + $scope.selectedNotifications.length + ' notifications?')) {
            return;
          }

          $scope.notificationsLoading = true;

          var selectedNotificationsIds = $scope.selectedNotifications.map(function(n){
            return n.id
          })

          Notification.resendNotifications({notificationIds: selectedNotificationsIds}, function (data) {
            if(data.errorMessage){
              $scope.infoMessage = data.errorMessage
              $scope.hasError = true;
            } else {
              $scope.infoMessage = "Success! Selected notifications have been resent!"
              $scope.hasError = false;
            }
            removeInfoMessage()
            $scope.notificationsLoading = false;
          }, function (err) {
            $scope.infoMessage = "Failed. Try again later or contact a developer.";
            $scope.hasError = true;
            $scope.notificationsLoading = false;
            removeInfoMessage();
          });
        }

        function removeInfoMessage() {
          setTimeout(function () {
            $scope.infoMessage = null;
          }, 5000);
        }


        $scope.confirmManualHandling = function (){
          if (!window.confirm('Are you sure the selected emails (' + $scope.selectedNotifications.length + ') have been handled?')) {
            return;
          }

          var selectedNotificationsIds = $scope.selectedNotifications.map(function(n){
            return n.id
          })

          Notification.update({notificationIds: selectedNotificationsIds}, function (data) {
            if(data.failedNotificationIds.length){
              $scope.infoMessage = "Failed. Try again later or contact a developer.";
              $scope.hasError = true;
            } else {
              $scope.infoMessage = "Success! Manual handling has been confirmed!"
              $scope.hasError = false;
            }
            $scope.notificationsLoading = false;
            removeInfoMessage()
          }, function (err) {
            $scope.hasError = true;
            $scope.infoMessage = "Failed. Try again later or contact a developer.";
            $scope.notificationsLoading = false;
            removeInfoMessage()
          });
        }

        $scope.clearSelectedNotifications = function() {
          $scope.notifications.forEach(function (n){
            n.selected = false;
          })
          $scope.selectedNotifications = [];
        }

        $scope.paginationArr = [];
        $scope.request.currentPage = 1;
        $scope.request.limit = '10';
        $scope.totalPages = 1;

        function getNotifications (){
          $scope.notificationsLoading = true;
          $scope.showPagination = true;

          var excludeFailedCount = shouldExcludeFailedNotifications();

          Notification.query({
            PageNumber: $scope.request.currentPage,
            NotificationTypes: $scope.request.typeNames,
            NotificationSlugs: $scope.request.typeSlugs,
            orderNumber: $scope.request.orderNumber,
            recipient: $scope.request.recipient,
            deliveryType: $scope.request.type,
            languageCode: $scope.request.languageCode,
            excludeFailedCount: true,
            pageCount: $scope.request.limit
          }, function (data, headers) {
            makePaginationArr(headers('x-total-count'));
            if (!shouldExcludeFailedNotifications()) {
              $scope.totalFailedNotifications = headers('x-total-failed');
            }

            $scope.notifications = data;
            $scope.notificationsLoading = false;
          }, function(err) {
            console.log("Error getting notifications => ", err);
            $scope.notificationsLoading = false;
          });
        }

        function shouldExcludeFailedNotifications() {
          return (
            !isNullOrEmpty($scope.request.types)
            || !isNullOrEmpty($scope.request.orderNumber)
            || !isNullOrEmpty($scope.request.recipient)
            || !isNullOrEmpty($scope.request.type)
            || !isNullOrEmpty($scope.request.languageCode)
          );
        }

        function isNullOrEmpty(input) {
          return input === null || angular.isUndefined(input) || input === '';
        }

        function makePaginationArr(totalNotifications){
          // Calculate total pages
          $scope.totalPages = Math.ceil(totalNotifications / 20);
          $scope.paginationArr = [];

          // First and last visible pages
          var firstVisiblePage, lastVisiblePage;
          if ($scope.request.currentPage <= 6) {
            firstVisiblePage = 1;
            lastVisiblePage = $scope.totalPages > 10 ? 10 : $scope.totalPages;
          } else if ($scope.request.currentPage + 4 >= $scope.totalPages) {
            firstVisiblePage = $scope.totalPages - 9 > 0 ? $scope.totalPages - 9 : 1;
            lastVisiblePage = $scope.totalPages;
          } else {
            firstVisiblePage = $scope.request.currentPage - 5;
            lastVisiblePage = $scope.request.currentPage + 4;
          }

          // Push the visible pages to the pagination array
          for (var i = firstVisiblePage; i <= lastVisiblePage; i++){
            $scope.paginationArr.push(i);
          }
        }

        // Initial load of notification
        getNotifications();

        $scope.doNewPage = function (number) {
          if(number == '...' || Number(number) < 1 || Number(number) > $scope.totalPages){
            return;
          }
          $scope.request.currentPage = number;
          $scope.paginationArr = [];
          getNotifications(number);
        }

        $scope.getTooltip = function(status){
          switch (status) {
            case "delivered": {
              return "Message has been received but not yet opened."
            }

            case "sent": {
              return "Message has been sent but not yet delivered."
            }

            case "opened": {
              return "Message has been opened by recipient."
            }

            case "resent": {
              return "Message has been resent but not yet delivered."
            }

            case "clicked": {
              return "The recipient has clicked on a link";
            }

            case "handled": {
              return "It has been manually handled";
            }

            default: {
              return "It was not possible to deliver the message.";
            }
          }
        }
    })
;
